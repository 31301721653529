import React, { FC } from 'react';
import { Query, GET_LIST } from 'react-admin';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { Resources } from 'Constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { ISearchCloneingUser } from 'Providers';
import { getMultiLanguageUserName } from 'Components/utils/getFullName';
import AsyncSelect from 'react-select/async';

const styles = {
  loading: {
    minHeight: '100px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputsWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginTop: '10px',
  },
  control: { width: '80%', marginRight: '20px' },
};

interface ICloneRoleProps {
  classes: { [key: string]: any };
  userId: number;
  currentSelectValue: any;
  onChangeSelect: (user: any) => void;
  onButtonClick: (targetUserId: number, sourceUserId: number) => void;
  onSelectSearch: (inputValue: string) => ISearchCloneingUser[];
}

const CloneRole: FC<ICloneRoleProps> = ({
  classes,
  userId,
  currentSelectValue,
  onChangeSelect,
  onButtonClick,
  onSelectSearch,
}) => (
  <Query type={GET_LIST} resource={Resources.USERS}>
    {({ data, loading, error }) => {
      if (loading) {
        return (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        );
      }
      if (error) {
        return <p>Ошибка получения списка пользователей</p>;
      }

      return (
        <>
          <Typography variant="title">Заполнить права на основании</Typography>
          <div className={classes.inputsWrapper}>
            <AsyncSelect
              loadOptions={onSelectSearch}
              className={classes.control}
              isClearable
              defaultOptions={data.filter(user => userId !== user.user_id)}
              getOptionLabel={option => getMultiLanguageUserName(option)}
              onChange={onChangeSelect}
              value={currentSelectValue}
            />
            <Button
              size="small"
              variant="contained"
              color="primary"
              disabled={!currentSelectValue}
              onClick={(): void => onButtonClick(userId, currentSelectValue.user_id)}
            >
              {'Заполнить права'}
            </Button>
          </div>
        </>
      );
    }}
  </Query>
);

export const CloneRoleComponent = withStyles(styles as any)(CloneRole);
