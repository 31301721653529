import React, { FC, useState } from 'react';
import { cloneRole, searchCloneingUser } from 'Providers';
import { debounce } from 'lodash';
import { CloneRoleComponent } from '../Components';

interface ICloneRole {
  userId: number;
  setProjectRefreshNeeded: React.Dispatch<React.SetStateAction<boolean>>;
  setObjectRefreshNeeded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CloneRole: FC<ICloneRole> = ({
  userId,
  setProjectRefreshNeeded,
  setObjectRefreshNeeded,
}) => {
  const [currentSelectValue, setCurrentSelectValue] = useState();

  const searchCloneingUserDebounce = debounce(
    (inputValue: string, callback: any) => callback(searchCloneingUser(inputValue)),
    300,
  );

  const onSelectSearch = async (inputValue: string) => {
    try {
      const response = new Promise(resolve => {
        searchCloneingUserDebounce(inputValue, resolve);
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

  const onChangeSelect = (user: any): void => {
    setCurrentSelectValue(user || null);
  };

  const onButtonClick = async (targetUserId: number, sourceUserId: number): Promise<void> => {
    await cloneRole(targetUserId, sourceUserId);
    setCurrentSelectValue(null);
    setProjectRefreshNeeded(prevState => !prevState);
    setObjectRefreshNeeded(prevState => !prevState);
  };

  return (
    <CloneRoleComponent
      {...{
        userId,
        currentSelectValue,
        onChangeSelect,
        onButtonClick,
        onSelectSearch,
      }}
    />
  );
};
