import React, { FC } from 'react';
import { Create, SimpleForm, TextInput, LongTextInput } from 'react-admin';
import { Card, CardContent } from '@material-ui/core';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Resources } from 'Constants';

const styles = {
  card: { width: '100%', marginBottom: '1.5rem', marginTop: '1.5rem' },
  cardContent: { position: 'relative' },
  list: { width: '100%', display: 'none' },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 1000,
  },
};

const ScheduleTemplateCreateComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => (
  <Create title="Новый шаблон модели" {...props}>
    <SimpleForm redirect="list">
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <IconButton
            onClick={() => propsPush(`/${Resources.SCHEDULE_TEMPLATE}`)}
            className={classes.closeBtn}
          >
            <CloseIcon />
          </IconButton>
          <TextInput fullWidth required source="code" label="Код" />
          <TextInput fullWidth required source="name" label="Название" />
          <LongTextInput fullWidth source="description" label="Описание" defaultValue="" />
        </CardContent>
      </Card>
    </SimpleForm>
  </Create>
);

const connectedScheduleTemplateCreate = connect(null, { push })(ScheduleTemplateCreateComponent);
export const ScheduleTemplateCreate = withStyles(styles as any)(connectedScheduleTemplateCreate);
