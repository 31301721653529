export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';

export const downloadExcelAction = (property, date, settings) => ({
  type: `CUSTOM/${DOWNLOAD_FILE}`,
  payload: { ...settings.report[property], date },
  meta: {
    fetch: DOWNLOAD_FILE,
    resource: 'orgs',
    onFailure: { notification: { body: 'Ошибка при загрузке xls-файла', level: 'warning' } },
  },
});
