/* eslint-disable @typescript-eslint/camelcase */

export const abbreviation = {
  JobUnit: {
    none: {
      shortName: 'Нет',
      longName: 'Нет',
    },
    piece: {
      shortName: 'шт.',
      longName: 'Штука',
    },
    meter: {
      shortName: 'м',
      longName: 'Метр',
    },
    cubic_meter: {
      shortName: 'м.куб.',
      longName: 'Кубический метр.',
    },
    square_meter: {
      shortName: 'м.кв.',
      longName: 'Квадратный метр',
    },
    linear_meter: {
      shortName: 'м.пог.',
      longName: 'Погонный метр',
    },
    percent: {
      shortName: '%',
      longName: 'Процент',
    },
    ton: {
      shortName: 'т.',
      longName: 'Тонна',
    },
    centimeter: {
      shortName: 'см',
      longName: 'Сантиметр',
    },
    millimeter: {
      shortName: 'мм',
      longName: 'Миллиметр',
    },
    square_centimeter: {
      shortName: 'см2',
      longName: 'Сантиметр квадратный',
    },
    cubic_centimeter: {
      shortName: 'см3',
      longName: 'Сантиметр кубический',
    },
    liter: {
      shortName: 'л',
      longName: 'Литр',
    },
    milliliter: {
      shortName: 'мл',
      longName: 'Миллилитр',
    },
    gram: {
      shortName: 'г',
      longName: 'Грамм',
    },
    kilogram_per_cubic_meter: {
      shortName: 'кг/м3',
      longName: 'Килограмм / метр кубический',
    },
    gram_per_cubic_centimeter: {
      shortName: 'г/см3',
      longName: 'Грамм / cантиметр кубический',
    },
    megapascal: {
      shortName: 'МПа',
      longName: 'Мегапаскаль',
    },
    pascal: {
      shortName: 'Па',
      longName: 'Паскаль',
    },
    kilometer: {
      shortName: 'км',
      longName: 'Километр',
    },
    hectare: {
      shortName: 'га',
      longName: 'Гектар',
    },
    ar: {
      shortName: 'а',
      longName: 'Ар',
    },
    kilogram: {
      shortName: 'кг',
      longName: 'Килограмм',
    },
    kit: {
      shortName: 'компл',
      longName: 'комплект',
    },
  },
  GroupUnit: {
    space: 'Пространственные элементы',
    site: 'Стройплощадка',
    construction: 'Конструктивные элементы',
    system: 'Инженерные системы',
  },
  CalculationMethod: {
    arithmetic_mean: 'Среднеарифметическое',
    weighting_factor: 'С использованием весового коэффициента',
    expenditure_weighting_factor: 'С использованием финансового весового коэффициента',
    basic_expenditure_weighting_factor: 'С использованием базисного весового коэффициента',
  },
  TypeOfDirectory: {
    common: 'Общий',
    ksi: '5-КТ КСИ',
  },
  ScheduleDeviationDayMethod: {
    plan: 'По плановому темпу',
    date_finish: 'По дате завершения',
  },
  BusinessRelation: {
    ks2: 'KC-2',
    ks3: 'KC-3',
  },
  CostPlanFactSource: {
    ks2: 'Утвержденные акты КС-2',
    schedule_fact_correction: 'Утвержденные акты по финансам',
  },
  ResourceFactMethodPath: {
    schedule: 'В разрезе видов работ',
    object: 'Сводно по объекту',
  },
};
