import React, { FC } from 'react';
import { Datagrid, TextField, List } from 'react-admin';
import { scheduleTemplateIdField } from 'Constants';

export const ScheduleTemplateList: FC = props => (
  <List {...props} title="Шаблоны модели" exporter={false}>
    <Datagrid rowClick="edit">
      <TextField source={scheduleTemplateIdField} label="Id" />
      <TextField source="code" label="Код" />
      <TextField source="name" label="Название" />
      <TextField source="description" label="Описание" />
    </Datagrid>
  </List>
);
