import React, { FC } from 'react';
import {
  Edit,
  TabbedForm,
  TextInput,
  FormTab,
  DisabledInput,
  LongTextInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  DeleteButton,
} from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Card, CardContent, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  referencePassportFieldElementIdField,
  referencePassportFieldElementPrefix,
  referencePassportFieldIdField,
  referencePassportFieldPrefix,
  Resources,
} from 'Constants';
import { Link } from 'react-router-dom';
import { Add } from '@material-ui/icons';
import { keyRegex } from '../../../Utils/stringMethods';

const styles = {
  card: { width: '100%', marginBottom: '1.5rem', marginTop: '1.5rem' },
  cardContent: { position: 'relative' },
  list: { width: '100%', display: 'none' },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 1000,
  },
  textRight: { textAlight: 'right' },
  badge: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    display: 'inline-flex',
    padding: '.025rem .5rem',
    borderRadius: '1rem',
    marginLeft: '1rem',
  },
};

const RenderTitle = ({ record }: any) => <span>{record.name}</span>;

const AddElementButton = ({ record }: any) => {
  const AddElementLink = (props: any) => (
    <Link
      to={`/${Resources.PASSPORT_FIELD_ELEMENT}/create?${referencePassportFieldPrefix}=${record.id}`}
      {...props}
    />
  );

  return (
    <Button component={AddElementLink} variant="text" color="primary" size="small">
      <Add />
      &nbsp;New element
    </Button>
  );
};

const PassportFieldEditComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => {
  const { id } = props;
  return (
    <Edit title={<RenderTitle />} {...props}>
      <TabbedForm redirect="list">
        <FormTab label="Общая информация">
          <Card className={classes.card}>
            <CardContent className={classes.сardContent}>
              <DisabledInput fullWidth required source={referencePassportFieldIdField} label="Id" />
              <IconButton
                onClick={() => propsPush(`/${Resources.PASSPORT_FIELD}`)}
                className={classes.closeBtn}
              >
                <CloseIcon />
              </IconButton>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent className={classes.сardContent}>
              <TextInput fullWidth required source="code" label="Код" validate={keyRegex()} />
              <TextInput fullWidth required source="name" label="Название" />
              <LongTextInput fullWidth source="description" label="Описание" defaultValue="" />
            </CardContent>
          </Card>
        </FormTab>
        <FormTab label="Элементы">
          <ReferenceManyField
            resource={Resources.PASSPORT_FIELD_ELEMENT}
            reference={Resources.PASSPORT_FIELD_ELEMENT}
            target={referencePassportFieldElementPrefix}
            label=""
            fullWidth
          >
            <Datagrid rowClick="edit">
              <TextField source={referencePassportFieldElementIdField} label="Id" />
              <TextField source="value" label="Значение" />
              <DeleteButton redirect={`/${Resources.PASSPORT_FIELD}/${id}/1`} />
            </Datagrid>
          </ReferenceManyField>
          <AddElementButton />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const connectedPassportFieldEdit = connect(null, { push })(PassportFieldEditComponent);
export const PassportFieldEdit = withStyles(styles as any)(connectedPassportFieldEdit);
