import React, { FC } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
  Edit,
  TabbedForm,
  FormTab,
  DisabledInput,
  TextInput,
  ReferenceField,
  LongTextInput,
  ReferenceManyField,
  TextField,
  Datagrid,
  EmailField,
  EditButton,
  FunctionField,
  DateField,
  FileInput,
  FileField,
  BooleanInput,
  SelectInput,
  NumberInput,
  minValue,
  ReferenceInput,
  AutocompleteArrayInput,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  organisationPrefix,
  organisationIdField,
  userIdField,
  Resources,
  projectIdField,
  referenceProjectTypePrefix,
  orgNameLabel,
  userNameLabel,
  projectLabel,
  gunttDefaultDeviationType,
  referenceCurrencyPrefix,
} from 'Constants';
import Typography from '@material-ui/core/Typography';
import { getFullUserName, getMultiLanguageName } from 'Components/utils/getFullName';
import { FileDownload } from 'Components/Common/FileDownload';
import { downloadExcelAction } from 'Providers/utils/customActions';
import { get } from 'lodash';
import { OrganisationSettings } from './OrganisationSettings';
import { emailRegex } from '../../../Utils/stringMethods';

const styles = {
  card: { width: '100%', marginBottom: '30px', overflow: 'visible' },
  сardContent: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    justifyContent: 'space-between',
  },
  btn: { position: 'absolute', right: '0', top: '0' },
  innerDataGrid: { width: '100%' },
  formTab: { marginBottom: '20px' },
};

const RenderTitle = ({ record }: any) => <span>{getMultiLanguageName(record)}</span>;

const FullNameField = ({ record = {} }) => <span>{getMultiLanguageName(record)}</span>;

const OrganisationEditComponent: FC = ({
  push: propsPush,
  classes = {},
  settings,
  downloadExcel,
  ...props
}: any) => (
  <Edit title={<RenderTitle />} {...props}>
    <TabbedForm redirect="list">
      <FormTab label="Общая информация">
        <Card className={classes.card}>
          <CardContent className={classes.сardContent}>
            <DisabledInput source={organisationIdField} label="Id" fullWidth />
            <IconButton onClick={() => propsPush(`/${Resources.ORGS}`)} className={classes.btn}>
              <CloseIcon />
            </IconButton>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent className={classes.сardContent}>
            <TextInput source="name_ru" label={orgNameLabel.name_ru} fullWidth required />
            <TextInput source="name_en" label={orgNameLabel.name_en} fullWidth />
            <LongTextInput source="description_ru" label={orgNameLabel.description_ru} fullWidth />
            <LongTextInput source="description_en" label={orgNameLabel.description_en} fullWidth />
            <LongTextInput source="meta" label="Мета-информация" fullWidth />
            <TextInput
              source="support_email"
              label="E-mail тех.поддержки"
              fullWidth
              validate={emailRegex()}
            />
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent className={classes.сardContent}>
            <FileDownload
              label="Выгрузка данных в разрезе объекта"
              onClick={(date: Date): Promise<void> =>
                downloadExcel('suek_excel_object', date, settings)
              }
              disabled={!get(settings, 'report.suek_excel_object')}
            />
            <FileDownload
              label="Выгрузка по записям факта в разрезе вида работ"
              onClick={(date: Date): Promise<void> =>
                downloadExcel('suek_excel_fact', date, settings)
              }
              disabled={!get(settings, 'report.suek_excel_fact')}
            />
            <FileDownload
              label="Выгрузка отчета о готовности объектов"
              onClick={(date: Date): Promise<void> =>
                downloadExcel('suek_excel_object_readiness', date, settings)
              }
              disabled={!get(settings, 'report.suek_excel_object_readiness')}
            />
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent className={classes.сardContent}>
            <FileInput source="files" label="Выберите файл Dashboard" accept=".mrt">
              <FileField source="src" title="title" />
            </FileInput>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="caption">Настройки индикаторов организации</Typography>
            <div>
              <BooleanInput
                source="settings.status_indicator_foreman_visible"
                label="Сценарий Прораб"
              />
              <BooleanInput
                source="settings.status_indicator_revisor_visible"
                label="Сценарий Контролер"
              />
            </div>
            <SelectInput
              fullWidth
              source="settings.guntt_default_deviation_type"
              label="Значение показателя в сводном отчете по умолчанию"
              choices={gunttDefaultDeviationType}
              optionText="name"
              optionValue="value"
            />
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="caption">Настройки модуля задач</Typography>
            <NumberInput
              fullWidth
              source="settings.task_time_status_expires_days"
              label="Количество дней до планового срока исполнения задачи, при котором отображать статус «Истекает»"
              defaultValue={3}
              step={1}
              validate={[minValue(0, 'Значение не может быть меньше 0')]}
            />
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="caption">Валюта организации</Typography>
            <ReferenceInput
              fullWidth
              source={referenceCurrencyPrefix}
              resource={Resources.REFERENCE_CURRENCY}
              reference={Resources.REFERENCE_CURRENCY}
              label="Выберите валюту организации"
            >
              <SelectInput optionText={record => record.name} />
            </ReferenceInput>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="caption">Сервисы</Typography>
            <AutocompleteArrayInput
              source={'parsedOrgServicesIds'}
              choices={props.services}
              label="Выберите сервисы для организации"
              fullWidth
            />
          </CardContent>
        </Card>
      </FormTab>

      <FormTab label="Пользователи" className={classes.formTab}>
        <ReferenceManyField
          resource={Resources.USERS}
          reference={Resources.USERS}
          target={organisationPrefix}
          label=""
          className={classes.innerDataGrid}
        >
          <Datagrid>
            <TextField source={userIdField} label="Id" />
            <FunctionField
              render={user => getFullUserName(user, 'ru')}
              label={userNameLabel.fullname_ru}
            />
            <FunctionField
              render={user => getFullUserName(user, 'en')}
              label={userNameLabel.fullname_en}
            />
            <DateField source="birthdate" label="Дата изменения учетной записи" />
            <EmailField source="email" label="E-mail" />
            <TextField source="phone" label="Телефон" />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      <FormTab label="Проекты" className={classes.formTab}>
        <ReferenceManyField
          resource={Resources.PROJECTS}
          reference={Resources.PROJECTS}
          target={organisationPrefix}
          label=""
          className={classes.innerDataGrid}
        >
          <Datagrid>
            <TextField source={projectIdField} label="Id" />
            <TextField source="name_ru" label={projectLabel.name_ru} />
            <TextField source="name_en" label={projectLabel.name_en} />
            <ReferenceField
              source={referenceProjectTypePrefix}
              resource={Resources.REFERENCE_PROJECT_TYPE}
              reference={Resources.REFERENCE_PROJECT_TYPE}
              label="Тип проекта"
            >
              <FullNameField />
            </ReferenceField>
            <TextField source="description_ru" label={projectLabel.description_ru} />
            <TextField source="description_en" label={projectLabel.description_en} />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      <FormTab label="Настройки" className={classes.formTab}>
        <OrganisationSettings source="settings.translate" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

const mapStateToProps = state =>
  state.form && state.form['record-form'] && state.form['record-form'].values;

const connectedOrganisationEdit = connect(mapStateToProps, {
  downloadExcel: downloadExcelAction,
  push,
})(OrganisationEditComponent);
export const OrganisationEdit = withStyles(styles as any)(connectedOrganisationEdit);
