export const API_URL = process.env.API_URL!;
export const DATE_SERVER_MASK = 'YYYY-MM-DD';
export const PICKER_DATE_MASK = 'DD.MM.YYYY';
export const APP_AUTH_PASSWORD_PATTERN = process.env.APP_AUTH_PASSWORD_PATTERN!;

export enum VALIDATE_ERRORS {
  REQUIRED = 'Поле является обязательным для заполнения',
  ALIAS_REGEX = 'Код должен состоять из строчных латинских букв и цифр, разделенных . или _',
  UNIQUE = 'Значение должно быть уникальным',
  PASSWORD_REGEX = 'Пароль должен быть длиной не менее 8 символов, содержать цифры, латинские символы верхнего и нижнего регистров, а также специальные символы',
  KEY_REGEX = 'Код должен быть длиной от 2 до 40 символов, состоять из строчных латинских букв и цифр, разделенных знаком _',
  NO_SPACE_REGEX = 'Поле не должно содержать пробельных символов',
  EMAIL_REGEX = 'Введите корректный email адрес',
}
