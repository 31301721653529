/* eslint-disable @typescript-eslint/camelcase */

import axios from 'axios';
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_ONE,
  GET_MANY_REFERENCE,
  GET_MANY,
  UPDATE,
} from 'react-admin';
import {
  API_URL,
  REST,
  rolePrefix,
  roleIdField,
  approvalPrefix,
  approvalIdField,
  approvalStepDataPath,
  approvalStepPrefix,
  approvalStepIdField,
  approvalStepNextIdField,
} from 'Constants';
import { get } from 'lodash';
import {
  PgUnAssoc,
  removePrefix,
  removePrefixInArray,
  setPrefix,
  setPrefixInArray,
} from '../utils';
import {
  IRAGetListParams,
  IRAGetManyParams,
  IRAGetOneParams,
  IRAUpdateParams,
} from '../../Interfaces';

export const approvalStepProvider = async (type: string, params: any): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const { filter } = params as IRAGetListParams;
        const { id } = filter;
        if (id) {
          const clearId = removePrefix(approvalPrefix, id);
          const response = await axios.post(`${API_URL}${REST.AdminApprovalStep.LIST}`, {
            [approvalIdField]: Number(clearId),
          });
          const parsedApprovalStep = PgUnAssoc(get(response, approvalStepDataPath));
          const parsedApprovalStepWithId = setPrefixInArray(
            parsedApprovalStep,
            approvalStepPrefix,
            approvalStepIdField,
          ).map(el => ({
            ...el,
            [rolePrefix]: setPrefix(rolePrefix, el[roleIdField]),
            [approvalPrefix]: setPrefix(approvalPrefix, el[approvalIdField]),
            [approvalStepPrefix]: setPrefix(approvalStepPrefix, el[approvalStepIdField]),
            [approvalStepNextIdField]:
              setPrefix(approvalStepPrefix, el[approvalStepNextIdField]) || null,
          }));
          return {
            data: parsedApprovalStepWithId,
            total: parsedApprovalStepWithId.length,
          };
        }
        return { data: [], total: 0 };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(approvalStepPrefix, id);

        const response = await axios.post(`${API_URL}${REST.AdminApprovalStep.LIST}`, {
          [approvalStepIdField]: [Number(clearId)],
        });
        const parsedApprovalStep = PgUnAssoc(get(response, approvalStepDataPath))[0];

        return {
          data: {
            ...parsedApprovalStep,
            id: setPrefix(approvalStepPrefix, parsedApprovalStep[approvalStepIdField]),
            [rolePrefix]: setPrefix(rolePrefix, parsedApprovalStep[roleIdField]),
            [approvalPrefix]: setPrefix(approvalPrefix, parsedApprovalStep[approvalIdField]),
            [approvalStepPrefix]: setPrefix(
              approvalStepPrefix,
              parsedApprovalStep[approvalStepIdField],
            ),
            [approvalStepNextIdField]:
              setPrefix(approvalStepPrefix, parsedApprovalStep[approvalStepNextIdField]) || null,
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const clearIds = removePrefixInArray(ids, approvalStepPrefix).filter(el => el !== null);
        if (ids.length > 0) {
          const requestBody = {
            [approvalStepIdField]: clearIds,
          };
          const response = await axios.post(
            `${API_URL}${REST.AdminApprovalStep.LIST}`,
            requestBody,
          );
          const parsedApprovalStep = PgUnAssoc(get(response, approvalStepDataPath));
          const parsedApprovalStepWithId = parsedApprovalStep.map(el => ({
            ...el,
            id: setPrefix(approvalStepPrefix, el[approvalStepIdField]),
            [approvalPrefix]: setPrefix(approvalPrefix, el[approvalIdField]),
            [approvalStepPrefix]: setPrefix(approvalStepPrefix, el[approvalStepIdField]),
            // eslint-disable-next-line max-len
            [approvalStepNextIdField]: el[approvalStepNextIdField]
              ? setPrefix(approvalStepPrefix, el[approvalStepNextIdField])
              : null,
          }));
          return { data: parsedApprovalStepWithId };
        }
        return { data: [] };
      }
      case GET_MANY_REFERENCE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(approvalPrefix, id);
        const response = await axios.post(`${API_URL}${REST.AdminApprovalStep.LIST}`, {
          [approvalIdField]: Number(clearId),
        });
        const parsedApprovalStep = PgUnAssoc(get(response, approvalStepDataPath));
        const parsedApprovalStepWithId = setPrefixInArray(
          parsedApprovalStep,
          approvalStepPrefix,
          approvalStepIdField,
        ).map(el => ({
          ...el,
          [approvalPrefix]: setPrefix(approvalPrefix, el[approvalIdField]),
          [approvalStepPrefix]: setPrefix(approvalStepPrefix, el[approvalStepIdField]),
          // eslint-disable-next-line max-len
          [approvalStepNextIdField]: el[approvalStepNextIdField]
            ? setPrefix(approvalStepPrefix, el[approvalStepNextIdField])
            : null,
        }));
        return {
          data: parsedApprovalStepWithId,
          total: parsedApprovalStepWithId.length,
        };
      }
      case CREATE: {
        const rawData = params.data;
        const roleId = removePrefix(rolePrefix, rawData[rolePrefix]);
        const approvalId = removePrefix(approvalPrefix, rawData[approvalPrefix]);

        const nextId = removePrefix(approvalStepPrefix, rawData[approvalStepNextIdField]) || null;

        const sendData = {
          approval_id: approvalId,
          role_id: roleId,
          title: rawData.title,
          expiration: rawData.expiration,
          acceptance_count: rawData.acceptance_count,
          [approvalStepNextIdField]: nextId,
        };

        const response = await axios.post(`${API_URL}${REST.AdminApprovalStep.CREATE}`, sendData);

        const newApprovalStep = get(response, approvalStepDataPath);

        return {
          data: {
            ...newApprovalStep,
            // eslint-disable-next-line max-len
            id: setPrefix(approvalStepPrefix, newApprovalStep[approvalStepIdField]),
            [approvalPrefix]: setPrefix(approvalPrefix, newApprovalStep[approvalIdField]),
            [approvalStepNextIdField]:
              setPrefix(approvalStepPrefix, newApprovalStep[approvalStepNextIdField]) || null,
          },
        };
      }
      case UPDATE: {
        const { data } = params as IRAUpdateParams;
        const {
          id,
          initial,
          [rolePrefix]: roleId,
          [approvalStepNextIdField]: nextId,
          [approvalStepPrefix]: stepId,
          [approvalPrefix]: processId,
          ...sendData
        } = data as any;

        const response = await axios.post(`${API_URL}${REST.AdminApprovalStep.UPDATE}`, {
          ...sendData,
          [roleIdField]: removePrefix(rolePrefix, roleId),
          [approvalStepNextIdField]: removePrefix(approvalStepPrefix, nextId) || null,
        });

        const parsedObject = get(response, approvalStepDataPath);

        if (initial) {
          const result = await axios.post(`${API_URL}${REST.AdminApprovalStep.SET}`, {
            [approvalStepIdField]: sendData[approvalStepIdField],
          });
          const parsedResult = get(result, approvalStepDataPath);
          parsedObject.initial = parsedResult.initial;
        }

        return {
          data: {
            ...parsedObject,
            id: setPrefix(approvalStepPrefix, parsedObject[approvalStepIdField]),
            [rolePrefix]: setPrefix(rolePrefix, parsedObject[roleIdField]),
          },
        };
      }
      case DELETE: {
        try {
          const { id, previousData } = params;
          const clearId = removePrefix(approvalStepPrefix, id);
          await axios.post(`${API_URL}${REST.AdminApprovalStep.DELETE}`, {
            [approvalStepIdField]: [clearId],
          });
          return { data: previousData };
        } catch (error) {
          throw new Error(`${error.response.data.error.message}`);
        }
      }
      case DELETE_MANY: {
        try {
          const { ids } = params;
          const clearIds = removePrefixInArray(ids, approvalPrefix);
          await axios.post(`${API_URL}${REST.AdminApproval.DELETE}`, {
            [approvalIdField]: clearIds,
          });
          return { data: ids };
        } catch (error) {
          throw new Error(`${error.response.data.error.message}`);
        }
      }
      default: {
        throw new Error(`Неизвестный метод получения ${approvalStepPrefix}: ${type}`);
      }
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${approvalStepPrefix}-${type}`);
  }
};
