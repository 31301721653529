/* eslint-disable @typescript-eslint/camelcase */
import { IRAGetListParams } from 'Interfaces/RAproviders/common';
import { organisationPrefix } from '../../Constants';
import { removePrefix } from './idPrefixes';

interface IResultQuery {
  filter: {
    [key: string]: string;
  };
  sort: {
    [key: string]: 1 | -1;
  };
}

const filterToAPI = {
  name_ru: 'name_ru',
  name_en: 'name_en',
  organisation_id: 'organisation_id',
};

const sortValueToAPI = {
  ASC: 1,
  DESC: -1,
};

const sortFieldToAPI = {
  project: {
    created: 'created',
    project_id: 'project_id',
    RAorganisationId: 'organisation_id',
    name_ru: 'name_ru',
    name_en: 'name_en',
    region_id: 'region_id',
    id: 'project_id',
  },
  object: {
    created: 'created',
    object_id: 'object_id',
    RAprojectId: 'project_id',
    name_ru: 'name_ru',
    name_en: 'name_en',
    region_id: 'region_id',
    id: 'object_id',
  },
};

export const parseRAParamsToQuery = (params: IRAGetListParams, type: string): IResultQuery => {
  const { filter, sort } = params;
  const resultFilter = Object.entries(filter).reduce((acc, [key, value]) => {
    const prefix = value[0].split('-')[0];
    if (prefix === organisationPrefix) {
      const ids = (value as Array<string>).map(el => removePrefix(organisationPrefix, el));
      acc[filterToAPI[key]] = ids;
    } else {
      acc[filterToAPI[key]] = value;
    }
    return acc;
  }, {});
  const resultSort = Object.keys(sortFieldToAPI[type]).includes(sort.field)
    ? { [sortFieldToAPI[type][sort.field]]: sortValueToAPI[sort.order] }
    : {};
  return { filter: resultFilter, sort: resultSort };
};
