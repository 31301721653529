/* eslint-disable @typescript-eslint/camelcase */
import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import { Resources } from 'Constants';
import { get } from 'lodash';
import React, { FC } from 'react';
import { addField } from 'react-admin';
import { connect } from 'react-redux';

interface IOrganisationSettingsProps {
  source: string;
}

const styles = {
  root: { marginTop: '20px' },
  groupWrapper: { display: 'flex' },
  groupItem: { marginRight: '20px' },
  title: { margin: '10px 25px' },
};

const OrganisationSettingsComponent: FC<IOrganisationSettingsProps> = addField(
  ({ classes, input, record }: any) => {
    const { value, onChange } = input;

    const {
      settings: { translate },
    } = record;

    const onChangeHandler = (e, fieldKey): void => {
      e.preventDefault();
      const newData = {
        menu_object_name_ru: translate?.menu_object_name_ru || 'Объекты',
        menu_object_name_en: translate?.menu_object_name_en || 'Objects',
        menu_project_name_ru: translate?.menu_project_name_ru || 'Проекты',
        menu_project_name_en: translate?.menu_object_name_ru || 'Projects',
        status_indicator_foreman_name_ru: translate?.status_indicator_foreman_name_ru || 'Прораб',
        status_indicator_foreman_name_en: translate?.status_indicator_foreman_name_en || 'Foreman',
        status_indicator_revisor_name_ru:
          translate?.status_indicator_revisor_name_ru || 'Контроллер',
        status_indicator_revisor_name_en: translate?.status_indicator_revisor_name_en || 'Revisor',
        ...value,
        [fieldKey]: e.target.value,
      };
      onChange(newData);
    };

    return (
      <>
        <Card className={classes.root}>
          <Typography variant="caption" className={classes.title}>
            Наименование разделов меню навигации (в скобках указано для какой версии, русской или
            англоязычной будет применено наименование)
          </Typography>
          <CardContent className={classes.сardContent}>
            <div className={classes.groupWrapper}>
              <TextField
                defaultValue="Раздел 'Объекты' (Рус)"
                disabled
                fullWidth
                className={classes.groupItem}
              />
              <TextField
                defaultValue={translate?.menu_object_name_ru || 'Объекты'}
                onChange={e => onChangeHandler(e, 'menu_object_name_ru')}
                fullWidth
              />
            </div>
          </CardContent>
          <CardContent className={classes.сardContent}>
            <div className={classes.groupWrapper}>
              <TextField
                defaultValue="Раздел 'Объекты' (Eng)"
                disabled
                fullWidth
                className={classes.groupItem}
              />
              <TextField
                defaultValue={translate?.menu_object_name_en || 'Objects'}
                onChange={e => onChangeHandler(e, 'menu_object_name_en')}
                fullWidth
              />
            </div>
          </CardContent>
          <CardContent className={classes.сardContent}>
            <div className={classes.groupWrapper}>
              <TextField
                defaultValue="Раздел 'Проекты' (Рус)"
                disabled
                fullWidth
                className={classes.groupItem}
              />
              <TextField
                defaultValue={translate?.menu_project_name_ru || 'Проекты'}
                onChange={e => onChangeHandler(e, 'menu_project_name_ru')}
                fullWidth
              />
            </div>
          </CardContent>
          <CardContent className={classes.сardContent}>
            <div className={classes.groupWrapper}>
              <TextField
                defaultValue="Раздел 'Проекты' (Eng)"
                disabled
                fullWidth
                className={classes.groupItem}
              />
              <TextField
                defaultValue={translate?.menu_project_name_en || 'Projects'}
                onChange={e => onChangeHandler(e, 'menu_project_name_en')}
                fullWidth
              />
            </div>
          </CardContent>
        </Card>
        <Card className={classes.root}>
          <Typography variant="caption" className={classes.title}>
            Наименование индикаторов
          </Typography>
          <CardContent className={classes.сardContent}>
            <div className={classes.groupWrapper}>
              <TextField
                defaultValue="Индикатор 'Прораб' (Рус)"
                disabled
                fullWidth
                className={classes.groupItem}
              />
              <TextField
                defaultValue={translate?.status_indicator_foreman_name_ru || 'Прораб'}
                onChange={e => onChangeHandler(e, 'status_indicator_foreman_name_ru')}
                fullWidth
              />
            </div>
          </CardContent>
          <CardContent className={classes.сardContent}>
            <div className={classes.groupWrapper}>
              <TextField
                defaultValue="Индикатор 'Прораб' (Eng)"
                disabled
                fullWidth
                className={classes.groupItem}
              />
              <TextField
                defaultValue={translate?.status_indicator_foreman_name_en || 'Foreman'}
                onChange={e => onChangeHandler(e, 'status_indicator_foreman_name_en')}
                fullWidth
              />
            </div>
          </CardContent>
          <CardContent className={classes.сardContent}>
            <div className={classes.groupWrapper}>
              <TextField
                defaultValue="Индикатор 'Контроллер' (Рус)"
                disabled
                fullWidth
                className={classes.groupItem}
              />
              <TextField
                defaultValue={translate?.status_indicator_revisor_name_ru || 'Контроллер'}
                onChange={e => onChangeHandler(e, 'status_indicator_revisor_name_ru')}
                fullWidth
              />
            </div>
          </CardContent>
          <CardContent className={classes.сardContent}>
            <div className={classes.groupWrapper}>
              <TextField
                defaultValue="Индикатор 'Контроллер' (Eng)"
                disabled
                fullWidth
                className={classes.groupItem}
              />
              <TextField
                defaultValue={translate?.status_indicator_revisor_name_en || 'Revisor'}
                onChange={e => onChangeHandler(e, 'status_indicator_revisor_name_en')}
                fullWidth
              />
            </div>
          </CardContent>
        </Card>
      </>
    );
  },
);

const mapStateToProps = state => ({
  reduxItemType: {
    project: get(state, `admin.resources.${Resources.REFERENCE_PROJECT_TYPE}.data`),
    object: get(state, `admin.resources.${Resources.REFERENCE_OBJECT_TYPE}.data`),
  },
});

export const OrganisationSettings = connect(mapStateToProps)(
  withStyles(styles)(OrganisationSettingsComponent),
);
