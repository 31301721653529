import React, { FC, useState } from 'react';
import { Query, GET_LIST } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import { Resources, searchPanelConfig, RolesEntities } from 'Constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getMultiLanguageUserName } from 'Components/utils/getFullName';
import AsyncSelect from 'react-select/async';
import { ProjectsRoles } from './ProjectsRoles';
import { ObjectsRoles } from './ObjectsRoles';
import { CloneRole } from './CloneRole';

const styles = {
  root: {
    width: '100%',
    marginTop: '20px',
    backgroundColor: '#eee',
    overflow: 'visible',
  },
  сardContent: { display: 'flex', flexWrap: 'wrap', position: 'relative' },
  loading: {
    minHeight: '300px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

interface IRolesProps {
  classes: any;
  userId: number;
  record: any;
}

const CommonRoles: FC<IRolesProps> = ({ classes, userId, record }) => {
  const fullName = getMultiLanguageUserName(record);
  const [modalProps, setModalProps] = useState({ show: false });
  const [objectRefreshNeeded, setObjectRefreshNeeded] = useState(false);
  const [projectRefreshNeeded, setProjectRefreshNeeded] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState([]);

  return (
    <Query type={GET_LIST} resource={Resources.ROLE}>
      {({ data, loading, error }) => {
        if (loading) {
          return (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          );
        }
        if (error) {
          return <p>Ошибка получения списка всех ролей в системе</p>;
        }
        const projectRoles = data
          ? data.filter(el => el.alias.startsWith(RolesEntities.project))
          : [];

        const objectRoles = data
          ? /**
             * Строка, закомментированая ниже фильтрует роли для объекта,
             * ключ которого начинается только с алиаса "object"
             * Условие расширено до алиасов "object" и "ba"
             */
            // ? data.filter((el) => el.alias.startsWith(RolesEntities.object)
            data.filter(
              el =>
                el.alias.startsWith(RolesEntities.object) || el.alias.startsWith(RolesEntities.ba),
            )
          : [];

        return (
          <>
            <Card className={classes.root}>
              <CardContent className={classes.сardContent}>
                <CloneRole
                  userId={userId}
                  setObjectRefreshNeeded={setObjectRefreshNeeded}
                  setProjectRefreshNeeded={setProjectRefreshNeeded}
                />
              </CardContent>
            </Card>
            <Card className={classes.root}>
              <CardContent className={classes.сardContent}>
                <ProjectsRoles
                  {...searchPanelConfig.project}
                  allRoles={projectRoles}
                  allSystemRoles={data}
                  userId={userId}
                  modalProps={modalProps}
                  setModalProps={setModalProps}
                  fullName={fullName}
                  setObjectRefreshNeeded={setObjectRefreshNeeded}
                  setSelectedProjects={setSelectedProjects}
                  projectRefreshNeeded={projectRefreshNeeded}
                  selectedObjects={selectedObjects}
                />
              </CardContent>
            </Card>
            <Card className={classes.root}>
              <CardContent className={classes.сardContent}>
                <ObjectsRoles
                  {...searchPanelConfig.object}
                  allRoles={objectRoles}
                  allSystemRoles={data}
                  userId={userId}
                  modalProps={modalProps}
                  setModalProps={setModalProps}
                  fullName={fullName}
                  objectRefreshNeeded={objectRefreshNeeded}
                  selectedProjects={selectedProjects}
                  setProjectRefreshNeeded={setProjectRefreshNeeded}
                  setSelectedObjects={setSelectedObjects}
                />
              </CardContent>
            </Card>
          </>
        );
      }}
    </Query>
  );
};

export const CommonRolesContainer = withStyles(styles as any)(CommonRoles);
