import React, { FC } from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import { roleIdField } from 'Constants';

const styles = {
  сardLongItem: { maxWidth: '500px' },
};

const RoleListComponent: FC = ({ classes = {}, ...props }: any) => (
  <List {...props} exporter={false} title="Список ролей пользователей">
    <Datagrid rowClick="edit">
      <TextField source={roleIdField} label="Id" />
      <TextField source="name" label="Название" />
      <TextField source="alias" label="Код" />
      <TextField source="description" label="Описание" className={classes.сardLongItem} />
    </Datagrid>
  </List>
);

export const RoleList = withStyles(styles as any)(RoleListComponent);
