import React, { FC, useState, useEffect } from 'react';
import { addField } from 'react-admin';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Resources,
  referenceProjectTypeTemplate, // TODO-08
} from 'Constants';

interface IPassportProps {
  source: string;
  locale: string;
  idPrefix: string;
  type: string;
}

const styles = {
  root: { marginTop: '20px' },
  groupWrapper: { display: 'flex', gap: '20px', padding: '4px 0' },
  // groupItem: { marginRight: '20px' },
};

const PassportComponent: FC<IPassportProps> = addField(
  ({ classes, record, input, locale, idPrefix, type, reduxItemType }: any) => {
    const [template, setTemplate] = useState();
    const [templateAlias, setTemplateAlias] = useState();
    const { value, onChange } = input;

    useEffect(() => {
      const itemType = get(reduxItemType, `${type}.${record[idPrefix]}`);
      if (itemType) {
        setTemplate(itemType[`${referenceProjectTypeTemplate}_${locale}`]); // TODO-08
        setTemplateAlias(itemType.alias);
      }
    }, [record, reduxItemType]);

    const onChangeHandler = (e, fieldKey, isSpecial = false): void => {
      e.preventDefault();
      const newAliasData = value[templateAlias as string];
      const newData = {
        ...value,
        [templateAlias as string]: {
          ...newAliasData,
          [fieldKey]: isSpecial
            ? {
                isSpecial: true,
                value: e.target.value,
                response: {
                  data: null,
                  status: e.target.value.toString().length > 0 ? 'pending' : null,
                },
              }
            : e.target.value,
        },
      };
      onChange(newData);
    };

    return (
      <>
        {template ? (
          <Card className={classes.root}>
            <CardContent className={classes.сardContent}>
              {get(template, 'fields', []).map((
                el, // TODO-08
              ) => (
                <>
                  {el.fieldType !== 'target_ref' ? (
                    <div key={el.fieldKey} className={classes.groupWrapper}>
                      <TextField
                        defaultValue={el.fieldLabel}
                        disabled
                        fullWidth
                        className={classes.groupItem}
                      />
                      <TextField
                        defaultValue={
                          el.fieldIsSpecial
                            ? get(value, `${templateAlias}[${el.fieldKey}][value]`, '')
                            : get(value, `${templateAlias}[${el.fieldKey}]`, '')
                        }
                        onChange={e => onChangeHandler(e, el.fieldKey, el.fieldIsSpecial)}
                        fullWidth
                        disabled={el.readOnly}
                        helperText={el.readOnly && 'Только для чтения'}
                      />
                    </div>
                  ) : null}
                </>
              ))}
            </CardContent>
          </Card>
        ) : null}
      </>
    );
  },
);

const mapStateToProps = state => ({
  reduxItemType: {
    project: get(state, `admin.resources.${Resources.REFERENCE_PROJECT_TYPE}.data`),
    object: get(state, `admin.resources.${Resources.REFERENCE_OBJECT_TYPE}.data`),
  },
});

export const Passport = connect(mapStateToProps)(withStyles(styles)(PassportComponent));
