import * as React from 'react';
import { Admin, Resource, Login } from 'react-admin';
import {
  AccountBalance,
  Description,
  FolderOpen,
  Gavel,
  ShowChart,
  Domain,
  AccountCircle,
} from '@material-ui/icons';
import {
  AnalyticalReportList,
  AnalyticalReportEdit,
  AnalyticalReportCreate,
  OrganisationList,
  OrganisationEdit,
  OrganisationCreate,
  UserList,
  UserEdit,
  UserCreate,
  ProjectList,
  ProjectEdit,
  ProjectCreate,
  ObjectCreate,
  ObjectList,
  ObjectEdit,
  PermissionList,
  PermissionEdit,
  PermissionCreate,
  ElementEdit,
  ElementCreate,
  JobEdit,
  JobCreate,
  JobUnitList,
  GroupUnitLis,
  ProjectTypeList,
  ProjectTypeEdit,
  ProjectTypeCreate,
  ObjectTypeList,
  ObjectTypeEdit,
  ObjectTypeCreate,
  RoleList,
  RoleEdit,
  RoleCreate,
  CalculationMethodList,
  TypeOfDirectoryList,
  FolderTemplatesList,
  FolderTemplatesCreate,
  FolderTemplatesEdit,
  FileTemplateList,
  FileTemplateCreate,
  FileTemplateEdit,
  CategoryList,
  CategoryCreate,
  CategoryEdit,
  FolderCreate,
  ServicesList,
  ServiceEdit,
  FolderEdit,
  PassportAttributesList,
  ScheduleTemplateCreate,
  ScheduleTemplateEdit,
  ScheduleTemplateList,
  ScheduleCreate,
  ScheduleEdit,
  ObjectLifeCycleStageList,
  ObjectLifeCycleStageCreate,
  ObjectLifeCycleStageEdit,
  ScheduleDeviationDayMethodList,
  SpecialityList,
  SpecialityCreate,
  SpecialityEdit,
  ElementReferenceList,
  ElementReferenceEdit,
  ElementReferenceCreate,
  JobReferenceList,
  JobReferenceEdit,
  JobReferenceCreate,
  BusinessRelationList,
  ApprovalList,
  ApprovalCreate,
  ApprovalStepCreate,
  ApprovalStepEdit,
  ApprovalEdit,
  CostPlanFactSourceList,
  EventLogList,
  PassportFieldList,
  PassportFieldCreate,
  PassportFieldEdit,
  PassportFieldElementCreate,
  PassportFieldElementEdit,
  DatabaseMigrationList,
  CurrencyList,
  CurrencyCreate,
  CurrencyEdit,
  ResourceFactMethodList,
} from 'Components';
import { authProvider, dataProviders } from 'Providers';
import { axiosInitialization, methodsCookie } from 'Utils';
import { analyticalReportTemplateReducer } from 'Providers/utils/customReducers';
import { StimulSoft } from 'Components/StimulSoft/StimulSoft';
import { Resources } from '../../Constants';
import ladLoginBackground from './ladLoginBackground.png';

const ladLoginPage = (): React.ReactNode => <Login backgroundImage={ladLoginBackground} />;
export class App extends React.Component {
  public componentDidMount(): void {
    const token = methodsCookie.getItem('token');
    if (token) {
      axiosInitialization(token);
    }
  }

  public render(): React.ReactNode {
    return (
      <Admin
        customReducers={{ analyticalReportTemplate: analyticalReportTemplateReducer }}
        loginPage={ladLoginPage}
        dashboard={StimulSoft}
        authProvider={authProvider}
        dataProvider={dataProviders}
        locale="ru"
      >
        {(): React.ReactNode => [
          <Resource
            key={Resources.ANALYTICAL_REPORTS}
            name={Resources.ANALYTICAL_REPORTS}
            list={AnalyticalReportList}
            edit={AnalyticalReportEdit}
            create={AnalyticalReportCreate}
            options={{ label: 'Аналитические отчеты' }}
            icon={ShowChart}
          />,
          <Resource
            key={Resources.ORGS}
            name={Resources.ORGS}
            list={OrganisationList}
            edit={OrganisationEdit}
            create={OrganisationCreate}
            options={{ label: 'Организации' }}
            icon={AccountBalance}
          />,
          <Resource
            key={Resources.USERS}
            name={Resources.USERS}
            list={UserList}
            edit={UserEdit}
            create={UserCreate}
            options={{ label: 'Пользователи' }}
            icon={AccountCircle}
          />,
          <Resource
            key={Resources.PROJECTS}
            name={Resources.PROJECTS}
            list={ProjectList}
            edit={ProjectEdit}
            create={ProjectCreate}
            options={{ label: 'Проекты' }}
            icon={FolderOpen}
          />,
          <Resource
            key={Resources.OBJECT}
            name={Resources.OBJECT}
            list={ObjectList}
            edit={ObjectEdit}
            create={ObjectCreate}
            options={{ label: 'Объекты' }}
            icon={FolderOpen}
          />,
          <Resource
            key={Resources.CATEGORY}
            name={Resources.CATEGORY}
            list={CategoryList}
            create={CategoryCreate}
            edit={CategoryEdit}
            options={{ label: 'Категории' }}
            icon={FolderOpen}
          />,
          <Resource
            key={Resources.FOLDER_TEMPLATES}
            name={Resources.FOLDER_TEMPLATES}
            list={FolderTemplatesList}
            create={FolderTemplatesCreate}
            edit={FolderTemplatesEdit}
            options={{ label: 'Шаблоны папок' }}
            icon={FolderOpen}
          />,
          <Resource
            key={Resources.FOLDER_TEMPLATES_FOLDER}
            name={Resources.FOLDER_TEMPLATES_FOLDER}
            // list={FolderList}
            create={FolderCreate}
            edit={FolderEdit}
            options={{ label: 'Папки' }}
            icon={FolderOpen}
          />,
          <Resource
            key={Resources.FILE_TEMPLATE}
            name={Resources.FILE_TEMPLATE}
            list={FileTemplateList}
            create={FileTemplateCreate}
            edit={FileTemplateEdit}
            options={{ label: 'Шаблоны файлов' }}
            icon={Description}
          />,
          <Resource
            key={Resources.APPROVAL}
            name={Resources.APPROVAL}
            list={ApprovalList}
            create={ApprovalCreate}
            edit={ApprovalEdit}
            options={{ label: 'Маршруты согласования' }}
            icon={Description}
          />,
          <Resource
            key={Resources.APPROVAL_STEP}
            name={Resources.APPROVAL_STEP}
            // list={ApprovalList}
            create={ApprovalStepCreate}
            edit={ApprovalStepEdit}
            options={{ label: 'Шаги согласования' }}
            icon={Description}
          />,
          <Resource
            key={Resources.SCHEDULE_TEMPLATE}
            name={Resources.SCHEDULE_TEMPLATE}
            list={ScheduleTemplateList}
            create={ScheduleTemplateCreate}
            edit={ScheduleTemplateEdit}
            options={{ label: 'Шаблоны модели' }}
            icon={Domain}
          />,
          <Resource
            key={Resources.SCHEDULE}
            name={Resources.SCHEDULE}
            create={ScheduleCreate}
            edit={ScheduleEdit}
          />,
          <Resource
            key={Resources.REFERENCE_JOB_REFERENCE}
            name={Resources.REFERENCE_JOB_REFERENCE}
            list={JobReferenceList}
            edit={JobReferenceEdit}
            create={JobReferenceCreate}
            options={{ label: 'Справочники работ' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.REFERENCE_SERVICES}
            name={Resources.REFERENCE_SERVICES}
            list={ServicesList}
            edit={ServiceEdit}
            options={{ label: 'Сервисы' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.REFERENCE_CURRENCY}
            name={Resources.REFERENCE_CURRENCY}
            list={CurrencyList}
            create={CurrencyCreate}
            edit={CurrencyEdit}
            options={{ label: 'Справочник валют' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.REFERENCE_JOB}
            name={Resources.REFERENCE_JOB}
            // list={JobList}
            edit={JobEdit}
            create={JobCreate}
            options={{ label: 'Справочник работ' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.REFERENCE_JOB_UNIT}
            name={Resources.REFERENCE_JOB_UNIT}
            list={JobUnitList}
            options={{ label: 'Справочник ед.изм.' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.REFERENCE_PROJECT_TYPE}
            name={Resources.REFERENCE_PROJECT_TYPE}
            list={ProjectTypeList}
            edit={ProjectTypeEdit}
            create={ProjectTypeCreate}
            options={{ label: 'Справ. типов проектов' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.REFERENCE_OBJECT_TYPE}
            name={Resources.REFERENCE_OBJECT_TYPE}
            list={ObjectTypeList}
            edit={ObjectTypeEdit}
            create={ObjectTypeCreate}
            options={{ label: 'Справ. типов объектов' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.REFERENCE_ELEMENT_REFERENCE}
            name={Resources.REFERENCE_ELEMENT_REFERENCE}
            list={ElementReferenceList}
            edit={ElementReferenceEdit}
            create={ElementReferenceCreate}
            options={{ label: 'Справочники констр. эл.' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.REFERENCE_ELEMENT}
            name={Resources.REFERENCE_ELEMENT}
            // list={ElementList}
            edit={ElementEdit}
            create={ElementCreate}
            options={{ label: 'Справочник констр. эл.' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.REFERENCE_GROUP_UNIT}
            name={Resources.REFERENCE_GROUP_UNIT}
            list={GroupUnitLis}
            options={{ label: 'Справ. типов конст. эл.' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.CALCULATION_METHOD}
            name={Resources.CALCULATION_METHOD}
            list={CalculationMethodList}
            options={{ label: 'Справ. методов расчета' }}
            icon={Gavel}
          />,
          <Resource // new*
            key={Resources.TYPEOF_DIRECTORY}
            name={Resources.TYPEOF_DIRECTORY}
            list={TypeOfDirectoryList}
            options={{ label: 'Справ. типов справочника к.э.' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.REFERENCE_OBJECT_LIFE_CYCLE_STAGE}
            name={Resources.REFERENCE_OBJECT_LIFE_CYCLE_STAGE}
            list={ObjectLifeCycleStageList}
            create={ObjectLifeCycleStageCreate}
            edit={ObjectLifeCycleStageEdit}
            options={{ label: 'Справочник этапов ЖЦ' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.PASSPORT_ATTR}
            name={Resources.PASSPORT_ATTR}
            list={PassportAttributesList}
            options={{ label: 'Справ. атрибутов паспорта объекта' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.SPECIALITY}
            name={Resources.SPECIALITY}
            list={SpecialityList}
            edit={SpecialityEdit}
            create={SpecialityCreate}
            options={{ label: 'Cправочник специальности' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.SCHEDULE_DEVIATION_DAY_METHOD}
            name={Resources.SCHEDULE_DEVIATION_DAY_METHOD}
            list={ScheduleDeviationDayMethodList}
            options={{ label: 'Справ. методов расчета дней отставания' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.REFERENCE_BUSINESS_RELATION}
            name={Resources.REFERENCE_BUSINESS_RELATION}
            list={BusinessRelationList}
            options={{ label: 'Справочник документов' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.REFERENCE_COST_PLAN_FACT_SOURCE}
            name={Resources.REFERENCE_COST_PLAN_FACT_SOURCE}
            list={CostPlanFactSourceList}
            options={{ label: 'Справочник источников данных' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.PASSPORT_FIELD}
            name={Resources.PASSPORT_FIELD}
            list={PassportFieldList}
            create={PassportFieldCreate}
            edit={PassportFieldEdit}
            options={{ label: 'Справ. паспортных полей' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.PASSPORT_FIELD_ELEMENT}
            name={Resources.PASSPORT_FIELD_ELEMENT}
            create={PassportFieldElementCreate}
            edit={PassportFieldElementEdit}
          />,
          <Resource
            key={Resources.RESOURCE_FACT_METHOD}
            name={Resources.RESOURCE_FACT_METHOD}
            list={ResourceFactMethodList}
            options={{ label: 'Справ. методов ввода факта по ресурсам' }}
            icon={Gavel}
          />,
          <Resource
            key={Resources.ROLE}
            name={Resources.ROLE}
            list={RoleList}
            edit={RoleEdit}
            create={RoleCreate}
            options={{ label: 'Роли' }}
            icon={FolderOpen}
          />,
          <Resource
            key={Resources.PERMISSION}
            name={Resources.PERMISSION}
            list={PermissionList}
            edit={PermissionEdit}
            create={PermissionCreate}
            options={{ label: 'Права' }}
            icon={FolderOpen}
          />,
          <Resource
            key={Resources.EVENT_LOG}
            name={Resources.EVENT_LOG}
            list={EventLogList}
            options={{ label: 'Журнал событий' }}
            icon={FolderOpen}
          />,
          <Resource
            key={Resources.DATABASE_MIGRATION}
            name={Resources.DATABASE_MIGRATION}
            list={DatabaseMigrationList}
            options={{ label: 'История миграций' }}
            icon={FolderOpen}
          />,
        ]}
      </Admin>
    );
  }
}
