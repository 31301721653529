import React, { FC } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  FunctionField,
  ReferenceField,
  DateField,
  Filter,
  TextInput,
} from 'react-admin';
import { getFullUserName, getMultiLanguageName } from 'Components/utils/getFullName';
import { Resources, organisationPrefix, userIdField, userNameLabel } from 'Constants';

const UserFilter = props => (
  <Filter {...props}>
    <TextInput label={userNameLabel.fullname_ru} source="filterName_ru" alwaysOn />
    <TextInput label={userNameLabel.fullname_en} source="filterName_en" alwaysOn />
    <TextInput label="E-mail" source="email" alwaysOn />
  </Filter>
);

const FullNameField = ({ record = {} }) => <span>{getMultiLanguageName(record)}</span>;

export const UserList: FC = props => (
  <List {...props} exporter={false} title="Список пользователей" filters={<UserFilter />}>
    <Datagrid rowClick="edit">
      <TextField source={userIdField} label="Id" />
      <FunctionField
        render={user => getFullUserName(user, 'ru')}
        label={userNameLabel.fullname_ru}
      />
      <FunctionField
        render={user => getFullUserName(user, 'en')}
        label={userNameLabel.fullname_en}
      />
      <DateField source="birthdate" label="Дата изменения учетной записи" />
      <EmailField source="email" label="E-mail" />
      <TextField source="phone" label="Телефон" />
      <ReferenceField
        resource={Resources.ORGS}
        source={organisationPrefix}
        reference={Resources.ORGS}
        label="Организация"
      >
        <FullNameField />
      </ReferenceField>
    </Datagrid>
  </List>
);
