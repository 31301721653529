import React, { FC } from 'react';
import {
  LongTextInput,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Resources,
  referenceGroupUnitIdField,
  elementLabel,
  referenceElementReferencePrefix,
} from 'Constants';

const styles = {
  card: { width: '100%', marginBottom: '10px' },
  сardContent: { display: 'flex', flexDirection: 'column', position: 'relative' },
  groupWrapper: { display: 'flex' },
  groupItem: { marginRight: '20px' },
  btn: { position: 'absolute', right: '0', top: '0' },
};

const ElementCreateComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => {
  const innerProps = { ...props };
  const elementReferenceString: string = innerProps.history.location.search;
  const elementReference = elementReferenceString
    ? elementReferenceString.replace(`?${referenceElementReferencePrefix}=`, '')
    : null;
  const redirect = elementReference
    ? `/${Resources.REFERENCE_ELEMENT_REFERENCE}/${elementReference}/1`
    : `/${Resources.REFERENCE_ELEMENT_REFERENCE}`;
  return (
    <Create title="Новый конструктивный элемент" {...props}>
      <SimpleForm redirect={redirect}>
        <>
          <Card className={classes.card}>
            <CardContent className={classes.сardContent}>
              <IconButton onClick={() => propsPush(redirect)} className={classes.btn}>
                <CloseIcon />
              </IconButton>
              <ReferenceInput
                source={referenceElementReferencePrefix}
                reference={Resources.REFERENCE_ELEMENT_REFERENCE}
                resource={Resources.REFERENCE_ELEMENT_REFERENCE}
                label="Справочник конструктивных элементов"
                fullWidth
                required
                disabled
              >
                <SelectInput optionText={record => record.name} />
              </ReferenceInput>
              <div className={classes.groupWrapper}>
                <TextInput
                  source="key"
                  label="Ключ"
                  fullWidth
                  required
                  className={classes.groupItem}
                />
                <TextInput source="code" label="Код" fullWidth required />
              </div>
              <div className={classes.groupWrapper}>
                <TextInput
                  source="name_ru"
                  label={elementLabel.name_ru}
                  fullWidth
                  className={classes.groupItem}
                  required
                />
                <TextInput source="name_en" label={elementLabel.name_en} fullWidth />
              </div>
              <ReferenceInput
                source={referenceGroupUnitIdField}
                resource={Resources.REFERENCE_GROUP_UNIT}
                reference={Resources.REFERENCE_GROUP_UNIT}
                label="Тип конструктивного элемента"
                fullWidth
                required
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <LongTextInput
                source="description_ru"
                label={elementLabel.description_ru}
                fullWidth
              />
              <LongTextInput
                source="description_en"
                label={elementLabel.description_en}
                fullWidth
              />
            </CardContent>
          </Card>
        </>
      </SimpleForm>
    </Create>
  );
};

const connectedElementCreate = connect(null, { push })(ElementCreateComponent);
export const ElementCreate = withStyles(styles as any)(connectedElementCreate);
