export const methodsLocaleStorage = {
  setItem: (name: string, value: string): boolean => {
    try {
      localStorage.setItem(name, value);
      return true;
    } catch (error) {
      return false;
    }
  },
  getItem: (name: string): string | null => {
    try {
      return localStorage.getItem(name);
    } catch (error) {
      return null;
    }
  },
  deleteItem: (name: string): boolean => {
    try {
      localStorage.removeItem(name);
      return true;
    } catch (error) {
      return false;
    }
  },
  clear: (): boolean => {
    try {
      localStorage.clear();
      return true;
    } catch (error) {
      return false;
    }
  },
};
