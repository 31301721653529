/* eslint-disable @typescript-eslint/camelcase */

import axios from 'axios';
import { GET_LIST, GET_ONE, DELETE, DELETE_MANY, UPDATE, CREATE } from 'react-admin';
import { get } from 'lodash';
import {
  IRADataProviderParams,
  IRAGetOneParams,
  IRADeleteParams,
  IRADeleteManyParams,
  IRAUpdateParams,
  IRACreateParams,
} from 'Interfaces';
import { API_URL, REST, analyticalTemplatesPath } from 'Constants';
import { replace } from 'react-router-redux';
import { PgUnAssoc, fileReaderPromise } from 'Providers/utils';
import { Base64Encode } from '../../Utils/stringMethods';

export const analyticalReportsProvider = async (
  type: string,
  params: IRADataProviderParams,
  // eslint-disable-next-line consistent-return
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(`${API_URL}${REST.AdminAnalyticsTemplate.LIST}`, {});
        const parsedTemplates = PgUnAssoc(get(response, analyticalTemplatesPath));
        // parsedTemplatesWithId - добавляем поле id так как этого требует react-admin
        const parsedTemplatesWithId = parsedTemplates.map(el => ({
          ...el,
          id: el.analytics_template_id,
        }));

        return {
          data: parsedTemplatesWithId,
          total: parsedTemplatesWithId.length,
        };
      }
      case GET_ONE: {
        // по какойто причине метод вызывается два раза в первом случае id в виде строки

        const { id } = params as IRAGetOneParams;
        const response = await axios.post(`${API_URL}${REST.AdminAnalyticsTemplate.LIST}`, {
          analytics_template_id: [Number(id)],
        });
        const parsedTemplate = PgUnAssoc(get(response, analyticalTemplatesPath))[0];

        return {
          data: {
            ...parsedTemplate,
            id, // добавляем поле id так как этого требует react-admin
          },
        };
      }
      case UPDATE: {
        const { data } = params as IRAUpdateParams;
        const {
          id,
          files,
          settings,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sort_order,
          ...sendData
        } = data as any;

        const sortOrder = Number(sort_order);
        const settingsParsed: string =
          typeof settings === 'string' ? JSON.parse(settings) : settings;
        const requestBody = {
          ...sendData,
          sort_order: sortOrder,
          settings: settingsParsed,
        };

        if (files) {
          const { rawFile } = files;
          const clearResult = await fileReaderPromise(rawFile);
          const report = Base64Encode(clearResult);
          requestBody.template = report;
        }
        const response = await axios.post(
          `${API_URL}${REST.AdminAnalyticsTemplate.UPDATE}`,
          requestBody,
        );

        const parsedObject = get(response, analyticalTemplatesPath);
        return {
          data: {
            ...parsedObject,
            // добавляем поле id так как этого требует react-admin
            id: parsedObject.analytics_template_id,
          },
        };
        break;
      }
      case CREATE: {
        const { data } = params as IRACreateParams;
        const {
          id,
          settings,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sort_order,
          ...sendData
        } = data as any;

        const sortOrder = Number(sort_order);
        const settingsParsed = JSON.parse(settings);

        const response = await axios.post(`${API_URL}${REST.AdminAnalyticsTemplate.CREATE}`, {
          ...sendData,
          sort_order: sortOrder,
          settings: settingsParsed,
        });

        const parsedObject = get(response, analyticalTemplatesPath);

        return {
          data: {
            ...parsedObject,
            id: parsedObject.analytics_template_id,
          },
        };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        await axios.post(`${API_URL}${REST.AdminAnalyticsTemplate.DELETE}`, {
          analytics_template_id: [id],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        await axios.post(`${API_URL}${REST.AdminAnalyticsTemplate.DELETE}`, {
          analytics_template_id: ids,
        });
        return { data: ids };
      }
      default:
        throw new Error('Неизвестный метод получения');
    }
  } catch (error) {
    throw new Error('Ошибка метода');
  }
};
