/* eslint-disable @typescript-eslint/camelcase */

import axios from 'axios';
import { GET_LIST, GET_ONE, CREATE, UPDATE, DELETE, GET_MANY, DELETE_MANY } from 'react-admin';
import {
  IRADataProviderParams,
  IRAGetOneParams,
  IRAGetManyParams,
  IRAUpdateParams,
  IRACreateParams,
  IRADeleteParams,
  IReferenceProjectTypeListResp,
  IReferenceProjectTypeUpdateProvider,
  IRADeleteManyParams,
} from 'Interfaces';
import {
  API_URL,
  REST,
  referenceProjectTypeDataPath,
  referenceProjectTypePrefix,
  referenceProjectTypeIdField,
} from 'Constants';
import { get } from 'lodash';
import {
  setPrefix,
  PgUnAssoc,
  removePrefix,
  removePrefixInArray,
  mapTemplateFromArray,
  mapTemplateToArray,
} from '../utils';

export const referenceProjectTypeProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(`${API_URL}${REST.AdminReference.ProjectType.LIST}`, {});
        const parsedProjectType: IReferenceProjectTypeListResp[] = PgUnAssoc(
          get(response, referenceProjectTypeDataPath),
        );
        const projectTypeWithId: any[] = parsedProjectType.map(el => ({
          ...el,
          ...mapTemplateToArray(el),
          id: setPrefix(referenceProjectTypePrefix, el[referenceProjectTypeIdField]),
        }));
        return { data: projectTypeWithId, total: projectTypeWithId.length };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(referenceProjectTypePrefix, id);
        const response: IReferenceProjectTypeListResp = await axios.post(
          `${API_URL}${REST.AdminReference.ProjectType.LIST}`,
          { [referenceProjectTypeIdField]: [clearId] },
        );
        const parsedProjectType: IReferenceProjectTypeListResp = PgUnAssoc(
          get(response, referenceProjectTypeDataPath),
        )[0];

        const passportTemplateWithPrevKeys = Object.entries(
          parsedProjectType.passport_template_ru,
        ).reduce((acc, item: any) => {
          const [key, value] = item;
          const keyType: string = key.slice(0, -1);

          return {
            ...acc,
            [key]: value?.map(field => ({
              ...field,
              [`${keyType}PrevKey`]: field[`${keyType}Key`],
            })),
          };
        }, {});

        const parsedProjectTypeWithPrevKey = {
          ...parsedProjectType,
          passport_template_ru: passportTemplateWithPrevKeys,
        };

        return {
          data: {
            ...parsedProjectTypeWithPrevKey,
            ...mapTemplateToArray(parsedProjectTypeWithPrevKey),
            id: setPrefix(
              referenceProjectTypePrefix,
              parsedProjectType[referenceProjectTypeIdField],
            ),
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const clearIds = removePrefixInArray(ids, referenceProjectTypePrefix).filter(
          el => el !== null,
        );
        const response = await axios.post(`${API_URL}${REST.AdminReference.ProjectType.LIST}`, {
          [referenceProjectTypeIdField]: clearIds,
        });
        const parsedProjectType: IReferenceProjectTypeListResp[] = PgUnAssoc(
          get(response, referenceProjectTypeDataPath),
        );
        const projectTypeWithId: any[] = parsedProjectType.map(el => ({
          ...el,
          ...mapTemplateToArray(el),
          id: setPrefix(referenceProjectTypePrefix, el[referenceProjectTypeIdField]),
        }));
        return { data: projectTypeWithId };
      }
      case UPDATE: {
        const rawData = (params as IRAUpdateParams).data as IReferenceProjectTypeUpdateProvider;
        const { fields, groups, id, widgets, ...rest } = rawData;
        const response = await axios.post(`${API_URL}${REST.AdminReference.ProjectType.UPDATE}`, {
          ...rest,
          ...mapTemplateFromArray(rawData),
        });
        const updatedProjectType = get(response, referenceProjectTypeDataPath);
        return {
          data: {
            ...updatedProjectType,
            ...mapTemplateToArray(updatedProjectType),
            id: setPrefix(
              referenceProjectTypePrefix,
              updatedProjectType[referenceProjectTypeIdField],
            ),
          },
        };
      }
      case CREATE: {
        const rawData = (params as IRACreateParams).data as any;
        const { fields, groups, widgets, ...rest } = rawData;
        const response = await axios.post(`${API_URL}${REST.AdminReference.ProjectType.CREATE}`, {
          ...rest,
          ...mapTemplateFromArray(rawData),
        });
        const newProjectType = get(response, referenceProjectTypeDataPath);
        return {
          data: {
            ...newProjectType,
            ...mapTemplateToArray(newProjectType),
            id: setPrefix(referenceProjectTypePrefix, newProjectType[referenceProjectTypeIdField]),
          },
        };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(referenceProjectTypePrefix, id);
        await axios.post(`${API_URL}${REST.AdminReference.ProjectType.DELETE}`, {
          [referenceProjectTypeIdField]: [clearId],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, referenceProjectTypePrefix);
        await axios.post(`${API_URL}${REST.AdminReference.ProjectType.DELETE}`, {
          [referenceProjectTypeIdField]: clearIds,
        });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${referenceProjectTypePrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${referenceProjectTypePrefix}-${type}`);
  }
};
