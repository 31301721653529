import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import withStyles from '@material-ui/core/styles/withStyles';

interface ISubmitCancelModalProps {
  show: boolean;
  title?: string;
  body: string;
  submit?: (e: any) => void;
  cancel?: (e: any) => void;
  onFinish?: (e: any) => void;
  shadow?: boolean;
  style?: any;
  boxShadow?: string;
}

const styles = {
  root: { background: 'red !important' },
};

const SubmitCancelModalComponent: FC<ISubmitCancelModalProps> = ({
  submit,
  cancel,
  onFinish,
  show,
  title,
  body,
  style = {},
  shadow = false,
}) => (
  <Dialog
    fullWidth
    open={show}
    disableBackdropClick
    disableEscapeKeyDown
    PaperProps={{
      style: {
        boxShadow: !shadow && 'none',
      },
    }}
    BackdropProps={{ style: { backgroundColor: !shadow && 'transparent' } }}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{body}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={e => {
          submit(e);
          onFinish(e);
        }}
        variant="contained"
        size="small"
        color="primary"
      >
        Подтвердить
      </Button>
      <Button
        onClick={e => {
          cancel(e);
          onFinish(e);
        }}
        variant="contained"
        size="small"
        color="secondary"
      >
        Отмена
      </Button>
    </DialogActions>
  </Dialog>
);

export const SubmitCancelModal = withStyles(styles as any)(SubmitCancelModalComponent);
