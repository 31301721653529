import axios from 'axios';
import {
  GET_LIST,
  GET_ONE,
  DELETE,
  DELETE_MANY,
  UPDATE,
  CREATE,
  GET_MANY_REFERENCE,
  GET_MANY,
} from 'react-admin';
import { get } from 'lodash';
import {
  IRAGetListParams,
  IRADataProviderParams,
  IRAGetOneParams,
  IRADeleteParams,
  IRADeleteManyParams,
  IRAUpdateParams,
  IRACreateParams,
  IRAGetManyReferenceParams,
  IRAGetManyParams,
} from 'Interfaces';
import {
  API_URL,
  REST,
  folderPath,
  folderIdField,
  folderPrefix,
  folderTemplatesIdField,
  folderTemplatesPrefix,
  folderParentIdField,
  fileTemplateIdField,
  fileTemplatePrefix,
  fileTemplatePath,
} from 'Constants';
import {
  setPrefixInArray,
  setPrefix,
  removePrefix,
  removePrefixInArray,
  PgUnAssoc,
} from 'Providers/utils';
import { request } from 'http';

export const folderProvider = async (
  type: string,
  params: any,
  // eslint-disable-next-line consistent-return
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const { filter } = params as IRAGetListParams;
        const { id } = filter;
        if (id) {
          const clearId = removePrefix(folderTemplatesPrefix, id);
          const requestBody = {
            [folderTemplatesIdField]: clearId,
          };
          const response = await axios.post(
            `${API_URL}${REST.AdminFolderTemplatesFolder.LIST}`,
            requestBody,
          );
          const parsedFolders = PgUnAssoc(get(response, folderPath));

          const parsedFoldersWithId = parsedFolders.map(el => ({
            ...el,
            id: setPrefix(folderPrefix, el[folderIdField]),
            [folderTemplatesPrefix]: setPrefix(folderTemplatesPrefix, el[folderTemplatesIdField]),
            [folderParentIdField]: el[folderParentIdField]
              ? setPrefix(folderPrefix, el[folderParentIdField])
              : null,
            [fileTemplatePrefix]: el[fileTemplateIdField]
              .filter(item => item !== null)
              .map(item => setPrefix(fileTemplatePrefix, item)),
          }));
          return {
            data: parsedFoldersWithId,
            total: parsedFoldersWithId.length,
          };
        }
        return { data: [], total: 0 };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(folderPrefix, id);
        const requestBoby = {
          [folderIdField]: [clearId],
        };
        const response = await axios.post(
          `${API_URL}${REST.AdminFolderTemplatesFolder.LIST}`,
          requestBoby,
        );
        const parsedFolder = PgUnAssoc(get(response, folderPath))[0];

        return {
          data: {
            ...parsedFolder,
            id: setPrefix(folderPrefix, parsedFolder[folderIdField]),
            [folderTemplatesPrefix]: setPrefix(
              folderTemplatesPrefix,
              parsedFolder[folderTemplatesIdField],
            ),
            [folderParentIdField]: parsedFolder[folderParentIdField]
              ? setPrefix(folderPrefix, parsedFolder[folderParentIdField])
              : null,
            [fileTemplatePrefix]: parsedFolder[fileTemplateIdField]
              .filter(item => item !== null)
              .map(el => setPrefix(fileTemplatePrefix, el)),
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const clearIds = removePrefixInArray(ids, folderPrefix).filter(el => el !== null);
        if (clearIds.length > 0) {
          const requestBody = {
            [folderIdField]: clearIds,
          };
          const response = await axios.post(
            `${API_URL}${REST.AdminFolderTemplatesFolder.LIST}`,
            requestBody,
          );
          const parsedFolders = PgUnAssoc(get(response, folderPath));
          const parsedFoldersWithId = parsedFolders.map(el => ({
            ...el,
            id: setPrefix(folderPrefix, el[folderIdField]),
            [folderTemplatesPrefix]: setPrefix(folderTemplatesPrefix, el[folderTemplatesIdField]),
            [folderParentIdField]: setPrefix(folderPrefix, el[folderParentIdField]),
          }));
          return { data: parsedFoldersWithId };
        }
        return { data: [] };
      }
      case GET_MANY_REFERENCE: {
        const { id } = params as IRAGetManyReferenceParams;
        const clearId = removePrefix(folderTemplatesPrefix, id);
        const sendData = { [folderTemplatesIdField]: clearId };
        const response = await axios.post(`${API_URL}${REST.AdminFolderTemplatesFolder.LIST}`, {
          ...sendData,
        });
        const parsedFolders = PgUnAssoc(get(response, folderPath));
        const foldersWithId = parsedFolders.map(el => ({
          ...el,
          id: setPrefix(folderPrefix, el[folderIdField]),
          [folderTemplatesPrefix]: setPrefix(folderTemplatesPrefix, el[folderTemplatesIdField]),
          [folderParentIdField]: setPrefix(folderPrefix, el[folderParentIdField]),
        }));
        return {
          data: foldersWithId,
          total: foldersWithId.length,
        };
      }
      case UPDATE: {
        const { data } = params as IRAUpdateParams;
        const {
          id,
          [folderIdField]: folderId,
          [folderTemplatesPrefix]: folderTemplates,
          [folderParentIdField]: folderParent,
          [fileTemplatePrefix]: fileTemplate,
          ...sendData
        } = data as any;
        const requestBody = {
          ...sendData,
          [folderIdField]: folderId,
          [folderTemplatesIdField]: removePrefix(folderTemplatesPrefix, folderTemplates),
          [folderParentIdField]:
            folderParent !== null ? removePrefix(folderPrefix, folderParent) : null,
        };
        const clearFileTemplateIds = removePrefixInArray(fileTemplate, fileTemplatePrefix).filter(
          el => el !== null,
        );
        await axios
          .post(`${API_URL}${REST.AdminFolderTemplatesFolder.FILE_SET}`, {
            [folderIdField]: folderId,
            [fileTemplateIdField]: clearFileTemplateIds,
          })
          .catch(error => {
            const errorMessage = error.response.data.error.message;
            throw new Error(`${errorMessage}`);
          });
        const response = await axios.post(
          `${API_URL}${REST.AdminFolderTemplatesFolder.UPDATE}`,
          requestBody,
        );
        const parsedObject = get(response, folderPath);

        const returnedData = {
          data: {
            ...parsedObject,
            [folderTemplatesPrefix]: setPrefix(folderPrefix, parsedObject[folderTemplatesIdField]),
            [folderParentIdField]: setPrefix(folderPrefix, parsedObject[folderParentIdField]),
            id: setPrefix(folderPrefix, parsedObject[folderIdField]),
          },
        };

        return returnedData;
      }
      case CREATE: {
        const { data } = params as IRACreateParams;
        const {
          [folderTemplatesPrefix]: folderTemplate,
          [folderParentIdField]: parentId,
          [fileTemplatePrefix]: fileTemplate,
          ...sendData
        } = data as any;

        const requestBody = {
          ...sendData,
          [folderTemplatesIdField]: removePrefix(folderTemplatesPrefix, folderTemplate),
          [folderParentIdField]: removePrefix(folderPrefix, parentId) || null,
        };
        const clearFileTemplateIds = removePrefixInArray(fileTemplate, fileTemplatePrefix);
        const response = await axios.post(
          `${API_URL}${REST.AdminFolderTemplatesFolder.CREATE}`,
          requestBody,
        );
        const parsedObject = get(response, folderPath);
        await axios
          .post(`${API_URL}${REST.AdminFolderTemplatesFolder.FILE_SET}`, {
            [folderIdField]: parsedObject[folderIdField],
            [fileTemplateIdField]: clearFileTemplateIds,
          })
          .catch(error => {
            const errorMessage = error.response.data.error.message;
            throw new Error(`${errorMessage}`);
          });
        return {
          data: {
            ...parsedObject,
            [folderTemplatesPrefix]: setPrefix(
              folderTemplatesPrefix,
              parsedObject[folderTemplatesIdField],
            ),
            [folderParentIdField]:
              setPrefix(folderPrefix, parsedObject[folderParentIdField]) || null,
            id: setPrefix(folderPrefix, parsedObject[folderIdField]),
          },
        };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(folderPrefix, id);
        await axios.post(`${API_URL}${REST.AdminFolderTemplatesFolder.DELETE}`, {
          [folderIdField]: [clearId],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, folderPrefix);
        await axios.post(`${API_URL}${REST.AdminFolderTemplatesFolder.DELETE}`, {
          [folderIdField]: clearIds,
        });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${folderPrefix}: ${type}`);
    }
  } catch (error) {
    const errorStr = error.toString();
    if (errorStr.includes('_is_linked_to_')) {
      const errorArr = errorStr.split('_');
      const fileTemplateId = errorArr[3];
      const folderId = errorArr[11];
      const responseFileTemplate = await axios.post(`${API_URL}${REST.AdminFileTemplate.LIST}`, {
        [fileTemplateIdField]: [+fileTemplateId],
      });
      const responseFolder = await axios.post(`${API_URL}${REST.AdminFolderTemplatesFolder.LIST}`, {
        [folderIdField]: [+folderId],
      });
      const fileTemplateName = PgUnAssoc(get(responseFileTemplate, fileTemplatePath))[0].name;
      const folderName = PgUnAssoc(get(responseFolder, folderPath))[0].name;
      throw new Error(`Шаблон файла «${fileTemplateName}» уже содержится в папке «${folderName}»`);
    } else {
      throw new Error(`Ошибка метода  ${folderPrefix}: ${type}`);
    }
  }
};
