import React, { useState, FC, useEffect } from 'react';
import { withDataProvider, showNotification } from 'react-admin';
import { searchObjects, getAllProjects, addObjectRole, addProjectRole } from 'Providers';
import { parseRoleAliasToIds } from 'Providers/utils';
import { objectIdField, projectIdField, searchPanelConfig, roleIdField } from 'Constants';
import { get } from 'lodash';
import { IRolesProps } from './i';
import { CommonRolesComponent } from '../Components';

// TODO: add spinner
export const ObjectsRoles: FC<IRolesProps> = withDataProvider(props => {
  const { dispatch } = props;
  const {
    userId,
    fullName,
    objectRefreshNeeded,
    selectedProjects,
    allSystemRoles,
    modalProps,
    setModalProps,
    setProjectRefreshNeeded,
  } = props;
  const [searchFilter, setSearchFilter] = useState('');
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [searchObjectValue, setSearchObjectValue] = useState(null);
  const [asyncDefaultOptions, setAsyncDefaultOptions] = useState([]);
  const [allProjectsResponse, setAllProjectsResponse] = useState([]);

  const searchFilterChangeHandler = event => {
    setSearchFilter(event.target.value);
  };

  const objectAsyncSearchHandler = async (inputValue: string) => {
    if (inputValue.length < 3 && inputValue.length !== 0) {
      return [];
    }
    const response = await searchObjects(userId, false, inputValue);
    return response;
  };

  const objectAsyncSearchOnChangeHandler = item => {
    setSearchObjectValue(item || null);
  };

  const getStartData = async () => {
    const assignedObjectPromise = searchObjects(userId);
    const notAssignedObjectPromise = searchObjects(userId, false, '');
    const allProjectsPromise = getAllProjects();
    const [assignedObject, notAssignedObject, allProjects] = await Promise.all([
      assignedObjectPromise,
      notAssignedObjectPromise,
      allProjectsPromise,
    ]);
    setAllProjectsResponse(allProjects);
    const assignedObjectWithExtraProjectData = assignedObject.map(object => {
      const { [projectIdField]: projectId } = object;
      // TODO-08: костыль
      const allProjectsNames = allProjects.find(project => project[projectIdField] === projectId);
      const projectName = allProjectsNames.name_ru || allProjectsNames.name;
      // TODO-08: костыль
      return {
        ...object,
        projectName, // TODO-08: костыль - см.выше
        projectId,
      };
    });
    const objectWithRoleData = parseRoleAliasToIds(
      assignedObjectWithExtraProjectData,
      allSystemRoles,
    );
    setSelectedObjects(objectWithRoleData);
    props.setSelectedObjects(objectWithRoleData);
    setAsyncDefaultOptions(notAssignedObject);
  };

  const addButtonHandler = async (user, object) => {
    try {
      if (!selectedProjects.find(el => el[projectIdField] === object[projectIdField])) {
        // TODO-08: костыль
        const addingProjectName = get(
          allProjectsResponse.find(el => el[projectIdField] === object[projectIdField]),
          'name_ru',
          '',
        );
        // TODO-08: костыль
        setModalProps({
          show: true,
          title: 'Нет ролей на родительский проект',
          body: `Проекту "${addingProjectName}" будет добавлена минимальная роль "Проект, пользователь"`,
          submit: async () => {
            await addProjectRole(
              [object[projectIdField]],
              [user],
              [searchPanelConfig.project.minimalRoleId],
            );
            await addObjectRole(
              [object[objectIdField]],
              [user],
              [searchPanelConfig.object.minimalRoleId],
            );
            setSearchObjectValue(null);
            await getStartData();
            setProjectRefreshNeeded(prev => !prev);
            setModalProps({ show: false });
          },
          cancel: () => setModalProps({ show: false }),
        });
      } else {
        await addObjectRole(
          [object[objectIdField]],
          [user],
          [searchPanelConfig.object.minimalRoleId],
        );
        setSearchObjectValue(null);
        await getStartData();
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const acceptRole = (roles, { projectId, projectName }) => {
    const rolesName = allSystemRoles
      .filter(role => roles.includes(role[roleIdField]))
      // TODO-08: костыль
      .map(el => `"${el.name_ru || el.name}"`)
      .join(', ');
    const selectedObjectsIds = selectedObjects.map(el => el[objectIdField]);
    const filteredObjectsIds = selectedObjects
      .filter(role => role[projectIdField] === projectId)
      .map(el => el[objectIdField]);

    setModalProps({
      show: true,
      title: 'Назначение ролей на объекты пользователя',
      body: `Установить пользователю "${fullName}" роли: ${rolesName}:`,
      submit: async selected => {
        try {
          await addObjectRole(
            selected === 'all' ? selectedObjectsIds : filteredObjectsIds,
            [userId],
            roles,
          );
          await getStartData();
          setModalProps({ show: false });
        } catch (error) {
          throw new Error(error);
        }
      },
      cancel: () => setModalProps({ show: false }),
      options: [
        {
          value: 'onlyProject',
          label: `на объекты проекта "${projectName}" (${filteredObjectsIds.length}) шт.`,
        },
        {
          value: 'all',
          label: `на все объекты (${selectedObjectsIds.length}) шт.`,
        },
      ],
    });
  };

  const deleteItem = async itemId => {
    try {
      await addObjectRole([itemId], [userId], []);
      await getStartData();
    } catch (error) {
      throw new Error(error);
    }
  };

  const changeItemRole = async (e, objectId) => {
    if (e.target.value.length) {
      await addObjectRole([objectId], [userId], e.target.value);
      await getStartData();
    } else {
      dispatch(showNotification('У объекта должна быть хотя бы одна роль', 'warning'));
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await getStartData();
      } catch (error) {
        setSelectedObjects([]);
        props.setSelectedObjects([]);
        setAsyncDefaultOptions([]);
        throw new Error(error);
      }
    })();
  }, [objectRefreshNeeded]);

  return (
    <CommonRolesComponent
      {...props}
      searchFilter={searchFilter}
      searchFilterChangeHandler={searchFilterChangeHandler}
      itemAsyncSearchHandler={objectAsyncSearchHandler}
      itemAsyncSearchOnChangeHandler={objectAsyncSearchOnChangeHandler}
      itemAsyncSearchDefaultOptions={asyncDefaultOptions}
      itemAsyncSearchValue={searchObjectValue}
      selectedItems={selectedObjects}
      allSystemRoles={allSystemRoles}
      addButtonHandler={addButtonHandler}
      bulkAction={{ acceptRole, appointItems: null, deleteItem }}
      changeItemRole={changeItemRole}
      modalProps={modalProps}
    />
  );
});
