import dayjs from 'dayjs';

dayjs.locale('ru');

export const methodsCookie = {
  isEnabled: (): boolean => navigator.cookieEnabled,

  setItem: (name: string, value: string): void => {
    document.cookie = `${name}=${encodeURIComponent(value)};path=/`;
  },

  getItem: (name: string): string | null => {
    const cookies = document.cookie.split(';');
    let targetItem = null;

    cookies.forEach(cookie => {
      const [key, value] = cookie.split('=');
      if (key.trim() === name) {
        targetItem = decodeURIComponent(value);
      }
    });
    return targetItem;
  },

  deleteItem: (key: string): void => {
    const time = dayjs()
      .subtract(7, 'day')
      .toString();
    document.cookie = `${key}=;path=/;expires=${time}`;
  },
};
