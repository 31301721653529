import React, { FC } from 'react';
import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import {
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  LongTextInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  BooleanInput,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Resources,
  referenceJobIdField,
  referenceJobUnitIdField,
  referenceGroupUnitIdField,
  jobLabel,
  referenceJobReferencePrefix,
  referenceJobParentIdField,
} from 'Constants';
import { getMultiLanguageName } from 'Components/utils/getFullName';
import Typography from '@material-ui/core/Typography';

const styles = {
  card: { width: '100%', marginBottom: '10px' },
  сardContent: { display: 'flex', flexDirection: 'column', position: 'relative' },
  groupWrapper: { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1.5rem' },
  btn: { position: 'absolute', right: '0', top: '0' },
};

const RenderTitle = ({ record }: any) => <span>{getMultiLanguageName(record)}</span>;

const JobEditComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => {
  const redirect = (basePath, id, data) => {
    const path = `/${Resources.REFERENCE_JOB_REFERENCE}/${data[referenceJobReferencePrefix]}/1`;
    return path;
  };
  return (
    <Edit title={<RenderTitle />} {...props}>
      <SimpleForm redirect={redirect}>
        <FormDataConsumer>
          {({ formData }): JSX.Element => (
            <>
              <Card className={classes.card}>
                <CardContent className={classes.сardContent}>
                  <DisabledInput source={referenceJobIdField} label="Id" />
                  <IconButton
                    onClick={() =>
                      propsPush(
                        `/${Resources.REFERENCE_JOB_REFERENCE}/${formData[referenceJobReferencePrefix]}/1`,
                      )
                    }
                    className={classes.btn}
                  >
                    <CloseIcon />
                  </IconButton>
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardContent className={classes.сardContent}>
                  <ReferenceInput
                    source={referenceJobReferencePrefix}
                    reference={Resources.REFERENCE_JOB_REFERENCE}
                    resource={Resources.REFERENCE_JOB_REFERENCE}
                    label="Справочник работ"
                    fullWidth
                    required
                    disabled
                  >
                    <SelectInput optionText={record => record.name} />
                  </ReferenceInput>
                  <div className={classes.groupWrapper}>
                    <TextInput source="code" label="Код" fullWidth className={classes.groupItem} />
                    <TextInput source="key" label="Ключ" fullWidth />
                  </div>
                  <div className={classes.groupWrapper}>
                    <TextInput
                      source="name_ru"
                      label={jobLabel.name_ru}
                      required
                      fullWidth
                      className={classes.groupItem}
                    />
                    <TextInput source="name_en" label={jobLabel.name_en} fullWidth />
                  </div>
                  <div className={classes.groupWrapper}>
                    <ReferenceInput
                      source={referenceJobUnitIdField}
                      resource={Resources.REFERENCE_JOB_UNIT}
                      reference={Resources.REFERENCE_JOB_UNIT}
                      label="Ед.изм."
                      fullWidth
                      required
                      className={classes.groupItem}
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput
                      source={referenceGroupUnitIdField}
                      resource={Resources.REFERENCE_GROUP_UNIT}
                      reference={Resources.REFERENCE_GROUP_UNIT}
                      label="Группа элементов"
                      fullWidth
                      required
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                  </div>
                  <LongTextInput source="description_ru" label={jobLabel.description_ru} />
                  <LongTextInput source="description_en" label={jobLabel.description_en} />
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardContent className={classes.сardContent}>
                  <Typography variant="caption">Настройки</Typography>
                  <ReferenceInput
                    source={referenceJobParentIdField}
                    reference={Resources.REFERENCE_JOB}
                    resource={Resources.REFERENCE_JOB}
                    filter={{
                      [referenceJobReferencePrefix]: formData?.[referenceJobReferencePrefix],
                      isJob: false,
                    }}
                    label="Родительский элемент"
                    fullWidth
                    allowEmpty
                    emptyValue={null}
                    defaultValue={null}
                  >
                    <SelectInput optionText={rec => `${'–'.repeat(rec.level)} ${rec.name_ru}`} />
                  </ReferenceInput>
                  <BooleanInput
                    label="Является работой"
                    source="job"
                    required
                    defaultValue={false}
                    options={{
                      disabled: true,
                    }}
                  />
                </CardContent>
              </Card>
            </>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

const connectedJobEdit = connect(null, { push, goBack })(JobEditComponent);
export const JobEdit = withStyles(styles as any)(connectedJobEdit);
