import { GET_LIST, GET_MANY } from 'react-admin';
import { IRADataProviderParams } from 'Interfaces';
import { passportAttributesData } from 'Constants';

export const referencePassportAttributes = (type: string, params: IRADataProviderParams): any => {
  try {
    switch (type) {
      case GET_LIST: {
        return { data: passportAttributesData, total: passportAttributesData.length };
      }
      case GET_MANY: {
        return { data: passportAttributesData };
      }
      default:
        throw new Error(`Неизвестный метод получения passport_attr: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода passport_attr-${type}`);
  }
};
