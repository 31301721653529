/* eslint-disable @typescript-eslint/camelcase */

// 'ru' must be first in array
export const availableLanguages = ['ru', 'en'];

export const userNameLabel = {
  lastname_ru: 'Фамилия',
  firstname_ru: 'Имя',
  patronymic_ru: 'Отчество',
  lastname_en: 'Lastname',
  firstname_en: 'Firstname',
  patronymic_en: 'Patronymic',
  fullname_ru: 'Имя',
  fullname_en: 'Name',
};

export const commonLabel = {
  name_ru: 'Название',
  name_en: 'Name',
  description_ru: 'Описание',
  description_en: 'Description',
};

export const orgNameLabel = {
  name_ru: 'Название',
  name_en: 'Name',
  description_ru: 'Описание',
  description_en: 'Description',
};

export const jobLabel = {
  name_ru: 'Название',
  name_en: 'Name',
  description_ru: 'Описание',
  description_en: 'Description',
};

export const elementLabel = {
  name_ru: 'Название',
  name_en: 'Name',
  description_ru: 'Описание',
  description_en: 'Description',
};

export const projectLabel = {
  name_ru: 'Название',
  name_en: 'Name',
  description_ru: 'Описание',
  description_en: 'Description',
};
