/* eslint-disable @typescript-eslint/camelcase */

import axios from 'axios';
import { API_URL, REST, projectIdField, projectDataPath, projectRoleDataPath } from 'Constants';
import { get } from 'lodash';
import { PgUnAssoc, includeRoleIntoItem } from '../utils';

export const searchProjects = async (user, isAssigned = true, searchString = '') => {
  try {
    const filter = isAssigned ? { assigned_to: user } : { not_assigned_to: user };
    const response = await axios.post(`${API_URL}${REST.AdminProject.LIST}`, {
      extended: true,
      // TODO-08: костыль (name_ru)
      filter: { ...filter, name_ru: searchString },
    });
    const project = PgUnAssoc(get(response, projectDataPath, []));
    const role = PgUnAssoc(get(response, projectRoleDataPath, []));
    return isAssigned ? includeRoleIntoItem(project, projectIdField, role) : project;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllProjects = async () => {
  try {
    const response = await axios.post(`${API_URL}${REST.AdminProject.LIST}`, {});
    return PgUnAssoc(get(response, projectDataPath));
  } catch (error) {
    throw new Error(error);
  }
};

export const addProjectRole = async (relations, users, roles) => {
  try {
    await axios.post(`${API_URL}${REST.AdminUser.RELATION.SET}`, {
      relation: 'project',
      relation_id: relations,
      user_id: users,
      role_id: roles,
      append: false,
    });
  } catch (error) {
    throw new Error(error);
  }
};
