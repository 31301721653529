import React, { FC } from 'react';
import { List, Datagrid, TextField, ReferenceField, Filter, TextInput } from 'react-admin';
import {
  Resources,
  organisationPrefix,
  projectIdField,
  referenceProjectTypePrefix,
  projectLabel,
} from 'Constants';
import { getMultiLanguageName } from 'Components/utils/getFullName';

const ProjectFilter = props => (
  <Filter {...props}>
    <TextInput label={projectLabel.name_ru} source="name_ru" alwaysOn />
    <TextInput label={projectLabel.name_en} source="name_en" alwaysOn />
  </Filter>
);

const FullNameField = ({ record = {} }) => <span>{getMultiLanguageName(record)}</span>;

export const ProjectList: FC = props => (
  <List {...props} exporter={false} title="Список проектов" filters={<ProjectFilter />}>
    <Datagrid rowClick="edit">
      <TextField source={projectIdField} label="Id" />
      <TextField source="name_ru" label={projectLabel.name_ru} />
      <TextField source="name_en" label={projectLabel.name_en} />
      <ReferenceField
        source={organisationPrefix}
        resource={Resources.ORGS}
        reference={Resources.ORGS}
        label="Организация"
      >
        <FullNameField />
      </ReferenceField>
      <ReferenceField
        source={referenceProjectTypePrefix}
        resource={Resources.REFERENCE_PROJECT_TYPE}
        reference={Resources.REFERENCE_PROJECT_TYPE}
        label="Тип проекта"
      >
        <FullNameField />
      </ReferenceField>
      <TextField source="description_ru" label={projectLabel.description_ru} />
      <TextField source="description_en" label={projectLabel.description_en} />
    </Datagrid>
  </List>
);
