import React, { FC } from 'react';
import {
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  DisabledInput,
  FormDataConsumer,
} from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Card, CardContent } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import { organisationPrefix, Resources, categoryIdField } from 'Constants';
import { getMultiLanguageName } from '../../utils';
import { Color, getCategoryColors } from './ColorField';

const LabelColor = ({ record }) => (
  <>
    <span>Цвет категории. Сейчас установлен : </span>
    <Color value={`#${record.color}`} />
  </>
);

const CustomSelectInput = ({ record = {}, source, className }) => (
  <SelectInput
    source={source}
    label={<LabelColor record={record} />}
    choices={getCategoryColors()}
    className={className}
  />
);

const styles = {
  card: { width: '100%', marginBottom: '10px', position: 'relative' },
  cardContent: { position: 'relative', top: '25px' },
  groupWrapper: { display: 'flex' },
  groupItem: { marginRight: '20px' },
  btn: { position: 'absolute', right: '0', top: '0' },
  selectInput: { width: '100%' },
  grid: { display: 'grid', gap: '1.5rem', gridTemplateColumns: 'repeat(2, 1fr)' },
};

const RenderTitle = ({ record }: any) => <span>{record.name}</span>;

const CategoryEditComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => (
  <Edit title={<RenderTitle />} {...props}>
    <SimpleForm redirect="list">
      <>
        <Card className={classes.card}>
          <CardContent className={classes.сardContent}>
            <IconButton onClick={() => propsPush(`/${Resources.CATEGORY}`)} className={classes.btn}>
              <CloseIcon />
            </IconButton>
            <DisabledInput fullWidth required source={categoryIdField} label="Id" />
            <ReferenceInput
              source={organisationPrefix}
              resource={Resources.ORGS}
              reference={Resources.ORGS}
              label="Организация"
              fullWidth
              required
              disabled
            >
              <SelectInput optionText={record => getMultiLanguageName(record)} />
            </ReferenceInput>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent className={classes.сardContent}>
            <TextInput fullWidth source="code" required label="Код" />
            <div className={classes.grid}>
              <TextInput
                fullWidth
                source="name_ru"
                className={classes.groupItem}
                required
                label="Название"
              />
              <TextInput fullWidth source="name_en" className={classes.groupItem} label="Name" />
            </div>
            <FormDataConsumer>
              {({ formData }): JSX.Element => (
                <CustomSelectInput
                  record={formData}
                  source="color"
                  className={classes.selectInput}
                />
              )}
            </FormDataConsumer>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent className={classes.сardContent}>
            <BooleanInput
              source="is_sub_menu"
              label="Сделать подменю навигации"
              required
              defaultValue={false}
            />
            <BooleanInput
              source="has_sub_menu_year"
              label="Сделать подменю по году ввода в эксплуатацию"
              required
              defaultValue={false}
            />
          </CardContent>
        </Card>
      </>
    </SimpleForm>
  </Edit>
);

const connectedCategoryEdit = connect(null, { push })(CategoryEditComponent);
export const CategoryEdit = withStyles(styles as any)(connectedCategoryEdit);
