// TODO: set types
export const filterResource = (resource: any[], filters: {}): any[] => {
  const filtersKeys = Object.keys(filters);
  const filteredResource = resource.filter(el => {
    let result = true;
    filtersKeys.forEach(filter => {
      const filterLocale = `_${filter.split('_').pop()}`;
      const prop = filter.startsWith('filterName')
        ? `${el[`lastname${filterLocale}`]}${el[`firstname${filterLocale}`]}${
            el[`patronymic${filterLocale}`]
          }`
        : el[filter];
      if (!prop || !prop.toLowerCase().includes(filters[filter].toLowerCase())) {
        result = false;
      }
    });
    return result;
  });
  return filteredResource;
};
