import React, { FC } from 'react';
import {
  Edit,
  DisabledInput,
  SimpleForm,
  TextField,
  TextInput,
  SaveButton,
  Toolbar,
} from 'react-admin';
import { Card, CardContent } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Resources } from 'Constants';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import withStyles from '@material-ui/core/styles/withStyles';

interface IProps {
  push: Function;
  classes: Record<string, string>;
}

const styles = {
  card: { width: '100%', marginBottom: '1.5rem', marginTop: '1.5rem' },
  cardContent: { position: 'relative' },
  list: { width: '100%', display: 'none' },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 1000,
  },
};

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
));

const ServiceEditComponent: FC<IProps> = ({ push: propsPush, classes = {}, ...props }) => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <IconButton
            onClick={(): void => propsPush(`/${Resources.REFERENCE_SERVICES}`)}
            className={classes.closeBtn}
          >
            <CloseIcon />
          </IconButton>
          <DisabledInput source="id" label="Id" fullWidth />
        </CardContent>
      </Card>

      <TextField source="name" label="Название" />
      <TextInput source="fieldsFrom" fullWidth />
    </SimpleForm>
  </Edit>
);

const connectedCurrencyEdit = connect(null, { push })(ServiceEditComponent);

export const ServiceEdit = withStyles(styles as any)(connectedCurrencyEdit);
