import React, { useState, FC, useEffect } from 'react';
import { withDataProvider, showNotification } from 'react-admin';
import { searchProjects, addProjectRole, searchObjects, addObjectRole } from 'Providers';
import { parseRoleAliasToIds } from 'Providers/utils';
import { projectIdField, searchPanelConfig, roleIdField, objectIdField } from 'Constants';
import { IRolesProps } from './i';
import { CommonRolesComponent } from '../Components';

// TODO: add spinner
export const ProjectsRoles: FC<IRolesProps> = withDataProvider(props => {
  const { dispatch } = props;
  const {
    userId,
    fullName,
    allSystemRoles,
    projectRefreshNeeded,
    modalProps,
    setModalProps,
    setObjectRefreshNeeded,
    selectedObjects,
  } = props;
  const [searchFilter, setSearchFilter] = useState('');
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [searchProjectValue, setSearchProjectValue] = useState(null);
  const [asyncDefaultOptions, setAsyncDefaultOptions] = useState([]);

  const searchFilterChangeHandler = (event): void => {
    setSearchFilter(event.target.value);
  };

  const projectAsyncSearchHandler = async (inputValue: string) => {
    try {
      if (inputValue.length < 3 && inputValue.length !== 0) {
        return asyncDefaultOptions;
      }
      const response = await searchProjects(userId, false, inputValue);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

  const projectAsyncSearchOnChangeHandler = (item): void => {
    setSearchProjectValue(item || null);
  };

  const getStartData = async () => {
    const assignedProjectPromise = searchProjects(userId);
    const notAssignedProjectPromise = searchProjects(userId, false, '');
    const [assignedProject, notAssignedProject] = await Promise.all([
      assignedProjectPromise,
      notAssignedProjectPromise,
    ]);
    const projectWithRoleData = parseRoleAliasToIds(assignedProject, allSystemRoles);
    setSelectedProjects(projectWithRoleData);
    props.setSelectedProjects(projectWithRoleData);
    setAsyncDefaultOptions(notAssignedProject);
  };

  const addButtonHandler = async (user, project) => {
    try {
      await addProjectRole(
        [project[projectIdField]],
        [user],
        [searchPanelConfig.project.minimalRoleId],
      );
      setSearchProjectValue(null);
      await getStartData();
    } catch (error) {
      throw new Error(error);
    }
  };

  const acceptRole = roles => {
    const rolesName = allSystemRoles
      .filter(role => roles.includes(role[roleIdField]))
      // TODO-08: костыль
      .map(el => `"${el.name_ru || el.name}"`)
      .join(', ');
    const selectedProjectsIds = selectedProjects.map(el => el[projectIdField]);
    setModalProps({
      show: true,
      title: 'Назначение ролей на все проекты пользователя',
      body: `Вы уверены, что хотите установить роли:
      ${rolesName} у всех проектов (${selectedProjectsIds.length} шт.)
      пользователя "${fullName}"?`,
      submit: async () => {
        try {
          await addProjectRole(selectedProjectsIds, [userId], roles);
          await getStartData();
          setModalProps({ show: false });
        } catch (error) {
          throw new Error(error);
        }
      },
      cancel: () => setModalProps({ show: false }),
    });
  };

  const appointItems = (projectId, roles) => {
    // TODO-08: костыль
    const { name_ru: nameRu, name } = selectedProjects.find(el => el[projectIdField] === projectId);
    setModalProps({
      show: true,
      title: 'Назначение пользователю ролей на все объекты конкретного проекта',
      body: `Вы уверены, что хотите установить пользователю "${fullName}"
      минимальную роль "Объект, пользователь" на все объекты из проекта "${nameRu || name}"?`, // TODO-08: костыль
      submit: async () => {
        try {
          const objects = await searchObjects(userId, false, '', [projectId]);
          const objectsId = objects.map(obj => obj[objectIdField]);
          if (objects.length > 0) {
            await addObjectRole(objectsId, [userId], [searchPanelConfig.object.minimalRoleId]);
          } else {
            dispatch(showNotification('В проекте нет ни одного объекта', 'warning'));
          }
          await getStartData();
          setObjectRefreshNeeded(prev => !prev);
          setModalProps({ show: false });
        } catch (error) {
          throw new Error(error);
        }
      },
      cancel: () => setModalProps({ show: false }),
    });
  };

  const deleteItem = async itemId => {
    const isCanNotDel = selectedObjects.some(obj => obj[projectIdField] === itemId);
    if (isCanNotDel) {
      dispatch(showNotification('У пользователя есть права на дочерние объекты', 'warning'));
    } else {
      try {
        await addProjectRole([itemId], [userId], []);
        await getStartData();
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const changeItemRole = async (e, projectId) => {
    if (e.target.value.length) {
      await addProjectRole([projectId], [userId], e.target.value);
      await getStartData();
    } else {
      dispatch(showNotification('У проекта должна быть хотя бы одна роль', 'warning'));
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await getStartData();
      } catch (error) {
        setSelectedProjects([]);
        props.setSelectedProjects([]);
        setAsyncDefaultOptions([]);
        throw new Error(error);
      }
    })();
  }, [projectRefreshNeeded]);

  return (
    <CommonRolesComponent
      {...props}
      searchFilter={searchFilter}
      searchFilterChangeHandler={searchFilterChangeHandler}
      itemAsyncSearchHandler={projectAsyncSearchHandler}
      itemAsyncSearchOnChangeHandler={projectAsyncSearchOnChangeHandler}
      itemAsyncSearchDefaultOptions={asyncDefaultOptions}
      itemAsyncSearchValue={searchProjectValue}
      selectedItems={selectedProjects}
      allSystemRoles={allSystemRoles}
      addButtonHandler={addButtonHandler}
      bulkAction={{ acceptRole, appointItems, deleteItem }}
      changeItemRole={changeItemRole}
      modalProps={modalProps}
    />
  );
});
