import React, { FC } from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  LongTextInput,
  DisabledInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  DeleteButton,
  ReferenceField,
  NumberField,
  List,
} from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Card, CardContent, Button } from '@material-ui/core';
import {
  scheduleTemplateIdField,
  Resources,
  schedulePrefix,
  scheduleIdField,
  scheduleTemplatePrefix,
  referenceObjectLifeCycleStagePrefix,
  scheduleParentIdField,
} from 'Constants';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { Add } from '@material-ui/icons';

const styles = {
  card: { width: '100%', marginBottom: '1.5rem', marginTop: '1.5rem' },
  cardContent: { position: 'relative' },
  list: { width: '100%', display: 'none' },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 1000,
  },
  textRight: { textAlight: 'right' },
  badge: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    display: 'inline-flex',
    padding: '.025rem .5rem',
    borderRadius: '1rem',
    marginLeft: '1rem',
  },
};

const RenderTitle = ({ record }: any) => <span>{record.name}</span>;
const NameWithBadge = ({ record, badgeClassName, ...rest }) => (
  <span>
    {record.level > 0 && `${'—'.repeat(record.level)} `}
    {record.name}
    {record.child_count > 0 && <span className={badgeClassName}>{record.child_count}</span>}
  </span>
);

const AddScheduleButton = ({ record }: any) => {
  const AddScheduleLink = (props: any) => (
    <Link to={`/${Resources.SCHEDULE}/create?${scheduleTemplatePrefix}=${record.id}`} {...props} />
  );

  return (
    <Button component={AddScheduleLink} variant="text" color="primary" size="small">
      <Add />
      &nbsp;New element
    </Button>
  );
};

const ScheduleTemplateEditComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => {
  const { id } = props;
  return (
    <Edit title={<RenderTitle />} {...props}>
      <TabbedForm redirect="list">
        <FormTab label="Общая информация">
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <DisabledInput fullWidth required source={scheduleTemplateIdField} label="Id" />
              <IconButton
                onClick={() => propsPush(`/${Resources.SCHEDULE_TEMPLATE}`)}
                className={classes.closeBtn}
              >
                <CloseIcon />
              </IconButton>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <TextInput fullWidth required source="code" label="Код" />
              <TextInput fullWidth required source="name" label="Название" />
              <LongTextInput fullWidth source="description" label="Описание" defaultValue="" />
            </CardContent>
          </Card>
        </FormTab>
        <FormTab label="Структура">
          <ReferenceManyField
            resource={Resources.SCHEDULE}
            reference={Resources.SCHEDULE}
            target={schedulePrefix}
            label=""
            fullWidth
          >
            <Datagrid rowClick="edit">
              <TextField source={scheduleIdField} label="Id" />
              <NameWithBadge
                record={record => record}
                label="Название"
                badgeClassName={classes.badge}
              />
              <ReferenceField
                source={referenceObjectLifeCycleStagePrefix}
                reference={Resources.REFERENCE_OBJECT_LIFE_CYCLE_STAGE}
                resource={Resources.REFERENCE_OBJECT_LIFE_CYCLE_STAGE}
                label="Этап ЖЦ"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                source={scheduleParentIdField}
                reference={Resources.SCHEDULE}
                resource={Resources.SCHEDULE}
                label="Родительский элемент"
                allowEmpty
              >
                <TextField source="name" />
              </ReferenceField>
              <DeleteButton redirect={`/${Resources.SCHEDULE_TEMPLATE}/${id}/1`} />
            </Datagrid>
          </ReferenceManyField>
          <AddScheduleButton />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const connectedScheduleTemplateEdit = connect(null, { push })(ScheduleTemplateEditComponent);
export const ScheduleTemplateEdit = withStyles(styles as any)(connectedScheduleTemplateEdit);
