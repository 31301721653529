import axios from 'axios';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import { get } from 'lodash';
import {
  methodsCookie,
  methodsLocaleStorage,
  axiosInitialization,
  axiosResetDefaults,
} from 'Utils';
import { API_URL, REST, tokenDataPath, infoDataPath } from 'Constants';
import { IAuthProvider } from 'Interfaces';

export const authProvider = async (type: string, params: IAuthProvider): Promise<string> => {
  switch (type) {
    case AUTH_LOGIN: {
      const { username, password } = params;
      try {
        const authResponse = await axios.post(`${API_URL}${REST.Admin.AUTH}`, {
          email: username,
          password,
        });
        const token = get(authResponse, tokenDataPath);
        methodsCookie.setItem('token', token);
        axiosInitialization(token);
        const infoResponse = await axios.post(`${API_URL}${REST.Admin.SELF.INFO}`, {});
        const info = get(infoResponse, infoDataPath);
        methodsLocaleStorage.setItem('selfInfo', JSON.stringify(info));
        return `Добро пожаловать, ${info.firstname || username}`;
      } catch (error) {
        throw new Error('Ошибка в логине или пароле');
      }
    }
    case AUTH_LOGOUT: {
      try {
        await axios.post(`${API_URL}${REST.Admin.LOGOUT}`, {});
        axiosResetDefaults();
        methodsLocaleStorage.deleteItem('selfInfo');
        methodsCookie.deleteItem('token');
        return 'Вы вышли из системы';
      } catch (e) {
        console.log('e', e);
        return null;
      }
    }
    case AUTH_ERROR: {
      const { status } = params;
      const token = methodsCookie.getItem('token');
      if (status === 401 || status === 403 || !token) {
        throw new Error('Ошибка авторизации');
      }
      return `Проверка ошибки авторизации прошла успешно: ${status}`;
    }
    case AUTH_CHECK: {
      const token = methodsCookie.getItem('token');
      if (token) {
        return 'Проверка авторизации прошла успешно';
      }
      throw new Error('Пользователь не авторизован');
    }
    case AUTH_GET_PERMISSIONS: {
      if (methodsCookie.getItem('token')) {
        return 'true';
      }
      throw new Error('Permissions не были получены');
    }
    default: {
      throw new Error('Неизвестный метод авторизации');
    }
  }
};
