import React, { FC } from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import { Resources } from 'Constants';
import { aliasRegex } from '../../../Utils/stringMethods';

const styles = {
  card: { width: '100%', marginBottom: '10px' },
  сardContent: { display: 'flex', flexDirection: 'column', position: 'relative' },
  btn: { position: 'absolute', right: '0', top: '0' },
};

const PermissionCreateComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => (
  <Create title="Новый тип прав" {...props}>
    <SimpleForm redirect="list">
      <Card className={classes.card}>
        <CardContent className={classes.сardContent}>
          <IconButton onClick={() => propsPush(`/${Resources.PERMISSION}`)} className={classes.btn}>
            <CloseIcon />
          </IconButton>
          <TextInput source="name" label="Описание" />
          <TextInput source="alias" label="Код" validate={aliasRegex()} />
        </CardContent>
      </Card>
    </SimpleForm>
  </Create>
);

const connectedPermissionCreate = connect(null, { push })(PermissionCreateComponent);
export const PermissionCreate = withStyles(styles as any)(connectedPermissionCreate);
