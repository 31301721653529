import React, { FC } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
  Create,
  SimpleForm,
  TextInput,
  LongTextInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import { Resources } from 'Constants';

const styles = {
  card: { width: '100%', marginBottom: '10px' },
  сardContent: { display: 'flex', flexDirection: 'column', position: 'relative' },
  btn: { position: 'absolute', right: '0', top: '0' },
};

const RenderTitle = ({ record }: any) => <span>{record.name}</span>;

const JobReferenceCreateComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => (
  <Create title={<RenderTitle />} {...props}>
    <SimpleForm redirect="list">
      <Card className={classes.card}>
        <CardContent className={classes.сardContent}>
          <IconButton
            onClick={() => propsPush(`/${Resources.REFERENCE_JOB_REFERENCE}`)}
            className={classes.btn}
          >
            <CloseIcon />
          </IconButton>
          <TextInput source="code" label="Код" fullWidth />
          <TextInput source="name" label="Название" fullWidth required />
          <ReferenceInput
            source="permission"
            reference={Resources.PERMISSION}
            label="Право доступа"
            fullWidth
            required
            helperText={null}
          >
            <SelectInput optionText="name" optionValue="alias" />
          </ReferenceInput>
          <LongTextInput source="description" label="Описание" fullWidth />
        </CardContent>
      </Card>
    </SimpleForm>
  </Create>
);

const connectedJobReferenceCreate = connect(null, { push })(JobReferenceCreateComponent);
export const JobReferenceCreate = withStyles(styles as any)(connectedJobReferenceCreate);
