/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';
import { API_URL, REST, objectIdField, objectRoleDataPath, objectDataPath } from 'Constants';
import { get, isNull } from 'lodash';
import { PgUnAssoc, includeRoleIntoItem } from '../utils';

export const searchObjects = async (
  user,
  isAssigned = true,
  searchString = '',
  projectId = null,
) => {
  try {
    const filter = isAssigned ? { assigned_to: user } : { not_assigned_to: user };
    const response = await axios.post(`${API_URL}${REST.AdminObject.LIST}`, {
      project_id: projectId,
      extended: true,
      // TODO-08: костыль (name_ru)
      filter: isNull(projectId) ? { ...filter, name_ru: searchString } : {},
    });
    const object = PgUnAssoc(get(response, objectDataPath, []));
    const role = PgUnAssoc(get(response, objectRoleDataPath, []));
    return isAssigned ? includeRoleIntoItem(object, objectIdField, role) : object;
  } catch (e) {
    throw new Error(e);
  }
};

export const addObjectRole = async (relations, users, roles): Promise<any> => {
  try {
    await axios.post(`${API_URL}${REST.AdminUser.RELATION.SET}`, {
      relation: 'object',
      relation_id: relations,
      user_id: users,
      role_id: roles,
      append: false,
    });
  } catch (error) {
    throw new Error(error);
  }
};
