import React, { FC } from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import { Resources } from 'Constants';
import { keyRegex } from '../../../Utils/stringMethods';

const styles = {
  card: { width: '100%', marginBottom: '10px' },
  сardContent: { display: 'flex', flexDirection: 'column', position: 'relative' },
  btn: { position: 'absolute', right: '0', top: '0' },
};

const PassportFieldCreateComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => (
  <Create title="Новый справочник паспортных полей" {...props}>
    <SimpleForm redirect="list">
      <Card className={classes.card}>
        <CardContent className={classes.сardContent}>
          <IconButton
            onClick={() => propsPush(`/${Resources.PASSPORT_FIELD}`)}
            className={classes.btn}
          >
            <CloseIcon />
          </IconButton>
          <TextInput source="code" label="Код" className={classes.сardItem} validate={keyRegex()} />
          <TextInput source="name" label="Название" className={classes.сardItem} />
          <TextInput source="description" label="Описание" className={classes.сardItem} />
        </CardContent>
      </Card>
    </SimpleForm>
  </Create>
);

const connectedPassportFieldCreate = connect(null, { push })(PassportFieldCreateComponent);
export const PassportFieldCreate = withStyles(styles as any)(connectedPassportFieldCreate);
