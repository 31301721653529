import React, { FC } from 'react';
import {
  Edit,
  TabbedForm,
  SimpleForm,
  TextInput,
  ReferenceInput,
  DisabledInput,
  SelectInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  ReferenceField,
  DeleteButton,
  EditButton,
  ReferenceArrayInput,
  SelectArrayInput,
  AutocompleteArrayInput,
  ChipField,
} from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Button, Card, CardContent } from '@material-ui/core';
import {
  folderIdField,
  folderTemplatesIdField,
  folderTemplatesPrefix,
  fileTemplatePrefix,
  Resources,
  fileTemplateIdField,
  folderPrefix,
} from 'Constants';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import { Add } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { filterToQuery } from 'Components/utils';
import CloseIcon from '@material-ui/icons/Close';

const styles = {
  card: {
    width: '100%',
    marginBottom: '1.5rem',
  },
  cardContent: {
    position: 'relative',
  },
  list: {
    width: '100%',
    display: 'none',
  },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 1000,
  },
};

const RenderTitle = ({ record }: any) => <span>{record.name}</span>;

const FolderEditComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => {
  const innerProps = { ...props };
  const { id } = innerProps;
  const folderTemplateString: string = innerProps.history.location.search;
  const folderTemplate = folderTemplateString
    ? folderTemplateString.replace(`?${folderTemplatesPrefix}=`, '')
    : false;
  const redirect = folderTemplate
    ? `/${Resources.FOLDER_TEMPLATES}/${folderTemplate}/1`
    : `/${Resources.FOLDER_TEMPLATES}`;
  return (
    <Edit title={<RenderTitle />} {...props}>
      <SimpleForm redirect={redirect}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <DisabledInput fullWidth required source={folderIdField} label="Id" />
            <IconButton onClick={() => propsPush(redirect)} className={classes.closeBtn}>
              <CloseIcon />
            </IconButton>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <ReferenceInput
              source={folderTemplatesPrefix}
              reference={Resources.FOLDER_TEMPLATES}
              label="Шаблон"
              fullWidth
              required
              disabled
            >
              <SelectInput optionText={record => record.name} />
            </ReferenceInput>
            <TextInput fullWidth required source="name" label="Название" />
            <ReferenceInput
              source="parent_id"
              reference={Resources.FOLDER_TEMPLATES_FOLDER}
              label="Родительская папка"
              filter={{ id: folderTemplate }}
              fullWidth
              allowEmpty
            >
              <SelectInput optionText="name" resettable />
            </ReferenceInput>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <ReferenceArrayInput
              source={fileTemplatePrefix}
              reference={Resources.FILE_TEMPLATE}
              resource={Resources.FILE_TEMPLATE}
              label="Шаблоны файлов"
              fullWidth
              allowEmpty
            >
              <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
          </CardContent>
        </Card>
        {/* <ReferenceManyField
          resource={Resources.FILE_TEMPLATE}
          reference={Resources.FILE_TEMPLATE}
          fullWidth
          label=""
          target={folderPrefix}
        >
          <Datagrid rowClick="edit">
            <TextField source={fileTemplateIdField} label="Id" />
            <TextField source="name" label="Название" />
            <DeleteButton redirect={`/${Resources.FOLDER_TEMPLATES_FOLDER}/${id}/1`} />
          </Datagrid>
        </ReferenceManyField> */}
      </SimpleForm>
    </Edit>
  );
};

// export const FolderEdit = connect(
//   null, { push },
// )(FolderEditComponent);

const connectedFolderEdit = connect(null, { push })(FolderEditComponent);
export const FolderEdit = withStyles(styles as any)(connectedFolderEdit);
