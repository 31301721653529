import React, { FC } from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { referenceJobReferenceIdField } from 'Constants';

export const JobReferenceList: FC = props => (
  <List {...props} exporter={false} title="Справочник работ">
    <Datagrid rowClick="edit">
      <TextField source={referenceJobReferenceIdField} label="Id" />
      <TextField source="code" label="Код" />
      <TextField source="name" label="Название" />
      <TextField source="description" label="Описание" />
    </Datagrid>
  </List>
);
