import React, { FC } from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, DateInput } from 'react-admin';
import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import { Resources, organisationPrefix } from 'Constants';
import { PasswordInput } from 'Components/Common';
import { getMultiLanguageName } from 'Components/utils/getFullName';
import { FullName } from './FullName';

const styles = {
  card: { width: '100%', marginBottom: '10px' },
  сardContent: { display: 'flex', flexDirection: 'column', position: 'relative' },
  btn: { position: 'absolute', right: '0', top: '0' },
  roleSelect: { width: '90%', margin: '20px' },
};

export const UserCreateComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => (
  <Create title="Новый пользователь" {...props}>
    <SimpleForm redirect="edit">
      <Card className={classes.card}>
        <CardContent className={classes.сardContent}>
          <IconButton onClick={() => propsPush(`/${Resources.USERS}`)} className={classes.btn}>
            <CloseIcon />
          </IconButton>
          <FullName locale="ru" />
          <FullName locale="en" />
          <DateInput source="birthdate" label="Дата изменения учетной записи" />
          <TextInput source="email" label="E-mail" className={classes.сardItem} />
          <TextInput source="phone" label="Телефон" className={classes.сardItem} />
          <TextInput source="position" label="Должность" className={classes.cardItem} />
          <ReferenceInput
            resource={Resources.ORGS}
            source={organisationPrefix}
            reference={Resources.ORGS}
            label="Организация"
            className={classes.сardItem}
            required
          >
            <SelectInput optionText={record => getMultiLanguageName(record)} />
          </ReferenceInput>
          <PasswordInput source="password" label="Пароль" required />
        </CardContent>
      </Card>
    </SimpleForm>
  </Create>
);

const connectedUserCreate = connect(null, { push, goBack })(UserCreateComponent);
export const UserCreate = withStyles(styles as any)(connectedUserCreate);
