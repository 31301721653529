/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';
import {
  GET_LIST,
  GET_ONE,
  UPDATE,
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_MANY_REFERENCE,
} from 'react-admin';
import {
  API_URL,
  REST,
  objectDataPath,
  objectIdField,
  objectPrefix,
  projectIdField,
  projectPrefix,
  referenceObjectTypeIdField,
  referenceObjectTypePrefix,
  objectMeta,
  objectRelation,
  imageDataPath,
  imagePrefix,
  folderTemplatesIdField,
  folderTemplatesPrefix,
  scheduleTemplatePrefix,
  scheduleTemplateIdField,
  scheduleAfterIdField,
  referenceElementReferencePrefix,
  referenceElementReferenceIdField,
  referenceJobReferencePrefix,
  referenceJobReferenceIdField,
  referenceJobReplaceIdField,
  referenceJobPrefix,
  approvalKs2IdField,
  approvalPrefix,
  approvalKs3IdField,
} from 'Constants';
import {
  IRADataProviderParams,
  IRAGetOneParams,
  IRACreateParams,
  IRAUpdateParams,
  IRADeleteParams,
  IObjectListResp,
  IObjectUpdateProvider,
  IObjectCreateProvider,
  IRADeleteManyParams,
  IRAGetManyReferenceParams,
  IRAGetListParams,
} from 'Interfaces';
import { get } from 'lodash';
import {
  setPrefix,
  removePrefix,
  PgUnAssoc,
  mapMetaToFields,
  mapMetaFromFields,
  removePrefixInArray,
  mapImageToFields,
  parseRAParamsToQuery,
} from '../utils';

export const objectProvider = async (type: string, params: IRADataProviderParams): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const { pagination } = params as IRAGetListParams;
        const { page: offset, perPage: limit } = pagination;
        const query = parseRAParamsToQuery(params as IRAGetListParams, 'object');
        const response = await axios.post(`${API_URL}${REST.AdminObject.LIST}`, {
          ...query,
          limit,
          offset: (offset - 1) * limit,
        });
        const parsedObjects = PgUnAssoc(get(response, objectDataPath));
        const objectsWithId = parsedObjects.map((el: IObjectListResp) => ({
          ...el,
          id: setPrefix(objectPrefix, el[objectIdField]),
          [projectPrefix]: setPrefix(projectPrefix, el[projectIdField]),
          [referenceObjectTypePrefix]: setPrefix(
            referenceObjectTypePrefix,
            el[referenceObjectTypeIdField],
          ),
          [folderTemplatesPrefix]: setPrefix(folderTemplatesPrefix, el[folderTemplatesIdField]),
          [scheduleTemplatePrefix]: setPrefix(scheduleTemplatePrefix, el[scheduleTemplateIdField]),
          settings: {
            ...el.settings,
            [referenceElementReferencePrefix]: setPrefix(
              referenceElementReferencePrefix,
              el.settings[referenceElementReferenceIdField],
            ),
            [referenceJobReferencePrefix]: setPrefix(
              referenceJobReferencePrefix,
              el.settings[referenceJobReferenceIdField],
            ),
            [approvalKs2IdField]:
              setPrefix(approvalPrefix, el.settings[approvalKs2IdField]) || null,
            [approvalKs3IdField]:
              setPrefix(approvalPrefix, el.settings[approvalKs3IdField]) || null,
          },
        }));
        return { data: objectsWithId, total: response.data.result.total };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(objectPrefix, id);

        const detail = await axios.post(`${API_URL}${REST.AdminObject.DETAIL}`, {
          object_id: clearId,
        });

        const response = await axios.post(`${API_URL}${REST.AdminObject.LIST}`, {
          [objectIdField]: [clearId],
        });
        const parsedObject = PgUnAssoc(get(response, objectDataPath))[0];
        const { settings: parsedObjectSettings } = parsedObject;

        // TODO: перенести в imageProvider
        const images = await axios({
          method: 'post',
          url: `${API_URL}${REST.AdminImage.LIST}`,
          data: {
            image_relation: objectRelation,
            image_relation_id: [clearId],
          },
        });
        const parsedImage = mapImageToFields(PgUnAssoc(get(images, imageDataPath)));
        // TODO: перенести в imageProvider

        return {
          data: {
            ...parsedObject,
            id: setPrefix(objectPrefix, parsedObject[objectIdField]),
            services: detail.data.result.services,
            servicesConfigurations: detail.data.result.servicesConfigurations,
            [projectPrefix]: setPrefix(projectPrefix, parsedObject[projectIdField]),
            [referenceObjectTypePrefix]: setPrefix(
              referenceObjectTypePrefix,
              parsedObject[referenceObjectTypeIdField],
            ),
            [folderTemplatesPrefix]:
              parsedObject[folderTemplatesIdField] !== null
                ? setPrefix(folderTemplatesPrefix, parsedObject[folderTemplatesIdField])
                : null,
            [scheduleTemplatePrefix]: setPrefix(
              scheduleTemplatePrefix,
              parsedObject[scheduleTemplateIdField],
            ),
            [objectMeta]: mapMetaToFields(parsedObject[objectMeta]),
            [imagePrefix]: parsedImage,
            settings: {
              ...parsedObjectSettings,
              [referenceElementReferencePrefix]: setPrefix(
                referenceElementReferencePrefix,
                parsedObjectSettings[referenceElementReferenceIdField],
              ),
              [referenceJobReferencePrefix]: setPrefix(
                referenceJobReferencePrefix,
                parsedObjectSettings[referenceJobReferenceIdField],
              ),
              [approvalKs2IdField]:
                setPrefix(approvalPrefix, parsedObjectSettings[approvalKs2IdField]) || null,
              [approvalKs3IdField]:
                setPrefix(approvalPrefix, parsedObjectSettings[approvalKs3IdField]) || null,
            },
          },
        };
      }
      case UPDATE: {
        const rawData = (params as IRAUpdateParams).data as IObjectUpdateProvider;

        const {
          [imagePrefix]: unusedImage,
          id,
          servicesConfigurations,
          [projectPrefix]: unUsed1,
          [projectIdField]: usUsed2,
          [referenceObjectTypePrefix]: objectType,
          [folderTemplatesPrefix]: folderTemplate,
          [scheduleTemplatePrefix]: scheduleTemplate,
          settings,
          ...sendData
        } = rawData;

        const clearObjectTypeId = removePrefix(referenceObjectTypePrefix, objectType);
        const clearFolderTemplateId =
          folderTemplate !== null ? removePrefix(folderTemplatesPrefix, folderTemplate) : null;
        const clearScheduleTemplateId =
          scheduleTemplate !== null ? removePrefix(scheduleTemplatePrefix, scheduleTemplate) : null;

        await axios.post(`${API_URL}${REST.AdminObject.SETTINGS.SET}`, {
          guntt_status_gray: false,
          image_gallery_is_public: false,
          allow_schedule_object: false,
          critical_path: false,
          ...settings,
          [objectIdField]: removePrefix(objectPrefix, id),
          [referenceElementReferenceIdField]: removePrefix(
            referenceElementReferencePrefix,
            settings[referenceElementReferencePrefix],
          ),
          [referenceJobReferenceIdField]: removePrefix(
            referenceJobReferencePrefix,
            settings[referenceJobReferencePrefix],
          ),
          [referenceJobReplaceIdField]:
            removePrefix(referenceJobPrefix, settings[referenceJobReplaceIdField]) || null,
          [approvalKs2IdField]: removePrefix(approvalPrefix, settings[approvalKs2IdField]) || null,
          [approvalKs3IdField]: removePrefix(approvalPrefix, settings[approvalKs3IdField]) || null,
        });

        await axios.post(`${API_URL}${REST.AdminObject.SCHEDULE_TEMPLATE.SET}`, {
          [objectIdField]: removePrefix(objectPrefix, id),
          [scheduleTemplateIdField]: clearScheduleTemplateId,
        });

        await axios.post(`${API_URL}${REST.AdminObject.FOLDER_TEMPLATE.SET}`, {
          [objectIdField]: removePrefix(objectPrefix, id),
          [folderTemplatesIdField]: clearFolderTemplateId,
        });

        const response = await axios.post(`${API_URL}${REST.AdminObject.UPDATE}`, {
          ...sendData,
          [referenceObjectTypeIdField]: clearObjectTypeId,
          [objectMeta]: mapMetaFromFields(sendData[objectMeta]),
        });

        const parsedObject = get(response, objectDataPath);

        const objectConfigurations = Object.entries(servicesConfigurations).map((tuple: any) => ({
          enabled: tuple[1]?.enabled,
          serviceId: Number(tuple[0]),
          fields: tuple[1]?.fields,
        }));

        await axios.post(`${API_URL}${REST.AdminObject.UPDATE_SERVICES}`, {
          objectId: rawData.object_id,
          objectConfigurations: objectConfigurations,
        });

        return {
          data: {
            ...parsedObject,
            id: setPrefix(objectPrefix, parsedObject[objectIdField]),
            [projectPrefix]: setPrefix(projectPrefix, parsedObject[projectIdField]),
            [referenceObjectTypePrefix]: setPrefix(
              referenceObjectTypePrefix,
              parsedObject[referenceObjectTypeIdField],
            ),
            [folderTemplatesPrefix]: setPrefix(
              folderTemplatesPrefix,
              parsedObject[folderTemplatesIdField],
            ),
            [scheduleTemplatePrefix]: setPrefix(
              scheduleTemplatePrefix,
              parsedObject[scheduleAfterIdField],
            ),
            [objectMeta]: mapMetaToFields(parsedObject[objectMeta]),
          },
        };
      }
      case CREATE: {
        const { data: rawData } = params as IRACreateParams;

        const {
          [imagePrefix]: unusedImage,
          [projectPrefix]: projectId,
          [referenceObjectTypePrefix]: objectTypeId,
          [folderTemplatesPrefix]: folderTemplate,
          [scheduleTemplatePrefix]: scheduleTemplate,
          settings,
          ...others
        } = rawData as IObjectCreateProvider;
        const clearProjectId = removePrefix(projectPrefix, projectId);
        const clearObjectTypeId = removePrefix(referenceObjectTypePrefix, objectTypeId);
        const clearFolderTemplateId =
          folderTemplate !== null ? removePrefix(folderTemplatesPrefix, folderTemplate) : null;
        const clearScheduleTemplateId =
          scheduleTemplate !== null ? removePrefix(scheduleTemplatePrefix, scheduleTemplate) : null;
        const sendData = {
          ...others,
          [projectIdField]: clearProjectId,
          [referenceObjectTypeIdField]: clearObjectTypeId,
        };
        const response = await axios.post(`${API_URL}${REST.AdminObject.CREATE}`, {
          ...sendData,
          [objectMeta]: mapMetaFromFields(others[objectMeta]),
          [referenceJobReferenceIdField]: removePrefix(
            referenceJobReferencePrefix,
            settings[referenceJobReferencePrefix],
          ),
        });
        const parsedObject = get(response, objectDataPath);

        // Set object settings
        await axios.post(`${API_URL}${REST.AdminObject.SETTINGS.SET}`, {
          guntt_status_gray: false,
          image_gallery_is_public: false,
          allow_schedule_object: false,
          critical_path: false,
          resource_fact_method: parsedObject.settings.resource_fact_method,
          ...(settings as {}),
          [objectIdField]: parsedObject[objectIdField],
          [referenceElementReferenceIdField]: removePrefix(
            referenceElementReferencePrefix,
            settings[referenceElementReferencePrefix],
          ),
          [referenceJobReferenceIdField]: removePrefix(
            referenceJobReferencePrefix,
            settings[referenceJobReferencePrefix],
          ),
        });

        // Set schedule template to object
        await axios.post(`${API_URL}${REST.AdminObject.SCHEDULE_TEMPLATE.SET}`, {
          [objectIdField]: parsedObject[objectIdField],
          [scheduleTemplateIdField]: clearScheduleTemplateId,
        });

        // Set folder template to object
        await axios.post(`${API_URL}${REST.AdminObject.FOLDER_TEMPLATE.SET}`, {
          [objectIdField]: parsedObject[objectIdField],
          [folderTemplatesIdField]: clearFolderTemplateId,
        });

        return {
          data: {
            ...parsedObject,
            id: setPrefix(objectPrefix, parsedObject[objectIdField]),
            [projectPrefix]: setPrefix(projectPrefix, parsedObject[projectIdField]),
            [referenceObjectTypePrefix]: setPrefix(
              referenceObjectTypePrefix,
              parsedObject[referenceObjectTypeIdField],
            ),
            [objectMeta]: mapMetaToFields(parsedObject[objectMeta]),
          },
        };
      }
      case GET_MANY_REFERENCE: {
        const { id } = params as IRAGetManyReferenceParams;
        const clearId = removePrefix(projectPrefix, id);
        const sendData = { [projectIdField]: [clearId] };
        const query = parseRAParamsToQuery(params as IRAGetListParams, 'object');
        const response = await axios.post(`${API_URL}${REST.AdminObject.LIST}`, {
          ...sendData,
          ...query,
        });
        const parsedObjects = PgUnAssoc(get(response, objectDataPath));
        const objectsWithId = parsedObjects.map((el: IObjectListResp) => ({
          ...el,
          id: setPrefix(objectPrefix, el[objectIdField]),
          [projectPrefix]: setPrefix(projectPrefix, el[projectIdField]),
          [referenceObjectTypePrefix]: setPrefix(
            referenceObjectTypePrefix,
            el[referenceObjectTypeIdField],
          ),
        }));
        return { data: objectsWithId, total: objectsWithId.length };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(objectPrefix, id);
        await axios.post(`${API_URL}${REST.AdminObject.DELETE}`, { [objectIdField]: [clearId] });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, objectPrefix);
        await axios.post(`${API_URL}${REST.AdminObject.DELETE}`, { [objectIdField]: clearIds });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${objectPrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${objectPrefix}-${type}`);
  }
};

export const objectTransfer = async (objectId: number, projectId: number): Promise<any> => {
  try {
    await axios.post(`${API_URL}${REST.AdminObject.TRANSFER}`, {
      object_id: objectId,
      project_id: projectId,
    });
  } catch (e) {
    throw new Error('Ошибка при смене проекта');
  }
};

export const revokeRelation = async (userId: number, relationId: number): Promise<any> => {
  try {
    await axios.post(`${API_URL}${REST.AdminUser.RELATION.REVOKE}`, {
      relation: 'object',
      relation_id: relationId,
      user_id: userId,
    });
  } catch (e) {
    throw new Error('Ошибка при удалении прав');
  }
};
