/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';
import { API_URL, PICKER_DATE_MASK, REST } from 'Constants';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import { DOWNLOAD_EVENT_FILE } from 'Providers/utils/customActions';

export const eventLogProvider = async (type: string, params): Promise<any> => {
  try {
    switch (type) {
      case DOWNLOAD_EVENT_FILE: {
        const { payload } = params;
        const body = {
          history_action: payload.history_action,
          date_from: payload.date_from,
          date_to: payload.date_to,
          organisation_id: payload.organisation_id ? payload.organisation_id : null,
        };
        try {
          const result = await axios.post(`${API_URL}${REST.AdminHistory.DOWNLOAD}`, body, {
            responseType: 'blob',
          });
          const fileName =
            result.headers['content-disposition'].split('=')[1].replace(/"/g, '') || 'report.xlsx';
          FileSaver.saveAs(result.data, fileName);
          return result;
        } catch (error) {
          const responseError = await error?.response?.data?.text();
          if (JSON.parse(responseError).error === 'Workbook is empty') {
            throw new Error(
              `За период с ${dayjs(payload.date_from).format(PICKER_DATE_MASK)} - ${dayjs(
                payload.date_to,
              ).format(PICKER_DATE_MASK)} записи отсутсвуют`,
            );
          }
        }
        return null;
      }
      default: {
        throw new Error(`Неизвестный метод получения : ${type}`);
      }
    }
  } catch (error) {
    throw new Error(error);
  }
};
