import React, { FC } from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { referenceObjectLifeCycleStageIdField } from 'Constants';

export const ObjectLifeCycleStageList: FC = props => (
  <List {...props} exporter={false} title="Справочник этапов жизненного цикла объектов">
    <Datagrid rowClick="edit">
      <TextField source={referenceObjectLifeCycleStageIdField} label="Id" />
      <TextField source="code" label="Код" />
      <TextField fullWidth source="name_ru" label="Название" />
      <TextField fullWidth source="name_en" label="Name" />
    </Datagrid>
  </List>
);
