import React, { FC, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
  Edit,
  DisabledInput,
  TextInput,
  withDataProvider,
  SelectInput,
  ReferenceInput,
  LongTextInput,
  GET_ONE,
  TabbedForm,
  FormTab,
  FormDataConsumer,
  addField,
  showNotification,
  BooleanInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  FunctionField,
  DateField,
  EmailField,
  EditButton,
  GET_MANY_REFERENCE,
  NumberInput,
  minValue,
  required,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Circular from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  objectIdField,
  projectPrefix,
  referenceObjectTypePrefix,
  Resources,
  objectMeta,
  imagePrefix,
  objectPrefix,
  commonLabel,
  availableLanguages,
  calculationMethodIdField,
  userIdField,
  userNameLabel,
  typeOfDirectoryIdField,
  folderTemplatesIdField,
  folderTemplatesPrefix,
  scheduleTemplatePrefix,
  scheduleDeviationDayMethodIdField,
  referenceElementReferencePrefix,
  referenceJobReferencePrefix,
  referenceJobReplaceIdField,
  rolePrefix,
  approvalPrefix,
  expenditureLevelTypes,
  referenceCostPlanFactIdField,
  factCorrectionPeriodByExpenditureTypes,
  VALIDATE_ERRORS,
} from 'Constants';
import { deleteOneImage } from 'Providers/DataProviders/imageProvider';
import {
  Gallery,
  EditableUrlInput,
  SelectModal,
  Passport,
  SubmitCancelModal,
} from 'Components/Common';
import { removePrefix, videoLinkFieldType } from 'Providers/utils';
import { objectTransfer, revokeRelation } from 'Providers/DataProviders/objectProvider';
import { getFullUserName, getMultiLanguageName } from 'Components/utils/getFullName';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = {
  card: { width: '100%', marginTop: '1.5rem' },
  сardContent: { display: 'flex', flexDirection: 'column', position: 'relative' },
  groupWrapper: { display: 'flex', alignItems: 'flex-end', gap: '0 2rem' },
  groupWrapperConfirm: {
    display: 'flex',
    alignItems: 'center',
    gap: '0 1rem',
    fontSize: '0.875rem',
    fontFamily: 'Arial',
  },
  groupItem: { marginRight: '20px' },
  btn: { position: 'absolute', right: '0', top: '0' },
  projectWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  projectButton: { marginLeft: '20px', width: '200px', height: '36px' },
  innerDataGrid: { width: '100%' },
  textNoWrap: { whiteSpace: 'nowrap' },
  textMarginTop: { marginTop: '25px' },
  hidden: { display: 'none' },
};

const fieldTypeChoices = Object.entries(videoLinkFieldType).map(el => ({
  id: el[1].type,
  name: el[1].label,
}));

const RenderTitle = ({ record }: any) => <span>{getMultiLanguageName(record)}</span>;

const BooleanInputWithConfirm = ({ source, label }: any) => (
  <BooleanInput
    source={source}
    label={label}
    defaultValue={false}
    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
      // eslint-disable-next-line no-restricted-globals
      const isToggle = confirm(
        'При смене режима переключателя, ранее введенные данные по трудозатратам и численности будут удалены. Вы подтверждаете данное действие??',
      );
      if (!isToggle) {
        e.preventDefault();
      }
    }}
  />
);

const ServicesConfigurations = ({ record }: any) => {
  if (record.services.length < 1)
    return <Typography>Нет доступных сервисов для подключения</Typography>;

  return (
    <>
      {record.services.map(service => (
        <Fragment key={service.id}>
          <BooleanInput
            source={`servicesConfigurations[${service.id}].enabled`}
            label={service.name}
            defaultValue={false}
          />
          {service.fields_to.map((field, index) => (
            <TextInput
              key={index}
              source={`servicesConfigurations[${service.id}].fields[${field}]`}
              // TODO: label hardcoded so need name system from back
              label={field === 'purchase_number' ? 'номер закупки' : field}
              fullWidth
              required
            />
          ))}
        </Fragment>
      ))}
    </>
  );
};

const ObjectEditComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => {
  const { dataProvider, id: objectId, dispatch } = props;
  const [userId, setUserId] = useState(null);
  const [relationId, setRelationId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [canSelectKs2, setCanSelectKs2] = useState(false);
  const [jobReferenceChanged, setJobReferenceChanged] = useState(false);
  const [showConfirmKs2Modal, setShowConfirmKs2Modal] = useState(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [confirmMessage, setConfirmMessage] = useState<string>('');
  const [onConfirmSubmit, setOnConfirmSubmit] = useState(() => () => console.log('Confirm submit'));
  const [onConfirmCancel, setOnConfirmCancel] = useState(() => () => console.log('Confirm cancel'));

  const CustomDeleteField = ({ record }: any) => {
    const id = window.location.hash
      .split('/')
      .splice(-2, 1)
      .join('')
      .split('-')
      .pop();
    return (
      <Button
        onClick={() => {
          setShowConfirmModal(true);
          setUserId(record.user_id);
          setRelationId(Number(id));
        }}
      >
        <DeleteIcon />
      </Button>
    );
  };

  const handleDeleteItem = async (_, imageId: number, formData, raDataProvider) => {
    const { id } = formData;
    await deleteOneImage(imageId);
    raDataProvider(GET_ONE, Resources.OBJECT, { id }, { refresh: true });
  };

  const handleChangeProject = async (
    object: number,
    project: number,
    raDataProvider: any,
  ): Promise<any> => {
    try {
      await objectTransfer(object, project);
      raDataProvider(GET_ONE, Resources.OBJECT, { object }, { refresh: true });
      dispatch(showNotification('Проект успешно изменен', 'default'));
    } catch (e) {
      dispatch(showNotification('Не удалось изменить проект', 'warning'));
    }
  };

  const handleRevokeRelation = async (uId, rId, raDataProvider: any) => {
    await revokeRelation(uId, rId);
    raDataProvider(GET_MANY_REFERENCE, Resources.OBJECT, { uId }, { refresh: true });
  };

  const ExtendedProjectSelect = addField(({ input, choices, source, label, onClick }) => {
    const realChoices = choices.map(el => ({
      value: `${el.project_id}`,
      label: getMultiLanguageName(el),
    }));
    const selected = String(removePrefix(projectPrefix, input.value));

    return (
      <div className={classes.projectWrapper}>
        <SelectInput
          {...input}
          source={source}
          choices={[...choices]}
          required
          label={label}
          disabled
          fullWidth
          optionText={record => getMultiLanguageName(record)}
        />
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.projectButton}
          onClick={() => onClick(realChoices, selected)}
        >
          Сменить проект
        </Button>
      </div>
    );
  });

  interface IHandleConfirmProps {
    e: React.ChangeEvent<HTMLInputElement>;
    message: string;
    onSubmit?: () => void;
    onCancel?: () => void;
  }

  const handleConfirm = ({ e, message, onSubmit, onCancel }: IHandleConfirmProps): void => {
    setConfirmMessage(message);
    if (onSubmit) {
      setOnConfirmSubmit(() => onSubmit);
    }
    if (onCancel) {
      setOnConfirmCancel(() => onCancel);
    }
    setShowConfirm(true);
  };

  const handleConfirmConstructor = (e, formData, message) =>
    handleConfirm({
      e,
      message,
      onCancel: async () => {
        const { id: object } = formData;

        await dataProvider(GET_ONE, Resources.OBJECT, { object }, { refresh: true });
      },
    });

  return (
    <Edit title={<RenderTitle />} {...props}>
      <TabbedForm redirect="list">
        <FormTab label="Общая информация">
          <SubmitCancelModal
            title="Внимание"
            body={confirmMessage}
            show={showConfirm}
            submit={onConfirmSubmit}
            cancel={onConfirmCancel}
            shadow
            onFinish={() => setShowConfirm(false)}
          />
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...rest }): JSX.Element => (
              <>
                <Card className={classes.card}>
                  <CardContent className={classes.сardContent}>
                    <DisabledInput
                      source={objectIdField}
                      label="Id"
                      className={classes.сardItemUp}
                    />
                    <IconButton
                      onClick={() => propsPush(`/${Resources.OBJECT}`)}
                      className={classes.btn}
                    >
                      <CloseIcon />
                    </IconButton>
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent className={classes.сardContent}>
                    <div className={classes.groupWrapper}>
                      <TextInput
                        source="name_ru"
                        label={commonLabel.name_ru}
                        fullWidth
                        className={classes.groupItem}
                        required
                      />
                      <TextInput source="name_en" label={commonLabel.name_en} fullWidth />
                    </div>
                    <ReferenceInput
                      source={projectPrefix}
                      resource={Resources.PROJECTS}
                      reference={Resources.PROJECTS}
                      label="Проект"
                      fullWidth
                      required
                    >
                      <ExtendedProjectSelect
                        onClick={(choices, selected): void => {
                          setShowModal(true);
                          setProjectOptions(choices);
                          setSelectedProject(selected);
                        }}
                      />
                    </ReferenceInput>
                    <ReferenceInput
                      source={referenceObjectTypePrefix}
                      resource={Resources.REFERENCE_OBJECT_TYPE}
                      reference={Resources.REFERENCE_OBJECT_TYPE}
                      label="Тип объекта"
                      fullWidth
                      defaultValue={null}
                      validate={[required(VALIDATE_ERRORS.REQUIRED)]}
                    >
                      <SelectInput optionText={record => getMultiLanguageName(record)} />
                    </ReferenceInput>
                    <ReferenceInput
                      source={calculationMethodIdField}
                      resource={Resources.CALCULATION_METHOD}
                      reference={Resources.CALCULATION_METHOD}
                      label="Метод расчета"
                      fullWidth
                      required
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput
                      source={`settings.${scheduleDeviationDayMethodIdField}`}
                      resource={Resources.SCHEDULE_DEVIATION_DAY_METHOD}
                      reference={Resources.SCHEDULE_DEVIATION_DAY_METHOD}
                      label="Метод расчёта дней отставания"
                      fullWidth
                      required
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput
                      source={folderTemplatesPrefix}
                      reference={Resources.FOLDER_TEMPLATES}
                      label="Шаблон папок"
                      fullWidth
                      allowEmpty
                      emptyValue={null}
                      defaultValue={null}
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput
                      source={scheduleTemplatePrefix}
                      reference={Resources.SCHEDULE_TEMPLATE}
                      label="Шаблон модели объекта"
                      fullWidth
                      allowEmpty
                      emptyValue={null}
                      defaultValue={null}
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                    <SelectInput
                      label="Тип ссылки на видео"
                      source="video_type"
                      choices={[...fieldTypeChoices]}
                      defaultValue={null}
                    />
                    <EditableUrlInput source="broadcast_url" {...props} />
                    <LongTextInput
                      source="description_ru"
                      label={commonLabel.description_ru}
                      fullWidth
                    />
                    <LongTextInput
                      source="description_en"
                      label={commonLabel.description_en}
                      fullWidth
                    />
                    <LongTextInput source={objectMeta} label="Мета-информация" fullWidth />
                    <SubmitCancelModal
                      title="Внимание!"
                      body="При изменении способа формирования актов, ранее созданные акты будут удалены."
                      submit={() => {
                        setCanSelectKs2(true);
                        setShowConfirmKs2Modal(false);
                      }}
                      cancel={() => setShowConfirmKs2Modal(false)}
                      show={showConfirmKs2Modal}
                      boxShadow="0px 11px 15px -7px rgb(0 0 0 / 20%)"
                    />
                    <SelectModal
                      submit={value => {
                        handleChangeProject(
                          +removePrefix(objectPrefix, objectId),
                          +value,
                          dataProvider,
                        );
                        setShowModal(false);
                      }}
                      cancel={() => setShowModal(false)}
                      show={showModal}
                      title="Выберите новый проект"
                      options={projectOptions}
                      selected={selectedProject}
                    />
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <Typography variant="caption">Настройки классификаторов</Typography>
                    <ReferenceInput
                      source={typeOfDirectoryIdField}
                      resource={Resources.TYPEOF_DIRECTORY}
                      reference={Resources.TYPEOF_DIRECTORY}
                      label="Тип справочника конструктивных элементов"
                      fullWidth
                      required
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                    <div className={classes.projectWrapper}>
                      <SelectInput
                        required
                        source="settings.ks2_forming_method"
                        label="Формирование актов КС-2"
                        choices={[
                          { id: 'by_file', name: 'По файлу' },
                          { id: 'by_schedule', name: 'На основе позиций из модели объекта' },
                          { id: 'by_estimate', name: 'На основе сметных позиций' },
                        ]}
                        disabled={!canSelectKs2}
                        fullWidth
                        onChange={() => setCanSelectKs2(false)}
                      />
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.projectButton}
                        onClick={() => setShowConfirmKs2Modal(true)}
                      >
                        Изменить способ
                      </Button>
                    </div>
                    <ReferenceInput
                      source={`settings.${referenceElementReferencePrefix}`}
                      reference={Resources.REFERENCE_ELEMENT_REFERENCE}
                      resource={Resources.REFERENCE_ELEMENT_REFERENCE}
                      label="Справочник конструктивных элементов"
                      fullWidth
                      allowEmpty
                      emptyValue={null}
                      defaultValue={null}
                      disabled={formData[typeOfDirectoryIdField] !== 'common'}
                      className={
                        formData[typeOfDirectoryIdField] !== 'common' ? classes.hidden : ''
                      }
                    >
                      <SelectInput optionText={record => record.name} />
                    </ReferenceInput>

                    <ReferenceInput
                      source={`settings.${referenceJobReferencePrefix}`}
                      reference={Resources.REFERENCE_JOB_REFERENCE}
                      resource={Resources.REFERENCE_JOB_REFERENCE}
                      label="Справочник работ"
                      fullWidth
                      onChange={e => setJobReferenceChanged(true)}
                    >
                      <SelectInput optionText={record => record.name} />
                    </ReferenceInput>
                    {jobReferenceChanged && (
                      <ReferenceInput
                        source={`settings.${referenceJobReplaceIdField}`}
                        reference={Resources.REFERENCE_JOB}
                        resource={Resources.REFERENCE_JOB}
                        label="Укажите работу для переноса"
                        helperText="При смене справочника видов работ у объекта необходимо указать элемент из нового справочника, который будет присвоен всем работам в модели"
                        fullWidth
                        required
                        filter={{
                          [referenceJobReferencePrefix]:
                            formData?.settings?.[referenceJobReferencePrefix],
                          isJob: true,
                        }}
                      >
                        <SelectInput
                          optionText={rec => `${'–'.repeat(rec.level)} ${rec.name_ru}`}
                        />
                      </ReferenceInput>
                    )}
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent className={classes.сardContent}>
                    <Typography variant="caption">Настройки трудозатрат и численности</Typography>
                    <div className={classes.groupWrapper}>
                      <BooleanInput
                        source="settings.labor_cost"
                        label="Планирование по трудозатратам"
                        defaultValue={false}
                      />
                    </div>
                    <div className={classes.groupWrapperConfirm}>
                      <span>Сводно по объекту</span>
                      <BooleanInputWithConfirm
                        source="settings.labor_cost_fact_use_schedule"
                        label="В разрезе видов работ"
                      />
                    </div>
                    <div className={classes.groupWrapperConfirm}>
                      <span>Сводно</span>
                      <BooleanInputWithConfirm
                        source="settings.labor_cost_fact_use_profession"
                        label="По специальностям"
                      />
                    </div>
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent className={classes.сardContent}>
                    <Typography variant="caption">Настройки трудящихся и механизмов</Typography>
                    <ReferenceInput
                      source="settings.resource_fact_method"
                      resource={Resources.RESOURCE_FACT_METHOD}
                      reference={Resources.RESOURCE_FACT_METHOD}
                      label="Mетод ввода факта по ресурсам"
                      fullWidth
                      onChange={e =>
                        handleConfirmConstructor(
                          e,
                          formData,
                          formData.settings?.resource_fact_method === 'object'
                            ? 'ВНИМАНИЕ! Все ранее введенные данные по ресурсам будут удалены с уровня объекта'
                            : 'Все ранее введенные данные по ресурсам с уровня видов работ будут просуммированы и подняты на уровень объекта',
                        )
                      }
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent className={classes.сardContent}>
                    <Typography variant="caption">Настройки объекта</Typography>
                    <div className={classes.groupWrapper}>
                      <BooleanInput
                        source="settings.guntt_status_gray"
                        label="Отображать серый статус"
                        defaultValue={false}
                      />
                    </div>
                    <div className={classes.groupWrapper}>
                      <BooleanInput
                        source="settings.image_gallery_is_public"
                        label="Публичная фотогалерея"
                        defaultValue={false}
                      />
                    </div>
                    <div className={classes.groupWrapper}>
                      <BooleanInput
                        source="settings.allow_schedule_object"
                        label="Разрешить использование подобъектов"
                        defaultValue={false}
                      />
                    </div>
                    <div className={classes.groupWrapper}>
                      <BooleanInput
                        source="settings.allow_cost_item"
                        label="Использовать статьи затрат"
                        defaultValue={false}
                      />
                    </div>
                    <div className={classes.groupWrapper}>
                      <BooleanInput
                        source="settings.critical_path"
                        label="Расчет критического пути"
                        defaultValue={false}
                      />
                    </div>
                    <div className={classes.groupWrapper}>
                      <SelectInput
                        fullWidth
                        source="settings.schedule_expenditure_plan_fact_input"
                        label="Уровень ввода финансов"
                        choices={expenditureLevelTypes}
                        optionText="name"
                        optionValue="value"
                        onChange={e =>
                          handleConfirmConstructor(
                            e,
                            formData,
                            'Плановые и фактические финансовые данные будут удалены с уровней отличного от уровня Вид работ!',
                          )
                        }
                      />
                    </div>
                    <div className={classes.groupWrapper}>
                      <NumberInput
                        fullWidth
                        source="settings.guntt_status_red_deviation_day"
                        label="Количество дней отставания (влияет на индикацию готовности работы по сценарию «Прораб»)"
                        defaultValue={0}
                        step={1}
                        validate={[minValue(0, 'Значение не может быть меньше 0')]}
                      />
                    </div>
                    <div className={classes.groupWrapper}>
                      <SelectInput
                        fullWidth
                        source="settings.fact_correction_period_by_expenditure"
                        label="Период документа верификации по стоимости"
                        choices={factCorrectionPeriodByExpenditureTypes}
                        optionText="name"
                        optionValue="value"
                      />
                    </div>
                  </CardContent>
                </Card>

                <Card className={classes.card}>
                  <CardContent className={classes.сardContent}>
                    <Typography variant="caption">Маршруты согласования</Typography>
                    <ReferenceInput
                      source="settings.ks2_approval_id"
                      resource={Resources.APPROVAL}
                      reference={Resources.APPROVAL}
                      label="Выберите маршрут КС-2"
                      fullWidth
                      allowEmpty
                      emptyValue={null}
                      filter={{ relation: ['ks2'] }}
                    >
                      <SelectInput optionText="title" />
                    </ReferenceInput>
                    <ReferenceInput
                      source="settings.ks3_approval_id"
                      resource={Resources.APPROVAL}
                      reference={Resources.APPROVAL}
                      label="Выберите маршрут КС-3"
                      fullWidth
                      allowEmpty
                      emptyValue={null}
                      filter={{ relation: ['ks3'] }}
                    >
                      <SelectInput optionText="title" />
                    </ReferenceInput>
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent className={classes.сardContent}>
                    <Typography variant="caption">
                      Источник фактических данных для отчёта «Контроль финансирования и освоения
                      ОКС»
                    </Typography>
                    <ReferenceInput
                      source="settings.report_object_cost_plan_fact_source"
                      resource={Resources.REFERENCE_COST_PLAN_FACT_SOURCE}
                      reference={Resources.REFERENCE_COST_PLAN_FACT_SOURCE}
                      label="Источник данных"
                      fullWidth
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <Typography variant="caption">Сводный график</Typography>
                    <div className={classes.groupWrapper}>
                      <BooleanInput
                        source="settings.use_object_plan_forecast_data"
                        label="Влияние на отчетность"
                        options={{
                          disabled: !formData.settings?.schedule_update_from_object_plan,
                        }}
                        defaultValue={false}
                      />
                    </div>
                    <div className={classes.groupWrapper}>
                      <BooleanInput
                        source="settings.schedule_update_from_object_plan"
                        label="Использовать данные для обновления модели объекта"
                        defaultValue={false}
                      />
                    </div>
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <Typography variant="caption">Интеграция сервисов</Typography>
                    {rest.record.services ? <ServicesConfigurations {...rest} /> : <Circular />}
                  </CardContent>
                </Card>
              </>
            )}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Данные паспорта">
          {availableLanguages.map(language => (
            <Passport
              key={`passport_data_${language}`}
              source={`passport_data_${language}`}
              locale={language}
              idPrefix={referenceObjectTypePrefix}
              type={Resources.OBJECT}
            />
          ))}
        </FormTab>
        <FormTab label="Галерея">
          <FormDataConsumer>
            {({ formData, ...rest }): JSX.Element => (
              <Gallery
                {...rest}
                data={formData[imagePrefix]}
                onDeleteItem={(e, id) => handleDeleteItem(e, id, formData, dataProvider)}
              />
            )}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Пользователи" className={classes.formTab} {...props}>
          <ReferenceManyField
            resource={Resources.USERS}
            reference={Resources.USERS}
            target={objectPrefix}
            label=""
            className={classes.innerDataGrid}
          >
            <Datagrid>
              <TextField source={userIdField} label="Id" />
              <FunctionField
                render={user => getFullUserName(user, 'ru')}
                label={userNameLabel.fullname_ru}
              />
              <FunctionField
                render={user => getFullUserName(user, 'en')}
                label={userNameLabel.fullname_en}
              />
              <DateField source="birthdate" label="Дата изменения учетной записи" />
              <EmailField source="email" label="E-mail" />
              <TextField source="phone" label="Телефон" />
              <CustomDeleteField />
              <SubmitCancelModal
                body="Удалить права доступа на объект у пользователя?"
                show={showConfirmModal}
                submit={() => {
                  handleRevokeRelation(userId, relationId, dataProvider);
                  setShowConfirmModal(false);
                }}
                cancel={() => setShowConfirmModal(false)}
              />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const connectedObjectEdit = connect(null, { push })(withDataProvider(ObjectEditComponent));
export const ObjectEdit = withStyles(styles as any)(connectedObjectEdit);
