import axios from 'axios';
import { GET_LIST, GET_MANY } from 'react-admin';
import { IRADataProviderParams, IRAGetManyParams } from 'Interfaces';
import {
  API_URL,
  REST,
  referenceBusinessRelationDataPath,
  referenceBusinessRelationPrefix,
} from 'Constants';
import { get } from 'lodash';
import { mapArrayToResource, removePrefixInArray } from '../utils';

export const referenceBusinessRelationProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.BusinessRelation.LIST}`,
          {},
        );
        const businessRelation = get(response, referenceBusinessRelationDataPath);
        const parsedBusinessRelation = mapArrayToResource(
          businessRelation,
          referenceBusinessRelationPrefix,
          'BusinessRelation',
        );
        return { data: parsedBusinessRelation, total: parsedBusinessRelation.length };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const clearIds = removePrefixInArray(ids, referenceBusinessRelationPrefix);
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.BusinessRelation.LIST}`,
          {},
        );
        const businessRelation = get(response, referenceBusinessRelationDataPath);
        const filteredBusinessRelation = businessRelation.filter(el => clearIds.includes(el));
        const parsedBusinessRelation = mapArrayToResource(
          filteredBusinessRelation,
          referenceBusinessRelationPrefix,
          'BusinessRelation',
        );
        return { data: parsedBusinessRelation };
      }
      default:
        throw new Error(`Неизвестный метод получения ${referenceBusinessRelationPrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${referenceBusinessRelationPrefix}-${type}`);
  }
};
