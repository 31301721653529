import { Resources, objectPrefix, projectPrefix, projectIdField, objectIdField } from './resources';

export enum RolesEntities {
  project = 'project',
  object = 'object',
  user = 'user',
  ba = 'ba',
}

export const searchPanelConfig = {
  [RolesEntities.project]: {
    reference: Resources.PROJECTS,
    source: projectPrefix,
    label: 'проект',
    idField: projectIdField,
    minimalRoleId: 1,
  },
  [RolesEntities.object]: {
    reference: Resources.OBJECT,
    source: objectPrefix,
    label: 'объект',
    idField: objectIdField,
    minimalRoleId: 3,
  },
};
