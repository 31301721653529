import React, { FC, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

interface IRadioGroupModalProps {
  submit: (selected: string) => void;
  cancel: (selected: string) => void;
  show: boolean;
  title: string;
  body: string;
  options: { value: string; label: string }[];
}

export const RadioGroupModal: FC<IRadioGroupModalProps> = ({
  submit,
  cancel,
  show,
  title,
  body,
  options,
}) => {
  const [selected, setSelected] = useState<string>(options[0].value);

  return (
    <Dialog fullWidth open={show} disableBackdropClick disableEscapeKeyDown>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {body}
          <RadioGroup value={selected} onChange={(_, value): void => setSelected(value)}>
            {options.map(({ value, label }) => (
              <FormControlLabel value={value} key={value} control={<Radio />} label={label} />
            ))}
          </RadioGroup>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(): void => submit(selected)}
          variant="contained"
          size="small"
          color="primary"
        >
          Подтвердить
        </Button>
        <Button
          onClick={(): void => cancel(selected)}
          variant="contained"
          size="small"
          color="secondary"
        >
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
};
