import axios from 'axios';
import {
  GET_LIST,
  GET_ONE,
  DELETE,
  DELETE_MANY,
  UPDATE,
  CREATE,
  GET_MANY,
  GET_MANY_REFERENCE,
} from 'react-admin';
import { get } from 'lodash';
import {
  IRADataProviderParams,
  IRAGetOneParams,
  IRADeleteParams,
  IRADeleteManyParams,
  IRAUpdateParams,
  IRACreateParams,
  IRAGetManyParams,
  IRAGetManyReferenceParams,
} from 'Interfaces';
import {
  API_URL,
  REST,
  folderTemplatesPath,
  folderTemplatesIdField,
  folderTemplatesPrefix,
} from 'Constants';
import {
  setPrefixInArray,
  setPrefix,
  removePrefix,
  removePrefixInArray,
  PgUnAssoc,
} from 'Providers/utils';

export const folderTemplatesProvider = async (
  type: string,
  params: IRADataProviderParams,
  // eslint-disable-next-line consistent-return
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(`${API_URL}${REST.AdminFolderTemplates.LIST}`, {});
        const parsedTemplates = PgUnAssoc(get(response, folderTemplatesPath));
        const parsedTemplatesWithId = setPrefixInArray(
          parsedTemplates,
          folderTemplatesPrefix,
          folderTemplatesIdField,
        ).map(el => ({
          ...el,
        }));
        return {
          data: parsedTemplatesWithId,
          total: parsedTemplatesWithId.length,
        };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(folderTemplatesPrefix, id);
        const response = await axios.post(`${API_URL}${REST.AdminFolderTemplates.LIST}`, {
          [folderTemplatesIdField]: [clearId],
        });
        const parsedTemplate = PgUnAssoc(get(response, folderTemplatesPath))[0];

        return {
          data: {
            ...parsedTemplate,
            id: setPrefix(folderTemplatesPrefix, parsedTemplate[folderTemplatesIdField]),
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, folderTemplatesPrefix).filter(el => el !== null);
        const response = await axios.post(`${API_URL}${REST.AdminFolderTemplates.LIST}`, {
          [folderTemplatesIdField]: clearIds,
        });
        const parsedFolderTemplates = PgUnAssoc(get(response, folderTemplatesPath));
        const folderTemplatesWithId = setPrefixInArray(
          parsedFolderTemplates,
          folderTemplatesPrefix,
          folderTemplatesIdField,
        );
        return { data: folderTemplatesWithId };
      }
      case UPDATE: {
        const { data } = params as IRAUpdateParams;
        const { id, ...sendData } = data as any;

        const response = await axios.post(`${API_URL}${REST.AdminFolderTemplates.UPDATE}`, {
          ...sendData,
        });

        const parsedObject = get(response, folderTemplatesPath);
        return {
          data: {
            ...parsedObject,
            id: setPrefix(folderTemplatesPrefix, parsedObject[folderTemplatesIdField]),
          },
        };
      }
      case CREATE: {
        const { data } = params as IRACreateParams;
        const { id, ...sendData } = data as any;

        const response = await axios.post(`${API_URL}${REST.AdminFolderTemplates.CREATE}`, {
          ...sendData,
        });

        const parsedObject = get(response, folderTemplatesPath);

        return {
          data: {
            ...parsedObject,
            id: setPrefix(folderTemplatesPrefix, parsedObject[folderTemplatesIdField]),
          },
        };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(folderTemplatesPrefix, id);
        await axios.post(`${API_URL}${REST.AdminFolderTemplates.DELETE}`, {
          [folderTemplatesIdField]: [clearId],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, folderTemplatesPrefix);
        await axios.post(`${API_URL}${REST.AdminFolderTemplates.DELETE}`, {
          [folderTemplatesIdField]: clearIds,
        });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${folderTemplatesPrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода  ${folderTemplatesPrefix}: ${type}`);
  }
};
