import { abbreviation } from 'Constants';

interface IResource {
  id: string;
  name: string;
  code: string;
}

export const mapArrayToResource = (
  arr: string[],
  prefix: string | null,
  abbrev: string,
): IResource[] =>
  arr.map(el =>
    typeof abbreviation[abbrev][el] === 'string'
      ? {
          id: prefix === null ? el : `${prefix}-${el}`,
          name: abbreviation[abbrev][el],
          code: el,
        }
      : {
          id: prefix === null ? el : `${prefix}-${el}`,
          name: `${abbreviation[abbrev][el].longName} (${abbreviation[abbrev][el].shortName})`,
          code: el,
          ...abbreviation[abbrev][el],
        },
  );
