/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';
import {
  GET_LIST,
  GET_ONE,
  DELETE,
  DELETE_MANY,
  UPDATE,
  CREATE,
  GET_MANY,
  GET_MANY_REFERENCE,
} from 'react-admin';
import { get } from 'lodash';
import {
  IRADataProviderParams,
  IRAGetOneParams,
  IRADeleteParams,
  IRADeleteManyParams,
  IRAUpdateParams,
  IRACreateParams,
  IRAGetManyParams,
  IRAGetManyReferenceParams,
} from 'Interfaces';
import {
  API_URL,
  REST,
  fileTemplatePath,
  fileTemplateIdField,
  fileTemplatePrefix,
  folderIdField,
  folderPrefix,
} from 'Constants';
import {
  setPrefixInArray,
  setPrefix,
  removePrefix,
  removePrefixInArray,
  PgUnAssoc,
} from 'Providers/utils';

export const fileTemplateProvider = async (
  type: string,
  params: IRADataProviderParams,
  // eslint-disable-next-line consistent-return
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(`${API_URL}${REST.AdminFileTemplate.LIST}`, {});
        const parsedTemplates = PgUnAssoc(get(response, fileTemplatePath));
        const parsedTemplatesWithId = setPrefixInArray(
          parsedTemplates,
          fileTemplatePrefix,
          fileTemplateIdField,
        );
        return {
          data: parsedTemplatesWithId,
          total: parsedTemplatesWithId.length,
        };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(fileTemplatePrefix, id);
        const response = await axios.post(`${API_URL}${REST.AdminFileTemplate.LIST}`, {
          [fileTemplateIdField]: [clearId],
        });
        const parsedTemplate = PgUnAssoc(get(response, fileTemplatePath))[0];

        const fieldsWithPrevKeys = parsedTemplate.template.fields?.map(field => ({
          ...field,
          fieldPrevKey: field.fieldKey,
        }));

        return {
          data: {
            ...parsedTemplate,
            fields: fieldsWithPrevKeys,
            id: setPrefix(fileTemplatePrefix, parsedTemplate[fileTemplateIdField]),
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const withoutEmptyIds = ids.filter(el => el !== null);
        if (withoutEmptyIds.length <= 0) return { data: [] };
        const clearIds = removePrefixInArray(ids, fileTemplatePrefix);
        const response = await axios.post(`${API_URL}${REST.AdminFileTemplate.LIST}`, {
          [fileTemplateIdField]: clearIds,
        });
        const parsedTemplates = PgUnAssoc(get(response, fileTemplatePath));
        const parsedTemplatesWithId = setPrefixInArray(
          parsedTemplates,
          fileTemplatePrefix,
          fileTemplateIdField,
        );
        return { data: parsedTemplatesWithId };
      }
      case GET_MANY_REFERENCE: {
        const { id } = params as IRAGetManyReferenceParams;
        const clearId = removePrefix(folderPrefix, id);
        const requestBody = { [folderIdField]: clearId };
        const response = await axios.post(`${API_URL}${REST.AdminFileTemplate.LIST}`, requestBody);
        const parsedObject = PgUnAssoc(get(response, fileTemplatePath));
        const parsedObjectWithId = parsedObject.map(el => ({
          ...el,
          fields: el.template.fields,
          id: setPrefix(fileTemplatePrefix, el[fileTemplateIdField]),
        }));
        return {
          data: parsedObjectWithId,
          total: parsedObjectWithId.length,
        };
      }
      case CREATE: {
        const { data } = params as IRACreateParams;
        const { id, name, fields, ...sendData } = data as any;
        const requestBody = {
          ...sendData,
          name,
          template: { fields },
        };
        const response = await axios.post(
          `${API_URL}${REST.AdminFileTemplate.CREATE}`,
          requestBody,
        );
        const parsedTemplate = get(response, fileTemplatePath);
        return {
          data: {
            ...parsedTemplate,
            fields: parsedTemplate.template.fields,
            id: setPrefix(fileTemplatePrefix, parsedTemplate[fileTemplateIdField]),
          },
        };
      }
      case UPDATE: {
        const { data } = params as IRAUpdateParams;
        const {
          id,
          name,
          // eslint-disable-next-line @typescript-eslint/camelcase
          file_template_id,
          fields,
          ...sendData
        } = data as any;
        const fieldsWithoutPrevKeys = fields?.map(field => {
          const { fieldKey, fieldPrevKey, ...rest } = field;
          return {
            ...rest,
            fieldKey,
            ...(fieldKey !== fieldPrevKey && { fieldPrevKey }),
          };
        });
        const requestBody = {
          ...sendData,
          name,
          file_template_id,
          template: { fields: fieldsWithoutPrevKeys },
        };
        const response = await axios.post(
          `${API_URL}${REST.AdminFileTemplate.UPDATE}`,
          requestBody,
        );

        const parsedObject = get(response, fileTemplatePath);
        return {
          data: {
            ...parsedObject,
            fields: parsedObject.template.fields,
            id: setPrefix(fileTemplatePrefix, parsedObject[fileTemplateIdField]),
          },
        };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(fileTemplatePrefix, id);
        await axios.post(`${API_URL}${REST.AdminFileTemplate.DELETE}`, {
          [fileTemplateIdField]: [clearId],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, fileTemplatePrefix);
        await axios.post(`${API_URL}${REST.AdminFileTemplate.DELETE}`, {
          [fileTemplateIdField]: clearIds,
        });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${fileTemplatePrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода  ${fileTemplatePrefix}: ${type}`);
  }
};
