import React, { FC } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin';
import { Card, CardContent, Typography, withStyles } from '@material-ui/core';
import {
  referenceObjectLifeCycleStagePrefix,
  Resources,
  scheduleAfterIdField,
  scheduleParentIdField,
  scheduleTemplatePrefix,
} from 'Constants';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = {
  card: { width: '100%', marginBottom: '1.5rem' },
  cardContent: { position: 'relative' },
  list: { width: '100%', display: 'none' },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 1000,
  },
  grid: { display: 'grid', gap: '2rem', gridTemplateColumns: '1fr 1fr' },
};

const ScheduleCreateComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => {
  const innerProps = { ...props };
  const scheduleTemplateString: string = innerProps.history.location.search;
  const scheduleTemplate = scheduleTemplateString
    ? scheduleTemplateString.replace(`?${scheduleTemplatePrefix}=`, '')
    : null;
  const redirect = scheduleTemplate
    ? `/${Resources.SCHEDULE_TEMPLATE}/${scheduleTemplate}/1`
    : `/${Resources.SCHEDULE_TEMPLATE}`;
  return (
    <Create title="Новый элемент шаблона модели объекта" {...props}>
      <SimpleForm
        redirect={redirect}
        defaultValue={{
          [scheduleParentIdField]: null,
          [scheduleAfterIdField]: null,
        }}
      >
        {/**
         * Внутренние элементы компонента SimpleForm обернуты во фрагмент
         * Это необходимо для корректного проброса значений полей из location.search
         */}
        <>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <IconButton onClick={() => propsPush(redirect)} className={classes.closeBtn}>
                <CloseIcon />
              </IconButton>
              <ReferenceInput
                source={scheduleTemplatePrefix}
                reference={Resources.SCHEDULE_TEMPLATE}
                resource={Resources.SCHEDULE_TEMPLATE}
                label="Шаблон модели"
                fullWidth
                required
                disabled
              >
                <SelectInput optionText={record => record.name} />
              </ReferenceInput>
              <TextInput fullWidth required source="name" label="Название" />
              <ReferenceInput
                source={referenceObjectLifeCycleStagePrefix}
                reference={Resources.REFERENCE_OBJECT_LIFE_CYCLE_STAGE}
                resource={Resources.REFERENCE_OBJECT_LIFE_CYCLE_STAGE}
                label="Этап жизненного цикла объекта"
                fullWidth
                required
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography variant="caption">Позиция элемента в шаблоне</Typography>
              <ReferenceInput
                source={scheduleParentIdField}
                reference={Resources.SCHEDULE}
                resource={Resources.SCHEDULE}
                filter={{ [scheduleTemplatePrefix]: scheduleTemplate }}
                label="Родительский элемент"
                fullWidth
                allowEmpty
                emptyValue={null}
                defaultValue={null}
              >
                <SelectInput optionText={({ name, level }) => `${'–'.repeat(level)} ${name}`} />
              </ReferenceInput>
              <FormDataConsumer>
                {({ formData }) => (
                  <>
                    <ReferenceInput
                      source={scheduleAfterIdField}
                      reference={Resources.SCHEDULE}
                      resource={Resources.SCHEDULE}
                      filter={{
                        [scheduleTemplatePrefix]: scheduleTemplate,
                        [scheduleParentIdField]: formData?.[scheduleParentIdField],
                      }}
                      label="Предыдущий элемент шаблона модели объекта"
                      helperText="Необходимо выбрать элемент шаблона, после которого будет установлен текущий элемент, в зависимости от родительского элемента"
                      fullWidth
                      allowEmpty
                      emptyValue={null}
                      defaultValue={null}
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                  </>
                )}
              </FormDataConsumer>
            </CardContent>
          </Card>
        </>
      </SimpleForm>
    </Create>
  );
};

export const connectedScheduleCreate = connect(null, { push })(ScheduleCreateComponent);
export const ScheduleCreate = withStyles(styles as any)(connectedScheduleCreate);
