import React, { FC } from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { databaseMigrationIdField } from 'Constants';

export const DatabaseMigrationList: FC = ({ classes = {}, ...props }: any) => (
  <List
    {...props}
    exporter={false}
    title="История миграций"
    bulkActionButtons={false}
    pagination={false}
  >
    <Datagrid>
      <TextField source={databaseMigrationIdField} label="Id" />
      <DateField source="created" label="Дата" showTime />
      <TextField source="git_ref" label="Ветка" />
      <TextField source="git_commit_id" label="Идентификатор коммита" />
      <TextField source="status" label="Статус" />
      <TextField source="message" label="Сообщение" />
    </Datagrid>
  </List>
);
