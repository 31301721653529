import React, { FC } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
  Edit,
  DisabledInput,
  TextInput,
  LongTextInput,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Datagrid,
  TextField,
  DeleteButton,
  ReferenceField,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Resources,
  referenceElementReferenceIdField,
  referenceElementReferencePrefix,
  referenceElementPrefix,
  referenceElementIdField,
  referenceGroupUnitIdField,
} from 'Constants';
import { Card, CardContent, Button, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Add } from '@material-ui/icons';

const styles = {
  card: { width: '100%', marginBottom: '1rem', marginTop: '1rem' },
  сardContent: { display: 'flex', flexDirection: 'column', position: 'relative' },
  btn: { position: 'absolute', right: '0', top: '0' },
};

const RenderTitle = ({ record }: any) => <span>{record.name}</span>;

const NameWithBadge = ({ record, badgeClassName, ...rest }) => (
  <span>
    {record.level > 0 && `${'—'.repeat(record.level)} `}
    {record.name_ru}
    {record.child_count > 0 && <span className={badgeClassName}>{record.child_count}</span>}
  </span>
);

const AddElementButton = ({ record }: any) => {
  const AddElementLink = (props: any) => (
    <Link
      to={`/${Resources.REFERENCE_ELEMENT}/create?${referenceElementReferencePrefix}=${record.id}`}
      {...props}
    />
  );

  return (
    <Button component={AddElementLink} variant="text" color="primary" size="small">
      <Add />
      &nbsp;New element
    </Button>
  );
};

const ElementReferenceEditComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => {
  const { id } = props;
  return (
    <Edit title={<RenderTitle />} {...props}>
      <TabbedForm redirect="list">
        <FormTab label="Общая информация">
          <Card className={classes.card}>
            <CardContent className={classes.сardContent}>
              <DisabledInput source={referenceElementReferenceIdField} label="Id" />
              <IconButton
                onClick={() => propsPush(`/${Resources.REFERENCE_ELEMENT_REFERENCE}`)}
                className={classes.btn}
              >
                <CloseIcon />
              </IconButton>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent className={classes.сardContent}>
              <TextInput source="code" label="Код" fullWidth />
              <TextInput source="name" label="Название" fullWidth required />
              <ReferenceInput
                source="permission"
                reference={Resources.PERMISSION}
                label="Право доступа"
                fullWidth
                required
                helperText={null}
              >
                <SelectInput optionText="name" optionValue="alias" />
              </ReferenceInput>
              <LongTextInput source="description" label="Описание" fullWidth />
            </CardContent>
          </Card>
        </FormTab>
        <FormTab label="Элементы">
          <ReferenceManyField
            resource={Resources.REFERENCE_ELEMENT}
            reference={Resources.REFERENCE_ELEMENT}
            target={referenceElementPrefix}
            label=""
            fullWidth
          >
            <Datagrid rowClick="edit">
              <TextField source={referenceElementIdField} label="Id" />
              <TextField source="code" label="Код" />
              <TextField source="key" label="Ключ" />
              <NameWithBadge
                record={record => record}
                label="Название"
                badgeClassName={classes.badge}
              />
              <ReferenceField
                source={referenceGroupUnitIdField}
                resource={Resources.REFERENCE_GROUP_UNIT}
                reference={Resources.REFERENCE_GROUP_UNIT}
                label="Тип конструктивного элемента"
              >
                <TextField source="name" />
              </ReferenceField>
              <DeleteButton redirect={`/${Resources.REFERENCE_ELEMENT_REFERENCE}/${id}/1`} />
            </Datagrid>
          </ReferenceManyField>
          <AddElementButton />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const connectedElementReferenceEdit = connect(null, { push })(ElementReferenceEditComponent);
export const ElementReferenceEdit = withStyles(styles as any)(connectedElementReferenceEdit);
