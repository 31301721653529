import React, { useState } from 'react';
import { TextInput } from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { passwordRegex } from 'Utils/stringMethods';
import { PASSWORD_TYPES } from './PasswordInputTypes';
import { PasswordType } from './PasswordInputConstants';

export const PasswordInput = props => {
  const [type, setType] = useState<PasswordType>(PASSWORD_TYPES.password);

  const handleTypeToggle = (): void => {
    setType(prev =>
      prev === PASSWORD_TYPES.password ? PASSWORD_TYPES.text : PASSWORD_TYPES.password,
    );
  };

  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <TextInput {...props} type={type} fullWidth validate={passwordRegex()} />
      <IconButton onClick={handleTypeToggle} color="primary">
        {type === PASSWORD_TYPES.password ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
    </div>
  );
};
