import React, { FC } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Edit, SimpleForm, DisabledInput, TextInput } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import { Resources, permissionIdField } from 'Constants';
import { aliasRegex } from '../../../Utils/stringMethods';

const styles = {
  card: { width: '100%', marginBottom: '10px' },
  сardContent: { display: 'flex', flexWrap: 'wrap', position: 'relative' },
  сardItemUp: { width: '30%', margin: '10px', maxWidth: '320px' },
  сardItem: { width: '40%', margin: '20px', maxWidth: '450px' },
  btn: { position: 'absolute', right: '0', top: '0' },
};

const RenderTitle = ({ record }: any) => <span>{record.name}</span>;

const PermissionEditComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => (
  <Edit title={<RenderTitle />} {...props}>
    <SimpleForm redirect="list">
      <Card className={classes.card}>
        <CardContent className={classes.сardContent}>
          <DisabledInput source={permissionIdField} label="Id" className={classes.сardItemUp} />
          <IconButton onClick={() => propsPush(`/${Resources.PERMISSION}`)} className={classes.btn}>
            <CloseIcon />
          </IconButton>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent className={classes.сardContent}>
          <TextInput source="name" label="Описание" className={classes.сardItem} />
          <TextInput
            source="alias"
            label="Код"
            className={classes.сardItem}
            validate={aliasRegex()}
          />
        </CardContent>
      </Card>
    </SimpleForm>
  </Edit>
);

const connectedPermissionEdit = connect(null, { push })(PermissionEditComponent);
export const PermissionEdit = withStyles(styles as any)(connectedPermissionEdit);
