import React, { FC, useRef, useState, useEffect } from 'react';
import { addField } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import withStyles from '@material-ui/core/styles/withStyles';
import { IParsedImage } from 'Providers/utils';
import { imageIdField } from 'Constants';

interface IGalleryProps {
  data: IParsedImage[];
  onDeleteItem: () => {};
}

const styles = {
  root: { marginTop: '20px' },
  сardContent: { display: 'flex', flexWrap: 'wrap', position: 'relative' },
  galleryContainer: {
    width: '100%',
    margin: 0,
    paddingTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  imageContainer: { margin: '5px', position: 'relative' },
  btn: {
    position: 'absolute',
    right: '0',
    bottom: '0',
    color: 'white',
  },
  icon: { padding: '5px', backgroundColor: 'rgb(220, 0, 78)', borderRadius: '50%' },
};

const getColsNumber = (width: number): number => {
  if (width < 600) return 3;
  if (width >= 600 && width < 960) return 4;
  if (width >= 960 && width < 1280) return 5;
  if (width >= 1280 && width < 1920) return 6;
  return 7;
};

const GalleryComponent: FC<IGalleryProps> = ({
  data,
  onDeleteItem,
  classes = {},
}: any): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>();
  const [itemWidth, setItemWidth] = useState(0);

  const handleWindowSizeChange = () => {
    const containerWidth =
      containerRef.current && containerRef.current.getBoundingClientRect().width;
    const colsNumber = getColsNumber(containerWidth);
    setItemWidth(containerWidth / colsNumber - 5 * (colsNumber - 1));
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange();
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  if (!data) {
    return null;
  }

  if (!data.length) {
    return (
      <Card className={classes.root}>
        <CardContent className={classes.сardContent}>В галерее нет фотографий</CardContent>
      </Card>
    );
  }

  return (
    <div ref={containerRef} className={classes.galleryContainer}>
      {data.map(el => (
        <div key={el.url} className={classes.imageContainer}>
          <img src={el.url} alt="" width={itemWidth} height={itemWidth} />
          {itemWidth && (
            <IconButton onClick={e => onDeleteItem(e, el[imageIdField])} className={classes.btn}>
              <DeleteIcon className={classes.icon} />
            </IconButton>
          )}
        </div>
      ))}
    </div>
  );
};

export const Gallery = addField(withStyles(styles as any)(GalleryComponent));
