import React, { FC } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Tooltip from '@material-ui/core/Tooltip';
import { IRoleListResp } from 'Interfaces';
import { roleIdField, RolesEntities, searchPanelConfig } from 'Constants';
import { getMultiLanguageName } from 'Components/utils/getFullName';

interface IItems {
  name_ru: string;
  name: string; // TODO-08: костыль
  role: string;
  [key: string]: string;
}

interface IRolesTable {
  idField: string;
  reference: string;
  source: string;
  label: string;
  allRoles: IRoleListResp[];
  searchFilter: string;
  selectedItems: IItems[];
  classes: any;
  allSystemRoles: IRoleListResp[];
  bulkAction: any;
  changeItemRole: any;
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  select: {
    width: 175,
    fontSize: '0.8125rem',
  },
  iconsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const RolesTableComponent: FC<IRolesTable> = ({
  classes,
  idField,
  reference,
  label,
  allRoles,
  searchFilter,
  selectedItems,
  allSystemRoles,
  bulkAction,
  changeItemRole,
}) => {
  const { acceptRole, appointItems, deleteItem } = bulkAction;

  const filteredItems = selectedItems.filter(({ name_ru: nameRu, name }) =>
    // TODO-08: костыль
    nameRu
      ? nameRu.toLowerCase().includes(searchFilter.toLowerCase())
      : name.toLowerCase().includes(searchFilter.toLowerCase()),
  );

  const renderSelectedValue = (selected, roles): JSX.Element => (
    <div>
      {(Array.isArray(selected) ? selected : [selected]).map((el: number | string) => (
        <div key={`${idField}-Selected-Role-${el}`}>
          {/* // TODO-08: костыль */}
          {roles.find(role => role[roleIdField] === el)?.name_ru ||
            roles.find(role => role[roleIdField] === el)?.name}
        </div>
      ))}
    </div>
  );

  const renderSelectMenuItems = (items): JSX.Element =>
    items.map(
      // TODO-08: костыль
      ({ name_ru: nameRu, name, ...item }) => (
        <MenuItem key={`${idField}-Role_id_${item[roleIdField]}`} value={item[roleIdField]}>
          {/* // TODO-08: костыль */}
          {nameRu || name}
        </MenuItem>
      ),
    );

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell numeric>№</TableCell>
            <TableCell numeric>{`ID ${label}а`}</TableCell>
            {reference === RolesEntities.object ? (
              <TableCell>{`Наименование ${searchPanelConfig.project.label}а`}</TableCell>
            ) : null}
            <TableCell>{`Наименование ${label}а`}</TableCell>
            <TableCell>Роль</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredItems.map((props, order) => {
            // TODO-08: костыль
            const {
              name_ru: nameRu,
              name,
              [idField]: itemId,
              role,
              projectName,
              projectId,
            } = props;
            return (
              <TableRow key={`${idField}-${itemId}`}>
                <TableCell numeric>{order + 1}</TableCell>
                <TableCell numeric>{itemId}</TableCell>
                {reference === RolesEntities.object ? (
                  <TableCell component="th" scope="row">
                    {projectName}
                  </TableCell>
                ) : null}
                {/* // TODO-08: костыль */}
                {/* <TableCell component="th" scope="row">{nameRu || name}</TableCell> */}
                <TableCell component="th" scope="row">
                  {getMultiLanguageName(props)}
                </TableCell>
                <TableCell>
                  <div className={classes.iconsWrapper}>
                    <Select
                      multiple
                      value={role}
                      className={classes.select}
                      renderValue={selected => renderSelectedValue(selected, allSystemRoles)}
                      onChange={event => changeItemRole(event, itemId)}
                    >
                      {renderSelectMenuItems(allRoles)}
                    </Select>
                    <Tooltip title={`Назначить роли на все ${label}ы пользователя`}>
                      <IconButton onClick={() => acceptRole(role, { projectId, projectName })}>
                        <DoneAllIcon />
                      </IconButton>
                    </Tooltip>
                    {reference === RolesEntities.project ? (
                      <Tooltip title="Добавить роли на все объекты проекта">
                        <IconButton onClick={() => appointItems(itemId, role)}>
                          <PlaylistAddIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    <Tooltip title={`Удалить ${label}`}>
                      <IconButton onClick={() => deleteItem(itemId)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export const RolesTable = withStyles(styles as any)(RolesTableComponent);
