import React, { FC } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  LongTextInput,
  NumberInput,
  FileInput,
  FileField,
  SelectInput,
  ReferenceInput,
} from 'react-admin';
import { blur } from 'redux-form';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { analyticalReportTemplateTypes, Resources } from 'Constants';
import { aliasRegex } from '../../../Utils/stringMethods';

const AnalyticalReportCreateComponent: FC = ({
  push: propsPush,
  blur: reduxFormBlur,
  classes = {},
  ...props
}: any) => (
  <Create title="Новый шаблон" {...props}>
    <SimpleForm redirect="list">
      <TextInput fullWidth required source="name" label="Наименование" />
      <TextInput fullWidth required source="alias" label="Код" validate={aliasRegex()} />
      <SelectInput
        fullWidth
        required
        source="template_type"
        label="Тип аналитического отчёта"
        choices={analyticalReportTemplateTypes}
        optionText="name"
        optionValue="value"
      />
      <LongTextInput fullWidth source="comment" label="Описание" defaultValue="" />
      <ReferenceInput
        source="permission"
        reference={Resources.PERMISSION}
        resource={Resources.PERMISSION}
        label="Право доступа"
        fullWidth
        required
        helperText={null}
      >
        <SelectInput optionText="name" optionValue="alias" />
      </ReferenceInput>
      <NumberInput fullWidth source="sort_order" label="Порядок сортировки" defaultValue="10" />
      <LongTextInput fullWidth source="settings" label="Установочные данные (JSON)" />
      <FileInput
        required
        source="template"
        parse={file => {
          const fileReader = new FileReader();

          fileReader.onload = () => {
            reduxFormBlur('record-form', 'template', fileReader.result);
          };

          fileReader.readAsText(file?.rawFile);
          return file;
        }}
        label="Выберите файл шаблона"
        accept=".mrt"
      >
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export const AnalyticalReportCreate = connect(null, { push, blur })(
  AnalyticalReportCreateComponent,
);
