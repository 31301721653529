import React, { FC } from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import { Resources } from 'Constants';

const styles = {
  card: { width: '100%', marginBottom: '10px' },
  сardContent: { display: 'flex', flexDirection: 'column', position: 'relative' },
  groupWrapper: { display: 'flex' },
  groupItem: { marginRight: '20px' },
  btn: { position: 'absolute', right: '0', top: '0' },
};

const SpecialityCreateComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => (
  <Create title="Новая специальность" {...props}>
    <SimpleForm redirect="list">
      <Card className={classes.card}>
        <CardContent className={classes.сardContent}>
          <IconButton onClick={() => propsPush(`/${Resources.SPECIALITY}`)} className={classes.btn}>
            <CloseIcon />
          </IconButton>
          <div className={classes.groupWrapper}>
            <TextInput source="name" label="Наименование" fullWidth className={classes.groupItem} />
            <TextInput source="code" label="Код" fullWidth />
          </div>
        </CardContent>
      </Card>
    </SimpleForm>
  </Create>
);

const connectedSpecialityCreate = connect(null, { push })(SpecialityCreateComponent);
export const SpecialityCreate = withStyles(styles as any)(connectedSpecialityCreate);
