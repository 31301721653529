/* eslint-disable @typescript-eslint/camelcase */

import axios from 'axios';
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  UPDATE,
  CREATE,
  DELETE,
  GET_MANY_REFERENCE,
  DELETE_MANY,
} from 'react-admin';
import {
  API_URL,
  REST,
  userIdField,
  userDataPath,
  userPrefix,
  organisationPrefix,
  organisationIdField,
  DATE_SERVER_MASK,
  RolesEntities,
  rolePrefix,
  roleIdField,
  objectPrefix,
  objectRelation,
  roleRelationField,
  roleRelationIdField,
} from 'Constants';
import {
  IUserListResp,
  IRACreateParams,
  IRADeleteParams,
  IRAGetOneParams,
  IRAGetManyParams,
  IRAUpdateParams,
  IRADataProviderParams,
  IUserUpdateProvider,
  IUserCreateProvider,
  IRAGetManyReferenceParams,
  IRADeleteManyParams,
  IRAGetListParams,
} from 'Interfaces';
import { get } from 'lodash';
import dayjs from 'dayjs';
import {
  setPrefix,
  removePrefix,
  removePrefixInArray,
  PgUnAssoc,
  filterResource,
  mapUserRolesResponse,
} from '../utils';

export const userProvider = async (type: string, params: IRADataProviderParams): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const { filter = {} } = (params as IRAGetListParams) || {};
        const response = await axios.post(`${API_URL}${REST.AdminUser.LIST}`, {});
        const parsedUsers = PgUnAssoc(get(response, userDataPath));
        const filteredUsers = filterResource(parsedUsers, filter);
        const usersWithId = filteredUsers.map((el: IUserListResp) => ({
          ...el,
          id: setPrefix(userPrefix, el[userIdField]),
          [organisationPrefix]: setPrefix(organisationPrefix, el[organisationIdField]),
        }));
        return { data: usersWithId, total: usersWithId.length };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(userPrefix, id);
        const responseUserPromise = axios.post(`${API_URL}${REST.AdminUser.LIST}`, {
          [userIdField]: [clearId],
        });
        const userRolesPomise = axios.post(`${API_URL}${REST.AdminUser.RELATION.LIST}`, {
          [userIdField]: clearId,
          relation: RolesEntities.user,
          relation_id: [clearId],
        });
        const rolePromise = axios.post(`${API_URL}${REST.AdminRole.LIST}`, {});
        const [responseUser, userRoles, role] = await Promise.all([
          responseUserPromise,
          userRolesPomise,
          rolePromise,
        ]);
        const parsedUser = PgUnAssoc(get(responseUser, userDataPath))[0];
        const parsedRoles = mapUserRolesResponse(userRoles, role).map(el =>
          setPrefix(rolePrefix, el),
        );

        return {
          data: {
            ...parsedUser,
            id: setPrefix(userPrefix, parsedUser[userIdField]),
            [organisationPrefix]: setPrefix(organisationPrefix, parsedUser[organisationIdField]),
            [roleIdField]: parsedRoles,
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const clearIds = removePrefixInArray(ids, userPrefix);
        const response = await axios.post(`${API_URL}${REST.AdminUser.LIST}`, {
          [userIdField]: clearIds,
        });
        const parsedUsers = PgUnAssoc(get(response, userDataPath));
        const usersWithId = parsedUsers.map((el: IUserListResp) => ({
          ...el,
          id: setPrefix(userPrefix, el[userIdField]),
          [organisationPrefix]: setPrefix(organisationPrefix, el[organisationIdField]),
        }));
        return { data: usersWithId };
      }
      case UPDATE: {
        const rawData = (params as IRAUpdateParams).data as IUserUpdateProvider;
        const {
          id,
          birthdate,
          [organisationIdField]: unusedId,
          [organisationPrefix]: unusedPrefix,
          role_id: roleId,
          blocked: isBlocked,
          ...sendUser
        } = rawData;

        await axios.post(`${API_URL}${REST.AdminUser.RELATION.SET}`, {
          relation: RolesEntities.user,
          relation_id: [sendUser[userIdField]],
          [userIdField]: [sendUser[userIdField]],
          [roleIdField]: roleId.map(el => removePrefix(rolePrefix, el)),
          append: false,
        });

        await axios.post(`${API_URL}${REST.AdminUser.BLOCKED.SET}`, {
          [userIdField]: [sendUser[userIdField]],
          blocked: Boolean(isBlocked),
        });

        const responseUser = await axios.post(`${API_URL}${REST.AdminUser.UPDATE}`, {
          ...sendUser,
          birthdate: dayjs(sendUser.birthdate).format(DATE_SERVER_MASK),
          [organisationIdField]: removePrefix(organisationPrefix, rawData[organisationPrefix]),
        });

        const parsedUser = get(responseUser, userDataPath);
        return {
          data: {
            ...parsedUser,
            id: setPrefix(userPrefix, parsedUser[userIdField]),
            [organisationPrefix]: setPrefix(organisationPrefix, parsedUser[organisationIdField]),
          },
        };
      }
      case CREATE: {
        const { data: rawData } = params as IRACreateParams;
        const { [organisationPrefix]: orgId, ...others } = rawData as IUserCreateProvider;
        const clearId = removePrefix(organisationPrefix, orgId);
        const sendUser = { ...others, [organisationIdField]: clearId };
        const responseUser = await axios.post(`${API_URL}${REST.AdminUser.CREATE}`, sendUser);
        const newUser = get(responseUser, userDataPath);
        return {
          data: {
            ...newUser,
            id: setPrefix(userPrefix, newUser[userIdField]),
            [organisationPrefix]: setPrefix(organisationPrefix, newUser[organisationIdField]),
          },
        };
      }
      case GET_MANY_REFERENCE: {
        const fields = {
          [userPrefix as string]: userIdField,
          [organisationPrefix as string]: organisationIdField,
          roleRelationField,
          roleRelationIdField,
        };
        const { id, target } = params as IRAGetManyReferenceParams;
        const clearId = removePrefix(target, id);
        const sendData = {
          [organisationPrefix as string]: { [fields[target]]: [clearId] },
          [objectPrefix as string]: {
            [roleRelationField]: objectRelation,
            [roleRelationIdField]: [clearId],
          },
        };
        const endpoints = {
          [organisationPrefix as string]: REST.AdminUser.LIST,
          [objectPrefix as string]: REST.AdminRole.RELATION,
        };
        const response = await axios.post(`${API_URL}${endpoints[target]}`, sendData[target]);
        const parsedUsers = PgUnAssoc(get(response, userDataPath));
        const usersWithId = parsedUsers.map((el: IUserListResp) => ({
          ...el,
          id: setPrefix(userPrefix, el[userIdField]),
          [organisationPrefix]: setPrefix(organisationPrefix, el[organisationIdField]),
        }));
        return { data: usersWithId, total: usersWithId.length };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(userPrefix, id);
        await axios.post(`${API_URL}${REST.AdminUser.DELETE}`, { [userIdField]: [clearId] });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, userPrefix);
        await axios.post(`${API_URL}${REST.AdminUser.DELETE}`, { [userIdField]: clearIds });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${userPrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${userPrefix}-${type}`);
  }
};
