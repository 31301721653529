/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';
import {
  API_URL,
  REST,
  userIdField,
  userDataPath,
  userPrefix,
  organisationPrefix,
  organisationIdField,
} from 'Constants';
import { get } from 'lodash';
import { IUserListResp } from 'Interfaces';
import { setPrefix, PgUnAssoc, filterResource } from '../utils';

export const cloneRole = async (targetUserId: number, sourceUserId: number): Promise<void> => {
  try {
    await axios.post(`${API_URL}${REST.AdminUser.CLONE}`, {
      source_user_id: sourceUserId,
      target_user_id: targetUserId,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export interface ISearchCloneingUser extends IUserListResp {
  id: number | string;
  [organisationPrefix]: string;
}

export const searchCloneingUser = async (value: string): Promise<ISearchCloneingUser[]> => {
  try {
    const response = await axios.post(`${API_URL}${REST.AdminUser.LIST}`, {
      extended: true,
      filter: { full_name_ru: value },
    });
    const parsedUsers = PgUnAssoc(get(response, userDataPath));
    const filteredUsers = filterResource(parsedUsers, {});
    const usersWithId = filteredUsers.map((el: IUserListResp) => ({
      ...el,
      id: setPrefix(userPrefix, el[userIdField]),
      [organisationPrefix]: setPrefix(organisationPrefix, el[organisationIdField]),
    }));
    return usersWithId;
  } catch (error) {
    throw new Error(error);
  }
};
