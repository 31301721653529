import React, { FC } from 'react';
import { Datagrid, TextField, List, DeleteButton } from 'react-admin';
import { fileTemplateIdField } from 'Constants';

export const FileTemplateList: FC = props => (
  <List {...props} title="Шаблоны файлов" exporter={false}>
    <Datagrid rowClick="edit">
      <TextField source={fileTemplateIdField} label="Id" />
      <TextField source="code" label="Код" />
      <TextField source="name" label="Название" />
      <DeleteButton />
    </Datagrid>
  </List>
);
