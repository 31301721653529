/* eslint-disable @typescript-eslint/camelcase */

import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, CardHeader, MenuItem, Select } from '@material-ui/core';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  API_URL,
  DATE_SERVER_MASK,
  organisationIdField,
  organisationPrefix,
  PICKER_DATE_MASK,
  REST,
  organisationDataPath,
} from 'Constants';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import axios from 'axios';
import { PgUnAssoc, setPrefixInArray } from 'Providers/utils';
import { get } from 'lodash';
import { downloadEventLogExcelAction } from 'Providers/utils/customActions';
import { EventLogType, EVENT_LOG_ACTION_TYPES, IOrganization } from './EventLogConstantst';

const styles = {
  card: { backgroundColor: 'white' },
  content: {
    fontSize: '1rem',
    fontFamily: 'Arial',
    width: '50%',
  },
  selectLabel: { width: '150px', marginRight: '12px' },
  select: { display: 'flex', alignItems: 'center' },
  calendar: { marginTop: '12px', marginBottom: '12px' },
  calendarText: { marginBottom: '10px', display: 'flex' },
  downloadButton: {
    background: '#216ba5',
    color: 'white',
  },
  calendarDate: { fontWeight: '600' },
  datePicker: { display: 'flex' },
};

export const EventLogListComponent = ({ classes = {}, downloadExcel }: any): JSX.Element => {
  const [actions, setActions] = useState<EventLogType>(EVENT_LOG_ACTION_TYPES.system);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const onChange = (dates: Date[]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const [selectedOrganization, setSelectedOrganization] = useState<number>(0);

  const loadOrganizations = async () => {
    const response = await axios.post(`${API_URL}${REST.AdminOrganisation.LIST}`, {});
    const parsedOrgs = PgUnAssoc(get(response, organisationDataPath));
    const orgsWithId = setPrefixInArray(parsedOrgs, organisationPrefix, organisationIdField);
    console.log(orgsWithId);
    setOrganizations(orgsWithId);
  };

  const generateExcel = async (): Promise<void> => {
    await downloadExcel({
      history_action: actions,
      date_from: dayjs(startDate).format(DATE_SERVER_MASK),
      date_to: endDate
        ? dayjs(endDate).format(DATE_SERVER_MASK)
        : dayjs(startDate).format(DATE_SERVER_MASK),
      organisation_id: selectedOrganization !== 0 ? [selectedOrganization] : null,
    });
  };

  useEffect(() => {
    loadOrganizations();
  }, []);

  return (
    <Card className={classes.card}>
      <CardHeader title="Журнал событий" />
      <CardContent className={classes.content}>
        <div className={classes.select}>
          <span className={classes.selectLabel}>Действия</span>
          <Select onChange={e => setActions(e.target.value as EventLogType)} value={actions}>
            <MenuItem value={EVENT_LOG_ACTION_TYPES.system}>Системные действия</MenuItem>
            <MenuItem value={EVENT_LOG_ACTION_TYPES.admin}>Действия администратора</MenuItem>
          </Select>
        </div>
        <div className={classes.select}>
          <div className={classes.selectLabel}>Организации</div>
          <Select
            onChange={(e, a) => {
              setSelectedOrganization(+e.target.value);
            }}
            value={selectedOrganization}
          >
            <MenuItem value={0}>Все</MenuItem>
            {organizations &&
              organizations.map(item => (
                <MenuItem key={item.organisation_id} value={item.organisation_id}>
                  {item.name_ru}
                </MenuItem>
              ))}
          </Select>
        </div>
        <div className={classes.calendar}>
          <div className={classes.calendarText}>
            <div className={classes.selectLabel}>Выбрать период:</div>
            <span className={classes.calendarDate}>
              {`${dayjs(startDate).format(PICKER_DATE_MASK)} - ${dayjs(endDate || startDate).format(
                PICKER_DATE_MASK,
              )}`}
            </span>
          </div>
          <div className={classes.datePicker}>
            <DatePicker
              locale="ru"
              selected={endDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
              className={classes.picker}
            />
          </div>
        </div>
        <Button onClick={generateExcel} className={classes.downloadButton}>
          Сформировать
        </Button>
      </CardContent>
    </Card>
  );
};

const connectedEventLogList = connect(null, {
  downloadExcel: downloadEventLogExcelAction,
  push,
})(EventLogListComponent);
export const EventLogList = withStyles(styles as any)(connectedEventLogList);
