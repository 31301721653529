// TODO-08: посмотреть почему тут отключена meta
import React, { FC } from 'react';
import {
  LongTextInput,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import { organisationPrefix, referenceProjectTypePrefix, Resources, projectLabel } from 'Constants';
import { getMultiLanguageName } from 'Components/utils/getFullName';

const styles = {
  card: { width: '100%', marginBottom: '10px' },
  сardContent: { display: 'flex', flexDirection: 'column', position: 'relative' },
  groupWrapper: { display: 'flex' },
  groupItem: { marginRight: '20px' },
  btn: { position: 'absolute', right: '0', top: '0' },
};

export const ProjectCreateComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => (
  <Create title="Новый проект" {...props}>
    <SimpleForm redirect="list">
      <Card className={classes.card}>
        <CardContent className={classes.сardContent}>
          <IconButton onClick={() => propsPush(`/${Resources.PROJECTS}`)} className={classes.btn}>
            <CloseIcon />
          </IconButton>
          <div className={classes.groupWrapper}>
            <TextInput
              fullWidth
              source="name_ru"
              label={projectLabel.name_ru}
              className={classes.groupItem}
              required
            />
            <TextInput fullWidth source="name_en" label={projectLabel.name_en} />
          </div>
          <ReferenceInput
            source={organisationPrefix}
            resource={Resources.ORGS}
            reference={Resources.ORGS}
            label="Организация"
            fullWidth
            required
          >
            <SelectInput optionText={record => getMultiLanguageName(record)} />
          </ReferenceInput>
          <ReferenceInput
            source={referenceProjectTypePrefix}
            resource={Resources.REFERENCE_PROJECT_TYPE}
            reference={Resources.REFERENCE_PROJECT_TYPE}
            label="Тип проекта"
            fullWidth
            required
          >
            <SelectInput optionText={record => getMultiLanguageName(record)} />
          </ReferenceInput>
          <LongTextInput source="description_ru" label={projectLabel.description_ru} fullWidth />
          <LongTextInput source="description_en" label={projectLabel.description_en} fullWidth />
          {/* <Card className={classes.card}>
            <CardContent className={classes.сardContent}>
              <ArrayInput source={projectMeta} label="Мета-информация" fullWidth>
                <SimpleFormIterator>
                  <TextInput source={Template.key} required />
                  <TextInput source={Template.label} />
                </SimpleFormIterator>
              </ArrayInput>
            </CardContent>
          </Card> */}
        </CardContent>
      </Card>
    </SimpleForm>
  </Create>
);

const connectedProjectCreate = connect(null, { push })(ProjectCreateComponent);
export const ProjectCreate = withStyles(styles as any)(connectedProjectCreate);
