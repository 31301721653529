import axios from 'axios';
import { GET_LIST, GET_MANY } from 'react-admin';
import { IRADataProviderParams, IRAGetManyParams } from 'Interfaces';
import { API_URL, REST, referenceGroupUnitDataPath, referenceGroupUnitPrefix } from 'Constants';
import { get } from 'lodash';
import { mapArrayToResource } from '../utils';

export const referenceGroupUnitProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(`${API_URL}${REST.AdminReference.GroupUnit.LIST}`, {});
        const groupUnit = get(response, referenceGroupUnitDataPath);
        const parsedGroupUnit = mapArrayToResource(groupUnit, null, 'GroupUnit');
        return { data: parsedGroupUnit, total: parsedGroupUnit.length };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const response = await axios.post(`${API_URL}${REST.AdminReference.GroupUnit.LIST}`, ids);
        const groupUnit = get(response, referenceGroupUnitDataPath);
        const filteredGroupUnit = groupUnit.filter(el => ids.includes(el));
        const parsedGroupUnit = mapArrayToResource(filteredGroupUnit, null, 'GroupUnit');
        return { data: parsedGroupUnit };
      }
      default:
        throw new Error(`Неизвестный метод получения ${referenceGroupUnitPrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${referenceGroupUnitPrefix}-${type}`);
  }
};
