export const DOWNLOAD_EVENT_FILE = 'DOWNLOAD_EVENT_FILE';

export const downloadEventLogExcelAction = payload => ({
  type: `CUSTOM/${DOWNLOAD_EVENT_FILE}`,
  payload: { payload },
  meta: {
    fetch: DOWNLOAD_EVENT_FILE,
    resource: 'event_log',
    onFailure: { notification: { body: 'Ошибка при загрузке xls-файла', level: 'warning' } },
  },
});
