import axios from 'axios';
import { GET_LIST, GET_ONE, UPDATE, CREATE, DELETE, GET_MANY, DELETE_MANY } from 'react-admin';
import {
  IRACreateParams,
  IRAGetOneParams,
  IRAUpdateParams,
  IRAGetManyParams,
  IRADataProviderParams,
  IRADeleteParams,
  IPermissionUpdateProvider,
  IRADeleteManyParams,
  IRAGetListParams,
} from 'Interfaces';
import { API_URL, REST, permissionIdField, permissionDataPath, permissionPrefix } from 'Constants';
import { get } from 'lodash';
import {
  setPrefix,
  setPrefixInArray,
  removePrefix,
  PgUnAssoc,
  removePrefixInArray,
  filterResource,
} from '../utils';

export const permissionProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const { filter } = params as IRAGetListParams;
        const response = await axios.post(`${API_URL}${REST.AdminPermission.LIST}`, {});
        const parsedPermission = PgUnAssoc(get(response, permissionDataPath));
        const filteredPermission = filterResource(parsedPermission, filter);
        const permissionWithId = setPrefixInArray(
          filteredPermission,
          permissionPrefix,
          permissionIdField,
        );

        return { data: permissionWithId, total: permissionWithId.length };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(permissionPrefix, id);
        const response = await axios.post(`${API_URL}${REST.AdminPermission.LIST}`, {
          [permissionIdField]: [clearId],
        });
        const parsedPermission = PgUnAssoc(get(response, permissionDataPath))[0];
        return {
          data: {
            ...parsedPermission,
            id: setPrefix(permissionPrefix, parsedPermission[permissionIdField]),
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;

        // эта проверка уберают ошибку связанную с компонентом ReferenceInput,
        // где в качестве ids можно передать any[]
        if (ids.length === 1 && !/\d/.test(ids[0])) return { data: [] };

        const clearIds = removePrefixInArray(ids, permissionPrefix);
        const response = await axios.post(`${API_URL}${REST.AdminPermission.LIST}`, {
          [permissionIdField]: clearIds,
        });
        const parsedPermission = PgUnAssoc(get(response, permissionDataPath));
        const permissionWithId = setPrefixInArray(
          parsedPermission,
          permissionPrefix,
          permissionIdField,
        );
        return { data: permissionWithId };
      }
      case UPDATE: {
        const rawData = (params as IRAUpdateParams).data as IPermissionUpdateProvider;
        const { id, ...sendData } = rawData;
        const response = await axios.post(`${API_URL}${REST.AdminPermission.UPDATE}`, sendData);
        const updatedPermission = get(response, permissionDataPath);
        return {
          data: {
            ...updatedPermission,
            id: setPrefix(permissionPrefix, updatedPermission[permissionIdField]),
          },
        };
      }
      case CREATE: {
        const { data: sendData } = params as IRACreateParams;
        const response = await axios.post(`${API_URL}${REST.AdminPermission.CREATE}`, sendData);
        const newPermission = get(response, permissionDataPath);
        return {
          data: {
            ...newPermission,
            id: setPrefix(permissionPrefix, newPermission[permissionIdField]),
          },
        };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(permissionPrefix, id);
        await axios.post(`${API_URL}${REST.AdminPermission.DELETE}`, {
          [permissionIdField]: [clearId],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, permissionPrefix);
        await axios.post(`${API_URL}${REST.AdminPermission.DELETE}`, {
          [permissionIdField]: clearIds,
        });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${permissionPrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${permissionPrefix}-${type}`);
  }
};
