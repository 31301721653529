import React, { FC } from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  DisabledInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  DeleteButton,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Button, Card, CardContent } from '@material-ui/core';
import {
  Resources,
  referenceBusinessRelationPrefix,
  rolePrefix,
  approvalPrefix,
  approvalIdField,
  approvalStepPrefix,
  approvalStepIdField,
} from 'Constants';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import { Add } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = {
  card: {
    width: '100%',
    marginBottom: '1.5rem',
    marginTop: '1.5rem',
  },
  cardContent: {
    position: 'relative',
  },
  list: {
    width: '100%',
    display: 'none',
  },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 1000,
  },
};

const RenderTitle = ({ record }: any) => <span>{record.name}</span>;

const AddStepButton = ({ record }: any) => {
  const AddStepLink = (props: any) => (
    <Link to={`/${Resources.APPROVAL_STEP}/create?${approvalPrefix}=${record.id}`} {...props} />
  );

  return (
    <Button component={AddStepLink} variant="text" color="primary">
      <Add />
      &nbsp;Новый шаг
    </Button>
  );
};

const EditStepButton = ({ record }: any) => {
  const EditStepLink = (props: any) => (
    <Link
      to={`/${Resources.APPROVAL_STEP}/${record.id}?${approvalPrefix}=${approvalPrefix}-${record[approvalIdField]}`}
      {...props}
    />
  );

  return (
    <Button component={EditStepLink} variant="text" color="primary">
      Edit
    </Button>
  );
};

const approvalEditComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => {
  const { id } = props;
  return (
    <Edit title={<RenderTitle />} {...props}>
      <TabbedForm redirect="list">
        <FormTab label="Общая информация">
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <DisabledInput fullWidth required source={approvalIdField} label="Id" />
              <IconButton
                onClick={() => propsPush(`/${Resources.APPROVAL}`)}
                className={classes.closeBtn}
              >
                <CloseIcon />
              </IconButton>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <TextInput fullWidth required source="title" label="Название" />
              <ReferenceInput
                source={referenceBusinessRelationPrefix}
                resource={Resources.REFERENCE_BUSINESS_RELATION}
                reference={Resources.REFERENCE_BUSINESS_RELATION}
                label="Документ"
                fullWidth
                required
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <ReferenceInput
                source={rolePrefix}
                resource={Resources.ROLE}
                reference={Resources.ROLE}
                label="Роль"
                fullWidth
                required
                filter={{
                  codePrefix: 'ba',
                }}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </CardContent>
          </Card>
        </FormTab>
        <FormTab label="Шаги согласования">
          <ReferenceManyField
            resource={Resources.APPROVAL_STEP}
            reference={Resources.APPROVAL_STEP}
            target={approvalStepPrefix}
            label=""
            fullWidth
          >
            <Datagrid>
              <TextField source={approvalStepIdField} label="Id" />
              <TextField source="title" label="Наименование" />
              <TextField source="acceptance_count" label="Кол-во необходимых согласований" />
              <TextField source="expiration" label="Кол-во дней на согласование" />
              <EditStepButton />
              <DeleteButton redirect={`/${Resources.APPROVAL}/${id}/1`} />
            </Datagrid>
          </ReferenceManyField>
          <AddStepButton />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const connectedApprovalEdit = connect(null, { push })(approvalEditComponent);
export const ApprovalEdit = withStyles(styles as any)(connectedApprovalEdit);
