import React, { useState } from 'react';
import { addField } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';

const styles = {
  editableUrlInput: { width: '100%', display: 'flex', marginTop: '20px' },
};

const EditableUrlInputComponent = addField(({ input, classes }) => {
  const { onChange, value } = input;
  const [url, setUrl] = useState(value);

  const handleUrlChange = (e): void => {
    const currentUrl = e.target.value === '' ? null : e.target.value;
    setUrl(currentUrl);
    onChange(currentUrl);
  };

  return (
    <div className={classes.editableUrlInput}>
      <TextField
        defaultValue={url}
        onChange={handleUrlChange}
        label="URL видеотрансляции"
        fullWidth
      />
      <IconButton disabled={!url} href={url} target="blank" color="primary">
        <VisibilityIcon />
      </IconButton>
    </div>
  );
});

export const EditableUrlInput = withStyles(styles as any)(EditableUrlInputComponent);
