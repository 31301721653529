/* eslint-disable @typescript-eslint/camelcase */

import axios from 'axios';
import { GET_LIST, UPDATE, GET_ONE, CREATE, DELETE, GET_MANY, DELETE_MANY } from 'react-admin';
import {
  IRADataProviderParams,
  IRAGetOneParams,
  IRAGetManyParams,
  IRAUpdateParams,
  IRADeleteParams,
  IRACreateParams,
  IReferenceObjectTypeListResp,
  IReferenceObjectTypeUpdateProvider,
  IReferenceObjectTypeCreateProvider,
  IRADeleteManyParams,
} from 'Interfaces';
import {
  API_URL,
  REST,
  referenceObjectTypeDataPath,
  referenceObjectTypePrefix,
  referenceObjectTypeIdField,
  organisationPrefix,
  organisationIdField,
} from 'Constants';
import { get, uniqBy } from 'lodash';
import {
  PgUnAssoc,
  setPrefix,
  removePrefix,
  removePrefixInArray,
  mapTemplateToArray,
  mapTemplateFromArray,
  setPrefixInArray,
} from '../utils';

export const referenceObjectTypeProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(`${API_URL}${REST.AdminReference.ObjectType.LIST}`, {});
        const parsedObjectType: IReferenceObjectTypeListResp[] = PgUnAssoc(
          get(response, referenceObjectTypeDataPath),
        );
        const objectTypeWithId: any[] = parsedObjectType.map(el => ({
          ...el,
          ...mapTemplateToArray(el),
          attributes: el.schedule_template_ru?.fields,
          id: setPrefix(referenceObjectTypePrefix, el[referenceObjectTypeIdField]),
        }));
        return { data: objectTypeWithId, total: objectTypeWithId.length };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(referenceObjectTypePrefix, id);
        const response: IReferenceObjectTypeListResp = await axios.post(
          `${API_URL}${REST.AdminReference.ObjectType.LIST}`,
          { [referenceObjectTypeIdField]: [clearId] },
        );
        const parsedObjectType: IReferenceObjectTypeListResp = PgUnAssoc(
          get(response, referenceObjectTypeDataPath),
        )[0];

        const passportTemplateWithPrevKeys = Object.entries(
          parsedObjectType.passport_template_ru,
        ).reduce((acc, item: any) => {
          const [key, value] = item;
          const keyType: string = key.slice(0, -1);

          return {
            ...acc,
            [key]: value?.map(field => ({
              ...field,
              [`${keyType}PrevKey`]: field[`${keyType}Key`],
            })),
          };
        }, {});

        const parsedObjectTypeWithPrevKey = {
          ...parsedObjectType,
          passport_template_ru: passportTemplateWithPrevKeys,
        };

        const organisationWithId =
          parsedObjectType[organisationIdField]?.length > 0
            ? parsedObjectType[organisationIdField]?.map(
                organisationId => `${organisationPrefix}-${organisationId}`,
              )
            : null;

        return {
          data: {
            ...parsedObjectTypeWithPrevKey,
            ...mapTemplateToArray(parsedObjectTypeWithPrevKey),
            attributes: parsedObjectType.schedule_template_ru?.fields.map((el: any) => ({
              ...el,
              fieldPrevKey: el.fieldKey,
            })),
            id: setPrefix(referenceObjectTypePrefix, parsedObjectType[referenceObjectTypeIdField]),
            [organisationPrefix]: organisationWithId,
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const clearIds = removePrefixInArray(ids, referenceObjectTypePrefix).filter(
          el => el !== null,
        );
        const response = await axios.post(`${API_URL}${REST.AdminReference.ObjectType.LIST}`, {
          [referenceObjectTypeIdField]: clearIds,
        });
        const parsedObjectType: IReferenceObjectTypeListResp[] = PgUnAssoc(
          get(response, referenceObjectTypeDataPath),
        );
        const objectTypeWithId: any[] = parsedObjectType.map(el => ({
          ...el,
          ...mapTemplateToArray(el),
          attributes: el.schedule_template_ru?.fields,
          id: setPrefix(referenceObjectTypePrefix, el[referenceObjectTypeIdField]),
        }));
        return { data: objectTypeWithId };
      }
      case UPDATE: {
        const rawData = (params as IRAUpdateParams).data as IReferenceObjectTypeUpdateProvider;
        const {
          fields,
          groups,
          id,
          widgets,
          attributes,
          [organisationPrefix]: organizationIds,
          ...rest
        } = rawData;
        const clearOrganisationIds: number[] | null =
          organizationIds?.length > 0
            ? removePrefixInArray(organizationIds, organisationPrefix)
            : null;

        let modifiedAttributes = null;
        if (attributes) {
          modifiedAttributes = attributes.map((el: any) => {
            const { fieldPrevKey, fieldKey, ...restFields } = el;
            return {
              fieldHideInModelObject: false,
              fieldHideInReports: false,
              fieldKey,
              ...(fieldKey !== fieldPrevKey && { fieldPrevKey }),
              ...restFields,
            };
          });
        }

        const response = await axios.post(`${API_URL}${REST.AdminReference.ObjectType.UPDATE}`, {
          ...rest,
          ...mapTemplateFromArray(rawData),
          schedule_template_ru: modifiedAttributes ? { fields: modifiedAttributes } : null,
          [organisationIdField]: clearOrganisationIds,
        });
        const updatedObjectType = get(response, referenceObjectTypeDataPath);
        const organisationWithPrefix =
          updatedObjectType[organisationPrefix]?.length > 0
            ? updatedObjectType[organisationPrefix].map(orgId =>
                setPrefix(organisationPrefix, orgId),
              )
            : null;
        return {
          data: {
            ...updatedObjectType,
            ...mapTemplateToArray(updatedObjectType),
            attributes: updatedObjectType.schedule_template_ru?.fields,
            id: setPrefix(referenceObjectTypePrefix, updatedObjectType[referenceObjectTypeIdField]),
            [organisationPrefix]: organisationWithPrefix,
          },
        };
      }
      case CREATE: {
        const rawData = (params as IRACreateParams).data as IReferenceObjectTypeCreateProvider;
        const {
          fields,
          groups,
          widgets,
          attributes,
          [organisationPrefix]: organizationIds,
          ...rest
        } = rawData;
        const clearOrganisationIds: number[] | null =
          organizationIds?.length > 0
            ? removePrefixInArray(organizationIds, organisationPrefix)
            : null;

        let modifiedAttributes = null;
        if (attributes) {
          modifiedAttributes = attributes.map((el: any) => ({
            fieldHideInModelObject: false,
            fieldHideInReports: false,
            ...el,
          }));
        }
        const response = await axios.post(`${API_URL}${REST.AdminReference.ObjectType.CREATE}`, {
          ...rest,
          ...mapTemplateFromArray(rawData),
          schedule_template_ru: modifiedAttributes ? { fields: modifiedAttributes } : null,
          [organisationIdField]: clearOrganisationIds,
        });
        const newObjectType = get(response, referenceObjectTypeDataPath);

        const organisationWithPrefix =
          newObjectType[organisationPrefix]?.length > 0
            ? newObjectType[organisationPrefix].map(orgId => setPrefix(organisationPrefix, orgId))
            : null;

        return {
          data: {
            ...newObjectType,
            ...mapTemplateToArray(newObjectType),
            attributes: newObjectType.schedule_template_ru?.fields,
            id: setPrefix(referenceObjectTypePrefix, newObjectType[referenceObjectTypeIdField]),
            [organisationPrefix]: organisationWithPrefix,
          },
        };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(referenceObjectTypePrefix, id);
        await axios.post(`${API_URL}${REST.AdminReference.ObjectType.DELETE}`, {
          [referenceObjectTypeIdField]: [clearId],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, referenceObjectTypePrefix);
        await axios.post(`${API_URL}${REST.AdminReference.ObjectType.DELETE}`, {
          [referenceObjectTypeIdField]: clearIds,
        });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${referenceObjectTypePrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(error.message || `Ошибка метода ${referenceObjectTypePrefix}-${type}`);
  }
};
