export const fileReaderPromise = (file): Promise<any> => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = (): void => {
      fileReader.abort();
      reject(fileReader.error);
    };

    fileReader.onload = (): void => {
      resolve(fileReader.result);
    };
    fileReader.readAsText(file);
  });
};
