/* eslint-disable @typescript-eslint/camelcase */

import axios from 'axios';
import { CREATE, DELETE, DELETE_MANY, GET_LIST, GET_ONE, UPDATE } from 'react-admin';
import {
  API_URL,
  REST,
  categoryDataPath,
  categoryPrefix,
  categoryIdField,
  organisationPrefix,
  organisationIdField,
  organisationDataPath,
  objectPrefix,
  objectIdField,
} from 'Constants';
import { get } from 'lodash';
import {
  setPrefixInArray,
  PgUnAssoc,
  setPrefix,
  removePrefix,
  removePrefixInArray,
} from '../utils';
import {
  IRADeleteManyParams,
  IRADeleteParams,
  IRAGetOneParams,
  IRAUpdateParams,
  IUserUpdateProvider,
} from '../../Interfaces';

export const categoryProvider = async (type: string, params: any): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const { filter } = params;

        const response = filter.organisation_id
          ? await axios.post(`${API_URL}${REST.AdminCategory.LIST}`, {
              organisation_id: Number(filter.organisation_id.split('-').pop()),
            })
          : await axios.post(`${API_URL}${REST.AdminCategory.LIST}`, { object_category_id: [] });

        const parsedCategory = PgUnAssoc(get(response, categoryDataPath));
        const categoryWithId = setPrefixInArray(
          parsedCategory,
          categoryPrefix,
          categoryIdField,
        ).map(el => ({
          ...el,
          [organisationPrefix]: setPrefix(organisationPrefix, el[organisationIdField]),
        }));

        return { data: categoryWithId, total: categoryWithId.length };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(categoryPrefix, id);

        const response = await axios.post(`${API_URL}${REST.AdminCategory.LIST}`, {
          [categoryIdField]: [clearId],
        });
        const parsedCategory = PgUnAssoc(get(response, categoryDataPath))[0];

        return {
          data: {
            ...parsedCategory,
            id: setPrefix(categoryPrefix, parsedCategory[categoryIdField]),
            [organisationPrefix]: setPrefix(
              organisationPrefix,
              parsedCategory[organisationIdField],
            ),
          },
        };
      }
      case CREATE: {
        const { data: rawData } = params;
        const { [organisationPrefix]: organisationId, ...others } = rawData;

        const clearOrganisationId = removePrefix(organisationPrefix, organisationId);

        const sendData = {
          ...others,
          [organisationIdField]: clearOrganisationId,
          color: rawData.color.slice(1),
          archived: !rawData.is_sub_menu,
          // is_sub_menu: rawData.is_sub_menu ? rawData.is_sub_menu : false,
        };

        const response = await axios.post(`${API_URL}${REST.AdminCategory.CREATE}`, {
          ...sendData,
        });

        const newCategory = get(response, categoryDataPath);
        return {
          data: { ...newCategory, id: setPrefix(categoryPrefix, newCategory[categoryIdField]) },
        };
      }
      case UPDATE: {
        const rawData = params.data;
        const { color: rawDataColor, is_sub_menu: isSubMenu, ...rest } = rawData;
        const color = rawDataColor.indexOf('#') !== -1 ? rawDataColor.slice(1) : rawDataColor;

        const response = await axios.post(`${API_URL}${REST.AdminCategory.UPDATE}`, {
          ...rest,
          color,
          is_sub_menu: isSubMenu,
          archived: !isSubMenu,
        });

        const parsedCategory = get(response, categoryDataPath);
        return {
          data: {
            ...parsedCategory,
            id: setPrefix(categoryPrefix, parsedCategory[categoryIdField]),
            [organisationPrefix]: setPrefix(
              organisationPrefix,
              parsedCategory[organisationIdField],
            ),
          },
        };
      }
      case DELETE: {
        try {
          const { id, previousData } = params;
          const clearId = removePrefix(categoryPrefix, id);
          await axios.post(`${API_URL}${REST.AdminCategory.DELETE}`, {
            [categoryIdField]: [clearId],
          });
          return { data: previousData };
        } catch (error) {
          throw new Error(`${error.response.data.error.message}`);
        }
      }
      case DELETE_MANY: {
        try {
          const { ids } = params;
          const clearIds = removePrefixInArray(ids, categoryPrefix);
          await axios.post(`${API_URL}${REST.AdminCategory.DELETE}`, {
            [categoryIdField]: clearIds,
          });
          return { data: ids };
        } catch (error) {
          throw new Error(`${error.response.data.error.message}`);
        }
      }
      default: {
        throw new Error(`Неизвестный метод получения ${categoryPrefix}: ${type}`);
      }
    }
  } catch (error) {
    switch (error.message) {
      case 'object_category_in_use':
        throw new Error('Удаляемая категория используется на платформе');
      default:
        throw new Error(`Ошибка метода ${categoryPrefix}-${type}`);
    }
  }
};
