import axios from 'axios';
import { GET_LIST, GET_MANY } from 'react-admin';
import { IRADataProviderParams, IRAGetManyParams } from 'Interfaces';
import {
  API_URL,
  REST,
  referenceResourceFactMethodPath,
  referenceResourceFactMethodPrefix,
} from 'Constants';
import { get } from 'lodash';
import { mapArrayToResource, removePrefixInArray } from '../utils';

export const referenceResourceFactMethodProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.ResourceFactMethod.LIST}`,
          {},
        );
        const data = get(response, referenceResourceFactMethodPath);
        const parsedData = mapArrayToResource(data, null, 'ResourceFactMethodPath');
        return { data: parsedData, total: parsedData.length };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const clearIds = removePrefixInArray(ids, referenceResourceFactMethodPrefix);
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.ResourceFactMethod.LIST}`,
          {},
        );
        const data = get(response, referenceResourceFactMethodPath);
        const filteredData = data.filter(el => clearIds.includes(el));
        const parsedData = mapArrayToResource(filteredData, null, 'ResourceFactMethodPath');
        return { data: parsedData };
      }
      default:
        throw new Error(
          `Неизвестный метод получения ${referenceResourceFactMethodPrefix}: ${type}`,
        );
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${referenceResourceFactMethodPrefix}-${type}`);
  }
};
