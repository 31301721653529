import React, { FC } from 'react';
import { List, Datagrid, TextField } from 'react-admin';

export const JobUnitList: FC = props => (
  <List {...props} exporter={false} title="Справочник работ" bulkActionButtons={false}>
    <Datagrid>
      <TextField source="code" label="Код" />
      <TextField source="longName" label="Название" />
      <TextField source="shortName" label="Сокращенное название" />
    </Datagrid>
  </List>
);
