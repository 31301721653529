import React, { FC } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';
import { Card, CardContent, withStyles } from '@material-ui/core';
import {
  fileTemplateIdField,
  fileTemplatePrefix,
  folderParentIdField,
  folderPrefix,
  folderTemplatesIdField,
  folderTemplatesPrefix,
  Resources,
} from 'Constants';
import { getMultiLanguageName } from 'Components/utils';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = {
  card: {
    width: '100%',
    marginBottom: '1.5rem',
  },
  cardContent: {
    position: 'relative',
  },
  list: {
    width: '100%',
    display: 'none',
  },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 1000,
  },
};

const FolderCreateComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => {
  const innerProps = { ...props };
  const folderTemplateString: string = innerProps.history.location.search;
  const folderTemplate = folderTemplateString
    ? folderTemplateString.replace(`?${folderTemplatesPrefix}=`, '')
    : false;
  const redirect = folderTemplate
    ? `/${Resources.FOLDER_TEMPLATES}/${folderTemplate}/1`
    : `/${Resources.FOLDER_TEMPLATES}`;
  return (
    <Create title="Новая папка" {...props}>
      <SimpleForm redirect={redirect}>
        <>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <ReferenceInput
                source={folderTemplatesPrefix}
                reference={Resources.FOLDER_TEMPLATES}
                label="Шаблон"
                fullWidth
                required
                disabled
              >
                <SelectInput optionText={record => record.name} />
              </ReferenceInput>
              <TextInput fullWidth required source="name" label="Название" />
              <ReferenceInput
                source={folderParentIdField}
                resource={Resources.FOLDER_TEMPLATES_FOLDER}
                reference={Resources.FOLDER_TEMPLATES_FOLDER}
                label="Родительская папка"
                filter={{ id: folderTemplate }}
                fullWidth
              >
                <SelectInput optionText="name" resettable />
              </ReferenceInput>
              <IconButton onClick={() => propsPush(redirect)} className={classes.closeBtn}>
                <CloseIcon />
              </IconButton>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <ReferenceArrayInput
                source={fileTemplatePrefix}
                reference={Resources.FILE_TEMPLATE}
                resource={Resources.FILE_TEMPLATE}
                label="Шаблоны файлов"
                fullWidth
                allowEmpty
              >
                <SelectArrayInput optionText="name" resettable />
              </ReferenceArrayInput>
            </CardContent>
          </Card>
        </>
      </SimpleForm>
    </Create>
  );
};

export const connectedFolderCreate = connect(null, { push })(FolderCreateComponent);
export const FolderCreate = withStyles(styles as any)(connectedFolderCreate);
