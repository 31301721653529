import React, { FC } from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  LongTextInput,
  DisabledInput,
  ReferenceManyField,
  ReferenceField,
  Datagrid,
  TextField,
  DeleteButton,
  CreateButton,
  EditButton,
} from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Button, Card, CardContent } from '@material-ui/core';
import {
  folderTemplatesIdField,
  Resources,
  folderPrefix,
  folderIdField,
  folderTemplatesPrefix,
  folderParentIdField,
} from 'Constants';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import { Add } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = {
  card: {
    width: '100%',
    marginBottom: '1.5rem',
    marginTop: '1.5rem',
  },
  cardContent: {
    position: 'relative',
  },
  list: {
    width: '100%',
    display: 'none',
  },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 1000,
  },
};

const RenderTitle = ({ record }: any) => <span>{record.name}</span>;

const AddFolderButton = ({ record }: any) => {
  const AddFolderLink = (props: any) => (
    <Link
      to={`/${Resources.FOLDER_TEMPLATES_FOLDER}/create?${folderTemplatesPrefix}=${record.id}`}
      {...props}
    />
  );

  return (
    <Button component={AddFolderLink} variant="text" color="primary">
      <Add />
      &nbsp;New folder
    </Button>
  );
};

const CustomEditFolderButton = ({ record }: any) => {
  const CustomEditFolderLink = (props: any) => (
    <Link
      to={`/${Resources.FOLDER_TEMPLATES_FOLDER}/${record.id}?${folderTemplatesPrefix}=${folderTemplatesPrefix}-${record[folderTemplatesIdField]}`}
      {...props}
    />
  );

  return (
    <Button component={CustomEditFolderLink} variant="text" color="primary">
      Edit
    </Button>
  );
};

const FolderTemplatesEditComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => {
  const { id } = props;
  return (
    <Edit title={<RenderTitle />} {...props}>
      <TabbedForm redirect="list">
        <FormTab label="Общая информация">
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <DisabledInput fullWidth required source={folderTemplatesIdField} label="Id" />
              <IconButton
                onClick={() => propsPush(`/${Resources.FOLDER_TEMPLATES}`)}
                className={classes.closeBtn}
              >
                <CloseIcon />
              </IconButton>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <TextInput fullWidth required source="code" label="Код" />
              <TextInput fullWidth required source="name" label="Название" />
              <LongTextInput fullWidth source="description" label="Описание" defaultValue="" />
            </CardContent>
          </Card>
        </FormTab>
        <FormTab label="Папки">
          <ReferenceManyField
            resource={Resources.FOLDER_TEMPLATES_FOLDER}
            reference={Resources.FOLDER_TEMPLATES_FOLDER}
            target={folderPrefix}
            label=""
            fullWidth
          >
            <Datagrid>
              <TextField source={folderIdField} label="Id" />
              <TextField source="name" label="Название" />
              {/* <TextField source={folderParentIdField} label="Родительская папка" /> */}
              <ReferenceField
                source={folderParentIdField}
                reference={Resources.FOLDER_TEMPLATES_FOLDER}
                label="Родительская папка"
                fullWidth
                allowEmpty
                linkType={false}
              >
                <TextField source="name" />
              </ReferenceField>
              <CustomEditFolderButton />
              <DeleteButton redirect={`/${Resources.FOLDER_TEMPLATES}/${id}/1`} />
            </Datagrid>
          </ReferenceManyField>
          <AddFolderButton />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const connectedFolderTemplatesEdit = connect(null, { push })(FolderTemplatesEditComponent);
export const FolderTemplatesEdit = withStyles(styles as any)(connectedFolderTemplatesEdit);
