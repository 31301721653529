import axios from 'axios';
import { API_URL } from 'Constants';

export const axiosInitialization = (token: string): void => {
  axios.defaults.baseURL = API_URL;
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const axiosResetDefaults = (): void => {
  axios.defaults.baseURL = '';
  axios.defaults.headers.common.Authorization = '';
};
