import base64js from 'base64-js';
import { regex } from 'react-admin';
import { countBy } from 'lodash';
import { APP_AUTH_PASSWORD_PATTERN, VALIDATE_ERRORS } from 'Constants';

export function Base64Encode(str) {
  const bytes = new TextEncoder().encode(str);
  return base64js.fromByteArray(bytes);
}

export function Base64Decode(str, encoding = 'utf-8') {
  const bytes = base64js.toByteArray(str);
  return new TextDecoder(encoding).decode(bytes);
}

export function aliasRegex() {
  return regex(/^[a-z0-9][a-z_0-9.]+[a-z0-9]$/, VALIDATE_ERRORS.ALIAS_REGEX);
}

export function uniqueValues(fieldName, fieldSource = 'fieldKey') {
  return (value, allValues) => {
    const keys = allValues[fieldName].map(el => el[fieldSource]);
    if (countBy(keys, item => item)[value] > 1) {
      return VALIDATE_ERRORS.UNIQUE;
    }
    return undefined;
  };
}

export function passwordRegex() {
  return regex(
    new RegExp(
      APP_AUTH_PASSWORD_PATTERN ||
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\w\d\s:])([^\sА-Яа-яЁё]){8,}$/,
    ),
    VALIDATE_ERRORS.PASSWORD_REGEX,
  );
}

export function keyRegex() {
  return regex(/^(?=[a-z_0-9]{2,40}$)[a-z0-9](?!\w*__)(?:\w*[^\W_])?$/, VALIDATE_ERRORS.KEY_REGEX);
}

export function noSpaceRegex() {
  return regex(/^[\S]*$/, VALIDATE_ERRORS.NO_SPACE_REGEX);
}

export function emailRegex() {
  return regex(new RegExp(/[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+/), VALIDATE_ERRORS.EMAIL_REGEX);
}
