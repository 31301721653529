import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { push } from 'react-router-redux';
import {
  Edit,
  DisabledInput,
  TextInput,
  ReferenceInput,
  DateInput,
  SelectInput,
  TabbedForm,
  FormTab,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  withDataProvider,
  GET_ONE,
  showNotification,
  BooleanInput,
  FormDataConsumer,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Resources,
  userIdField,
  organisationPrefix,
  userPrefix,
  roleIdField,
  RolesEntities,
} from 'Constants';
import { PasswordInput, CustomToolbar } from 'Components/Common';
import { getFullUserName, getMultiLanguageName } from 'Components/utils/getFullName';
import { removePrefix } from 'Providers/utils';
import { get } from 'lodash';
import { CommonRolesContainer } from './Roles/Containers';
import { FullName } from './FullName';

const styles = {
  card: { width: '100%', marginBottom: '10px' },
  сardContent: { position: 'relative' },
  сardItemUp: { width: '30%', margin: '10px', maxWidth: '320px' },
  сardItem: { width: '40%', margin: '20px', maxWidth: '450px' },
  btn: { position: 'absolute', right: '0', top: '0' },
  content: { '& > div': { overflow: 'visible' } },
};

const RenderTitle = ({ record }: any) => (
  <span>{`${getFullUserName(record, 'ru')}${getFullUserName(record, 'en', ' (', ')')}`}</span>
);

const UserEditComponent: FC = ({
  push: propsPush,
  pathName,
  classes = {},
  roleValues,
  ...props
}: any) => {
  const { id, dispatch, location, dataProvider } = props;
  const userId = removePrefix(userPrefix, id);
  const currentTab = +location.pathname?.split(id)[1].replace('/', '');

  const inputValueMatcher = (_, suggestion) =>
    suggestion &&
    suggestion.alias.startsWith(RolesEntities.user) &&
    !roleValues?.includes(suggestion.id)
      ? suggestion.id
      : undefined;

  const handleClick = () => {
    dataProvider(GET_ONE, Resources.USERS, { id }).catch(e => {
      dispatch(showNotification('Возникла непредвиденная ошибка', 'warning'));
    });
  };

  return (
    <Edit title={<RenderTitle />} className={classes.content} {...props}>
      <TabbedForm redirect="list" submitOnEnter={false} toolbar={<CustomToolbar />}>
        <FormTab label="Свойства пользователя" onClick={handleClick}>
          {currentTab === 0 && (
            <>
              <Card className={classes.card}>
                <CardContent className={classes.сardContent}>
                  <DisabledInput source={userIdField} label="Id" fullWidth />
                  <IconButton
                    onClick={() => dispatch(push(`/${Resources.USERS}`))}
                    className={classes.btn}
                  >
                    <CloseIcon />
                  </IconButton>
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardContent className={classes.сardContent}>
                  <FullName locale="ru" />
                  <FullName locale="en" />
                  <DateInput source="birthdate" label="Дата изменения учетной записи" />
                  <TextInput source="email" label="E-mail" className={classes.сardItem} />
                  <TextInput source="phone" label="Телефон" className={classes.сardItem} />
                  <TextInput source="position" label="Должность" fullWidth />
                  <ReferenceInput
                    source={organisationPrefix}
                    reference={Resources.ORGS}
                    label="Организация"
                    fullWidth
                    required
                  >
                    <SelectInput optionText={record => getMultiLanguageName(record)} />
                  </ReferenceInput>
                  <PasswordInput label="Новый пароль" source="password" />
                </CardContent>
              </Card>

              <Card className={classes.card}>
                <CardContent className={classes.сardContent}>
                  <ReferenceArrayInput
                    reference={Resources.ROLE}
                    source={roleIdField}
                    label="Глобальные роли"
                  >
                    <AutocompleteArrayInput
                      optionText="name"
                      inputValueMatcher={inputValueMatcher}
                      fullWidth
                    />
                  </ReferenceArrayInput>
                </CardContent>
              </Card>

              <Card className={classes.card}>
                <CardContent className={classes.сardContent}>
                  <Typography variant="caption">Настройки пользователя</Typography>
                  <BooleanInput
                    source="blocked"
                    label="Пользователь заблокирован"
                    defaultValue={false}
                    fullWidth
                  />
                  <FormDataConsumer>
                    {({ formData }) =>
                      typeof formData.blocked === 'number' && (
                        <code style={{ width: '100%' }}>
                          {`Дата блокировки: ${dayjs
                            .unix(formData.blocked)
                            .format('DD.MM.YYYY HH:MM:ss')}`}
                        </code>
                      )
                    }
                  </FormDataConsumer>
                </CardContent>
              </Card>
            </>
          )}
        </FormTab>
        <FormTab label="Роли">
          {currentTab === 1 && <CommonRolesContainer userId={userId} />}
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const mapStateToProps = state => ({
  roleValues: get(state, 'form.record-form.values.role_id'),
  pathName: state.router.location.pathname,
});

const connectedUserEdit = withDataProvider(UserEditComponent);
// eslint-disable-next-line no-lone-blocks
{
  /* const connectedUserEdit = connect(mapStateToProps, { push })(UserEditComponent); */
}
export const UserEdit = withStyles(styles as any)(connectedUserEdit);
