import React, { FC } from 'react';
import { List, Datagrid, TextField } from 'react-admin';

const MyDatagridHack = props => {
  const newIds = props?.ids.filter(id => typeof id !== 'string');
  const newProps = { ...props, ids: newIds };
  return (
    <Datagrid {...newProps} rowClick="edit">
      <TextField source="analytics_template_id" label="Id" />
      <TextField source="name" label="Название" />
      <TextField source="template_type" label="Тип" />
      <TextField source="alias" label="Псевдоним" />
    </Datagrid>
  );
};

const AnalyticalReportListComponent: FC = ({
  classes = {},
  setAnalyticalReportTemplate,
  ...props
}: any) => (
  <List {...props} exporter={false} title="Список шаблонов аналитическиx отчетов">
    <MyDatagridHack />
  </List>
);

export const AnalyticalReportList = AnalyticalReportListComponent;
