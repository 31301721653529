import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { registerField, blur } from 'redux-form';
import {
  Edit,
  SimpleForm,
  TextInput,
  FileInput,
  FileField,
  SelectInput,
  LongTextInput,
  ReferenceInput,
  NumberInput,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { setAnalyticalReportTemplateAction } from 'Providers/utils/customActions';
import { analyticalReportTemplateTypes, Resources } from 'Constants';
import { aliasRegex } from '../../../Utils/stringMethods';

const styles = {
  card: { width: '100%', marginBottom: '30px', overflow: 'visible' },
  сardContent: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    justifyContent: 'space-between',
  },
  btn: { width: '100%' },
  innerDataGrid: { width: '100%' },
  formTab: { marginBottom: '20px' },
  hidden: { display: 'none' },
};

const RenderTitle = ({ record }: any) => (
  <span>{`Редактирование шаблона отчета: ${record.name}`}</span>
);

const AnalyticalReportEditComponent: FC = ({
  push: propsPush,
  classes = {},
  registerField: reduxFormRegisterField,
  blur: reduxFormBlur,
  analyticalReportTemplate,
  setAnalyticalReportTemplate,
  ...props
}: any) => {
  useEffect(() => {
    if (analyticalReportTemplate) {
      reduxFormBlur('record-form', 'template', analyticalReportTemplate);
      setAnalyticalReportTemplate(null);
    }
  }, []);

  return (
    <Edit title={<RenderTitle />} {...props}>
      <SimpleForm redirect="list">
        <Button
          variant="outlined"
          color="primary"
          onClick={(): void => {
            setAnalyticalReportTemplate(props.template);
            propsPush('/');
          }}
          className={classes.btn}
        >
          {'Перейти к редактированию шаблона'}
        </Button>
        <TextInput fullWidth required source="name" label="Наименование" />
        <TextInput fullWidth required source="alias" label="Код" validate={aliasRegex()} />
        <SelectInput
          fullWidth
          required
          source="template_type"
          label="Тип аналитического отчёта"
          choices={analyticalReportTemplateTypes}
          optionText="name"
          optionValue="value"
        />
        <LongTextInput fullWidth source="comment" label="Описание" />
        <ReferenceInput
          source="permission"
          reference={Resources.PERMISSION}
          resource={Resources.PERMISSION}
          label="Право доступа"
          fullWidth
          required
          translateChoice={false}
          helperText={null}
        >
          <SelectInput optionText="name" optionValue="alias" />
        </ReferenceInput>
        <NumberInput fullWidth source="sort_order" label="Порядок сортировки" defaultValue="10" />
        <LongTextInput
          fullWidth
          source="settings"
          format={rec => {
            if (typeof rec === 'object') return JSON.stringify(rec);
            return rec;
          }}
          label="Установочные данные (JSON)"
        />
        <FileInput source="files" label="Шаблон отчёта" accept=".mrt">
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};
// eslint-disable-next-line no-mixed-operators
const mapStateToProps = state => ({
  ...state.form?.['record-form']?.values,
  analyticalReportTemplate: state.analyticalReportTemplate,
});

const connectedAnalyticalReportEdit = connect(mapStateToProps, {
  push,
  registerField,
  blur,
  setAnalyticalReportTemplate: setAnalyticalReportTemplateAction,
})(AnalyticalReportEditComponent);
export const AnalyticalReportEdit = withStyles(styles as any)(connectedAnalyticalReportEdit);
