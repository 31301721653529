import axios from 'axios';
import { GET_LIST, GET_ONE, UPDATE, CREATE, DELETE, DELETE_MANY, GET_MANY } from 'react-admin';
import {
  IRADataProviderParams,
  IRAGetOneParams,
  IRACreateParams,
  IRAUpdateParams,
  IRADeleteParams,
  IReferenceJobCreateProvider,
  IRADeleteManyParams,
  IRAGetManyParams,
} from 'Interfaces';
import {
  API_URL,
  REST,
  referenceJobReferenceIdField,
  referenceJobReferenceDataPath,
  referenceJobReferencePrefix,
} from 'Constants';
import { get } from 'lodash';
import { setPrefix, removePrefix, PgUnAssoc, removePrefixInArray } from '../utils';

export const referenceJobReferenceProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(`${API_URL}${REST.AdminReference.JobReference.LIST}`, {});
        const parsedJob = PgUnAssoc(get(response, referenceJobReferenceDataPath));
        const parsedJobWithId = parsedJob.map(el => ({
          ...el,
          id: setPrefix(referenceJobReferencePrefix, el[referenceJobReferenceIdField]),
        }));
        return { data: parsedJobWithId, total: parsedJobWithId.length };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(referenceJobReferencePrefix, id);
        const response = await axios.post(`${API_URL}${REST.AdminReference.JobReference.LIST}`, {
          [referenceJobReferenceIdField]: [clearId],
        });
        const parsedJob = PgUnAssoc(get(response, referenceJobReferenceDataPath))[0];
        return {
          data: {
            ...parsedJob,
            id: setPrefix(referenceJobReferencePrefix, parsedJob[referenceJobReferenceIdField]),
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const clearIds = removePrefixInArray(ids, referenceJobReferencePrefix);
        const response = await axios.post(`${API_URL}${REST.AdminReference.JobReference.LIST}`, {
          [referenceJobReferenceIdField]: clearIds,
        });
        const parsedData = PgUnAssoc(get(response, referenceJobReferenceDataPath));
        const parsedDataWithId = parsedData.map(el => ({
          ...el,
          id: setPrefix(referenceJobReferencePrefix, el[referenceJobReferenceIdField]),
        }));
        return { data: parsedDataWithId };
      }
      case UPDATE: {
        const rawData = (params as IRAUpdateParams).data;
        const { id, ...others } = rawData;
        const sendData = { ...others };
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.JobReference.UPDATE}`,
          sendData,
        );
        const parsedJob = get(response, referenceJobReferenceDataPath);
        return {
          data: {
            ...parsedJob,
            id: setPrefix(referenceJobReferencePrefix, parsedJob[referenceJobReferenceIdField]),
          },
        };
      }
      case CREATE: {
        const rawData = (params as IRACreateParams).data as IReferenceJobCreateProvider;
        const sendData = { ...rawData };
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.JobReference.CREATE}`,
          sendData,
        );
        const parsedJob = get(response, referenceJobReferenceDataPath);
        return {
          data: {
            ...parsedJob,
            id: setPrefix(referenceJobReferencePrefix, parsedJob[referenceJobReferenceIdField]),
          },
        };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(referenceJobReferencePrefix, id);
        await axios.post(`${API_URL}${REST.AdminReference.JobReference.DELETE}`, {
          [referenceJobReferenceIdField]: [clearId],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, referenceJobReferencePrefix);
        await axios.post(`${API_URL}${REST.AdminReference.JobReference.DELETE}`, {
          [referenceJobReferenceIdField]: clearIds,
        });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${referenceJobReferencePrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${referenceJobReferencePrefix}-${type}`);
  }
};
