import axios from 'axios';
import { GET_LIST, GET_MANY } from 'react-admin';
import { IRADataProviderParams, IRAGetManyParams } from 'Interfaces';
import { API_URL, REST, referenceJobUnitDataPath, referenceJobUnitPrefix } from 'Constants';
import { get } from 'lodash';
import { mapArrayToResource } from '../utils';

export const referenceJobUnitProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(`${API_URL}${REST.AdminReference.JobUnit.LIST}`, {});
        const jobUnit = get(response, referenceJobUnitDataPath);
        const parsedJobUnit = mapArrayToResource(jobUnit, null, 'JobUnit');
        return { data: parsedJobUnit, total: parsedJobUnit.length };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const response = await axios.post(`${API_URL}${REST.AdminReference.JobUnit.LIST}`, {});
        const jobUnit = get(response, referenceJobUnitDataPath);
        const filteredJobUnit = jobUnit.filter(el => ids.includes(el));
        const parsedJobUnit = mapArrayToResource(filteredJobUnit, null, 'JobUnit');
        return { data: parsedJobUnit };
      }
      default:
        throw new Error(`Неизвестный метод получения ${referenceJobUnitPrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${referenceJobUnitPrefix}-${type}`);
  }
};
