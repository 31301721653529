import React, { FC } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
  Edit,
  DisabledInput,
  TextInput,
  ReferenceInput,
  LongTextInput,
  SelectInput,
  TabbedForm,
  FormTab,
  FormDataConsumer,
  GET_ONE,
  withDataProvider,
  ReferenceManyField,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  BooleanInput,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  projectIdField,
  organisationPrefix,
  referenceProjectTypePrefix,
  Resources,
  projectMeta,
  imagePrefix,
  objectPrefix,
  objectIdField,
  referenceObjectTypePrefix,
  projectLabel,
  commonLabel,
  availableLanguages,
} from 'Constants';
import { Gallery, Passport } from 'Components/Common';
import { deleteOneImage } from 'Providers/DataProviders/imageProvider';
import { getMultiLanguageName } from 'Components/utils/getFullName';

const styles = {
  card: { width: '100%', marginBottom: '10px' },
  сardContent: { display: 'flex', flexDirection: 'column', position: 'relative' },
  groupWrapper: { display: 'flex', justifyContent: 'space-between' },
  groupItem: { marginRight: '20px' },
  btn: { position: 'absolute', right: '0', top: '0' },
  innerDataGrid: { width: '100%' },
};

const RenderTitle = ({ record }: any) => <span>{getMultiLanguageName(record)}</span>;

const ProjectEditComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => {
  const { dataProvider } = props;

  const handleDeleteItem = async (_, imageId: number, formData, raDataProvider) => {
    const { id } = formData;
    await deleteOneImage(imageId);
    raDataProvider(GET_ONE, Resources.PROJECTS, { id }, { refresh: true });
  };

  const FullNameField = ({ record = {} }) => <span>{getMultiLanguageName(record)}</span>;

  return (
    <Edit title={<RenderTitle />} {...props}>
      <TabbedForm redirect="list">
        <FormTab label="Общая информация">
          <Card className={classes.card}>
            <CardContent className={classes.сardContent}>
              <DisabledInput source={projectIdField} label="Id" />
              <IconButton
                onClick={() => propsPush(`/${Resources.PROJECTS}`)}
                className={classes.btn}
              >
                <CloseIcon />
              </IconButton>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent className={classes.сardContent}>
              <div className={classes.groupWrapper}>
                <TextInput
                  fullWidth
                  source="name_ru"
                  label={projectLabel.name_ru}
                  className={classes.groupItem}
                  required
                />
                <TextInput fullWidth source="name_en" label={projectLabel.name_en} />
              </div>
              <ReferenceInput
                source={organisationPrefix}
                resource={Resources.ORGS}
                reference={Resources.ORGS}
                label="Организация"
                fullWidth
                required
              >
                <SelectInput optionText={record => getMultiLanguageName(record)} disabled />
              </ReferenceInput>
              <ReferenceInput
                source={referenceProjectTypePrefix}
                resource={Resources.REFERENCE_PROJECT_TYPE}
                reference={Resources.REFERENCE_PROJECT_TYPE}
                label="Тип проекта"
                fullWidth
                required
              >
                <SelectInput optionText={record => getMultiLanguageName(record)} />
              </ReferenceInput>
              <LongTextInput
                source="description_ru"
                label={projectLabel.description_ru}
                fullWidth
              />
              <LongTextInput
                source="description_en"
                label={projectLabel.description_en}
                fullWidth
              />
              <LongTextInput source={projectMeta} label="Мета-информация" fullWidth />
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent className={classes.сardContent}>
              <Typography variant="caption">Настройки округления</Typography>
              <div className={classes.groupWrapper}>
                <BooleanInput source="settings.round_value" label="Показатели физ. объемов" />
                <BooleanInput source="settings.round_percent" label="Проценты" />
                <BooleanInput source="settings.round_expenditure" label="Финансовые показатели" />
                <BooleanInput source="settings.round_day" label="Дни" />
              </div>
              <Typography variant="caption">История изменений модели объекта</Typography>
              <div className={classes.groupWrapper}>
                <BooleanInput
                  source="settings.schedule_update_requires_comment"
                  label="Указывать примечание"
                />
              </div>
              <Typography variant="caption">Настройки индикаторов проекта</Typography>
              <div>
                <BooleanInput
                  source="settings.status_indicator_foreman_visible"
                  label="Сценарий Прораб"
                />
                <BooleanInput
                  source="settings.status_indicator_revisor_visible"
                  label="Сценарий Контролер"
                />
              </div>
              <Typography variant="caption">Публичная фотогалерея</Typography>
              <div className={classes.groupWrapper}>
                <BooleanInput
                  source="settings.image_gallery_is_public"
                  label="Использовать публичную фотогалерею"
                  defaultValue={false}
                />
              </div>
            </CardContent>
          </Card>
        </FormTab>
        <FormTab label="Данные паспорта">
          {availableLanguages.map(language => (
            <Passport
              key={`passport_data_${language}`}
              source={`passport_data_${language}`}
              locale={language}
              idPrefix={referenceProjectTypePrefix}
              type={Resources.PROJECTS}
            />
          ))}
        </FormTab>
        <FormTab label="Объекты">
          <ReferenceManyField
            resource={Resources.OBJECT}
            reference={Resources.OBJECT}
            target={objectPrefix}
            label=""
            className={classes.innerDataGrid}
          >
            <Datagrid>
              <TextField source={objectIdField} label="Id" />
              <TextField source="name_ru" label={commonLabel.name_ru} fullWidth />
              <TextField source="name_en" label={commonLabel.name_en} fullWidth />
              <ReferenceField
                source={referenceObjectTypePrefix}
                resource={Resources.REFERENCE_OBJECT_TYPE}
                reference={Resources.REFERENCE_OBJECT_TYPE}
                label="Тип объекта"
              >
                <FullNameField />
              </ReferenceField>
              <TextField source="description_ru" label={commonLabel.description_ru} />
              <TextField source="description_en" label={commonLabel.description_en} />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Галерея">
          <FormDataConsumer>
            {({ formData, ...rest }): JSX.Element => (
              <Gallery
                {...rest}
                data={formData[imagePrefix]}
                onDeleteItem={(e, id) => handleDeleteItem(e, id, formData, dataProvider)}
              />
            )}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const connectedProjectEdit = connect(null, { push })(withDataProvider(ProjectEditComponent));
export const ProjectEdit = withStyles(styles as any)(connectedProjectEdit);
