import React from 'react';
import { SubmitCancelModal, RadioGroupModal } from 'Components/Common';
import { RolesTable } from './RolesTable';
import { SearchPanel } from './SearchPanel';

export const CommonRolesComponent = ({
  reference,
  source,
  label,
  idField,
  allRoles,
  userId,
  searchFilter,
  searchFilterChangeHandler,
  selectedItems,
  allSystemRoles,
  itemAsyncSearchHandler,
  itemAsyncSearchOnChangeHandler,
  itemAsyncSearchDefaultOptions,
  itemAsyncSearchValue,
  addButtonHandler,
  bulkAction,
  changeItemRole,
  modalProps,
}) => (
  <>
    <SearchPanel
      {...{
        reference,
        source,
        label,
        userId,
        idField,
      }}
      searchFilter={searchFilter}
      searchFilterChangeHandler={searchFilterChangeHandler}
      itemAsyncSearchHandler={itemAsyncSearchHandler}
      itemAsyncSearchOnChangeHandler={itemAsyncSearchOnChangeHandler}
      itemAsyncSearchDefaultOptions={itemAsyncSearchDefaultOptions}
      itemAsyncSearchValue={itemAsyncSearchValue}
      addButtonHandler={addButtonHandler}
    />
    <RolesTable
      {...{
        idField,
        allRoles,
        userId,
        reference,
        label,
      }}
      searchFilter={searchFilter}
      selectedItems={selectedItems}
      allSystemRoles={allSystemRoles}
      bulkAction={bulkAction}
      changeItemRole={changeItemRole}
    />
    {modalProps.options ? (
      <RadioGroupModal {...modalProps} />
    ) : (
      <SubmitCancelModal {...modalProps} />
    )}
  </>
);
