import axios from 'axios';
import { GET_LIST } from 'react-admin';
import {
  API_URL,
  REST,
  databaseMigrationPrefix,
  databaseMigrationPath,
  databaseMigrationIdField,
} from 'Constants';
import { get } from 'lodash';
import { setPrefixInArray } from '../utils';

export const databaseMigrationProvider = async (type: string, params: any): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(
          `${API_URL}${REST.AdminHistory.DATABASE.MIGRATION.LIST}`,
          {},
        );
        const data = get(response, databaseMigrationPath);
        const dataWithId = setPrefixInArray(
          data,
          databaseMigrationPrefix,
          databaseMigrationIdField,
        );
        return { data: dataWithId, total: dataWithId.length };
      }
      default: {
        throw new Error(`Неизвестный метод получения ${databaseMigrationPrefix}: ${type}`);
      }
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${databaseMigrationPrefix}-${type}`);
  }
};
