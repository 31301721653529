import React, { FC } from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { referenceCurrencyIdField } from '../../../Constants';

export const CurrencyList: FC = props => (
  <List {...props} exporter={false} title="Справочник валют">
    <Datagrid rowClick="edit">
      <TextField source={referenceCurrencyIdField} label="Id" />
      <TextField source="alias" label="Сокращенное название" />
      <TextField source="name" label="Название" />
    </Datagrid>
  </List>
);
