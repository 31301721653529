import React, { FC } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  DisabledInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Card, CardContent, Typography } from '@material-ui/core';
import {
  referenceObjectLifeCycleStagePrefix,
  Resources,
  scheduleAfterIdField,
  scheduleIdField,
  scheduleParentIdField,
  scheduleTemplateIdField,
  scheduleTemplatePrefix,
} from 'Constants';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = {
  card: { width: '100%', marginBottom: '1.5rem' },
  cardContent: { position: 'relative' },
  list: { width: '100%', display: 'none' },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 1000,
  },
  grid: { display: 'grid', gap: '2rem', gridTemplateColumns: '1fr 1fr' },
};

const RenderTitle = ({ record }: any) => (
  <span>
    {record.name}
    {record.child_count > 0 && <span>{` (${record.child_count})`}</span>}
  </span>
);

const ScheduleEditComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => {
  const redirect = (basePath, id, data) =>
    `/${Resources.SCHEDULE_TEMPLATE}/${data[scheduleTemplatePrefix]}/1`;

  return (
    <Edit title={<RenderTitle />} {...props}>
      <SimpleForm
        redirect={redirect}
        defaultValue={{
          [scheduleParentIdField]: null,
          [scheduleAfterIdField]: null,
        }}
      >
        <FormDataConsumer>
          {({ formData }): JSX.Element => (
            <>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <IconButton
                    onClick={() =>
                      propsPush(
                        `/${Resources.SCHEDULE_TEMPLATE}/${formData[scheduleTemplatePrefix]}/1`,
                      )
                    }
                    className={classes.closeBtn}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DisabledInput fullWidth required source={scheduleIdField} label="Id" />
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <ReferenceInput
                    source={scheduleTemplatePrefix}
                    reference={Resources.SCHEDULE_TEMPLATE}
                    resource={Resources.SCHEDULE_TEMPLATE}
                    label="Шаблон модели"
                    fullWidth
                    required
                    disabled
                  >
                    <SelectInput optionText={record => record.name} />
                  </ReferenceInput>
                  <TextInput fullWidth required source="name" label="Название" />
                  <ReferenceInput
                    source={referenceObjectLifeCycleStagePrefix}
                    reference={Resources.REFERENCE_OBJECT_LIFE_CYCLE_STAGE}
                    resource={Resources.REFERENCE_OBJECT_LIFE_CYCLE_STAGE}
                    label="Этап жизненного цикла объекта"
                    fullWidth
                    required
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Typography variant="caption">Позиция элемента в шаблоне</Typography>
                  <ReferenceInput
                    source={scheduleParentIdField}
                    reference={Resources.SCHEDULE}
                    resource={Resources.SCHEDULE}
                    label="Родительский элемент"
                    fullWidth
                    allowEmpty
                    emptyValue={null}
                    defaultValue={null}
                    filter={{ [scheduleTemplatePrefix]: formData?.[scheduleTemplatePrefix] }}
                  >
                    <SelectInput optionText={({ name, level }) => `${'–'.repeat(level)} ${name}`} />
                  </ReferenceInput>
                  <ReferenceInput
                    source={scheduleAfterIdField}
                    reference={Resources.SCHEDULE}
                    resource={Resources.SCHEDULE}
                    label="Предыдущий элемент шаблона модели объекта"
                    fullWidth
                    allowEmpty
                    emptyValue={null}
                    defaultValue={null}
                    filter={{
                      [scheduleTemplatePrefix]: formData?.[scheduleTemplatePrefix],
                      [scheduleParentIdField]: formData?.[scheduleParentIdField],
                    }}
                    helperText="Необходимо выбрать элемент шаблона, после которого будет установлен текущий элемент, в зависимости от родительского элемента"
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </CardContent>
              </Card>
            </>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

const connectedScheduleEdit = connect(null, { push })(ScheduleEditComponent);
export const ScheduleEdit = withStyles(styles as any)(connectedScheduleEdit);
