import React, { FC } from 'react';
import { Datagrid, TextField, List } from 'react-admin';
import { folderTemplatesIdField } from 'Constants';

export const FolderTemplatesList: FC = props => (
  <List {...props} title="Шаблоны структуры папок" exporter={false}>
    <Datagrid rowClick="edit">
      <TextField source={folderTemplatesIdField} label="Id" />
      <TextField source="code" label="Код" />
      <TextField source="name" label="Название" />
      <TextField source="description" label="Описание" />
    </Datagrid>
  </List>
);
