import axios from 'axios';
import { GET_LIST, GET_MANY } from 'react-admin';
import { IRADataProviderParams, IRAGetManyParams } from 'Interfaces';
import { API_URL, REST, calculationMethodPrefix, typeOfDirectoryDataPath } from 'Constants';
import { get } from 'lodash';
import { mapArrayToResource } from '../utils';

export const referenceTypeOfDirectoryProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.TypeOfDirectory.LIST}`,
          {},
        );
        const typeOfDirectory = get(response, typeOfDirectoryDataPath);
        const parsedTypeOfDirectory = mapArrayToResource(typeOfDirectory, null, 'TypeOfDirectory');
        return { data: parsedTypeOfDirectory, total: parsedTypeOfDirectory.length };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.TypeOfDirectory.LIST}`,
          {},
        );
        const typeOfDirectory = get(response, typeOfDirectoryDataPath);
        const filteredTypeOfDirectory = typeOfDirectory.filter(el => ids.includes(el));
        const parsedTypeOfDirectory = mapArrayToResource(
          filteredTypeOfDirectory,
          null,
          'TypeOfDirectory',
        );
        return { data: parsedTypeOfDirectory };
      }
      default:
        throw new Error(`Неизвестный метод получения ${calculationMethodPrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода test ${calculationMethodPrefix}-${type}`);
  }
};
