/* eslint-disable */
import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import { get } from 'lodash';
import Button from '@material-ui/core/Button';
import { setAnalyticalReportTemplateAction } from 'Providers/utils/customActions';

declare global {
  interface Window {
    Stimulsoft: any;
  }
}

class StimulSoftComponents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      report: null,
      needClearTemplate: true,
    };
  }

  componentDidMount() {
    let analyticalTemplate: string = get(this.props, `analyticalReportTemplate`, null);
    let analyticalTemplateToJSON: string = analyticalTemplate;

    const options = new window.Stimulsoft.Designer.StiDesignerOptions();
    options.appearance.fullScreenMode = false;
    const designer = new window.Stimulsoft.Designer.StiDesigner(options, 'StiDesigner', false);
    window.Stimulsoft.Base.StiLicense.key =
      '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHmNn7uloRDabwhIL8JziTQsXdMqGimTz67/fQbpp9S7wnblXVEA0n6VxD2W58y1kxzLp/559nfILEjf/ZiBMEgW9U9iAtSXztMMAZsFIoasJ/51i6gWH0ohdPMSV7zn0sRPvi2LhTCvd6/UY06Ed8CM7eBx2Rg5EfjTh38766NcRmIIhH/tS/YIAIdbdKguVZKDDi8y+WYuZQ3J6aDnsI5RVrgdvcLObKtBF/oGq0XFqINN8KgOx4qZMk9yCV/BaUdjUu6eMXkBmoBeyBwdITC2VxiNGfoAJNu0GPWv/KEQYZwLmVzEdg/+WX8VJRtbYOP+r+iM03BcrDJKWt9uanHyjBLxKliymxfH1y3cXY+O2tFZnRagWv7Wob0Ib8Hw5a9GLb/FaK/stqmDxpkw+YsbZ/NcwFTHopieNaDcbEAquvivssn/5PM065KEezkKesP/4uoVJyYO6hKqxdkmgGfWQpcCbeNgS+7vUx+hdSyIbJ7hh60ZIggQHi6/FDYaoxwLPtMd0BWc8DNAbykz1SD6upnYWfvUso79ef0i0Wb5tRrQgMR/W+3jIXdLdWpecWOPI5vPHSeo7saRhuDZcRtYAjtX74nOrmlAhUitwFf8EASO4Sd/bfTHr4j5IxTTj/oqoIPDN558rY1who6DXHieDF1tikOts+5F82IsWjLdqAkkGNNVRW39J3xzjUH8X2D0VKay1zEhjB+xWj4OjRPQN9Az06GzVW4CIgkgeShK3962PUih6b6/Ch/bzj5rUUIOnUYs94hHvUfXHGTYGJ0sZHyJ5tkIQpuiDr0ERYj5Ow==';
    window.Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile('stimulsoft/ru.xml');
    const report = new window.Stimulsoft.Report.StiReport();

    this.setState({
      ...this.state,
      report,
    });

    if (analyticalTemplate) {
      report.load(analyticalTemplateToJSON);
    }

    designer.report = report;
    designer.renderHtml('designer');
  }

  componentWillUnmount() {
    if (get(this.state, `needClearTemplate`)) {
      const setAnalyticalReportTemplate = get(this.props, `setAnalyticalReportTemplate`);
      setAnalyticalReportTemplate(null);
    }
  }

  onBackToEditReport = () => {
    const goBack = get(this.props, `goBack`);
    const report = get(this.state, `report`);
    const jsonString = report.saveToJsonString();
    const setAnalyticalReportTemplate = get(this.props, `setAnalyticalReportTemplate`);

    this.setState({
      ...this.state,
      needClearTemplate: false,
    });

    setAnalyticalReportTemplate(jsonString);
    goBack();
  };

  render() {
    return (
      <Card>
        <Title title="StimulSoft Designer" />
        <CardContent>
          {get(this.props, `analyticalReportTemplate`, null) && (
            <Button variant="outlined" color="primary" onClick={this.onBackToEditReport}>
              {'Вернуться к редактированию'}
            </Button>
          )}
          <div id="designer" />
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    analyticalReportTemplate: state.analyticalReportTemplate,
  };
};

export const StimulSoft = connect(mapStateToProps, {
  goBack,
  setAnalyticalReportTemplate: setAnalyticalReportTemplateAction,
})(StimulSoftComponents);
