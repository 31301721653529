import React, { FC } from 'react';
import { Datagrid, TextField, List, ReferenceField } from 'react-admin';
import {
  approvalIdField,
  referenceBusinessRelationPrefix,
  Resources,
  rolePrefix,
} from '../../../Constants';

export const ApprovalList: FC = props => (
  <List {...props} title="Маршруты согласования" exporter={false}>
    <Datagrid rowClick="edit">
      <TextField source={approvalIdField} label="Id" />
      <TextField source="title" label="Название" />
      <ReferenceField
        source={referenceBusinessRelationPrefix}
        resource={Resources.REFERENCE_BUSINESS_RELATION}
        reference={Resources.REFERENCE_BUSINESS_RELATION}
        label="Документ"
        linkType={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source={rolePrefix}
        resource={Resources.ROLE}
        reference={Resources.ROLE}
        label="Роль"
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);
