import { IImageListResp } from 'Interfaces/Endpoints';
import { imageIdField } from 'Constants';

export interface IParsedImage {
  url: string;
}

export const mapImageToFields = (images: IImageListResp[]): IParsedImage[] =>
  images.map(image => ({
    url: image.converted.small.url,
    [imageIdField]: image[imageIdField],
  }));
