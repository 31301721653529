import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AsyncSelect from 'react-select/async';

const styles = {
  inputsWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginTop: '10px',
  },
  searchInput: { width: '100%' },
  control: { width: '80%', marginRight: '20px' },
};

interface ISearchPanelProps {
  reference: string;
  source: string;
  label: string;
  searchFilter: string;
  idField: string;
  searchFilterChangeHandler: () => void;
  itemAsyncSearchHandler: () => void;
  itemAsyncSearchOnChangeHandler: () => void;
  itemAsyncSearchDefaultOptions: {}[];
  itemAsyncSearchValue: any;
  classes: any;
  userId: number;
  addButtonHandler: (userId: number, itemAsyncSearchValue: any) => void;
}

const SearchPanelComponent: FC<ISearchPanelProps> = ({
  classes = {},
  source,
  label,
  idField,
  userId,
  searchFilter,
  searchFilterChangeHandler,
  itemAsyncSearchHandler,
  itemAsyncSearchOnChangeHandler,
  itemAsyncSearchDefaultOptions,
  itemAsyncSearchValue,
  addButtonHandler,
}) => (
  <>
    <Typography variant="title">{`${label[0].toUpperCase()}${label.slice(1)}ы`}</Typography>
    <div className={classes.inputsWrapper}>
      <AsyncSelect
        loadOptions={itemAsyncSearchHandler}
        className={classes.control}
        label={`Поиск ${label}а для добавления`}
        isClearable
        defaultOptions={itemAsyncSearchDefaultOptions}
        onChange={itemAsyncSearchOnChangeHandler}
        // TODO-08: костыль
        getOptionLabel={option => option.name_ru || option.name}
        getOptionValue={option => option[idField]}
        value={itemAsyncSearchValue}
      />
      <Button
        size="small"
        variant="contained"
        color="primary"
        disabled={!itemAsyncSearchValue}
        onClick={() => addButtonHandler(userId, itemAsyncSearchValue)}
      >
        {`Добавить ${label}`}
      </Button>
    </div>
    <TextField
      id={`SearchFilter-${source}`}
      value={searchFilter}
      onChange={searchFilterChangeHandler}
      label={`Фильтр по добавленным ${label}ам`}
      className={classes.searchInput}
    />
  </>
);

export const SearchPanel = withStyles(styles as any)(SearchPanelComponent);
