import React, { FC } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ChipField,
  required,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import { roleIdField, Resources, rolePermissionIdField } from 'Constants';
import { filterToQuery } from 'Components/utils';
import { aliasRegex } from '../../../Utils/stringMethods';

const styles = {
  card: { width: '100%', marginBottom: '30px' },
  сardContent: { display: 'flex', flexWrap: 'wrap', position: 'relative' },
  сardItemUp: { width: '30%', margin: '10px', maxWidth: '320px' },
  сardItem: { width: '40%', margin: '20px', maxWidth: '450px' },
  сardLongItem: { width: '90%', margin: '20px' },
  btn: { position: 'absolute', right: '0', top: '0' },
  innerDataGrid: { width: '100%' },
  formTab: { marginBottom: '20px' },
  roleSelect: { width: '90%', margin: '20px' },
};

const RenderTitle = ({ record }: any) => <span>{record.name}</span>;

const RoleEditComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => (
  <Edit title={<RenderTitle />} {...props}>
    <SimpleForm redirect="list">
      <Card className={classes.card}>
        <CardContent className={classes.сardContent}>
          <DisabledInput source={roleIdField} label="Id" className={classes.сardItemUp} />
          <IconButton onClick={() => propsPush(`/${Resources.ROLE}`)} className={classes.btn}>
            <CloseIcon />
          </IconButton>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent className={classes.сardContent}>
          <TextInput source="name" label="Название" className={classes.сardItem} />
          <TextInput
            source="alias"
            label="Код"
            className={classes.сardItem}
            validate={aliasRegex()}
          />
          <TextInput source="description" label="Описание" className={classes.сardItem} />
          <ReferenceArrayInput
            className={classes.roleSelect}
            reference="permission"
            source={rolePermissionIdField}
            label="Права"
            filterToQuery={searchText => filterToQuery('name', searchText)}
          >
            <AutocompleteArrayInput optionText="name" validate={[required]} fullWidth>
              <ChipField source="name" />
            </AutocompleteArrayInput>
          </ReferenceArrayInput>
        </CardContent>
      </Card>
    </SimpleForm>
  </Edit>
);

const connectedRoleEditComponent = connect(null, { push })(RoleEditComponent);
export const RoleEdit = withStyles(styles as any)(connectedRoleEditComponent);
