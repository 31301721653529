import { IRoleListResp } from 'Interfaces/Endpoints/role';
import { roleIdField, userRoleDataPath, roleDataPath } from 'Constants';
import { PgUnAssoc } from 'Providers/utils/lib_pg_assoc_new';
import { get } from 'lodash';

interface IRole {
  role: string[];
}

export const includeRoleIntoItem = (items: {}[], itemIdField: string, roles: IRole[]) =>
  items.map(item => ({
    ...item,
    role: roles.find(role => role[itemIdField] === item[itemIdField]).role,
  }));

export const parseRoleAliasToIds = (items: { role: string[] }[], allSystemRoles: IRoleListResp[]) =>
  items.map(({ role, ...item }) => ({
    ...item,
    role: role.reduce((acc, alias) => {
      const roleId = allSystemRoles.find(systemRole => systemRole.alias === alias)?.[roleIdField];
      if (roleId) acc.push(roleId);
      return acc;
    }, []),
  }));

export const mapUserRolesResponse = (userRoles, allRoles) => {
  const allRolesHash = PgUnAssoc(get(allRoles, roleDataPath)).reduce((acc, el) => {
    acc[el.alias] = el[roleIdField];
    return acc;
  }, {});

  return get(PgUnAssoc(get(userRoles, userRoleDataPath)), '[0].role', []).map(
    el => allRolesHash[el],
  );
};
