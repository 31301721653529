import axios from 'axios';
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  DELETE,
  DELETE_MANY,
  UPDATE,
  GET_MANY_REFERENCE,
  GET_MANY,
} from 'react-admin';
import {
  IRACreateParams,
  IRADataProviderParams,
  IRADeleteManyParams,
  IRADeleteParams,
  IRAGetManyParams,
  IRAGetManyReferenceParams,
  IRAGetOneParams,
  IRAUpdateParams,
} from 'Interfaces';
import {
  API_URL,
  REST,
  referencePassportFieldIdField,
  referencePassportFieldDataPath,
  referencePassportFieldPrefix,
  referencePassportFieldElementDataPath,
  referencePassportFieldElementPrefix,
  referencePassportFieldElementIdField,
} from 'Constants';
import { get } from 'lodash';
import {
  PgUnAssoc,
  removePrefix,
  removePrefixInArray,
  setPrefix,
  setPrefixInArray,
} from '../utils';

export const referencePassportFieldElementProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const crearId = removePrefix(referencePassportFieldElementPrefix, id);
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.ElementPassportField.LIST}`,
          {
            [referencePassportFieldElementIdField]: [crearId],
          },
        );
        const parsedData = PgUnAssoc(get(response, referencePassportFieldElementDataPath))[0];
        return {
          data: {
            ...parsedData,
            id: setPrefix(
              referencePassportFieldElementPrefix,
              parsedData[referencePassportFieldElementIdField],
            ),
            [referencePassportFieldPrefix]: setPrefix(
              referencePassportFieldPrefix,
              parsedData[referencePassportFieldIdField],
            ),
          },
        };
      }
      case GET_MANY_REFERENCE: {
        const { id } = params as IRAGetManyReferenceParams;
        const clearId = removePrefix(referencePassportFieldPrefix, id);
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.ElementPassportField.LIST}`,
          {
            [referencePassportFieldIdField]: clearId,
          },
        );
        const parsedData = PgUnAssoc(get(response, referencePassportFieldElementDataPath));
        const parsedDataWithId = parsedData.map(el => ({
          ...el,
          id: setPrefix(
            referencePassportFieldElementPrefix,
            el[referencePassportFieldElementIdField],
          ),
          [referencePassportFieldPrefix]: setPrefix(
            referencePassportFieldPrefix,
            el[referencePassportFieldIdField],
          ),
        }));
        return {
          data: parsedDataWithId,
          total: parsedDataWithId.length,
        };
      }
      case UPDATE: {
        const rawData = (params as IRAUpdateParams).data;
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.ElementPassportField.UPDATE}`,
          rawData,
        );
        const parsedData = get(response, referencePassportFieldElementDataPath);
        return {
          data: {
            ...parsedData,
            id: setPrefix(referencePassportFieldPrefix, parsedData[referencePassportFieldIdField]),
            [referencePassportFieldPrefix]: setPrefix(
              referencePassportFieldPrefix,
              parsedData[referencePassportFieldIdField],
            ),
          },
        };
      }
      case CREATE: {
        const { data } = params as IRACreateParams;
        const { [referencePassportFieldPrefix]: passportFieldId, ...sendData } = data as any;
        const clearPassportFieldId = removePrefix(referencePassportFieldPrefix, passportFieldId);
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.ElementPassportField.CREATE}`,
          {
            [referencePassportFieldIdField]: clearPassportFieldId,
            ...sendData,
          },
        );
        const parsedData = get(response, referencePassportFieldElementDataPath);
        return {
          data: {
            ...parsedData,
            id: setPrefix(
              referencePassportFieldElementPrefix,
              parsedData[referencePassportFieldElementIdField],
            ),
            [referencePassportFieldPrefix]: setPrefix(
              referencePassportFieldPrefix,
              parsedData[referencePassportFieldIdField],
            ),
          },
        };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(referencePassportFieldElementPrefix, id);
        await axios.post(`${API_URL}${REST.AdminReference.ElementPassportField.DELETE}`, {
          [referencePassportFieldElementIdField]: [clearId],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, referencePassportFieldElementPrefix);
        await axios.post(`${API_URL}${REST.AdminReference.ElementPassportField.DELETE}`, {
          [referencePassportFieldElementIdField]: clearIds,
        });
        return { data: ids };
      }
      default:
        throw new Error(
          `Неизвестный метод получения ${referencePassportFieldElementPrefix}: ${type}`,
        );
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${referencePassportFieldElementPrefix}-${type}`);
  }
};
