import { Filter, ReferenceInput, SelectInput } from 'react-admin';
import React from 'react';
import { Resources } from '../../../Constants';

export const CategoryFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Организация"
      source="organisation_id"
      reference={Resources.ORGS}
      allowEmpty
    >
      <SelectInput optionText="name_ru" />
    </ReferenceInput>
  </Filter>
);
