import React, { FC } from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import { organisationIdField, orgNameLabel } from 'Constants';

const styles = {
  сardLongItem: { maxWidth: '500px' },
};

const OrganisationListComponent: FC = ({ classes = {}, ...props }: any) => (
  <List {...props} exporter={false} title="Список организаций">
    <Datagrid rowClick="edit">
      <TextField source={organisationIdField} label="Id" />
      <TextField source="name_ru" label={orgNameLabel.name_ru} />
      <TextField source="name_en" label={orgNameLabel.name_en} />
      <TextField
        source="description_ru"
        label={orgNameLabel.description_ru}
        className={classes.сardLongItem}
      />
      <TextField
        source="description_en"
        label={orgNameLabel.description_en}
        className={classes.сardLongItem}
      />
    </Datagrid>
  </List>
);

export const OrganisationList = withStyles(styles as any)(OrganisationListComponent);
