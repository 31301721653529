import React, { useState, FC } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { WithStyles, createStyles } from '@material-ui/core';

registerLocale('ru', ru);

const styles = createStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginTop: '15px',
  },
  input: { borderRadius: '5px', lineHeight: '28px', borderColor: '#eee' },
  downloadButton: { marginLeft: '20px' },
});

interface IFileDownloadProps extends WithStyles<typeof styles> {
  label: string;
  onClick: (selectedDate: Date) => void;
  startDate?: Date;
  disabled?: boolean;
}

const FileDownloadComponent: FC<IFileDownloadProps> = ({
  classes,
  label,
  startDate,
  onClick,
  disabled = false,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date>(startDate || new Date());

  return (
    <div>
      <Typography variant="caption">{label}</Typography>
      <div className={classes.wrapper}>
        <DatePicker
          locale="ru"
          dateFormat="dd/MM/yyyy"
          selected={selectedDate}
          onChange={(date: Date): void => setSelectedDate(date)}
          className={classes.input}
          disabled={disabled}
        />
        <Button
          size="small"
          variant="contained"
          color="primary"
          className={classes.downloadButton}
          onClick={(): void => onClick(selectedDate)}
          disabled={disabled}
        >
          Выгрузить
        </Button>
      </div>
      {disabled ? (
        <Typography variant="caption" color="error">
          Не найдены настройки для выгрузки отчета
        </Typography>
      ) : null}
    </div>
  );
};

export const FileDownload = withStyles(styles)(FileDownloadComponent);
