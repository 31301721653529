const separator = '-';

export const setPrefix = (prefix: string, id: number | string): string =>
  id === null ? null : `${prefix}${separator}${id}`;

// export const setPrefix = (prefix: string, id: number | string): string => {
// `${prefix}${separator}${id}`;
// }

export const setPrefixInArray = (arr: any = [], prefix: string, field: string): any[] =>
  arr.map(el => ({ ...el, id: setPrefix(prefix, el[field]) }));

export const removePrefix = (prefix: string, rawId = ''): number | string => {
  if (rawId === null) return null;
  const clearId = rawId.replace(`${prefix}${separator}`, '');
  if (Number.isNaN(+clearId)) {
    return clearId === 'null' ? null : clearId;
  }
  return +clearId;
};

export const removePrefixInArray = (arr: any = [], prefix: string): any[] =>
  arr.map(el => removePrefix(prefix, el));
