import React, { FC } from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { referenceProjectTypeIdField } from 'Constants';
import { commonLabel } from 'Constants/locale';

export const ProjectTypeList: FC = props => (
  <List {...props} exporter={false} title="Справочник типов проектов">
    <Datagrid rowClick="edit">
      <TextField source={referenceProjectTypeIdField} label="Id" />
      <TextField source="name_ru" label={commonLabel.name_ru} />
      <TextField source="name_en" label={commonLabel.name_en} />
      <TextField source="alias" label="Код" />
      <TextField source="description_ru" label={commonLabel.description_ru} />
      <TextField source="description_en" label={commonLabel.description_en} />
    </Datagrid>
  </List>
);
