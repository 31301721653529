export enum Resources {
  ANALYTICAL_REPORTS = 'analytical_reports',
  APPROVAL = 'approval',
  APPROVAL_STEP = 'approval_step',
  USERS = 'users',
  ORGS = 'orgs',
  PROJECTS = 'project',
  OBJECT = 'object',
  CATEGORY = 'category',
  PROJECT_MODEL = 'projectModels',
  ROLE = 'role',
  REFERENCE_JOB_REFERENCE = 'job_reference',
  REFERENCE_CURRENCY = 'currency',
  REFERENCE_SERVICES = 'services',
  REFERENCE_JOB = 'job',
  REFERENCE_JOB_UNIT = 'job_unit',
  REFERENCE_GROUP_UNIT = 'job_element_group',
  REFERENCE_PROJECT_TYPE = 'project_type',
  REFERENCE_OBJECT_TYPE = 'object_type',
  REFERENCE_ELEMENT_REFERENCE = 'element_reference',
  REFERENCE_ELEMENT = 'element',
  PERMISSION = 'permission',
  CALCULATION_METHOD = 'calculation_method',
  TYPEOF_DIRECTORY = 'typeof_directory',
  STIMULSOFT = 'stimulsoft',
  FOLDER_TEMPLATES = 'folder_templates',
  FOLDER_TEMPLATES_FOLDER = 'folder_templates_folder',
  FILE_TEMPLATE = 'file_template',
  SCHEDULE_TEMPLATE = 'schedule_template',
  SCHEDULE = 'schedule',
  REFERENCE_OBJECT_LIFE_CYCLE_STAGE = 'object_life_cycle_stage',
  PASSPORT_ATTR = 'passport_attr',
  SPECIALITY = 'speciality',
  SCHEDULE_DEVIATION_DAY_METHOD = 'schedule_deviation_day_method',
  REFERENCE_BUSINESS_RELATION = 'business_process_relation',
  EVENT_LOG = 'event_log',
  REFERENCE_COST_PLAN_FACT_SOURCE = 'report_object_cost_plan_fact_source',
  DATABASE_MIGRATION = 'database_migration',
  PASSPORT_FIELD = 'passport_field',
  PASSPORT_FIELD_ELEMENT = 'passport_field_element',
  RESOURCE_FACT_METHOD = 'resource_fact_method',
}

export const approvalIdField = 'approval_id';
export const approvalDataPath = 'data.result.approval';
export const approvalPrefix = 'RAapprovalId';

export const approvalStepIdField = 'approval_step_id';
export const approvalStepDataPath = 'data.result.approval_step';
export const approvalStepPrefix = 'RAapprovalStepId';
export const approvalStepNextIdField = 'next_approval_step_id';
export const approvalKs2IdField = 'ks2_approval_id';
export const approvalKs3IdField = 'ks3_approval_id';

export const referenceBusinessRelationIdField = 'business_process_relation_id';
export const referenceBusinessRelation = 'relation';
export const referenceBusinessRelationDataPath = 'data.result.reference.approval_relation';
export const referenceBusinessRelationPrefix = 'RAreferenceBusinessProcessRelationId';

export const referenceCostPlanFactIdField = 'report_object_cost_plan_fact_source';
export const referenceCostPlanFactDataPath =
  'data.result.reference.report_object_cost_plan_fact_source';
export const referenceCostPlanFactPrefix = 'RAreferenceCostPlanFactSource';

export const organisationIdField = 'organisation_id';
export const organisationDataPath = 'data.result.organisation';
export const organisationPrefix = 'RAorganisationId';

export const userIdField = 'user_id';
export const userDataPath = 'data.result.user';
export const userPrefix = 'RAuserId';
export const userRoleDataPath = 'data.result.role';

export const projectIdField = 'project_id';
export const projectDataPath = 'data.result.project';
export const projectPrefix = 'RAprojectId';
export const projectMeta = 'meta';
export const projectImageRelation = 'project';
export const projectRoleDataPath = 'data.result.assigned_role_list.project';

export const objectIdField = 'object_id';
export const objectDataPath = 'data.result.object';
export const objectPrefix = 'RAobject​Id';
export const objectMeta = 'meta';
export const objectRelation = 'object';
export const objectRoleDataPath = 'data.result.assigned_role_list.object';

export const categoryIdField = 'object_category_id';
export const categoryDataPath = 'data.result.object_category';
export const categoryPrefix = 'RAcategoryId';

export const specialityIdField = 'profession_id';
export const specialityDataPath = 'data.result.reference.profession';
export const specialityPrefix = 'RAprofessionId';

export const referenceJobReferenceIdField = 'job_reference_id';
export const referenceJobReferenceDataPath = 'data.result.reference.job_reference';
export const referenceJobReferencePrefix = 'RAreferenceJobReferenceId';

export const referenceJobIdField = 'job_id';
export const referenceJobDataPath = 'data.result.reference.job';
export const referenceJobPrefix = 'RAreferenceJobId';
export const referenceJobParentIdField = 'parent_id';
export const referenceJobReplaceIdField = 'job_replace_id';

export const referenceJobUnitIdField = 'job_unit';
export const referenceJobUnitDataPath = 'data.result.reference.job_unit';
export const referenceJobUnitPrefix = 'RAreferenceJobUnitId';

export const referenceGroupUnitIdField = 'job_element_group';
export const referenceGroupUnitDataPath = 'data.result.reference.job_element_group';
export const referenceGroupUnitPrefix = 'RAreferenceGroupUnitId';

export const referenceProjectTypeIdField = 'project_type_id';
export const referenceProjectTypeDataPath = 'data.result.reference.project_type';
export const referenceProjectTypePrefix = 'RAreferenceProjectTypeId';
export const referenceProjectTypeTemplate = 'passport_template';

export const referenceObjectTypeIdField = 'object_type_id';
export const referenceObjectTypeDataPath = 'data.result.reference.object_type';
export const referenceObjectTypePrefix = 'RAreferenceObjectTypeId';
export const referenceObjectTypeTemplate = 'passport_template';

export const roleIdField = 'role_id';
export const roleDataPath = 'data.result.role';
export const rolePrefix = 'RAroleId';
export const roleRelationField = 'relation';
export const roleRelationIdField = 'relation_id';
export const rolePermissionIdField = 'role_permission';
export const rolePermissionDataPath = 'data.result.rolePermission';

export const permissionIdField = 'permission_id';
export const permissionDataPath = 'data.result.permission';
export const permissionPrefix = 'RApermissionId';

export const referenceElementReferenceIdField = 'element_reference_id';
export const referenceElementReferenceDataPath = 'data.result.reference.element_reference';
export const referenceElementReferencePrefix = 'RAreferenceElementReferenceId';

export const referenceElementIdField = 'element_id';
export const referenceElementDataPath = 'data.result.reference.element';
export const referenceElementPrefix = 'RAreferenceElementId';

export const imageIdField = 'image_id';
export const imageDataPath = 'data.result.image';
export const imagePrefix = 'RAimage';

export const calculationMethodIdField = 'calculation_method';
export const calculationMethodDataPath = 'data.result.reference.calculation_method';
export const calculationMethodPrefix = 'RAreferenceCalculationMethodId';

export const typeOfDirectoryIdField = 'object_schedule_reference_type';
export const typeOfDirectoryDataPath = 'data.result.object_schedule_reference_type';
export const typeOfDirectoryPrefix = 'RAreferencetypeOfDirectoryId';

export const analyticalTemplatesPath = 'data.result.analytics_template';

export const folderTemplatesPath = 'data.result.folder_template';
export const folderTemplatesIdField = 'folder_template_id';
export const folderTemplatesPrefix = 'RAfolderTemplatesId';

export const folderPath = 'data.result.folder_template_folder';
export const folderIdField = 'folder_template_folder_id';
export const folderPrefix = 'RAfolderId';
export const folderParentIdField = 'parent_id';

export const fileTemplatePath = 'data.result.file_template';
export const fileTemplateIdField = 'file_template_id';
export const fileTemplatePrefix = 'RAfileTemplateId';

export const scheduleTemplatePath = 'data.result.schedule_template';
export const scheduleTemplateIdField = 'schedule_template_id';
export const scheduleTemplatePrefix = 'RAscheduleTemplateId';

export const schedulePath = 'data.result.schedule_template_schedule';
export const scheduleIdField = 'schedule_template_schedule_id';
export const schedulePrefix = 'RAscheduleId';
export const scheduleParentIdField = 'parent_id';
export const scheduleAfterIdField = 'after_schedule_template_schedule_id';

export const referenceObjectLifeCycleStagePath = 'data.result.reference.object_life_cycle_stage';
export const referenceObjectLifeCycleStageIdField = 'object_life_cycle_stage_id';
export const referenceObjectLifeCycleStagePrefix = 'RAreferenceObjectLifeCycleStageId';

export const referenceCurrencyPath = 'data.result.reference.currency';
export const referenceCurrencyIdField = 'currency_id';
export const referenceCurrencyPrefix = 'RAreferenceCurrencyId';

export const referenceServicePath = 'data.result.integration_service';

export const scheduleDeviationDayMethodPath = 'data.result.reference.schedule_deviation_day_method';
export const scheduleDeviationDayMethodIdField = 'schedule_deviation_day_method';
export const scheduleDeviationDayMethodPrefix = 'RAscheduleDeviationDayMethodId';

export const databaseMigrationPath = 'data.result.database_migration';
export const databaseMigrationIdField = 'database_migration_id';
export const databaseMigrationPrefix = 'RAdatabaseMigrationId';

export const referencePassportFieldIdField = 'passport_field_reference_id';
export const referencePassportFieldDataPath = 'data.result.passport_field_reference';
export const referencePassportFieldPrefix = 'RAreferencePassportFieldId';

export const referencePassportFieldElementIdField = 'passport_field_reference_element_id';
export const referencePassportFieldElementDataPath =
  'data.result.reference.passport_field_reference_element';
export const referencePassportFieldElementPrefix = 'RAreferencePassportFieldElementId';

export const referenceResourceFactMethodPath = 'data.result.reference.resource_fact_method';
export const referenceResourceFactMethodPrefix = 'RAreferenceResourceFactMethod';
