import React, { FC } from 'react';
import { TextInput } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import { userNameLabel } from 'Constants';

interface IFullNameProps {
  locale: string;
}

const styles = {
  fullNameWrapper: { display: 'flex', width: '100%' },
  fullNameField: { marginRight: '20px' },
};

const FullNameComponent: FC<IFullNameProps> = ({ classes = {}, locale, ...props }: any) => (
  <div className={classes.fullNameWrapper}>
    <TextInput
      {...props}
      source={`lastname_${locale}`}
      label={userNameLabel[`lastname_${locale}`]}
      fullWidth
      className={classes.fullNameField}
    />
    <TextInput
      {...props}
      source={`firstname_${locale}`}
      label={userNameLabel[`firstname_${locale}`]}
      fullWidth
      className={classes.fullNameField}
    />
    <TextInput
      {...props}
      source={`patronymic_${locale}`}
      label={userNameLabel[`patronymic_${locale}`]}
      fullWidth
    />
  </div>
);

export const FullName = withStyles(styles as any)(FullNameComponent);
