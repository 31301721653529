import React, { FC } from 'react';
import { Edit, SimpleForm, TextInput, DisabledInput } from 'react-admin';
import { Card, CardContent } from '@material-ui/core';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { referenceCurrencyIdField, Resources } from 'Constants';

const styles = {
  card: { width: '100%', marginBottom: '1.5rem', marginTop: '1.5rem' },
  cardContent: { position: 'relative' },
  list: { width: '100%', display: 'none' },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 1000,
  },
};

const RenderTitle = ({ record }: any) => <span>{record.name}</span>;

const CurrencyEditComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => (
  <Edit title={<RenderTitle />} {...props}>
    <SimpleForm redirect="list">
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <IconButton
            onClick={() => propsPush(`/${Resources.REFERENCE_CURRENCY}`)}
            className={classes.closeBtn}
          >
            <CloseIcon />
          </IconButton>
          <DisabledInput source={referenceCurrencyIdField} fullWidth label="Id" />
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <TextInput fullWidth required source="name" label="Название" />
          <TextInput
            fullWidth
            required
            source="alias"
            label="Сокращенное название"
            helperText="Принимаются только латинские символы"
          />
        </CardContent>
      </Card>
    </SimpleForm>
  </Edit>
);

const connectedCurrencyEdit = connect(null, { push })(CurrencyEditComponent);

// eslint-disable-next-line max-len
export const CurrencyEdit = withStyles(styles as any)(connectedCurrencyEdit);
