/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import {
  IOrganisationCreateProvider,
  IOrganisationUpdateProvider,
  IRACreateParams,
  IRADataProviderParams,
  IRADeleteManyParams,
  IRADeleteParams,
  IRAGetManyParams,
  IRAGetOneParams,
  IRAUpdateParams,
} from 'Interfaces';
import {
  API_URL,
  REST,
  organisationPrefix,
  organisationIdField,
  organisationDataPath,
  DATE_SERVER_MASK,
  referenceCurrencyPrefix,
  referenceCurrencyIdField,
  referenceServicePath,
} from 'Constants';
import { get, omitBy, isNil } from 'lodash';
import { CREATE, DELETE, DELETE_MANY, GET_LIST, GET_MANY, GET_ONE, UPDATE } from 'react-admin';
import {
  PgUnAssoc,
  removePrefix,
  removePrefixInArray,
  setPrefix,
  setPrefixInArray,
} from '../utils';
import { DOWNLOAD_FILE } from '../utils/customActions';

export const organisationProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(`${API_URL}${REST.AdminOrganisation.LIST}`, {});
        const parsedOrgs = PgUnAssoc(get(response, organisationDataPath));
        const orgsWithId = setPrefixInArray(
          parsedOrgs,
          organisationPrefix,
          organisationIdField,
        ).map(el => ({
          ...el,
          [referenceCurrencyPrefix]: setPrefix(
            referenceCurrencyPrefix,
            el[referenceCurrencyIdField],
          ),
        }));
        return { data: orgsWithId, total: orgsWithId.length };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(organisationPrefix, id);
        const response = await axios.post(`${API_URL}${REST.AdminOrganisation.LIST}`, {
          [organisationIdField]: [clearId],
        });

        const detail = await axios.post(`${API_URL}${REST.AdminOrganisation.DETAIL}`, {
          organisation_id: clearId,
        });
        const parsedOrgServices = PgUnAssoc(get(detail, referenceServicePath));
        const parsedOrgServicesIds = parsedOrgServices.map(service => service.id);

        const services = await axios.post(`${API_URL}${REST.AdminReference.Service.LIST}`);
        const parsedServices = get(services, referenceServicePath);

        const parsedOrg = PgUnAssoc(get(response, organisationDataPath))[0];

        const parsedMeta = !parsedOrg.meta ? '' : JSON.stringify(parsedOrg.meta); // TODO: это просто хардкод meta
        const parseDataOrgForTranslate = {
          ...parsedOrg,
          settings: { ...parsedOrg.settings, translate: JSON.parse(parsedOrg.settings.translate) },
        };

        return {
          data: {
            ...parseDataOrgForTranslate,
            id: setPrefix(organisationPrefix, parsedOrg[organisationIdField]),
            services: parsedServices,
            parsedOrgServices: parsedOrgServices,
            parsedOrgServicesIds: parsedOrgServicesIds,
            meta: parsedMeta, // TODO: это просто хардкод meta
            [referenceCurrencyPrefix]: setPrefix(
              referenceCurrencyPrefix,
              parsedOrg[referenceCurrencyIdField],
            ),
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const clearIds = removePrefixInArray(ids, organisationPrefix);
        const response = await axios.post(`${API_URL}${REST.AdminOrganisation.LIST}`, {
          [organisationIdField]: clearIds,
        });
        const parsedOrgs = PgUnAssoc(get(response, organisationDataPath));
        const orgsWithId = setPrefixInArray(
          parsedOrgs,
          organisationPrefix,
          organisationIdField,
        ).map(el => ({
          ...el,
          [referenceCurrencyPrefix]: setPrefix(
            referenceCurrencyPrefix,
            el[referenceCurrencyIdField],
          ),
        }));
        return { data: orgsWithId };
      }
      case UPDATE: {
        const rawData = (params as IRAUpdateParams).data as IOrganisationUpdateProvider;
        const { id, files, settings, parsedOrgServicesIds, ...sendData } = rawData as any;
        const clearId = removePrefix(organisationPrefix, id);

        const currencyId = removePrefix(referenceCurrencyPrefix, sendData[referenceCurrencyPrefix]);

        // удаляет из обьекта ключи со значением null
        const requestData = omitBy(
          {
            ...settings,
            translate:
              typeof settings.translate === 'string'
                ? JSON.parse(settings.translate)
                : settings.translate,
            report:
              typeof settings.report === 'string' ? JSON.parse(settings.report) : settings.report,
            [organisationIdField]: removePrefix(organisationPrefix, id),
          },
          isNil,
        );

        await axios.post(`${API_URL}${REST.AdminOrganisation.SETTINGS.SET}`, requestData);
        if (files) {
          const formData = new FormData();
          formData.append('file', files.rawFile);

          await axios.post(`${API_URL}${REST.AdminFile.UPLOAD}`, formData, {
            headers: {
              'x-file-relation': 'organisation',
              'x-file-relation-id': rawData.organisation_id,
              'x-public': true,
            },
          });
        }

        const sendMeta = !sendData.meta ? null : JSON.parse(sendData.meta as string); // TODO: это просто хардкод meta

        await axios.post(`${API_URL}${REST.AdminOrganisation.UPDATE_SERVICES}`, {
          service_id: parsedOrgServicesIds,
          organisation_id: clearId,
        });

        const response = await axios.post(
          `${API_URL}${REST.AdminOrganisation.UPDATE}`,
          {
            ...sendData,
            currency_id: currencyId,
            meta: sendMeta,
          }, // TODO: это просто хардкод meta
        );
        const updatedOrg = get(response, organisationDataPath);
        return {
          data: {
            ...updatedOrg,
            id: setPrefix(organisationPrefix, updatedOrg[organisationIdField]),
          },
        };
      }
      case CREATE: {
        const sendData = (params as IRACreateParams).data as IOrganisationCreateProvider;

        const sendMeta = !sendData.meta ? null : JSON.parse(sendData.meta as string); // TODO: это просто хардкод meta

        const response = await axios.post(
          `${API_URL}${REST.AdminOrganisation.CREATE}`,
          { ...sendData, meta: sendMeta }, // TODO: это просто хардкод meta
        );
        const newOrg = get(response, organisationDataPath);
        return {
          data: { ...newOrg, id: setPrefix(organisationPrefix, newOrg[organisationIdField]) },
        };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(organisationPrefix, id);
        await axios.post(`${API_URL}${REST.AdminOrganisation.DELETE}`, {
          [organisationIdField]: [clearId],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, organisationPrefix);
        await axios.post(`${API_URL}${REST.AdminOrganisation.DELETE}`, {
          [organisationIdField]: clearIds,
        });
        return { data: ids };
      }
      case DOWNLOAD_FILE: {
        const { date, method, url, payload } = params as any;
        const result = await axios({
          method,
          url: `${API_URL}/admin${url}/excel`,
          params: { ...payload, date_to: dayjs(date).format(DATE_SERVER_MASK) },
          responseType: 'blob',
        });
        const fileName = get(result, 'headers.content-disposition.filename', 'report.xlsx');
        const blob = new Blob([result.data], {
          type: `${result.headers['content-type']};charset=utf-8`,
        });
        FileSaver.saveAs(blob, fileName);
        return { data: {} };
      }
      default:
        throw new Error(`Неизвестный метод получения ${organisationPrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${organisationPrefix}-${type}`);
  }
};
