import React, { FC } from 'react';
import {
  LongTextInput,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from 'react-admin';
import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Resources,
  referenceJobUnitIdField,
  referenceGroupUnitIdField,
  jobLabel,
  referenceJobReferencePrefix,
  referenceJobParentIdField,
} from 'Constants';
import Typography from '@material-ui/core/Typography';

const styles = {
  card: { width: '100%', marginBottom: '10px' },
  сardContent: { display: 'flex', flexDirection: 'column', position: 'relative' },
  groupWrapper: { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1.5rem' },
  btn: { position: 'absolute', right: '0', top: '0' },
};

const JobCreateComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => {
  const innerProps = { ...props };
  const jobReferenceString: string = innerProps.history.location.search;
  const jobReference = jobReferenceString
    ? jobReferenceString.replace(`?${referenceJobReferencePrefix}=`, '')
    : null;
  const redirect = jobReference
    ? `/${Resources.REFERENCE_JOB_REFERENCE}/${jobReference}/1`
    : `/${Resources.REFERENCE_JOB_REFERENCE}`;
  return (
    <Create title="Новый тип работ" {...props}>
      <SimpleForm redirect={redirect}>
        <>
          <Card className={classes.card}>
            <CardContent className={classes.сardContent}>
              <IconButton onClick={() => propsPush(redirect)} className={classes.btn}>
                <CloseIcon />
              </IconButton>
              <ReferenceInput
                source={referenceJobReferencePrefix}
                reference={Resources.REFERENCE_JOB_REFERENCE}
                resource={Resources.REFERENCE_JOB_REFERENCE}
                label="Справочник работ"
                fullWidth
                required
                disabled
              >
                <SelectInput optionText={record => record.name} />
              </ReferenceInput>
              <div className={classes.groupWrapper}>
                <TextInput source="code" label="Код" fullWidth className={classes.groupItem} />
                <TextInput source="key" label="Ключ" fullWidth />
              </div>
              <div className={classes.groupWrapper}>
                <TextInput
                  source="name_ru"
                  label={jobLabel.name_ru}
                  fullWidth
                  className={classes.groupItem}
                  required
                />
                <TextInput source="name_en" label={jobLabel.name_en} fullWidth />
              </div>
              <div className={classes.groupWrapper}>
                <ReferenceInput
                  source={referenceJobUnitIdField}
                  resource={Resources.REFERENCE_JOB_UNIT}
                  reference={Resources.REFERENCE_JOB_UNIT}
                  label="Ед.изм."
                  fullWidth
                  required
                  className={classes.groupItem}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput
                  source={referenceGroupUnitIdField}
                  resource={Resources.REFERENCE_GROUP_UNIT}
                  reference={Resources.REFERENCE_GROUP_UNIT}
                  label="Группа элементов"
                  fullWidth
                  required
                  className={classes.groupItem}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </div>
              <LongTextInput source="description_ru" label={jobLabel.description_ru} />
              <LongTextInput source="description_en" label={jobLabel.description_en} />
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent className={classes.сardContent}>
              <Typography variant="caption">Настройки</Typography>
              <ReferenceInput
                source={referenceJobParentIdField}
                reference={Resources.REFERENCE_JOB}
                resource={Resources.REFERENCE_JOB}
                filter={{
                  [referenceJobReferencePrefix]: jobReference,
                  isJob: false,
                }}
                label="Родительский элемент"
                fullWidth
                allowEmpty
                emptyValue={null}
                defaultValue={null}
              >
                <SelectInput optionText={rec => `${'–'.repeat(rec.level)} ${rec.name_ru}`} />
              </ReferenceInput>
              <BooleanInput label="Является работой" source="job" required defaultValue={false} />
            </CardContent>
          </Card>
        </>
      </SimpleForm>
    </Create>
  );
};

const connectedJobCreate = connect(null, { push, goBack })(JobCreateComponent);
export const JobCreate = withStyles(styles as any)(connectedJobCreate);
