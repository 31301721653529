import { availableLanguages, commonLabel, folderIdField, folderPrefix, Resources } from 'Constants';
import React, { FC } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  FormDataConsumer,
  ArrayInput,
  BooleanInput,
  addField,
  required,
} from 'react-admin';
import { uniqueValues } from 'Utils/stringMethods';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { Card, CardContent, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  ITemplateFieldName,
  ITemplateFormName,
  templateFieldType,
  templateFileFieldType,
} from '../../../Providers/utils';
import { IObjectTypeEditStyle } from '../../References/ObjectType/i';

const styles = {
  card: { width: '100%', marginBottom: '1.5rem' },
  fullWidth: { width: '100%' },
  сardContent: { display: 'flex', flexWrap: 'wrap', position: 'relative' },
  fieldsInLine: { width: '40%', marginRight: '20px' },
  fieldsWithMargin: { marginRight: '0' },
  btn: { position: 'absolute', right: '0', top: '0' },
  checkboxName: { display: 'flex' },
  checkboxButtonForward: {
    padding: '3px',
    color: 'black',
    backgroundColor: 'rgba(6, 128, 249, 0.08)',
    border: '1px solid #C2C8D6',
    borderRight: '0',
    borderRadius: '3px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    cursor: 'pointer',
    outline: 'none',
  },
  checkboxButtonBack: {
    padding: '3px',
    color: 'black',
    backgroundColor: 'rgba(6, 128, 249, 0.08)',
    border: '1px solid #C2C8D6',
    borderLeft: '0',
    borderRadius: '3px',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    cursor: 'pointer',
    outline: 'none',
  },
  checkboxButtonName: {
    padding: '3px 10px',
    color: 'black',
    backgroundColor: 'white',
    border: '1px solid #C2C8D6',
    borderLeft: '0',
    borderRight: '0',
    borderRadius: '0',
    cursor: 'pointer',
    outline: 'none',
  },
  groupWrapper: { display: 'flex', width: '100%' },
  groupItem: { marginRight: '20px' },
  privateField: { display: 'inline', marginLeft: '20px' },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '1rem',
    alignItems: 'flex-end',
  },
};

const fieldTypeChoices = Object.entries(templateFileFieldType).map(el => ({
  id: el[1].type,
  name: el[1].label,
}));

const FileTemplateCreateComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => (
  <Create title="Новый шаблон файла" {...props}>
    <SimpleForm redirect="list">
      <>
        <Card className={classes.card}>
          <CardContent className={classes.сardContent}>
            <TextInput
              fullWidth
              validate={[required('Обязательное поле')]}
              source="code"
              label="Код"
            />
            <TextInput fullWidth required source="name" label="Название" />
            <IconButton
              onClick={() => propsPush(`/${Resources.FILE_TEMPLATE}`)}
              className={classes.btn}
            >
              <CloseIcon />
            </IconButton>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent className={classes.сardContent}>
            <ArrayInput source={ITemplateFormName.fields} label="Шаблон мета-информации" fullWidth>
              <SimpleFormIterator>
                <FormDataConsumer>
                  {({ formData, scopedFormData, getSource, ...rest }): JSX.Element => (
                    <div className={classes.fieldRow}>
                      <TextInput
                        key="field-key"
                        {...rest}
                        source={getSource(`${ITemplateFieldName.fieldKey}`)}
                        validate={[
                          required('Обязательное поле'),
                          uniqueValues(ITemplateFormName.fields),
                        ]}
                        label="Код"
                        className={classes.fieldsWithMargin}
                      />
                      <TextInput
                        key="field-label"
                        {...rest}
                        source={getSource(`${ITemplateFieldName.fieldLabel}`)}
                        validate={required('Обязательное поле')}
                        label={commonLabel.name_ru}
                        className={classes.fieldsWithMargin}
                      />
                      <SelectInput
                        source={getSource(ITemplateFieldName.fieldType)}
                        choices={[...fieldTypeChoices]}
                        required
                        label="Тип поля"
                      />
                      <BooleanInput
                        label="Обязательное поле"
                        source={getSource(ITemplateFieldName.fieldRequired)}
                        defaultValue={false}
                        className={classes.privateField}
                      />
                    </div>
                  )}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography variant="caption">Настройки загрузки физического файла</Typography>
            <TextInput source="file_title" label="Название" fullWidth defaultValue={null} />
            <BooleanInput
              source="file_upload"
              label="Загружать физический файл"
              defaultValue={false}
            />
            <BooleanInput
              source="file_required"
              label="Загрузка файла обязательна"
              defaultValue={false}
            />
          </CardContent>
        </Card>
      </>
    </SimpleForm>
  </Create>
);

export const connectedFileTemplateCreate = connect(null, { push })(FileTemplateCreateComponent);

export const FileTemplateCreate = withStyles(styles as IObjectTypeEditStyle)(
  connectedFileTemplateCreate,
);
