/* eslint-disable @typescript-eslint/camelcase */

export const analyticalReportTemplateTypes = [
  { name: 'dashboard', value: 'dashboard' },
  { name: 'report', value: 'report' },
  { name: 'object_report', value: 'object_report' },
];

export const expenditureLevelTypes = [
  { name: 'По видам работ', value: 'schedule_job' },
  { name: 'По всей структуре', value: 'schedule_any' },
];

export const factCorrectionPeriodByExpenditureTypes = [
  { name: 'Произвольный', value: 'any' },
  { name: 'Месяц', value: 'month' },
];

export const gunttDefaultDeviationType = [
  { name: 'Дни отставания', value: 'deviation_day' },
  { name: 'Дни на завершение', value: 'days_to_complete' },
  { name: 'Отклонение', value: 'deviation_finish' },
];

export interface IPassportAttribute {
  id: string;
  fieldKey: string;
  fieldLabel_ru: string;
  fieldLabel_en?: string;
  fieldType: string;
  fieldPrivate: boolean;
  fieldIsSpecial?: boolean;
  readOnly?: boolean;
}

export const passportAttributesData: Array<IPassportAttribute> = [
  {
    id: 'cco_uin',
    fieldKey: 'cco_uin',
    fieldLabel_ru: 'UIN ОКС',
    fieldLabel_en: 'CCO UIN',
    fieldType: 'text',
    fieldPrivate: false,
    fieldIsSpecial: true,
  },
  {
    id: 'plot_cadastral_number',
    fieldKey: 'plot_cadastral_number',
    fieldLabel_ru: 'Кадастровый номер участка',
    fieldLabel_en: 'Plot cadastral number',
    fieldType: 'text',
    fieldPrivate: false,
    fieldIsSpecial: true,
  },
  {
    id: 'date_number_examination_engineering_surveys',
    fieldKey: 'date_number_examination_engineering_surveys',
    fieldLabel_ru: 'Дата и номер экспертизы инженерных изысканий',
    fieldLabel_en: 'Date and number of the examination of engineering surveys',
    fieldType: 'date_number',
    fieldPrivate: false,
    fieldIsSpecial: true,
  },
  {
    id: 'date_number_examination_project_documentation',
    fieldKey: 'date_number_examination_project_documentation',
    fieldLabel_ru: 'Дата и номер экспертизы проектной документации',
    fieldLabel_en: 'Date and number of examination of project documentation',
    fieldType: 'date_number',
    fieldPrivate: false,
    fieldIsSpecial: true,
  },
  {
    id: 'permit_building',
    fieldKey: 'permit_building',
    fieldLabel_ru: 'Разрешение на строительство',
    fieldLabel_en: 'Building permit',
    fieldType: 'text',
    fieldPrivate: false,
    fieldIsSpecial: true,
  },
  {
    id: 'permit_commissioning',
    fieldKey: 'permit_commissioning',
    fieldLabel_ru: 'Разрешение на ввод в эксплуатацию',
    fieldLabel_en: 'Commissioning permit',
    fieldType: 'text',
    fieldPrivate: false,
    fieldIsSpecial: true,
  },
  {
    id: 'cco_case',
    fieldKey: 'cco_case',
    fieldLabel_ru: 'Дело ОКС',
    fieldLabel_en: 'CCO case',
    fieldType: 'text',
    fieldPrivate: false,
    fieldIsSpecial: true,
    readOnly: true,
  },
  {
    id: 'picketing_work_site',
    fieldKey: 'picketing_work_site',
    fieldLabel_ru: 'Пикетаж участка производства работ',
    fieldLabel_en: 'Picketing of the work site',
    fieldType: 'text',
    fieldPrivate: false,
    fieldIsSpecial: true,
  },
  {
    id: 'road_id',
    fieldKey: 'road_id',
    fieldLabel_ru: 'Идентификатор дороги',
    fieldLabel_en: 'Road ID',
    fieldType: 'text',
    fieldPrivate: false,
    fieldIsSpecial: true,
  },
  {
    id: 'number_contract_work_road',
    fieldKey: 'number_contract_work_road',
    fieldLabel_ru: 'Номер договора, по которому ведутся работы на участке дороги',
    fieldLabel_en:
      'Number of the contract under which work is being carried out on the road section',
    fieldType: 'text',
    fieldPrivate: false,
    fieldIsSpecial: true,
  },
  {
    id: 'date_number_exam_engineer_survey_first',
    fieldKey: 'date_number_exam_engineer_survey_first',
    fieldLabel_ru: 'Дата и номер экспертизы инженерных изысканий - первичная',
    fieldLabel_en: 'Date and number of the examination of engineering surveys - first',
    fieldType: 'date_number',
    fieldPrivate: false,
    fieldIsSpecial: true,
  },
  {
    id: 'date_number_exam_engineer_survey_repeat',
    fieldKey: 'date_number_exam_engineer_survey_repeat',
    fieldLabel_ru: 'Дата и номер экспертизы инженерных изысканий - повторная',
    fieldLabel_en: 'Date and number of the examination of engineering surveys - repeat',
    fieldType: 'date_number',
    fieldPrivate: false,
    fieldIsSpecial: true,
  },
  {
    id: 'date_number_exam_project_doc_first',
    fieldKey: 'date_number_exam_project_doc_first',
    fieldLabel_ru: 'Дата и номер экспертизы проектной документации - первичная',
    fieldLabel_en: 'Date and number of examination of project documentation - first',
    fieldType: 'date_number',
    fieldPrivate: false,
    fieldIsSpecial: true,
  },
  {
    id: 'date_number_exam_project_doc_repeat',
    fieldKey: 'date_number_exam_project_doc_repeat',
    fieldLabel_ru: 'Дата и номер экспертизы проектной документации - повторная',
    fieldLabel_en: 'Date and number of examination of project documentation - repeat',
    fieldType: 'date_number',
    fieldPrivate: false,
    fieldIsSpecial: true,
  },
  {
    id: 'date_number_exam_estimate_first',
    fieldKey: 'date_number_exam_estimate_first',
    fieldLabel_ru:
      'Дата и номер экспертизы по проверке достоверности сметной стоимости - первичная',
    fieldLabel_en: 'Date and number of examination of estimate accuracy - first',
    fieldType: 'date_number',
    fieldPrivate: false,
    fieldIsSpecial: true,
  },
  {
    id: 'date_number_exam_estimate_repeat',
    fieldKey: 'date_number_exam_estimate_repeat',
    fieldLabel_ru:
      'Дата и номер экспертизы по проверке достоверности сметной стоимости - повторная',
    fieldLabel_en: 'Date and number of examination of estimate accuracy - repeat',
    fieldType: 'date_number',
    fieldPrivate: false,
    fieldIsSpecial: true,
  },
  {
    id: 'gpzu_number',
    fieldKey: 'gpzu_number',
    fieldLabel_ru: 'Номер градостроительного плана земельного участка',
    fieldLabel_en: 'Land plot development plan',
    fieldType: 'text',
    fieldPrivate: false,
    fieldIsSpecial: true,
  },
  {
    id: 'historical_expert_number',
    fieldKey: 'historical_expert_number',
    fieldLabel_ru: 'Номер заключения историко-культурной экспертизы',
    fieldLabel_en: 'Historical and cultural expertise',
    fieldType: 'text',
    fieldPrivate: false,
    fieldIsSpecial: true,
  },
];
