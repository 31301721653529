import React, { FC } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  DisabledInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Card, CardContent, Typography } from '@material-ui/core';
import {
  referencePassportFieldElementIdField,
  referencePassportFieldPrefix,
  Resources,
} from 'Constants';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = {
  card: { width: '100%', marginBottom: '1.5rem' },
  cardContent: { position: 'relative' },
  list: { width: '100%', display: 'none' },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 1000,
  },
  grid: { display: 'grid', gap: '2rem', gridTemplateColumns: '1fr 1fr' },
};

const RenderTitle = ({ record }: any) => record.value;

const PassportFieldElementEditComponent: FC = ({
  push: propsPush,
  classes = {},
  ...props
}: any) => {
  const redirect = (basePath, id, data) =>
    `/${Resources.PASSPORT_FIELD}/${data[referencePassportFieldPrefix]}/1`;

  return (
    <Edit title={<RenderTitle />} {...props}>
      <SimpleForm redirect={redirect}>
        <FormDataConsumer>
          {({ formData }): JSX.Element => (
            <>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <IconButton
                    onClick={() => {
                      propsPush(
                        `/${Resources.PASSPORT_FIELD}/${formData[referencePassportFieldPrefix]}/1`,
                      );
                    }}
                    className={classes.closeBtn}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DisabledInput
                    fullWidth
                    required
                    source={referencePassportFieldElementIdField}
                    label="Id"
                  />
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <ReferenceInput
                    source={referencePassportFieldPrefix}
                    reference={Resources.PASSPORT_FIELD}
                    resource={Resources.PASSPORT_FIELD}
                    label="Поле паспорта"
                    fullWidth
                    required
                    disabled
                  >
                    <SelectInput optionText={record => record.name} />
                  </ReferenceInput>
                  <TextInput fullWidth required source="value" label="Значение" />
                </CardContent>
              </Card>
            </>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

const connectedPassportFieldElementEdit = connect(null, { push })(
  PassportFieldElementEditComponent,
);
export const PassportFieldElementEdit = withStyles(styles as any)(
  connectedPassportFieldElementEdit,
);
