import { IRADataProviderParams } from 'Interfaces';
import { Resources } from '../../Constants';
import { analyticalReportsProvider } from './analyticalReportsProvider';
import { organisationProvider } from './organisationProvider';
import { userProvider } from './userProvider';
import { projectProvider } from './projectProvider';
import { referenceJobReferenceProvider } from './referenceJobReferenceProvider';
import { referenceJobProvider } from './referenceJobProvider';
import { referenceJobUnitProvider } from './referenceJobUnitProvider';
import { referenceGroupUnitProvider } from './referenceGroupUnitProvider';
import { referenceProjectTypeProvider } from './referenceProjectTypeProvider';
import { referenceObjectTypeProvider } from './referenceObjectTypeProvider';
import { roleProvider } from './roleProvider';
import { objectProvider } from './objectProvider';
import { folderTemplatesProvider } from './folderTemplatesProvider';
import { categoryProvider } from './categoryProvider';
import { permissionProvider } from './permissionProvider';
import { referenceElementProvider } from './referenceElementProvider';
import { referenceElementReferenceProvider } from './referenceElementReferenceProvider';
import { referenceCalculationMethodProvider } from './referenceCalculationMethodProvider';
import { referenceTypeOfDirectoryProvider } from './referenceTypeOfDirectoryProvider';
import { folderProvider } from './folderProvider';
import { fileTemplateProvider } from './fileTemplateProvider';
import { scheduleTemplateProvider } from './scheduleTemplateProvider';
import { scheduleProvider } from './scheduleProvider';
import { referenceObjectLifeCycleStageProvider } from './referenceObjectLifeCycleStageProvider';
import { referencePassportAttributes } from './referencePassportAttributes';
import { specialityProvider } from './specialityProvider';
import { referenceScheduleDeviationDayMethodProvider } from './referenceScheduleDeviationDayMethodProvider';
import { eventLogProvider } from './eventLogProvider';
import { referenceBusinessRelationProvider } from './referenceBusinessRelationProvider';
import { approvalProvider } from './approvalProvider';
import { approvalStepProvider } from './approvalStepProvider';
import { referenceCostPlanFactSourceProvider } from './referenceCostPlanFactProvider';
import { databaseMigrationProvider } from './databaseMigrationProvider';
import { referencePassportFieldProvider } from './referencePassportFieldProvider';
import { referencePassportFieldElementProvider } from './referencePassportFieldElementProvider';
import { referenceResourceFactMethodProvider } from './referenceResourceFactMethodProvider';
import { referenceCurrencyProvider } from './referenceCurrencyProvider';
import { referenceServiceProvider } from './referenceServiceProvider';

export const dataProviders = (
  type: string,
  resource: string,
  params: IRADataProviderParams,
): Promise<any> => {
  switch (resource) {
    case Resources.ANALYTICAL_REPORTS: {
      return analyticalReportsProvider(type, params);
    }

    case Resources.APPROVAL: {
      return approvalProvider(type, params);
    }

    case Resources.APPROVAL_STEP: {
      return approvalStepProvider(type, params);
    }

    case Resources.ORGS: {
      return organisationProvider(type, params);
    }

    case Resources.USERS: {
      return userProvider(type, params);
    }

    case Resources.PROJECTS: {
      return projectProvider(type, params);
    }

    case Resources.OBJECT: {
      return objectProvider(type, params);
    }

    case Resources.FOLDER_TEMPLATES: {
      return folderTemplatesProvider(type, params);
    }

    case Resources.FOLDER_TEMPLATES_FOLDER: {
      return folderProvider(type, params);
    }

    case Resources.FILE_TEMPLATE: {
      return fileTemplateProvider(type, params);
    }

    case Resources.SCHEDULE_TEMPLATE: {
      return scheduleTemplateProvider(type, params);
    }

    case Resources.SCHEDULE: {
      return scheduleProvider(type, params);
    }

    case Resources.CATEGORY: {
      return categoryProvider(type, params);
    }

    case Resources.REFERENCE_JOB: {
      return referenceJobProvider(type, params);
    }

    case Resources.REFERENCE_JOB_REFERENCE: {
      return referenceJobReferenceProvider(type, params);
    }

    case Resources.REFERENCE_JOB_UNIT: {
      return referenceJobUnitProvider(type, params);
    }

    case Resources.REFERENCE_GROUP_UNIT: {
      return referenceGroupUnitProvider(type, params);
    }

    case Resources.REFERENCE_PROJECT_TYPE: {
      return referenceProjectTypeProvider(type, params);
    }

    case Resources.REFERENCE_OBJECT_TYPE: {
      return referenceObjectTypeProvider(type, params);
    }

    case Resources.REFERENCE_ELEMENT_REFERENCE: {
      return referenceElementReferenceProvider(type, params);
    }

    case Resources.REFERENCE_ELEMENT: {
      return referenceElementProvider(type, params);
    }

    case Resources.ROLE: {
      return roleProvider(type, params);
    }

    case Resources.PERMISSION: {
      return permissionProvider(type, params);
    }

    case Resources.CALCULATION_METHOD: {
      return referenceCalculationMethodProvider(type, params);
    }

    case Resources.TYPEOF_DIRECTORY: {
      return referenceTypeOfDirectoryProvider(type, params);
    }

    case Resources.REFERENCE_OBJECT_LIFE_CYCLE_STAGE: {
      return referenceObjectLifeCycleStageProvider(type, params);
    }

    case Resources.REFERENCE_SERVICES: {
      return referenceServiceProvider(type, params);
    }

    case Resources.REFERENCE_CURRENCY: {
      return referenceCurrencyProvider(type, params);
    }

    case Resources.PASSPORT_ATTR: {
      return referencePassportAttributes(type, params);
    }

    case Resources.SPECIALITY: {
      return specialityProvider(type, params);
    }

    case Resources.SCHEDULE_DEVIATION_DAY_METHOD: {
      return referenceScheduleDeviationDayMethodProvider(type, params);
    }

    case Resources.REFERENCE_BUSINESS_RELATION: {
      return referenceBusinessRelationProvider(type, params);
    }

    case Resources.EVENT_LOG: {
      return eventLogProvider(type, params);
    }

    case Resources.REFERENCE_COST_PLAN_FACT_SOURCE: {
      return referenceCostPlanFactSourceProvider(type, params);
    }

    case Resources.DATABASE_MIGRATION: {
      return databaseMigrationProvider(type, params);
    }

    case Resources.PASSPORT_FIELD: {
      return referencePassportFieldProvider(type, params);
    }

    case Resources.PASSPORT_FIELD_ELEMENT: {
      return referencePassportFieldElementProvider(type, params);
    }

    case Resources.RESOURCE_FACT_METHOD: {
      return referenceResourceFactMethodProvider(type, params);
    }

    default: {
      throw new Error(`Неизвестный тип ресурса: ${resource}`);
    }
  }
};
