import axios from 'axios';
import {
  GET_LIST,
  GET_ONE,
  DELETE,
  DELETE_MANY,
  UPDATE,
  CREATE,
  GET_MANY,
  GET_MANY_REFERENCE,
} from 'react-admin';
import { get } from 'lodash';
import {
  IRADataProviderParams,
  IRAGetOneParams,
  IRADeleteParams,
  IRADeleteManyParams,
  IRAUpdateParams,
  IRACreateParams,
  IRAGetManyParams,
  IRAGetManyReferenceParams,
} from 'Interfaces';
import {
  API_URL,
  REST,
  scheduleTemplatePath,
  scheduleTemplateIdField,
  scheduleTemplatePrefix,
} from 'Constants';
import {
  setPrefixInArray,
  setPrefix,
  removePrefix,
  removePrefixInArray,
  PgUnAssoc,
} from 'Providers/utils';

export const scheduleTemplateProvider = async (
  type: string,
  params: IRADataProviderParams,
  // eslint-disable-next-line consistent-return
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(`${API_URL}${REST.AdminScheduleTemplate.LIST}`, {});
        const parsedTemplates = PgUnAssoc(get(response, scheduleTemplatePath));
        const parsedTemplatesWithId = setPrefixInArray(
          parsedTemplates,
          scheduleTemplatePrefix,
          scheduleTemplateIdField,
        ).map(el => ({
          ...el,
        }));
        return {
          data: parsedTemplatesWithId,
          total: parsedTemplatesWithId.length,
        };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(scheduleTemplatePrefix, id);
        const response = await axios.post(`${API_URL}${REST.AdminScheduleTemplate.LIST}`, {
          [scheduleTemplateIdField]: [clearId],
        });
        const parsedTemplate = PgUnAssoc(get(response, scheduleTemplatePath))[0];

        return {
          data: {
            ...parsedTemplate,
            id: setPrefix(scheduleTemplatePrefix, parsedTemplate[scheduleTemplateIdField]),
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const response = await axios.post(`${API_URL}${REST.AdminScheduleTemplate.LIST}`, {
          [scheduleTemplateIdField]: removePrefixInArray(ids, scheduleTemplatePrefix),
        });
        const parsedData = PgUnAssoc(get(response, scheduleTemplatePath));
        const dataWithId = setPrefixInArray(
          parsedData,
          scheduleTemplatePrefix,
          scheduleTemplateIdField,
        );
        return { data: dataWithId };
      }
      case UPDATE: {
        const { data } = params as IRAUpdateParams;
        const { id, ...sendData } = data as any;

        const response = await axios.post(`${API_URL}${REST.AdminScheduleTemplate.UPDATE}`, {
          ...sendData,
        });

        const parsedObject = get(response, scheduleTemplatePath);
        return {
          data: {
            ...parsedObject,
            id: setPrefix(scheduleTemplatePrefix, parsedObject[scheduleTemplateIdField]),
          },
        };
      }
      case CREATE: {
        const { data } = params as IRACreateParams;
        const { id, ...sendData } = data as any;

        const response = await axios.post(`${API_URL}${REST.AdminScheduleTemplate.CREATE}`, {
          ...sendData,
        });

        const parsedObject = get(response, scheduleTemplatePath);

        return {
          data: {
            ...parsedObject,
            id: setPrefix(scheduleTemplatePrefix, parsedObject[scheduleTemplateIdField]),
          },
        };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(scheduleTemplatePrefix, id);
        await axios.post(`${API_URL}${REST.AdminScheduleTemplate.DELETE}`, {
          [scheduleTemplateIdField]: [clearId],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, scheduleTemplatePrefix);
        await axios.post(`${API_URL}${REST.AdminScheduleTemplate.DELETE}`, {
          [scheduleTemplateIdField]: clearIds,
        });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${scheduleTemplatePrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода  ${scheduleTemplatePrefix}: ${type}`);
  }
};
