import React, { FC } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin';
import { Card, CardContent, Typography, withStyles } from '@material-ui/core';
import { Resources, referencePassportFieldPrefix } from 'Constants';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = {
  card: { width: '100%', marginBottom: '1.5rem' },
  cardContent: { position: 'relative' },
  list: { width: '100%', display: 'none' },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 1000,
  },
  grid: { display: 'grid', gap: '2rem', gridTemplateColumns: '1fr 1fr' },
};

const PassportFieldElementCreateComponent: FC = ({
  push: propsPush,
  classes = {},
  ...props
}: any) => {
  const innerProps = { ...props };
  const passportFieldString: string = innerProps.history.location.search;
  const passportField = passportFieldString
    ? passportFieldString.replace(`?${referencePassportFieldPrefix}=`, '')
    : null;
  const redirect = passportField
    ? `/${Resources.PASSPORT_FIELD}/${passportField}/1`
    : `/${Resources.PASSPORT_FIELD}`;
  return (
    <Create title="Новый элемент поля паспорта" {...props}>
      <SimpleForm redirect={redirect}>
        {/**
         * Внутренние элементы компонента SimpleForm обернуты во фрагмент
         * Это необходимо для корректного проброса значений полей из location.search
         */}
        <>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <IconButton onClick={() => propsPush(redirect)} className={classes.closeBtn}>
                <CloseIcon />
              </IconButton>
              <ReferenceInput
                source={referencePassportFieldPrefix}
                reference={Resources.PASSPORT_FIELD}
                resource={Resources.PASSPORT_FIELD}
                label="Поле паспорта"
                fullWidth
                required
                disabled
              >
                <SelectInput optionText={record => record.name} />
              </ReferenceInput>
              <TextInput fullWidth required source="value" label="Значение" />
            </CardContent>
          </Card>
        </>
      </SimpleForm>
    </Create>
  );
};

export const connectedPassportFieldElementCreate = connect(null, { push })(
  PassportFieldElementCreateComponent,
);
export const PassportFieldElementCreate = withStyles(styles as any)(
  connectedPassportFieldElementCreate,
);
