/* eslint-disable */
const parseFuncReturnResult = (res, argName = 'pg_get_function_result') => {
  const funcReturnArgsStr = res[0][argName];
  const fields = {};
  if (funcReturnArgsStr.substr(0, 5).toLowerCase() === 'table') {
    const values = funcReturnArgsStr.substr(6, funcReturnArgsStr.length - 7);
    for (const field of S(values).parseCSV()) {
      const splits = field.split(' ');
      fields[splits[0]] = splits.slice(1).join(' ');
    }
    return fields;
  }
  return res[0][argName];
};

const procValue = (type, value) => {
  switch (type) {
    case 'real':
    case 'double precision': {
      return S(value).toFloat();
    }
    case 'smallint':
    case 'bigint':
    case 'integer': {
      return S(value).toInt();
    }
    case 'boolean': {
      if (value === 't') {
        return true;
      }
      return false;
    }
    default: {
      return S(value).toString();
    }
  }
};

const PgAssoc = (schema, func, _s1, _s2, namedObj = false) => {
  const funcReturnValueResult = JSON.parse(_s1);
  const funcResult = JSON.parse(_s2);
  const funcReturnFields = parseFuncReturnResult(funcReturnValueResult);
  if (typeof funcReturnFields === 'object') {
    const procKeys = Object.keys(funcReturnFields);
    const procTypes = Object.values(funcReturnFields);
    return funcResult.reduce((result, item) => {
      const itemArr = S(item[func].substr(1, item[func].length - 2)).parseCSV();
      if (namedObj) {
        return itemArr.reduce((acc, value, i) => {
          acc[procKeys[i]] = procValue(procTypes[i], value);
          return acc;
        }, {});
      }
      return itemArr.reduce(
        (acc, value, i) => {
          acc[1].push(procValue(procTypes[i], value));
          return acc;
        },
        [Array.from(procKeys), []],
      );
    }, []);
  }
  return procValue(funcReturnFields, funcResult[0][func]);
};

export const PgUnAssoc = src => {
  if (src.length === 0) {
    return [];
  }
  const fieldNames = src[0];
  const result = src[1].reduce((srcResult, srcValue) => {
    const item = srcValue.reduce((itemResult, itemValue, itemIndex) => {
      itemResult[fieldNames[itemIndex]] = itemValue;
      return itemResult;
    }, {});

    srcResult.push(item);
    return srcResult;
  }, []);
  return result;
};
