import React, { FC } from 'react';
import { List, Datagrid, TextField, ReferenceField } from 'react-admin';
import {
  Resources,
  objectIdField,
  projectPrefix,
  referenceObjectTypePrefix,
  commonLabel,
  calculationMethodIdField,
} from 'Constants';
import { getMultiLanguageName } from 'Components/utils/getFullName';
import { ObjectFilter } from '../../Common/FiltersComponent/ObjectFilter';
import { PaginationList } from '../../Common/Pagination';

const FullNameField = ({ record = {} }) => record && <span>{getMultiLanguageName(record)}</span>;

export const ObjectList: FC = props => (
  <List
    pagination={<PaginationList />}
    {...props}
    exporter={false}
    title="Список объектов"
    filters={<ObjectFilter />}
  >
    <Datagrid rowClick="edit">
      <TextField source={objectIdField} label="Id" />
      <TextField source="name_ru" label={commonLabel.name_ru} />
      <TextField source="name_en" label={commonLabel.name_en} />
      <ReferenceField
        source={projectPrefix}
        resource={Resources.PROJECTS}
        reference={Resources.PROJECTS}
        label="Проект"
      >
        <FullNameField />
      </ReferenceField>
      <ReferenceField
        source={referenceObjectTypePrefix}
        resource={Resources.REFERENCE_OBJECT_TYPE}
        reference={Resources.REFERENCE_OBJECT_TYPE}
        label="Тип объекта"
        allowEmpty
      >
        <FullNameField />
      </ReferenceField>
      <ReferenceField
        source={calculationMethodIdField}
        resource={Resources.CALCULATION_METHOD}
        reference={Resources.CALCULATION_METHOD}
        label="Метод расчета"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="description_ru" label={commonLabel.description_ru} />
      <TextField source="description_en" label={commonLabel.description_en} />
    </Datagrid>
  </List>
);
