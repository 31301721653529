import axios from 'axios';
import { GET_LIST, GET_ONE, UPDATE, CREATE, DELETE, GET_MANY, DELETE_MANY } from 'react-admin';
import {
  API_URL,
  REST,
  roleIdField,
  roleDataPath,
  rolePrefix,
  rolePermissionIdField,
  rolePermissionDataPath,
  permissionPrefix,
  permissionIdField,
} from 'Constants';
import {
  IRADataProviderParams,
  IRACreateParams,
  IRADeleteParams,
  IRAGetOneParams,
  IRAUpdateParams,
  IRoleUpdateProvider,
  IRAGetManyParams,
  IRoleCreateProvider,
  IRADeleteManyParams,
  IRAGetListParams,
} from 'Interfaces';
import { get } from 'lodash';
import {
  setPrefix,
  setPrefixInArray,
  removePrefix,
  PgUnAssoc,
  removePrefixInArray,
} from '../utils';

export const roleProvider = async (type: string, params: IRADataProviderParams): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        let codePrefix: string;
        if (params) {
          const { filter } = params as IRAGetListParams;
          codePrefix = filter.codePrefix;
        }
        const response = await axios.post(`${API_URL}${REST.AdminRole.LIST}`, {});
        let parsedRoles = PgUnAssoc(get(response, roleDataPath));
        if (codePrefix) {
          parsedRoles = parsedRoles.filter(el => el.alias.split('.')[0] === codePrefix);
        }
        const rolesWithId = setPrefixInArray(parsedRoles, rolePrefix, roleIdField);
        return { data: rolesWithId, total: rolesWithId.length };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(rolePrefix, id);
        const promiseRole = axios.post(`${API_URL}${REST.AdminRole.LIST}`, {
          [roleIdField]: [clearId],
        });
        const promisePermission = axios.post(`${API_URL}${REST.AdminRole.PERMISSION.LIST}`, {
          [roleIdField]: [clearId],
        });
        const [responseRole, responsePermission] = await Promise.all([
          promiseRole,
          promisePermission,
        ]);
        const parsedRole = PgUnAssoc(get(responseRole, roleDataPath))[0];
        const parsedPermission = PgUnAssoc(get(responsePermission, rolePermissionDataPath));
        return {
          data: {
            ...parsedRole,
            id: setPrefix(rolePrefix, parsedRole[roleIdField]),
            [rolePermissionIdField]: parsedPermission.map(el =>
              setPrefix(permissionPrefix, el[permissionIdField]),
            ),
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const clearIds = removePrefixInArray(ids, rolePrefix);
        const response = await axios.post(`${API_URL}${REST.AdminRole.LIST}`, {
          [roleIdField]: clearIds,
        });
        const parsedRoles = PgUnAssoc(get(response, roleDataPath));
        const rolesWithId = setPrefixInArray(parsedRoles, rolePrefix, roleIdField);
        return { data: rolesWithId };
      }
      case UPDATE: {
        const rawData = (params as IRAUpdateParams).data as IRoleUpdateProvider;
        const { id, [rolePermissionIdField]: rolePermission, ...sendData } = rawData;
        const sendPermission = {
          [roleIdField]: sendData[roleIdField],
          [permissionIdField]: removePrefixInArray(rolePermission, permissionPrefix),
          append: false,
        };
        await axios.post(`${API_URL}${REST.AdminRole.PERMISSION.SET}`, sendPermission);
        const response = await axios.post(`${API_URL}${REST.AdminRole.UPDATE}`, sendData);
        const parsedRole = get(response, roleDataPath);
        return {
          data: {
            ...parsedRole,
            id: setPrefix(rolePrefix, parsedRole[roleIdField]),
            [rolePermissionIdField]: rolePermission,
          },
        };
      }
      case CREATE: {
        const rawData = (params as IRACreateParams).data as IRoleCreateProvider;
        const { [rolePermissionIdField]: rolePermission, ...sendData } = rawData;
        const response = await axios.post(`${API_URL}${REST.AdminRole.CREATE}`, sendData);
        const newRole = get(response, roleDataPath);
        const sendPermission = {
          [roleIdField]: newRole[roleIdField],
          [permissionIdField]: removePrefixInArray(rolePermission, permissionPrefix),
          append: false,
        };
        await axios.post(`${API_URL}${REST.AdminRole.PERMISSION.SET}`, sendPermission);
        return {
          data: {
            ...newRole,
            id: setPrefix(rolePrefix, newRole[roleIdField]),
            [rolePermissionIdField]: rolePermission,
          },
        };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(rolePrefix, id);
        await axios.post(`${API_URL}${REST.AdminRole.DELETE}`, { [roleIdField]: [clearId] });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, rolePrefix);
        await axios.post(`${API_URL}${REST.AdminRole.DELETE}`, { [roleIdField]: clearIds });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${rolePrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${rolePrefix}-${type}`);
  }
};
