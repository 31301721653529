import axios from 'axios';
import { GET_LIST, GET_MANY } from 'react-admin';
import { IRADataProviderParams, IRAGetManyParams } from 'Interfaces';
import { API_URL, REST, calculationMethodDataPath, calculationMethodPrefix } from 'Constants';
import { get } from 'lodash';
import { mapArrayToResource } from '../utils';

export const referenceCalculationMethodProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.CalculationMethod.LIST}`,
          {},
        );
        const calculationMethod = get(response, calculationMethodDataPath);
        const parsedCalculationMethod = mapArrayToResource(
          calculationMethod,
          null,
          'CalculationMethod',
        );
        return { data: parsedCalculationMethod, total: parsedCalculationMethod.length };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.CalculationMethod.LIST}`,
          {},
        );
        const calculationMethod = get(response, calculationMethodDataPath);
        const filteredCalculationMethod = calculationMethod.filter(el => ids.includes(el));
        const parsedCalculationMethod = mapArrayToResource(
          filteredCalculationMethod,
          null,
          'CalculationMethod',
        );
        return { data: parsedCalculationMethod };
      }
      default:
        throw new Error(`Неизвестный метод получения ${calculationMethodPrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${calculationMethodPrefix}-${type}`);
  }
};
