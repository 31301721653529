import React, { FC, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

interface ISelectModalProps {
  submit?: (selected: string) => void;
  cancel?: (selected: string) => void;
  show: boolean;
  title?: string;
  body?: string;
  options?: { value: string; label: string }[];
  selected?: string;
}

export const SelectModal: FC<ISelectModalProps> = ({
  submit,
  cancel,
  show,
  title,
  body,
  options,
  selected,
}) => {
  const [innerSelected, setInnerSelected] = useState<string>(selected);

  useEffect(() => {
    setInnerSelected(selected);
  }, [selected]);

  return (
    <Dialog fullWidth open={show} disableBackdropClick disableEscapeKeyDown>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {body}
          <Select
            value={innerSelected}
            onChange={(e): void => {
              setInnerSelected(e.target.value);
            }}
          >
            {options.map(({ value, label }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(): void => submit(innerSelected)}
          variant="contained"
          size="small"
          color="primary"
        >
          Подтвердить
        </Button>
        <Button
          onClick={(): void => cancel(innerSelected)}
          variant="contained"
          size="small"
          color="secondary"
        >
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
};
