import React, { FC } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  BooleanInput,
} from 'react-admin';
import { Card, CardContent, withStyles } from '@material-ui/core';
import { approvalPrefix, approvalStepNextIdField, Resources, rolePrefix } from 'Constants';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = {
  card: {
    width: '100%',
    marginBottom: '1.5rem',
  },
  cardContent: {
    position: 'relative',
  },
  list: {
    width: '100%',
    display: 'none',
  },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 1000,
  },
};

const approvalStepCreateComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => {
  const innerProps = { ...props };
  const approvalString: string = innerProps.history.location.search;
  const approval = approvalString ? approvalString.replace(`?${approvalPrefix}=`, '') : false;
  const redirect = approval ? `/${Resources.APPROVAL}/${approval}/1` : `/${Resources.APPROVAL}`;
  return (
    <Create title="Новый шаг согласования" {...props}>
      <SimpleForm
        redirect={redirect}
        defaultValue={{
          [approvalStepNextIdField]: null,
        }}
      >
        <>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <TextInput fullWidth required source="title" label="Наименование" />
              <ReferenceInput
                source={rolePrefix}
                resource={Resources.ROLE}
                reference={Resources.ROLE}
                label="Роль"
                fullWidth
                required
                filter={{
                  codePrefix: 'ba',
                }}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <NumberInput
                fullWidth
                required
                source="expiration"
                label="Количество дней на согласование"
              />
              <NumberInput
                fullWidth
                required
                source="acceptance_count"
                label="Количество необходимых согласований"
              />
              <ReferenceInput
                fullWidth
                source={approvalStepNextIdField}
                resource={Resources.APPROVAL_STEP}
                reference={Resources.APPROVAL_STEP}
                filter={{ id: approval }}
                label="Название следующего шага согласования"
              >
                <SelectInput optionText={record => record.title} />
              </ReferenceInput>
              <IconButton onClick={() => propsPush(redirect)} className={classes.closeBtn}>
                <CloseIcon />
              </IconButton>
            </CardContent>
          </Card>
        </>
      </SimpleForm>
    </Create>
  );
};

export const connectedApprovalStepCreate = connect(null, { push })(approvalStepCreateComponent);
// eslint-disable-next-line max-len
export const ApprovalStepCreate = withStyles(styles as any)(connectedApprovalStepCreate);
