import React, { FC } from 'react';
import { Create, SimpleForm, TextInput, LongTextInput } from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

const FolderTemplatesCreateComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => (
  <Create title="Новый шаблон структуры папок" {...props}>
    <SimpleForm redirect="list">
      <TextInput fullWidth required source="code" label="Код" />
      <TextInput fullWidth required source="name" label="Название" />
      <LongTextInput fullWidth source="description" label="Описание" defaultValue="" />
    </SimpleForm>
  </Create>
);

export const FolderTemplatesCreate = connect(null, { push })(FolderTemplatesCreateComponent);
