/* eslint-disable @typescript-eslint/camelcase */
import React, { FC } from 'react';
import { List, Datagrid, TextField, BooleanField, ReferenceField } from 'react-admin';
import { categoryIdField, Resources, organisationPrefix } from '../../../Constants';
import { ColorField } from './ColorField';
import { CategoryFilter } from '../../Common';

export const CategoryList: FC = props => (
  <List {...props} exporter={false} title="Список категорий объектов" filters={<CategoryFilter />}>
    <Datagrid rowClick="edit">
      <TextField source={categoryIdField} label="Id" />
      <TextField source="code" label="Код" />
      <TextField source="name_ru" label="Название" />
      <TextField source="name_en" label="Name" />
      <ColorField source="color" label="Цвет" />
      <BooleanField source="is_sub_menu" label="Подменю навигации?" />
      <ReferenceField
        source={organisationPrefix}
        resource={Resources.ORGS}
        reference={Resources.ORGS}
        label="Организация"
      >
        <TextField source="name_ru" />
      </ReferenceField>
    </Datagrid>
  </List>
);
