import React from 'react';

export const Color = ({ value }) => <input type="color" value={value} disabled />;

const categoryColors = [
  '#000000',
  '#ffa399',
  '#f92706',
  '#038247',
  '#22c378',
  '#ffe799',
  '#fece1a',
  '#1f50d8',
  '#4675f5',
  '#747b8b',
  '#97a1b4',
];

export const getCategoryColors = () =>
  categoryColors.map(colorHEX => ({
    id: colorHEX,
    name: <Color value={colorHEX} />,
  }));

export const ColorField = ({ record, source }: any) => {
  const color = `#${record[source]}`;

  return <input type="color" value={color} disabled />;
};
