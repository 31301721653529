/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';
import {
  GET_LIST,
  GET_ONE,
  UPDATE,
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_MANY_REFERENCE,
  GET_MANY,
} from 'react-admin';
import {
  IRADataProviderParams,
  IRAGetOneParams,
  IRACreateParams,
  IRAUpdateParams,
  IRADeleteParams,
  IReferenceJobUpdateProvider,
  IRADeleteManyParams,
  IRAGetListParams,
  IRAGetManyReferenceParams,
  IRAGetManyParams,
} from 'Interfaces';
import {
  API_URL,
  REST,
  referenceJobIdField,
  referenceJobDataPath,
  referenceJobPrefix,
  referenceJobReferenceIdField,
  referenceJobReferencePrefix,
  referenceJobParentIdField,
} from 'Constants';
import { get } from 'lodash';
import { setPrefix, removePrefix, PgUnAssoc, removePrefixInArray } from '../utils';

const hierarchySort = (data, parentId = null, level = 0) => {
  const children = data
    .filter(each => each[referenceJobParentIdField] === parentId)
    .sort((a, b) => a.sort_order - b.sort_order)
    .map(el => ({ ...el, level }));
  const result = [...children];
  children.forEach(child => {
    const currentIndex = result.findIndex(
      el => el[referenceJobIdField] === child[referenceJobIdField],
    );
    result.splice(
      currentIndex + 1,
      0,
      ...hierarchySort(data, child[referenceJobIdField], level + 1),
    );
  });
  return result;
};

export const referenceJobProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const { filter } = params as IRAGetListParams;
        const {
          [referenceJobReferencePrefix]: referenceJobReference,
          [referenceJobParentIdField]: parentId,
          isJob,
        } = filter;
        const requestBody = {
          [referenceJobReferenceIdField]: removePrefix(
            referenceJobReferencePrefix,
            referenceJobReference,
          ),
        };
        const response = await axios.post(`${API_URL}${REST.AdminReference.Job.LIST}`, requestBody);
        const parsedJobs = hierarchySort(PgUnAssoc(get(response, referenceJobDataPath))).filter(
          el => {
            if (parentId !== undefined) {
              return (
                el[referenceJobParentIdField] ===
                (parentId === null ? null : removePrefix(referenceJobPrefix, parentId))
              );
            }
            return true;
          },
        );
        // const filteredJobs = filterResource(parsedJobs, filter);
        /*
        const jobsWithId = setPrefixInArray(filteredJobs, referenceJobPrefix, referenceJobIdField);
        */
        const jobsWithId = parsedJobs
          .map(el => ({
            ...el,
            id: setPrefix(referenceJobPrefix, el[referenceJobIdField]),
            [referenceJobReferencePrefix]: setPrefix(
              referenceJobReferencePrefix,
              el[referenceJobReferenceIdField],
            ),
            [referenceJobParentIdField]: setPrefix(
              referenceJobPrefix,
              el[referenceJobParentIdField],
            ),
          }))
          .filter(({ job }) => job === isJob);

        return { data: jobsWithId, total: jobsWithId.length };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(referenceJobPrefix, id);
        const response = await axios.post(`${API_URL}${REST.AdminReference.Job.LIST}`, {
          [referenceJobIdField]: [clearId],
          children_tree: true,
        });
        const parsedJob = PgUnAssoc(get(response, referenceJobDataPath))[0];
        return {
          data: {
            ...parsedJob,
            id: setPrefix(referenceJobPrefix, parsedJob[referenceJobIdField]),
            [referenceJobReferencePrefix]: setPrefix(
              referenceJobReferencePrefix,
              parsedJob[referenceJobReferenceIdField],
            ),
            [referenceJobParentIdField]: setPrefix(
              referenceJobPrefix,
              parsedJob[referenceJobParentIdField],
            ),
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const clearIds = removePrefixInArray(ids, referenceJobPrefix);
        const response = await axios.post(`${API_URL}${REST.AdminReference.Job.LIST}`, {
          [referenceJobIdField]: clearIds,
        });
        const parsedData = hierarchySort(PgUnAssoc(get(response, referenceJobDataPath)));
        const parsedDataWithId = parsedData.map(el => ({
          ...el,
          id: setPrefix(referenceJobPrefix, el[referenceJobIdField]),
          [referenceJobReferencePrefix]: setPrefix(
            referenceJobReferencePrefix,
            el[referenceJobReferenceIdField],
          ),
          [referenceJobParentIdField]: setPrefix(referenceJobPrefix, el[referenceJobParentIdField]),
        }));
        return { data: parsedDataWithId };
      }
      case GET_MANY_REFERENCE: {
        const { id } = params as IRAGetManyReferenceParams;
        const clearId = removePrefix(referenceJobReferencePrefix, id);
        const response = await axios.post(`${API_URL}${REST.AdminReference.Job.LIST}`, {
          [referenceJobReferenceIdField]: clearId,
          children_tree: true,
        });
        const parsedJob = hierarchySort(PgUnAssoc(get(response, referenceJobDataPath)));
        const jobWithId = parsedJob.map(el => ({
          ...el,
          id: setPrefix(referenceJobPrefix, el[referenceJobIdField]),
          [referenceJobReferencePrefix]: setPrefix(
            referenceJobReferencePrefix,
            el[referenceJobReferenceIdField],
          ),
          [referenceJobParentIdField]: setPrefix(referenceJobPrefix, el[referenceJobParentIdField]),
        }));
        return {
          data: jobWithId,
          total: jobWithId.length,
        };
      }
      case UPDATE: {
        const rawData = (params as IRAUpdateParams).data as IReferenceJobUpdateProvider;
        const { id, ...sendData } = rawData;
        try {
          const response = await axios.post(`${API_URL}${REST.AdminReference.Job.UPDATE}`, {
            ...sendData,
            [referenceJobReferenceIdField]: removePrefix(
              referenceJobReferencePrefix,
              sendData[referenceJobReferencePrefix],
            ),
          });

          const parsedJob = get(response, referenceJobDataPath);

          await axios.post(`${API_URL}${REST.AdminReference.Job.MOVE}`, {
            [referenceJobIdField]: parsedJob[referenceJobIdField],
            [referenceJobParentIdField]: removePrefix(
              referenceJobPrefix,
              sendData[referenceJobParentIdField],
            ),
          });

          return {
            data: {
              ...parsedJob,
              id: setPrefix(referenceJobPrefix, parsedJob[referenceJobIdField]),
            },
          };
        } catch (error) {
          throw new Error(`${error.response.data.error.message}`);
        }
      }
      case CREATE: {
        const { data } = params as IRACreateParams;
        try {
          const sendData = {
            ...data,
            [referenceJobReferenceIdField]: removePrefix(
              referenceJobReferencePrefix,
              data[referenceJobReferencePrefix],
            ),
            [referenceJobParentIdField]: removePrefix(
              referenceJobPrefix,
              data[referenceJobParentIdField],
            ),
          };
          const response = await axios.post(
            `${API_URL}${REST.AdminReference.Job.CREATE}`,
            sendData,
          );
          const parsedReferenceJob = get(response, referenceJobDataPath);
          return {
            data: {
              ...parsedReferenceJob,
              id: setPrefix(referenceJobPrefix, parsedReferenceJob[referenceJobIdField]),
            },
          };
        } catch (error) {
          throw new Error(`${error.response.data.error.message}`);
        }
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(referenceJobPrefix, id);
        await axios.post(`${API_URL}${REST.AdminReference.Job.DELETE}`, {
          [referenceJobIdField]: [clearId],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, referenceJobPrefix);
        await axios.post(`${API_URL}${REST.AdminReference.Job.DELETE}`, {
          [referenceJobIdField]: clearIds,
        });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${referenceJobPrefix}: ${type}`);
    }
  } catch (error) {
    switch (error.message) {
      case 'job_parent_is_job':
        throw new Error('Невозможно назначить родительский элемент, который является работой');
      default:
        throw new Error(`Ошибка метода ${referenceJobPrefix}-${type}`);
    }
  }
};
