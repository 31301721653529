import React from 'react';
import { Toolbar, SaveButton, DeleteButton } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import { Route } from 'react-router-dom';

interface ICustomToolbarStyle {
  wrapper: {};
}

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
};

const CustomToolbarComponent = ({ classes, ...props }): JSX.Element => {
  const { basePath } = props;
  return (
    <Toolbar {...props}>
      <Route
        exact
        path={`${basePath}/:id`}
        render={(): JSX.Element => (
          <div className={classes.wrapper}>
            <SaveButton redirect="list" {...props} />
            <DeleteButton redirect="list" {...props} />
          </div>
        )}
      />
      <Route exact path={`${basePath}/:id/:tabIndex`} render={null} />
    </Toolbar>
  );
};

export const CustomToolbar = withStyles(styles as ICustomToolbarStyle)(CustomToolbarComponent);
