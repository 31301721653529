import axios from 'axios';
import { GET_LIST, GET_ONE, DELETE, DELETE_MANY, UPDATE, CREATE, GET_MANY } from 'react-admin';
import { get } from 'lodash';
import {
  IRADataProviderParams,
  IRAGetOneParams,
  IRADeleteParams,
  IRADeleteManyParams,
  IRAUpdateParams,
  IRACreateParams,
  IRAGetManyParams,
} from 'Interfaces';
import {
  API_URL,
  REST,
  referenceCurrencyPath,
  referenceCurrencyPrefix,
  referenceCurrencyIdField,
} from 'Constants';
import {
  setPrefixInArray,
  setPrefix,
  removePrefix,
  removePrefixInArray,
  PgUnAssoc,
} from 'Providers/utils';

export const referenceCurrencyProvider = async (
  type: string,
  params: IRADataProviderParams,
  // eslint-disable-next-line consistent-return
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(`${API_URL}${REST.AdminReference.Currency.LIST}`, {});
        const parsedData = PgUnAssoc(get(response, referenceCurrencyPath));
        const parsedDataWithId = setPrefixInArray(
          parsedData,
          referenceCurrencyPrefix,
          referenceCurrencyIdField,
        );
        return {
          data: parsedDataWithId,
          total: parsedDataWithId.length,
        };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(referenceCurrencyPrefix, id);
        // eslint-disable-next-line max-len
        const response = await axios.post(`${API_URL}${REST.AdminReference.Currency.LIST}`, {
          [referenceCurrencyIdField]: [clearId],
        });
        const parsedData = PgUnAssoc(get(response, referenceCurrencyPath))[0];

        return {
          data: {
            ...parsedData,
            id: setPrefix(referenceCurrencyPrefix, parsedData[referenceCurrencyIdField]),
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const response = await axios.post(`${API_URL}${REST.AdminReference.Currency.LIST}`, {
          [referenceCurrencyIdField]: removePrefixInArray(ids, referenceCurrencyPrefix),
        });

        const parsedData = PgUnAssoc(get(response, referenceCurrencyPath));
        const dataWithId = setPrefixInArray(
          parsedData,
          referenceCurrencyPrefix,
          referenceCurrencyIdField,
        );
        return { data: dataWithId };
      }
      case UPDATE: {
        const { data } = params as IRAUpdateParams;
        const { id, ...sendData } = data as any;

        // eslint-disable-next-line max-len
        const response = await axios.post(`${API_URL}${REST.AdminReference.Currency.UPDATE}`, {
          ...sendData,
        });
        const parsedData = get(response, referenceCurrencyPath);
        return {
          data: {
            ...parsedData,
            id: setPrefix(referenceCurrencyPrefix, parsedData[referenceCurrencyIdField]),
          },
        };
      }
      case CREATE: {
        const { data } = params as IRACreateParams;
        const { id, ...sendData } = data as any;

        // eslint-disable-next-line max-len
        const response = await axios.post(`${API_URL}${REST.AdminReference.Currency.CREATE}`, {
          ...sendData,
        });
        const parsedData = get(response, referenceCurrencyPath);

        return {
          data: {
            ...parsedData,
            id: setPrefix(referenceCurrencyPrefix, parsedData[referenceCurrencyIdField]),
          },
        };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(referenceCurrencyPrefix, id);
        await axios.post(`${API_URL}${REST.AdminReference.Currency.DELETE}`, {
          [referenceCurrencyIdField]: [clearId],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, referenceCurrencyPrefix);
        await axios.post(`${API_URL}${REST.AdminReference.Currency.DELETE}`, {
          [referenceCurrencyIdField]: clearIds,
        });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${referenceCurrencyPrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода  ${referenceCurrencyPrefix}: ${type}`);
  }
};
