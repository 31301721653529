import React, { FC } from 'react';
import { List, Datagrid, TextField } from 'react-admin';

export const CostPlanFactSourceList: FC = props => (
  <List {...props} exporter={false} title="Справочник источников данных" bulkActionButtons={false}>
    <Datagrid>
      <TextField source="code" label="Код" />
      <TextField source="name" label="Название" />
    </Datagrid>
  </List>
);
