import React, { FC } from 'react';
import { List, Datagrid, TextField } from 'react-admin';

export const ResourceFactMethodList: FC = props => (
  <List
    {...props}
    exporter={false}
    title="Справочник методов ввода факта по ресурсам"
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="code" label="Код" />
      <TextField source="name" label="Название" />
    </Datagrid>
  </List>
);
