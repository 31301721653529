import React, { FC } from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { Card, CardContent } from '@material-ui/core';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Resources } from 'Constants';

const styles = {
  card: { width: '100%', marginBottom: '1.5rem', marginTop: '1.5rem' },
  cardContent: { position: 'relative' },
  list: { width: '100%', display: 'none' },
  closeBtn: {
    position: 'absolute',
    right: '0',
    top: '0',
    zIndex: 1000,
  },
};

const ObjectLifeCycleStageCreateComponent: FC = ({
  push: propsPush,
  classes = {},
  ...props
}: any) => (
  <Create title="Новый этап жизненного цикла объекта" {...props}>
    <SimpleForm redirect="list">
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <IconButton
            onClick={() => propsPush(`/${Resources.REFERENCE_OBJECT_LIFE_CYCLE_STAGE}`)}
            className={classes.closeBtn}
          >
            <CloseIcon />
          </IconButton>
          <TextInput
            fullWidth
            required
            source="code"
            label="Код"
            helperText="Принимаются только латинские символы, цифры и символ _"
          />
          <TextInput fullWidth required source="name_ru" label="Название" />
          <TextInput fullWidth required source="name_en" label="Name" />
        </CardContent>
      </Card>
    </SimpleForm>
  </Create>
);

const connectedObjectLifeCycleStageCreate = connect(null, { push })(
  ObjectLifeCycleStageCreateComponent,
);

// eslint-disable-next-line max-len
export const ObjectLifeCycleStageCreate = withStyles(styles as any)(
  connectedObjectLifeCycleStageCreate,
);
