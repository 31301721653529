import axios from 'axios';
import {
  GET_LIST,
  GET_ONE,
  UPDATE,
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_MANY_REFERENCE,
} from 'react-admin';
import {
  IRADataProviderParams,
  IRAGetOneParams,
  IRACreateParams,
  IRAUpdateParams,
  IRADeleteParams,
  IReferenceElementUpdateProvider,
  IReferenceElementListResp,
  IReferenceElementCreateProvider,
  IRADeleteManyParams,
  IRAGetListParams,
  IRAGetManyReferenceParams,
} from 'Interfaces';
import {
  API_URL,
  REST,
  referenceElementIdField,
  referenceElementDataPath,
  referenceElementPrefix,
  referenceGroupUnitIdField,
  referenceGroupUnitPrefix,
  referenceElementReferencePrefix,
  referenceElementReferenceIdField,
} from 'Constants';
import { get } from 'lodash';
import { setPrefix, removePrefix, PgUnAssoc, removePrefixInArray, filterResource } from '../utils';

export const referenceElementProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const { filter } = params as IRAGetListParams;
        const response = await axios.post(`${API_URL}${REST.AdminReference.Element.LIST}`, {});
        const parsedElement = PgUnAssoc(get(response, referenceElementDataPath));
        const filteredElement = filterResource(parsedElement, filter);
        const elementWithId = filteredElement.map((el: IReferenceElementListResp) => ({
          ...el,
          id: setPrefix(referenceElementPrefix, el[referenceElementIdField]),
          [referenceGroupUnitPrefix]: setPrefix(
            referenceGroupUnitPrefix,
            el[referenceGroupUnitIdField],
          ),
          [referenceElementReferencePrefix]: setPrefix(
            referenceElementReferencePrefix,
            el[referenceElementReferenceIdField],
          ),
        }));
        return { data: elementWithId, total: elementWithId.length };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(referenceElementPrefix, id);
        const response = await axios.post(`${API_URL}${REST.AdminReference.Element.LIST}`, {
          [referenceElementIdField]: [clearId],
        });
        const parsedElement = PgUnAssoc(get(response, referenceElementDataPath))[0];
        return {
          data: {
            ...parsedElement,
            id: setPrefix(referenceElementPrefix, parsedElement[referenceElementIdField]),
            [referenceGroupUnitPrefix]: setPrefix(
              referenceGroupUnitPrefix,
              parsedElement[referenceGroupUnitIdField],
            ),
            [referenceElementReferencePrefix]: setPrefix(
              referenceElementReferencePrefix,
              parsedElement[referenceElementReferenceIdField],
            ),
          },
        };
      }
      case GET_MANY_REFERENCE: {
        const { id } = params as IRAGetManyReferenceParams;
        const clearId = removePrefix(referenceElementReferencePrefix, id);
        const response = await axios.post(`${API_URL}${REST.AdminReference.Element.LIST}`, {
          [referenceElementReferenceIdField]: [clearId],
        });
        const parsedSchedule = PgUnAssoc(get(response, referenceElementDataPath));
        const scheduleWithId = parsedSchedule.map(el => ({
          ...el,
          id: setPrefix(referenceElementPrefix, el[referenceElementIdField]),
        }));
        return {
          data: scheduleWithId,
          total: scheduleWithId.length,
        };
      }
      case UPDATE: {
        const rawData = (params as IRAUpdateParams).data as IReferenceElementUpdateProvider;
        const { id, [referenceGroupUnitIdField]: groupId, ...others } = rawData;
        const sendData = { ...others, [referenceGroupUnitIdField]: groupId };
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.Element.UPDATE}`,
          sendData,
        );
        const parsedElement = get(response, referenceElementDataPath);
        return {
          data: {
            ...parsedElement,
            id: setPrefix(referenceElementPrefix, parsedElement[referenceElementIdField]),
            [referenceGroupUnitPrefix]: setPrefix(
              referenceGroupUnitPrefix,
              parsedElement[referenceGroupUnitIdField],
            ),
          },
        };
      }
      case CREATE: {
        const rawData = (params as IRACreateParams).data as IReferenceElementCreateProvider;
        const {
          [referenceGroupUnitIdField]: groupId,
          [referenceElementReferencePrefix]: elementReferenceId,
          ...other
        } = rawData;
        const clearElementReferenceId = removePrefix(
          referenceElementReferencePrefix,
          elementReferenceId,
        );
        const sendData = {
          ...other,
          [referenceGroupUnitIdField]: groupId,
          [referenceElementReferenceIdField]: clearElementReferenceId,
        };
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.Element.CREATE}`,
          sendData,
        );
        const parsedElement = get(response, referenceElementDataPath);
        return {
          data: {
            ...parsedElement,
            id: setPrefix(referenceElementPrefix, parsedElement[referenceElementIdField]),
            [referenceGroupUnitPrefix]: setPrefix(
              referenceGroupUnitPrefix,
              parsedElement[referenceGroupUnitIdField],
            ),
          },
        };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(referenceElementPrefix, id);
        await axios.post(`${API_URL}${REST.AdminReference.Element.DELETE}`, {
          [referenceElementIdField]: [clearId],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, referenceElementPrefix);
        await axios.post(`${API_URL}${REST.AdminReference.Element.DELETE}`, {
          [referenceElementIdField]: clearIds,
        });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${referenceElementPrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${referenceElementPrefix}-${type}`);
  }
};
