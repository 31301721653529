import React, { FC } from 'react';
import { List, Datagrid, TextField } from 'react-admin';

export const CalculationMethodList: FC = props => (
  <List {...props} exporter={false} title="Справочник методов расчета" bulkActionButtons={false}>
    <Datagrid>
      <TextField source="name" label="Название" />
      <TextField source="code" label="Код" />
    </Datagrid>
  </List>
);
