/* eslint-disable @typescript-eslint/camelcase */

import { get } from 'lodash';
import {
  referenceProjectTypeTemplate,
  availableLanguages,
  referencePassportFieldIdField,
  referencePassportFieldPrefix,
} from 'Constants';
import { removePrefix, setPrefix } from './idPrefixes';

export enum ITemplateFormName {
  fields = 'fields',
  widgets = 'widgets',
  groups = 'groups',
  attributes = 'attributes',
  passportAttributes = 'passportAttributes',
}

export enum ITemplateFieldName {
  fieldKey = 'fieldKey',
  fieldPrevKey = 'fieldPrevKey',
  fieldLabel = 'fieldLabel',
  fieldType = 'fieldType',
  fieldRef = 'fieldRef',
  fieldPrivate = 'fieldPrivate', // Поле приватности паспортных данных
  fieldRequired = 'fieldRequired',
  fieldFile = 'fieldFile',
  fieldDateAssignmentFlag = 'fieldDateAssignmentFlag',
  fieldHideInModelObject = 'fieldHideInModelObject',
  fieldHideInReports = 'fieldHideInReports',
  saved = 'saved',
  fieldIsSpecial = 'fieldIsSpecial',
  readOnly = 'readOnly',
  passportFieldReferenceId = 'passport_field_reference_id',
}

export const templateFieldType = {
  text: { type: 'text', label: 'text' },
  link: { type: 'link', label: 'link' },
  number: { type: 'number', label: 'number' },
  targetRef: { type: 'target_ref', label: 'targetRef' },
  date_number: { type: 'date_number', label: 'date_number' },
  date: { type: 'date', label: 'date' },
  reference: { type: 'reference', label: 'reference' },
};

export const templateAttrFieldType = {
  text: { type: 'text', label: 'text' },
  number: { type: 'number', label: 'number' },
  date: { type: 'date', label: 'date' },
  price: { type: 'price', label: 'price' },
  link: { type: 'link', label: 'link' },
  targetRef: { type: 'target_ref', label: 'targetRef' },
};

export const templateDateAssignmentFlag = {
  min: { type: 'min', label: 'min' },
  max: { type: 'max', label: 'max' },
};

export const templateFileFieldType = {
  text: { type: 'text', label: 'text' },
  textarea: { type: 'textarea', label: 'textarea' },
  date: { type: 'date', label: 'date' },
  number: { type: 'number', label: 'number' },
  link: { type: 'link', label: 'link' },
  boolean: { type: 'boolean', label: 'boolean' },
  title: { type: 'title', label: 'title' },
  subtitle: { type: 'subtitle', label: 'subtitle' },
};

export const videoLinkFieldType = {
  link: { type: 'link', label: 'link' },
  stream: { type: 'stream', label: 'stream' },
};

export interface ITemplateField {
  fieldKey: string;
  fieldLabel_ru: string; // TODO: npo magic string
  fieldLabel_en: string; // TODO: npo magic string
  fieldType: any;
  fieldPrivate: any;
}

export enum ITemplateGroupFieldName {
  groupKey = 'groupKey',
  groupPrevKey = 'groupPrevKey',
  groupLabel = 'groupLabel',
  groupFields = 'groupFields',
  groupOrder = 'order',
}

export interface ITemplateGroup {
  groupKey: string;
  groupLabel: string;
  groupFields: string[];
}

export enum ITemplateWidgetName {
  mainWidget = 'mainWidget',
  listWidget = 'listWidget',
  guntWidget = 'guntWidget',
  utilizationOfFundsWidget = 'utilizationOfFundsWidget',
}

export enum ITemplateWidgetFieldName {
  widgetKey = 'widgetKey',
  widgetPrevKey = 'widgetPrevKey',
  widgetFields = 'widgetFields',
}

export interface ITemplateWidget {
  widgetKey: ITemplateWidgetName[];
  widgetFields: string[];
}

export interface ITemplateFieldResponse {
  fields: ITemplateField[];
  widgets: ITemplateWidget[];
  groups: ITemplateGroup[];
}

export interface IMappedTemplate {
  [ITemplateFieldName.fieldKey]: string;
  [ITemplateFieldName.fieldPrevKey]: string;
  [ITemplateFieldName.fieldLabel]: string;
  [ITemplateFieldName.fieldType]: string;
  [ITemplateFieldName.fieldPrivate]: string;
}

const removeUnPossibleFields = (allFields: string[], possibleFields: string[]): string[] =>
  allFields.map(el => String(el)).filter(el => possibleFields.includes(el));

const getAllTemplates = item => {
  const allTemplates = new Map([]);
  availableLanguages.forEach(language =>
    allTemplates.set(language, item[`${referenceProjectTypeTemplate}_${language}`] || {}),
  );
  return allTemplates;
};

const checkPrevKey = template => {
  const checkedTemplate = Object.entries(template).reduce((acc, item: any) => {
    const [key, value] = item;
    const keyType: string = key.slice(0, -1);

    return {
      ...acc,
      [key]: value.map((field: any) => {
        const {
          [`${keyType}PrevKey`]: fieldPrevKey,
          [`${keyType}Key`]: fieldKey,
          ...restFields
        } = field;

        return {
          ...restFields,
          [`${keyType}Key`]: fieldKey,
          ...(fieldPrevKey && fieldKey !== fieldPrevKey && { [`${keyType}PrevKey`]: fieldPrevKey }),
        };
      }),
    };
  }, {});

  return checkedTemplate;
};

const uniteAllFields = allTemplates => {
  // TODO-08: попробовать избавиться от привязки к русскому шаблону
  const fieldsRu = get(allTemplates.get(availableLanguages[0]), ITemplateFormName.fields, []);
  const templatesAsArray = [...allTemplates.entries()];
  const fields = fieldsRu.map(field => {
    const { [ITemplateFieldName.fieldLabel]: unUsed, ...resultObj } = field;
    templatesAsArray.forEach(template => {
      const templateSuffix = template[0];
      const currentKey = get(resultObj, ITemplateFieldName.fieldKey);
      const templateFields = get(template, `[1].${ITemplateFormName.fields}`, []);
      templateFields.forEach(templateField => {
        if (templateField[ITemplateFieldName.fieldKey] === currentKey) {
          resultObj[`${ITemplateFieldName.fieldLabel}_${templateSuffix}`] =
            templateField[ITemplateFieldName.fieldLabel];
        }
      });
    });
    return resultObj;
  });
  return fields;
};

const uniteAllGroups = allTemplates => {
  // TODO-08: попробовать избавиться от привязки к русскому шаблону
  const groupsRu = get(allTemplates.get(availableLanguages[0]), ITemplateFormName.groups, []);
  const templatesAsArray = [...allTemplates.entries()];
  const groups = groupsRu.map(group => {
    const { [ITemplateGroupFieldName.groupLabel]: unUsed, ...resultObj } = group;
    templatesAsArray.forEach(template => {
      const templateSuffix = template[0];
      const currentKey = get(resultObj, ITemplateGroupFieldName.groupKey);
      const templateGroups = get(template, `[1].${ITemplateFormName.groups}`, []);
      templateGroups.forEach(templateGroup => {
        if (templateGroup[ITemplateGroupFieldName.groupKey] === currentKey) {
          resultObj[`${ITemplateGroupFieldName.groupLabel}_${templateSuffix}`] =
            templateGroup[ITemplateGroupFieldName.groupLabel];
        }
      });
    });
    return resultObj;
  });
  return groups;
};

export const mapTemplateToArray = (item: any): any => {
  // TODO: set types
  const allTemplates = getAllTemplates(item);
  const fields = uniteAllFields(allTemplates);
  const newFields = fields.map(el => {
    if (el.fieldType === 'target_ref') {
      availableLanguages.forEach(language => {
        const currentLanguageLabel = el[`${ITemplateFieldName.fieldLabel}_${language}`];
        const fieldLabel = get(currentLanguageLabel, '[0]', '');
        const fieldRef = get(currentLanguageLabel, '[1]', '');
        /* eslint-disable-next-line no-param-reassign */
        el[`${ITemplateFieldName.fieldLabel}_${language}`] = fieldLabel;
        if (language === availableLanguages[0]) {
          /* eslint-disable-next-line no-param-reassign */
          el.fieldRef = fieldRef;
        }
      });
    }
    if (el.fieldType === 'reference') {
      const { [referencePassportFieldIdField]: passportFieldId, ...rest } = el;
      const elWithId = {
        ...rest,
        [referencePassportFieldIdField]: setPrefix(referencePassportFieldPrefix, passportFieldId),
      };
      return elWithId;
    }
    return el;
  });
  const groups = uniteAllGroups(allTemplates);
  return {
    ...item,
    [ITemplateFormName.fields]: newFields,
    [ITemplateFormName.groups]: groups.map((el, i) => ({
      ...el,
      [ITemplateGroupFieldName.groupOrder]: i + 1,
    })),
    // TODO-08: no magic strings
    [ITemplateFormName.widgets]: get(item, `${referenceProjectTypeTemplate}_ru.widgets`, []),
  };
};

export const mapTemplateFromArray = (rawData: any): any => {
  // TODO: set types
  const fields = get(rawData, ITemplateFormName.fields, []);
  const groups = get(rawData, ITemplateFormName.groups, []);
  const widgets = get(rawData, ITemplateFormName.widgets, []);
  const fieldsKeys = fields.map((el: ITemplateFieldName) => el[ITemplateFieldName.fieldKey]);

  const newFields = fields.map(el => {
    if (el.fieldType === 'target_ref') {
      const { fieldRef, ...rest } = el;
      const newField = { ...rest };
      availableLanguages.forEach(language => {
        newField[`${ITemplateFieldName.fieldLabel}_${language}`] = [
          el[`${ITemplateFieldName.fieldLabel}_${language}`],
          fieldRef,
        ];
      });
      return newField;
    }
    if (el.fieldType === 'reference') {
      const { [referencePassportFieldIdField]: passportFieldId, ...rest } = el;
      const clearEl = {
        ...rest,
        [referencePassportFieldIdField]: removePrefix(
          referencePassportFieldPrefix,
          passportFieldId,
        ),
      };
      return clearEl;
    }
    const { fieldRef, ...newEl } = el;
    return newEl;
  });

  const newGroups = groups
    .sort(
      (a, b) =>
        Number(a[ITemplateGroupFieldName.groupOrder]) -
        Number(b[ITemplateGroupFieldName.groupOrder]),
    )
    .map(({ [ITemplateGroupFieldName.groupOrder]: order, ...el }) => ({
      ...el,
      [ITemplateGroupFieldName.groupFields]: removeUnPossibleFields(
        get(el, ITemplateGroupFieldName.groupFields, []),
        fieldsKeys,
      ),
    }));

  const newWidgets = widgets.map(el => ({
    ...el,
    [ITemplateWidgetFieldName.widgetFields]: removeUnPossibleFields(
      get(el, ITemplateWidgetFieldName.widgetFields, []),
      fieldsKeys,
    ),
  }));

  // TODO-08: переделать в mapping по availableLanguages
  const newFieldsRu = newFields.map(field => ({
    [ITemplateFieldName.fieldKey]: field[ITemplateFieldName.fieldKey],
    [ITemplateFieldName.fieldPrevKey]: field[ITemplateFieldName.fieldPrevKey],
    [ITemplateFieldName.fieldType]: field[ITemplateFieldName.fieldType],
    [ITemplateFieldName.fieldPrivate]: field[ITemplateFieldName.fieldPrivate],
    [ITemplateFieldName.fieldIsSpecial]: field[ITemplateFieldName.fieldIsSpecial],
    [ITemplateFieldName.readOnly]: field[ITemplateFieldName.readOnly],
    [ITemplateFieldName.passportFieldReferenceId]:
      field[ITemplateFieldName.passportFieldReferenceId],
    [ITemplateFieldName.fieldLabel]: field[`${ITemplateFieldName.fieldLabel}_ru`],
  }));
  const newFieldsEn = newFields.map(field => ({
    [ITemplateFieldName.fieldKey]: field[ITemplateFieldName.fieldKey],
    [ITemplateFieldName.fieldPrevKey]: field[ITemplateFieldName.fieldPrevKey],
    [ITemplateFieldName.fieldType]: field[ITemplateFieldName.fieldType],
    [ITemplateFieldName.fieldPrivate]: field[ITemplateFieldName.fieldPrivate],
    [ITemplateFieldName.fieldIsSpecial]: field[ITemplateFieldName.fieldIsSpecial],
    [ITemplateFieldName.readOnly]: field[ITemplateFieldName.readOnly],
    [ITemplateFieldName.passportFieldReferenceId]:
      field[ITemplateFieldName.passportFieldReferenceId],
    [ITemplateFieldName.fieldLabel]: field[`${ITemplateFieldName.fieldLabel}_en`],
  }));
  const newGroupsRu = newGroups.map(group => ({
    [ITemplateGroupFieldName.groupFields]: group[ITemplateGroupFieldName.groupFields],
    [ITemplateGroupFieldName.groupKey]: group[ITemplateGroupFieldName.groupKey],
    [ITemplateGroupFieldName.groupLabel]: group[`${ITemplateGroupFieldName.groupLabel}_ru`],
  }));
  const newGroupsEn = newGroups.map(group => ({
    [ITemplateGroupFieldName.groupFields]: group[ITemplateGroupFieldName.groupFields],
    [ITemplateGroupFieldName.groupKey]: group[ITemplateGroupFieldName.groupKey],
    [ITemplateGroupFieldName.groupLabel]: group[`${ITemplateGroupFieldName.groupLabel}_en`],
  }));
  /* eslint-disable-next-line */
  const passport_template_ru = checkPrevKey({
    [ITemplateFormName.fields]: newFieldsRu,
    [ITemplateFormName.groups]: newGroupsRu,
    [ITemplateFormName.widgets]: newWidgets,
  });
  /* eslint-disable-next-line */
  const passport_template_en = checkPrevKey({
    [ITemplateFormName.fields]: newFieldsEn,
    [ITemplateFormName.groups]: newGroupsEn,
    [ITemplateFormName.widgets]: newWidgets,
  });
  // TODO-08: переделать в mapping по availableLanguages
  return { passport_template_ru, passport_template_en };
};
