import axios from 'axios';
import {
  GET_LIST,
  GET_ONE,
  DELETE,
  DELETE_MANY,
  UPDATE,
  CREATE,
  GET_MANY,
  GET_MANY_REFERENCE,
} from 'react-admin';
import { get } from 'lodash';
import {
  IRADataProviderParams,
  IRAGetOneParams,
  IRADeleteParams,
  IRADeleteManyParams,
  IRAUpdateParams,
  IRACreateParams,
  IRAGetManyParams,
  IRAGetManyReferenceParams,
} from 'Interfaces';
import {
  API_URL,
  REST,
  scheduleTemplatePath,
  scheduleTemplateIdField,
  scheduleTemplatePrefix,
  referenceObjectLifeCycleStagePath,
  referenceObjectLifeCycleStagePrefix,
  referenceObjectLifeCycleStageIdField,
} from 'Constants';
import {
  setPrefixInArray,
  setPrefix,
  removePrefix,
  removePrefixInArray,
  PgUnAssoc,
} from 'Providers/utils';

export const referenceObjectLifeCycleStageProvider = async (
  type: string,
  params: IRADataProviderParams,
  // eslint-disable-next-line consistent-return
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.ObjectLifeCycleStage.LIST}`,
          {},
        );
        const parsedData = PgUnAssoc(get(response, referenceObjectLifeCycleStagePath));
        const parsedDataWithId = setPrefixInArray(
          parsedData,
          referenceObjectLifeCycleStagePrefix,
          referenceObjectLifeCycleStageIdField,
        );
        return {
          data: parsedDataWithId,
          total: parsedDataWithId.length,
        };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(referenceObjectLifeCycleStagePrefix, id);
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.ObjectLifeCycleStage.LIST}`,
          {
            [referenceObjectLifeCycleStageIdField]: [clearId],
          },
        );
        const parsedData = PgUnAssoc(get(response, referenceObjectLifeCycleStagePath))[0];

        return {
          data: {
            ...parsedData,
            id: setPrefix(
              referenceObjectLifeCycleStagePrefix,
              parsedData[referenceObjectLifeCycleStageIdField],
            ),
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.ObjectLifeCycleStage.LIST}`,
          {
            [referenceObjectLifeCycleStageIdField]: removePrefixInArray(
              ids,
              referenceObjectLifeCycleStagePrefix,
            ),
          },
        );
        const parsedData = PgUnAssoc(get(response, referenceObjectLifeCycleStagePath));
        const dataWithId = setPrefixInArray(
          parsedData,
          referenceObjectLifeCycleStagePrefix,
          referenceObjectLifeCycleStageIdField,
        );
        return { data: dataWithId };
      }
      case UPDATE: {
        const { data } = params as IRAUpdateParams;
        const { id, ...sendData } = data as any;

        const response = await axios.post(
          `${API_URL}${REST.AdminReference.ObjectLifeCycleStage.UPDATE}`,
          { ...sendData },
        );
        const parsedData = get(response, referenceObjectLifeCycleStagePath);
        return {
          data: {
            ...parsedData,
            id: setPrefix(
              referenceObjectLifeCycleStagePrefix,
              parsedData[referenceObjectLifeCycleStageIdField],
            ),
          },
        };
      }
      case CREATE: {
        const { data } = params as IRACreateParams;
        const { id, ...sendData } = data as any;

        const response = await axios.post(
          `${API_URL}${REST.AdminReference.ObjectLifeCycleStage.CREATE}`,
          { ...sendData },
        );
        const parsedData = get(response, referenceObjectLifeCycleStagePath);

        return {
          data: {
            ...parsedData,
            id: setPrefix(
              referenceObjectLifeCycleStagePrefix,
              parsedData[referenceObjectLifeCycleStageIdField],
            ),
          },
        };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(referenceObjectLifeCycleStagePrefix, id);
        await axios.post(`${API_URL}${REST.AdminReference.ObjectLifeCycleStage.DELETE}`, {
          [referenceObjectLifeCycleStageIdField]: [clearId],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, referenceObjectLifeCycleStagePrefix);
        await axios.post(`${API_URL}${REST.AdminReference.ObjectLifeCycleStage.DELETE}`, {
          [referenceObjectLifeCycleStageIdField]: clearIds,
        });
        return { data: ids };
      }
      default:
        throw new Error(
          `Неизвестный метод получения ${referenceObjectLifeCycleStagePrefix}: ${type}`,
        );
    }
  } catch (error) {
    throw new Error(`Ошибка метода  ${referenceObjectLifeCycleStagePrefix}: ${type}`);
  }
};
