import axios from 'axios';
import { GET_LIST, GET_ONE, CREATE, DELETE, DELETE_MANY, UPDATE, GET_MANY } from 'react-admin';
import {
  IRACreateParams,
  IRADataProviderParams,
  IRADeleteManyParams,
  IRADeleteParams,
  IRAGetManyParams,
  IRAGetOneParams,
  IRAUpdateParams,
} from 'Interfaces';
import {
  API_URL,
  REST,
  referencePassportFieldIdField,
  referencePassportFieldDataPath,
  referencePassportFieldPrefix,
} from 'Constants';
import { get } from 'lodash';
import {
  PgUnAssoc,
  removePrefix,
  removePrefixInArray,
  setPrefix,
  setPrefixInArray,
} from '../utils';

export const referencePassportFieldProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.PassportField.LIST}`,
          {},
        );
        const data = PgUnAssoc(get(response, referencePassportFieldDataPath));
        const dataWithId = setPrefixInArray(
          data,
          referencePassportFieldPrefix,
          referencePassportFieldIdField,
        );
        return { data: dataWithId, total: dataWithId.length };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const crearId = removePrefix(referencePassportFieldPrefix, id);
        const response = await axios.post(`${API_URL}${REST.AdminReference.PassportField.LIST}`, {
          [referencePassportFieldIdField]: [crearId],
        });
        const parsedData = PgUnAssoc(get(response, referencePassportFieldDataPath))[0];
        return {
          data: {
            ...parsedData,
            id: setPrefix(referencePassportFieldPrefix, parsedData[referencePassportFieldIdField]),
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const response = await axios.post(`${API_URL}${REST.AdminReference.PassportField.LIST}`, {
          [referencePassportFieldIdField]: removePrefixInArray(ids, referencePassportFieldPrefix),
        });
        const parsedData = PgUnAssoc(get(response, referencePassportFieldDataPath));
        const dataWithId = setPrefixInArray(
          parsedData,
          referencePassportFieldPrefix,
          referencePassportFieldIdField,
        );
        return { data: dataWithId };
      }
      case UPDATE: {
        const rawData = (params as IRAUpdateParams).data;
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.PassportField.UPDATE}`,
          rawData,
        );
        const parsedData = get(response, referencePassportFieldDataPath);
        return {
          data: {
            ...parsedData,
            id: setPrefix(referencePassportFieldPrefix, parsedData[referencePassportFieldIdField]),
          },
        };
      }
      case CREATE: {
        const { data: sendData } = params as IRACreateParams;
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.PassportField.CREATE}`,
          sendData,
        );
        const newPassportField = get(response, referencePassportFieldDataPath);
        return {
          data: {
            ...newPassportField,
            id: setPrefix(
              referencePassportFieldPrefix,
              newPassportField[referencePassportFieldIdField],
            ),
          },
        };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(referencePassportFieldPrefix, id);
        await axios.post(`${API_URL}${REST.AdminReference.PassportField.DELETE}`, {
          [referencePassportFieldIdField]: [clearId],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, referencePassportFieldPrefix);
        await axios.post(`${API_URL}${REST.AdminReference.PassportField.DELETE}`, {
          [referencePassportFieldIdField]: clearIds,
        });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${referencePassportFieldPrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${referencePassportFieldPrefix}-${type}`);
  }
};
