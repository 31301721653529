import { SET_ANALYTICAL_REPORT_TEMPLATE } from '../customActions';
import { Base64Decode } from '../../../Utils/stringMethods';

export const analyticalReportTemplateReducer = (previousState = null, { type, payload }): any => {
  switch (type) {
    case SET_ANALYTICAL_REPORT_TEMPLATE:
      return payload.template ? Base64Decode(payload.template as string) : null;
    // .replace(/\*\*/g, '\\"').replace(/rn/g, '') : null;
    default:
      return previousState;
  }
};
