/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';
import { CREATE, DELETE, DELETE_MANY, GET_LIST, GET_ONE, GET_MANY, UPDATE } from 'react-admin';
import {
  API_URL,
  REST,
  rolePrefix,
  roleIdField,
  referenceBusinessRelationPrefix,
  referenceBusinessRelation,
  approvalDataPath,
  approvalIdField,
  approvalPrefix,
} from 'Constants';
import { get } from 'lodash';
import {
  PgUnAssoc,
  removePrefix,
  removePrefixInArray,
  setPrefix,
  setPrefixInArray,
} from '../utils';
import {
  IRAGetListParams,
  IRAGetManyParams,
  IRAGetOneParams,
  IRAUpdateParams,
} from '../../Interfaces';

export const approvalProvider = async (type: string, params: any): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const { filter } = params as IRAGetListParams;
        const response = await axios.post(`${API_URL}${REST.AdminApproval.LIST}`, {
          filter: filter ? { ...filter } : null,
        });
        const parsedApproval = PgUnAssoc(get(response, approvalDataPath));
        const parsedApprovalWithId = setPrefixInArray(
          parsedApproval,
          approvalPrefix,
          approvalIdField,
        ).map(el => ({
          ...el,
          [rolePrefix]: setPrefix(rolePrefix, el[roleIdField]),
          [referenceBusinessRelationPrefix]: setPrefix(
            referenceBusinessRelationPrefix,
            el[referenceBusinessRelation],
          ),
        }));
        return { data: parsedApprovalWithId, total: parsedApprovalWithId.length };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const clearIds = removePrefixInArray(ids, approvalPrefix).filter(el => el !== null);
        const response = await axios.post(`${API_URL}${REST.AdminApproval.LIST}`, {
          [approvalIdField]: clearIds,
        });
        const parsedApproval = PgUnAssoc(get(response, approvalDataPath));
        const parsedApprovalWithId = setPrefixInArray(
          parsedApproval,
          approvalPrefix,
          approvalIdField,
        ).map(el => ({
          ...el,
          [rolePrefix]: setPrefix(rolePrefix, el[roleIdField]),
          [referenceBusinessRelationPrefix]: setPrefix(
            referenceBusinessRelationPrefix,
            el[referenceBusinessRelation],
          ),
        }));
        return { data: parsedApprovalWithId, total: parsedApprovalWithId.length };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(approvalPrefix, id);

        const response = await axios.post(`${API_URL}${REST.AdminApproval.LIST}`, {
          [approvalIdField]: [clearId],
        });
        const parsedApproval = PgUnAssoc(get(response, approvalDataPath))[0];

        return {
          data: {
            ...parsedApproval,
            id: setPrefix(approvalPrefix, parsedApproval[approvalIdField]),
            [rolePrefix]: setPrefix(rolePrefix, parsedApproval[roleIdField]),
            [referenceBusinessRelationPrefix]: setPrefix(
              referenceBusinessRelationPrefix,
              parsedApproval[referenceBusinessRelation],
            ),
          },
        };
      }
      case CREATE: {
        const rawData = params.data;

        const roleId = removePrefix(rolePrefix, rawData[rolePrefix]);
        const relation = removePrefix(
          referenceBusinessRelationPrefix,
          rawData[referenceBusinessRelationPrefix],
        );

        const sendData = {
          title: rawData.title,
          role_id: roleId,
          relation,
        };

        const response = await axios.post(`${API_URL}${REST.AdminApproval.CREATE}`, sendData);

        const newApproval = get(response, approvalDataPath);
        return {
          data: {
            ...newApproval,
            id: setPrefix(approvalPrefix, newApproval[approvalIdField]),
          },
        };
      }
      case UPDATE: {
        const { data } = params as IRAUpdateParams;
        const { id, [rolePrefix]: roleId, ...sendData } = data as any;

        const response = await axios.post(`${API_URL}${REST.AdminApproval.UPDATE}`, {
          ...sendData,
          [roleIdField]: removePrefix(rolePrefix, roleId),
        });

        const parsedObject = get(response, approvalDataPath);
        return {
          data: {
            ...parsedObject,
            id: setPrefix(approvalPrefix, parsedObject[approvalIdField]),
            [rolePrefix]: setPrefix(rolePrefix, parsedObject[roleIdField]),
            [referenceBusinessRelationPrefix]: setPrefix(
              referenceBusinessRelationPrefix,
              parsedObject[referenceBusinessRelation],
            ),
          },
        };
      }
      case DELETE: {
        try {
          const { id, previousData } = params;
          const clearId = removePrefix(approvalPrefix, id);
          await axios.post(`${API_URL}${REST.AdminApproval.DELETE}`, {
            [approvalIdField]: [clearId],
          });
          return { data: previousData };
        } catch (error) {
          throw new Error(`${error.response.data.error.message}`);
        }
      }
      case DELETE_MANY: {
        try {
          const { ids } = params;
          const clearIds = removePrefixInArray(ids, approvalPrefix);
          await axios.post(`${API_URL}${REST.AdminApproval.DELETE}`, {
            [approvalIdField]: clearIds,
          });
          return { data: ids };
        } catch (error) {
          throw new Error(`${error.response.data.error.message}`);
        }
      }
      default: {
        throw new Error(`Неизвестный метод получения ${approvalPrefix}: ${type}`);
      }
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${approvalPrefix}-${type}`);
  }
};
