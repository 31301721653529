export const REST = {
  Admin: {
    AUTH: '/admin/auth',
    LOGOUT: '/admin/logout',
    SELF: {
      INFO: '/admin/self/info',
    },
  },
  AdminApproval: {
    CREATE: '/admin/approval/create',
    UPDATE: '/admin/approval/update',
    DELETE: '/admin/approval/delete',
    LIST: '/admin/approval/list',
  },
  AdminApprovalStep: {
    CREATE: '/admin/approval/step/create',
    UPDATE: '/admin/approval/step/update',
    DELETE: '/admin/approval/step/delete',
    LIST: '/admin/approval/step/list',
    SET: '/admin/approval/step/set/initial',
  },
  AdminUser: {
    CREATE: '/admin/user/create',
    UPDATE: '/admin/user/update',
    DELETE: '/admin/user/delete',
    LIST: '/admin/user/list',
    RELATION: {
      SET: '/admin/user/relation/role/set',
      LIST: '/admin/user/relation/role/list',
      REVOKE: '/admin/user/relation/role/revoke',
    },
    CLONE: '/admin/user/permission/clone',
    BLOCKED: {
      SET: '/admin/user/blocked/set',
    },
  },
  AdminOrganisation: {
    CREATE: '/admin/organisation/create',
    UPDATE: '/admin/organisation/update',
    UPDATE_SERVICES: '/admin/organisation/services/set',
    DETAIL: '/admin/organisation/detail',
    LIST: '/admin/organisation/list',
    DELETE: '/admin/organisation/delete',
    SETTINGS: {
      SET: '/admin/organisation/settings/set',
    },
  },
  AdminProject: {
    CREATE: '/admin/project/create',
    UPDATE: '/admin/project/update',
    LIST: '/admin/project/list',
    DELETE: '/admin/project/delete',
    SETTINGS: {
      SET: '/admin/project/settings/set',
    },
  },
  AdminObject: {
    CREATE: '/admin/object/create',
    UPDATE: '/admin/object/update',
    LIST: '/admin/object/list',
    UPDATE_SERVICES: '/admin/object/configuration/set',
    DETAIL: '/admin/object/detail',
    DELETE: '/admin/object/delete',
    TRANSFER: '/admin/object/transfer',
    SETTINGS: {
      SET: '/admin/object/settings/set',
    },
    SCHEDULE_TEMPLATE: {
      SET: '/admin/object/schedule/template/set',
    },
    FOLDER_TEMPLATE: {
      SET: '/admin/object/folder/template/set',
    },
  },
  AdminCategory: {
    CREATE: '/admin/object/category/create',
    UPDATE: '/admin/object/category/update',
    LIST: '/admin/object/category/list',
    DELETE: '/admin/object/category/delete',
  },
  AdminSpeciality: {
    CREATE: '/admin/reference/profession/create',
    UPDATE: '/admin/reference/profession/update',
    LIST: '/admin/reference/profession/list',
    DELETE: '/admin/reference/profession/delete',
  },
  AdminRole: {
    CREATE: '/admin/role/create',
    UPDATE: '/admin/role/update',
    LIST: '/admin/role/list',
    DELETE: '/admin/role/delete',
    RELATION: '/admin/role/relation/user/list',
    PERMISSION: {
      LIST: '/admin/role/permission/list',
      SET: '/admin/role/permission/set',
    },
  },
  AdminReference: {
    JobReference: {
      CREATE: '/admin/reference/job/reference/create',
      UPDATE: '/admin/reference/job/reference/update',
      LIST: '/admin/reference/job/reference/list',
      DELETE: '/admin/reference/job/reference/delete',
    },
    Job: {
      CREATE: '/admin/reference/job/create',
      UPDATE: '/admin/reference/job/update',
      LIST: '/admin/reference/job/list',
      DELETE: '/admin/reference/job/delete',
      MOVE: '/admin/reference/job/move',
    },
    Region: {
      LIST: '/admin/reference/region/list',
    },
    JobUnit: {
      LIST: '/admin/reference/job/unit/list',
    },
    GroupUnit: {
      LIST: '/admin/reference/group/unit/list',
    },
    ProjectType: {
      LIST: '/admin/reference/project/type/list',
      CREATE: '/admin/reference/project/type/create',
      UPDATE: '/admin/reference/project/type/update',
      DELETE: '/admin/reference/project/type/delete',
    },
    ObjectType: {
      LIST: '/admin/reference/object/type/list',
      CREATE: '/admin/reference/object/type/create',
      UPDATE: '/admin/reference/object/type/update',
      DELETE: '/admin/reference/object/type/delete',
    },
    FactType: {
      LIST: '/admin/reference/fact/type/list',
    },
    ElementReference: {
      CREATE: '/admin/reference/element/reference/create',
      UPDATE: '/admin/reference/element/reference/update',
      LIST: '/admin/reference/element/reference/list',
      DELETE: '/admin/reference/element/reference/delete',
    },
    Element: {
      CREATE: '/admin/reference/element/create',
      UPDATE: '/admin/reference/element/update',
      LIST: '/admin/reference/element/list',
      DELETE: '/admin/reference/element/delete',
    },
    CalculationMethod: {
      LIST: '/admin/reference/calculation/method/list',
    },
    TypeOfDirectory: {
      LIST: '/admin/reference/object/schedule/reference/type',
    },
    ObjectLifeCycleStage: {
      LIST: '/admin/reference/object/life/cycle/stage/list',
      CREATE: '/admin/reference/object/life/cycle/stage/create',
      UPDATE: '/admin/reference/object/life/cycle/stage/update',
      DELETE: '/admin/reference/object/life/cycle/stage/delete',
    },
    Service: {
      LIST: '/admin/integration/services/list',
      UPDATE: '/admin/integration/service/field/set',
    },
    Currency: {
      LIST: '/admin/reference/currency/list',
      CREATE: '/admin/reference/currency/create',
      UPDATE: '/admin/reference/currency/update',
      DELETE: '/admin/reference/currency/delete',
    },
    ScheduleDeviationDayMethod: {
      LIST: '/admin/reference/schedule/deviation/day/method/list',
    },
    BusinessRelation: {
      LIST: '/admin/reference/approval/relation/list',
    },
    CostPlanFactSource: {
      LIST: '/admin/reference/report/object/cost/plan/fact/source/list',
    },
    PassportField: {
      LIST: '/admin/passport/field/reference/list',
      CREATE: '/admin/passport/field/reference/create',
      UPDATE: '/admin/passport/field/reference/update',
      DELETE: '/admin/passport/field/reference/delete',
    },
    ElementPassportField: {
      LIST: '/admin/passport/field/reference/element/list',
      CREATE: '/admin/passport/field/reference/element/create',
      UPDATE: '/admin/passport/field/reference/element/update',
      DELETE: '/admin/passport/field/reference/element/delete',
    },
    ResourceFactMethod: {
      LIST: '/admin/reference/resource/fact/method/list',
    },
  },
  AdminPermission: {
    CREATE: '/admin/permission/create',
    UPDATE: '/admin/permission/update',
    LIST: '/admin/permission/list',
    DELETE: '/admin/permission/delete',
    SELF: '/admin/self/permission/list',
  },
  AdminImage: {
    LIST: '/admin/image/list',
    DELETE: '/admin/image/delete',
  },
  AdminFile: {
    UPLOAD: '/admin/file/upload',
  },
  AdminAnalyticsTemplate: {
    CREATE: '/admin/analytics/template/create',
    UPDATE: '/admin/analytics/template/update',
    DELETE: '/admin/analytics/template/delete',
    LIST: '/admin/analytics/template/list',
  },
  AdminFolderTemplates: {
    CREATE: '/admin/folder/template/create',
    UPDATE: '/admin/folder/template/update',
    DELETE: '/admin/folder/template/delete',
    LIST: '/admin/folder/template/list',
  },
  AdminFolderTemplatesFolder: {
    CREATE: '/admin/folder/template/folder/create',
    UPDATE: '/admin/folder/template/folder/move',
    DELETE: '/admin/folder/template/folder/delete',
    LIST: '/admin/folder/template/folder/list',
    FILE_SET: '/admin/folder/template/file/template/set',
  },
  AdminFileTemplate: {
    CREATE: '/admin/file/template/create',
    UPDATE: '/admin/file/template/update',
    DELETE: '/admin/file/template/delete',
    LIST: '/admin/file/template/list',
  },
  AdminScheduleTemplate: {
    CREATE: '/admin/schedule/template/create',
    UPDATE: '/admin/schedule/template/update',
    DELETE: '/admin/schedule/template/delete',
    LIST: '/admin/schedule/template/list',
  },
  AdminSchedule: {
    CREATE: '/admin/schedule/template/schedule/create',
    UPDATE: '/admin/schedule/template/schedule/update',
    DELETE: '/admin/schedule/template/schedule/delete',
    LIST: '/admin/schedule/template/schedule/list',
    MOVE: '/admin/schedule/template/schedule/move',
  },
  AdminHistory: {
    DOWNLOAD: '/admin/history/download',
    DATABASE: {
      MIGRATION: {
        LIST: '/admin/history/database/migration/list',
      },
    },
  },
};
