/* eslint-disable @typescript-eslint/camelcase */

// TODO-08: появились новые поля passport_data_ru, passport_data_en
import axios from 'axios';
import {
  GET_LIST,
  GET_ONE,
  UPDATE,
  CREATE,
  DELETE,
  GET_MANY,
  GET_MANY_REFERENCE,
  DELETE_MANY,
} from 'react-admin';
import {
  API_URL,
  REST,
  organisationIdField,
  organisationPrefix,
  projectDataPath,
  projectIdField,
  projectPrefix,
  referenceProjectTypePrefix,
  referenceProjectTypeIdField,
  projectMeta,
  projectImageRelation,
  imageDataPath,
  imagePrefix,
} from 'Constants';
import {
  IRADataProviderParams,
  IRAGetOneParams,
  IRAGetManyReferenceParams,
  IRACreateParams,
  IRAUpdateParams,
  IRADeleteParams,
  IRAGetManyParams,
  IProjectCreateProvider,
  IProjectListResp,
  IProjectUpdateProvider,
  IRADeleteManyParams,
  IRAGetListParams,
} from 'Interfaces';
import { get } from 'lodash';
import {
  setPrefix,
  removePrefix,
  removePrefixInArray,
  PgUnAssoc,
  mapMetaToFields,
  mapMetaFromFields,
  mapImageToFields,
  parseRAParamsToQuery,
} from '../utils';

export const projectProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const query = parseRAParamsToQuery(params as IRAGetListParams, 'project');
        const response = await axios.post(`${API_URL}${REST.AdminProject.LIST}`, query);
        const parsedProjects = PgUnAssoc(get(response, projectDataPath));
        const projectsWithId = parsedProjects.map((el: IProjectListResp) => ({
          ...el,
          id: setPrefix(projectPrefix, el[projectIdField]),
          [organisationPrefix]: setPrefix(organisationPrefix, el[organisationIdField]),
          [referenceProjectTypePrefix]: setPrefix(
            referenceProjectTypePrefix,
            el[referenceProjectTypeIdField],
          ),
          // TODO-08: проверить - нужно ли теперь это
          [projectMeta]: mapMetaToFields(el[projectMeta]),
        }));
        return { data: projectsWithId, total: projectsWithId.length };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(projectPrefix, id);
        const response = await axios.post(`${API_URL}${REST.AdminProject.LIST}`, {
          [projectIdField]: [clearId],
        });
        const parsedProject = PgUnAssoc(get(response, projectDataPath))[0];

        // TODO: перенести в imageProvider
        const images = await axios({
          method: 'post',
          url: `${API_URL}${REST.AdminImage.LIST}`,
          data: {
            image_relation: projectImageRelation,
            image_relation_id: [clearId],
          },
        });
        const parsedImage = mapImageToFields(PgUnAssoc(get(images, imageDataPath)));
        // TODO: перенести в imageProvider

        return {
          data: {
            ...parsedProject,
            id: setPrefix(projectPrefix, parsedProject[projectIdField]),
            [organisationPrefix]: setPrefix(organisationPrefix, parsedProject[organisationIdField]),
            [referenceProjectTypePrefix]: setPrefix(
              referenceProjectTypePrefix,
              parsedProject[referenceProjectTypeIdField],
            ),
            // TODO-08: проверить - нужно ли теперь это
            [projectMeta]: mapMetaToFields(parsedProject[projectMeta]),
            [imagePrefix]: parsedImage,
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const clearIds = removePrefixInArray(ids, projectPrefix);
        const response = await axios.post(`${API_URL}${REST.AdminProject.LIST}`, {
          [projectIdField]: clearIds,
        });
        const parsedProjects = PgUnAssoc(get(response, projectDataPath));
        const projectsWithId = parsedProjects.map((el: IProjectListResp) => ({
          ...el,
          id: setPrefix(projectPrefix, el[projectIdField]),
          [organisationPrefix]: setPrefix(organisationPrefix, el[organisationIdField]),
          [referenceProjectTypePrefix]: setPrefix(
            referenceProjectTypePrefix,
            el[referenceProjectTypeIdField],
          ),
          // TODO-08: проверить - нужно ли теперь это
          [projectMeta]: mapMetaToFields(el[projectMeta]),
        }));
        return { data: projectsWithId };
      }
      case UPDATE: {
        const rawData = (params as IRAUpdateParams).data as IProjectUpdateProvider;
        const {
          id,
          [organisationIdField]: unusedId,
          [organisationPrefix]: unusedPrefix,
          [imagePrefix]: unusedImage,
          settings,
          [referenceProjectTypePrefix]: projectTypeId,
          [projectMeta]: meta,
          ...sendData
        } = rawData;
        const clearProjectTypeId = removePrefix(referenceProjectTypePrefix, projectTypeId);

        await axios.post(`${API_URL}${REST.AdminProject.SETTINGS.SET}`, {
          ...settings,
          main_image_scenario_alias: 'square_preview_220',
          [projectIdField]: removePrefix(projectPrefix, id),
        });

        const response = await axios.post(`${API_URL}${REST.AdminProject.UPDATE}`, {
          ...sendData,
          [referenceProjectTypeIdField]: clearProjectTypeId,
          // TODO-08: проверить - нужно ли теперь это
          [projectMeta]: mapMetaFromFields(meta),
        });

        const parsedProject = get(response, projectDataPath);
        return {
          data: {
            ...parsedProject,
            id: setPrefix(projectPrefix, parsedProject[projectIdField]),
            [organisationPrefix]: setPrefix(organisationPrefix, parsedProject[organisationIdField]),
            [referenceProjectTypePrefix]: setPrefix(
              referenceProjectTypePrefix,
              parsedProject[referenceProjectTypeIdField],
            ),
            // TODO-08: проверить - нужно ли теперь это
            [projectMeta]: mapMetaToFields(parsedProject[projectMeta]),
          },
        };
      }
      case CREATE: {
        const rawData = (params as IRACreateParams).data as IProjectCreateProvider;
        const {
          [imagePrefix]: unusedImage,
          [organisationPrefix]: orgId,
          [referenceProjectTypePrefix]: projectTypeId,
          [projectMeta]: meta,
          ...others
        } = rawData;
        const clearOrgId = removePrefix(organisationPrefix, orgId);
        const clearProjectTypeId = removePrefix(referenceProjectTypePrefix, projectTypeId);
        const sendData = {
          ...others,
          [organisationIdField]: clearOrgId,
          // TODO-08: проверить - нужно ли теперь это
          [projectMeta]: mapMetaFromFields(meta),
          [referenceProjectTypeIdField]: clearProjectTypeId,
        };
        const response = await axios.post(`${API_URL}${REST.AdminProject.CREATE}`, sendData);
        const parsedProject = get(response, projectDataPath);
        return {
          data: {
            ...parsedProject,
            id: setPrefix(projectPrefix, parsedProject[projectIdField]),
            [organisationPrefix]: setPrefix(organisationPrefix, parsedProject[organisationIdField]),
            [referenceProjectTypePrefix]: setPrefix(
              referenceProjectTypePrefix,
              parsedProject[referenceProjectTypeIdField],
            ),
            // TODO-08: проверить - нужно ли теперь это
            [projectMeta]: mapMetaToFields(parsedProject[projectMeta]),
          },
        };
      }
      case GET_MANY_REFERENCE: {
        const fields = {
          [projectPrefix as string]: projectIdField,
          [organisationPrefix as string]: organisationIdField,
        };
        const { id, target } = params as IRAGetManyReferenceParams;
        const clearId = removePrefix(target, id);
        const sendData = { [fields[target]]: [clearId] };
        const query = parseRAParamsToQuery(params as IRAGetListParams, 'project');
        const response = await axios.post(`${API_URL}${REST.AdminProject.LIST}`, {
          ...sendData,
          ...query,
        });
        const parsedProjects = PgUnAssoc(get(response, projectDataPath));
        const projectsWithId = parsedProjects.map((el: IProjectListResp) => ({
          ...el,
          id: setPrefix(projectPrefix, el[projectIdField]),
          [organisationPrefix]: setPrefix(organisationPrefix, el[organisationIdField]),
          [referenceProjectTypePrefix]: setPrefix(
            referenceProjectTypePrefix,
            el[referenceProjectTypeIdField],
          ),
          // TODO-08: проверить - нужно ли теперь это
          [projectMeta]: mapMetaToFields(el[projectMeta]),
        }));
        return { data: projectsWithId, total: projectsWithId.length };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(projectPrefix, id);
        await axios.post(`${API_URL}${REST.AdminProject.DELETE}`, { [projectIdField]: [clearId] });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, projectPrefix);
        await axios.post(`${API_URL}${REST.AdminProject.DELETE}`, { [projectIdField]: clearIds });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${projectPrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${projectPrefix}-${type}`);
  }
};
