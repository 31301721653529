import React, { FC } from 'react';
import { List, Datagrid, TextField, BooleanField } from 'react-admin';

export const PassportAttributesList: FC = props => (
  <List
    {...props}
    exporter={false}
    title="Справочник атрибутов паспорта объекта"
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="fieldKey" label="Код" />
      <TextField source="fieldLabel_ru" label="Название" />
      <TextField source="fieldLabel_en" label="Name" />
      <TextField source="fieldType" label="Тип поля" />
      <BooleanField source="fieldPrivate" label="Приватность" />
    </Datagrid>
  </List>
);
