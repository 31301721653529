import React, { FC } from 'react';
import { List, Datagrid, TextField, Filter, TextInput } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import { permissionIdField } from 'Constants';

const styles = {
  сardLongItem: { maxWidth: '500px' },
};

const PermissionFilter = props => (
  <Filter {...props}>
    <TextInput label="Описание" source="name" alwaysOn />
    <TextInput label="Код" source="alias" alwaysOn />
  </Filter>
);

const PermissionListComponent: FC = ({ classes = {}, ...props }: any) => (
  <List {...props} exporter={false} title="Список организаций" filters={<PermissionFilter />}>
    <Datagrid rowClick="edit">
      <TextField source={permissionIdField} label="Id" />
      <TextField source="name" label="Описание" />
      <TextField source="alias" label="Код" className={classes.сardLongItem} />
    </Datagrid>
  </List>
);

export const PermissionList = withStyles(styles as any)(PermissionListComponent);
