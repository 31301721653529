/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';
import { API_URL, referenceServicePath, REST } from 'Constants';
import { IRADataProviderParams, IRAGetOneParams } from 'Interfaces';
import { get } from 'lodash';
import { GET_LIST, GET_ONE, UPDATE } from 'react-admin';

export const referenceServiceProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(`${API_URL}${REST.AdminReference.Service.LIST}`, {});
        const parsedData = get(response, referenceServicePath);

        return {
          data: parsedData,
          total: parsedData.length,
        };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;

        const response = await axios.post(`${API_URL}${REST.AdminReference.Service.LIST}`, {
          service_id: Number(id),
        });

        const parsedData = get(response, referenceServicePath)[0];

        return {
          data: {
            ...parsedData,
            fieldsFrom: parsedData.fields_from.join(', '),
          },
        };
      }
      case UPDATE: {
        const { data } = params as { data: { id: string; fieldsFrom: string } };
        const { id, fieldsFrom } = data;

        const fieldsArray = fieldsFrom.split(',').map((field: string) => field.trim());

        await axios.post(`${API_URL}${REST.AdminReference.Service.UPDATE}`, {
          service_id: Number(id),
          fields: fieldsArray,
        });

        return {
          data: {
            id: Number(id),
          },
        };
      }
      default:
        throw new Error(`Неизвестный метод получения сервиса: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода сервиса: ${type}`);
  }
};
