import React, { FC } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  ReferenceInput,
  LongTextInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Resources,
  referenceElementIdField,
  referenceGroupUnitIdField,
  elementLabel,
  referenceElementReferencePrefix,
} from 'Constants';
import { getMultiLanguageName } from 'Components/utils/getFullName';

const styles = {
  card: { width: '100%', marginBottom: '10px' },
  сardContent: { display: 'flex', flexDirection: 'column', position: 'relative' },
  groupWrapper: { display: 'flex' },
  groupItem: { marginRight: '20px' },
  btn: { position: 'absolute', right: '0', top: '0' },
};

const RenderTitle = ({ record }: any) => <span>{getMultiLanguageName(record)}</span>;

const ElementEditComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => {
  const redirect = (basePath, id, data) => {
    const path = `/${Resources.REFERENCE_ELEMENT_REFERENCE}/${data[referenceElementReferencePrefix]}/1`;
    return path;
  };

  return (
    <Edit title={<RenderTitle />} {...props}>
      <SimpleForm redirect={redirect}>
        <FormDataConsumer>
          {({ formData }): JSX.Element => (
            <>
              <Card className={classes.card}>
                <CardContent className={classes.сardContent}>
                  <DisabledInput source={referenceElementIdField} label="Id" />
                  <IconButton
                    onClick={() =>
                      propsPush(
                        `/${Resources.REFERENCE_ELEMENT_REFERENCE}/${formData[referenceElementReferencePrefix]}/1`,
                      )
                    }
                    className={classes.btn}
                  >
                    <CloseIcon />
                  </IconButton>
                </CardContent>
              </Card>
              <Card className={classes.card}>
                <CardContent className={classes.сardContent}>
                  <ReferenceInput
                    source={referenceElementReferencePrefix}
                    reference={Resources.REFERENCE_ELEMENT_REFERENCE}
                    resource={Resources.REFERENCE_ELEMENT_REFERENCE}
                    label="Справочник конструктивных элементов"
                    fullWidth
                    required
                    disabled
                  >
                    <SelectInput optionText={record => record.name} />
                  </ReferenceInput>
                  <div className={classes.groupWrapper}>
                    <TextInput source="key" label="Ключ" fullWidth className={classes.groupItem} />
                    <TextInput source="code" label="Код" fullWidth />
                  </div>
                  <div className={classes.groupWrapper}>
                    <TextInput
                      source="name_ru"
                      label={elementLabel.name_ru}
                      fullWidth
                      className={classes.groupItem}
                      required
                    />
                    <TextInput source="name_en" label={elementLabel.name_en} fullWidth />
                  </div>
                  <ReferenceInput
                    source={referenceGroupUnitIdField}
                    resource={Resources.REFERENCE_GROUP_UNIT}
                    reference={Resources.REFERENCE_GROUP_UNIT}
                    label="Тип конструктивного элемента"
                    fullWidth
                    required
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                  <LongTextInput
                    source="description_ru"
                    label={elementLabel.description_ru}
                    fullWidth
                  />
                  <LongTextInput
                    source="description_en"
                    label={elementLabel.description_en}
                    fullWidth
                  />
                </CardContent>
              </Card>
            </>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

const connectedElementEdit = connect(null, { push })(ElementEditComponent);
export const ElementEdit = withStyles(styles as any)(connectedElementEdit);
