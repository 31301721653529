import React, { FC } from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { rolePrefix, Resources, referenceBusinessRelationPrefix } from '../../../Constants';

const BusinessProcessCreateComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => (
  <Create title="Новый маршрут согласования" {...props}>
    <SimpleForm redirect="list">
      <TextInput fullWidth required source="title" label="Название" />
      <ReferenceInput
        source={referenceBusinessRelationPrefix}
        resource={Resources.REFERENCE_BUSINESS_RELATION}
        reference={Resources.REFERENCE_BUSINESS_RELATION}
        label="Документ"
        fullWidth
        required
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source={rolePrefix}
        resource={Resources.ROLE}
        reference={Resources.ROLE}
        label="Роль"
        fullWidth
        required
        filter={{
          codePrefix: 'ba',
        }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const ApprovalCreate = connect(null, { push })(BusinessProcessCreateComponent);
