import { get } from 'lodash';
import { availableLanguages } from 'Constants/locale';

const setPrefixSuffix = (value: string, prefix: string, suffix: string): string =>
  value ? `${prefix}${value}${suffix}` : '';

export const getFullUserName = (user: any, locale = 'ru', prefix = '', suffix = ''): string => {
  const fullName = `
    ${get(user, `lastname_${locale}`) !== null ? user[`lastname_${locale}`] : ''} 
    ${get(user, `firstname_${locale}`) !== null ? user[`firstname_${locale}`] : ''} 
    ${get(user, `patronymic_${locale}`) !== null ? user[`patronymic_${locale}`] : ''}
  `;
  return setPrefixSuffix(fullName.trim(), prefix, suffix);
};

export const getMultiLanguageName = (record: any, prefix = ' / ', suffix = ''): string =>
  availableLanguages.slice(1).reduce((acc, item) => {
    const currentName = setPrefixSuffix(record[`name_${item}`], prefix, suffix);
    return currentName ? `${acc}${currentName}` : acc;
  }, record.name_ru);

export const getMultiLanguageUserName = (user: any, prefix = ' / ', suffix = ''): string =>
  availableLanguages.slice(1).reduce((acc, item) => {
    const currentName = getFullUserName(user, item, prefix, suffix);
    return currentName ? `${acc}${currentName}` : acc;
  }, getFullUserName(user, 'ru'));
