import React, { FC } from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { referenceElementReferenceIdField } from 'Constants';

export const ElementReferenceList: FC = props => (
  <List {...props} exporter={false} title="Справочник конструктивных элементов">
    <Datagrid rowClick="edit">
      <TextField source={referenceElementReferenceIdField} label="Id" />
      <TextField source="code" label="Код" />
      <TextField source="name" label="Название" />
      <TextField source="description" label="Описание" />
    </Datagrid>
  </List>
);
