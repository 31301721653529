import React, { FC } from 'react';
import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
} from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import { organisationPrefix, Resources } from 'Constants';
import { getMultiLanguageName } from '../../utils';
import { getCategoryColors } from './ColorField';

const styles = {
  card: { width: '100%', marginBottom: '10px' },
  сardContent: { display: 'flex', flexDirection: 'column', position: 'relative' },
  groupWrapper: { display: 'flex' },
  groupItem: { marginRight: '20px' },
  btn: { position: 'absolute', right: '0', top: '0' },
  grid: { display: 'grid', gap: '1.5rem', gridTemplateColumns: 'repeat(2, 1fr)' },
};

const CategoryCreateComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => (
  <Create title="Новая категория" {...props}>
    <SimpleForm redirect="list">
      <>
        <Card className={classes.card}>
          <CardContent className={classes.сardContent}>
            <IconButton onClick={() => propsPush(`/${Resources.CATEGORY}`)} className={classes.btn}>
              <CloseIcon />
            </IconButton>
            <ReferenceInput
              source={organisationPrefix}
              resource={Resources.ORGS}
              reference={Resources.ORGS}
              label="Организация"
              fullWidth
              required
            >
              <SelectInput optionText={record => getMultiLanguageName(record)} />
            </ReferenceInput>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent className={classes.сardContent}>
            <TextInput fullWidth source="code" required label="Код" />
            <div className={classes.grid}>
              <TextInput
                fullWidth
                source="name_ru"
                className={classes.groupItem}
                required
                label="Название"
              />
              <TextInput fullWidth source="name_en" className={classes.groupItem} label="Name" />
            </div>
            <SelectInput
              required
              source="color"
              label="Выберите цвет категории"
              choices={getCategoryColors()}
            />
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent className={classes.сardContent}>
            <BooleanInput
              source="is_sub_menu"
              label="Сделать подменю навигации"
              required
              defaultValue={false}
            />
            <BooleanInput
              source="has_sub_menu_year"
              label="Сделать подменю по году ввода в эксплуатацию"
              required
              defaultValue={false}
            />
          </CardContent>
        </Card>
      </>
    </SimpleForm>
  </Create>
);

const connectedCategoryCreate = connect(null, { push })(CategoryCreateComponent);
export const CategoryCreate = withStyles(styles as any)(connectedCategoryCreate);
