import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
  Create,
  SimpleForm,
  TextInput,
  LongTextInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  SelectInput,
  FormDataConsumer,
  ReferenceInput,
  addField,
  BooleanInput,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { getMultiLanguageName } from 'Components/utils';
import {
  Resources,
  commonLabel,
  availableLanguages,
  passportAttributesData,
  VALIDATE_ERRORS,
  organisationPrefix,
} from 'Constants';
import {
  ITemplateFieldName,
  templateFieldType,
  ITemplateFormName,
  ITemplateField,
  ITemplateGroupFieldName,
  ITemplateWidgetFieldName,
  ITemplateWidgetName,
  templateAttrFieldType,
  templateDateAssignmentFlag,
} from 'Providers/utils';
import { get, uniqBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { TransferList, ITransferListItem } from 'Components/Common';
import { IObjectTypeCreateStyle } from './i';
import { keyRegex, uniqueValues } from '../../../Utils/stringMethods';

const styles = {
  card: { width: '100%', marginBottom: '10px' },
  fullWidth: { width: '100%' },
  сardContent: { display: 'flex', flexDirection: 'column', position: 'relative' },
  fieldsInLine: { width: '40%', marginRight: '20px' },
  fieldsWithMargin: { marginRight: '20px' },
  btn: { position: 'absolute', right: '0', top: '0' },
  checkboxName: { display: 'flex' },
  checkboxButtonForward: {
    padding: '3px',
    color: 'black',
    backgroundColor: 'rgba(6, 128, 249, 0.08)',
    border: '1px solid #C2C8D6',
    borderRight: '0',
    borderRadius: '3px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    cursor: 'pointer',
    outline: 'none',
  },
  checkboxButtonBack: {
    padding: '3px',
    color: 'black',
    backgroundColor: 'rgba(6, 128, 249, 0.08)',
    border: '1px solid #C2C8D6',
    borderLeft: '0',
    borderRadius: '3px',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    cursor: 'pointer',
    outline: 'none',
  },
  checkboxButtonName: {
    padding: '3px 10px',
    color: 'black',
    backgroundColor: 'white',
    border: '1px solid #C2C8D6',
    borderLeft: '0',
    borderRight: '0',
    borderRadius: '0',
    cursor: 'pointer',
    outline: 'none',
  },
  groupWrapper: { display: 'flex' },
  groupItem: { marginRight: '20px' },
  fieldsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gap: '1.5rem',
    alignItems: 'flex-end',
  },
  withoutWidth: { width: 'auto' },
  privateField: { display: 'inline', marginLeft: '20px' },
};

const fieldTypeChoices = Object.entries(templateFieldType).map(el => ({
  id: el[1].type,
  name: el[1].label,
}));
const attrFieldTypeChoices = Object.entries(templateAttrFieldType).map(el => ({
  id: el[1].type,
  name: el[1].label,
}));
const widgetNameChoices = Object.values(ITemplateWidgetName).map(el => ({ id: el, name: el }));
const assignmentFlagChoices = Object.entries(templateDateAssignmentFlag).map(el => ({
  id: el[1].type,
  name: el[1].label,
}));

const getPossibleFields = (formData): { id: string; name: string }[] => {
  const fields = get(formData, ITemplateFormName.fields, []);
  const possibleKeys = [];
  if (fields) {
    fields.forEach((el: ITemplateField) => {
      const field = get(el, ITemplateFieldName.fieldKey, '');
      const label = get(el, `${ITemplateFieldName.fieldLabel}_ru`); // TODO-08: no magic string
      const type = get(el, ITemplateFieldName.fieldType);
      if (field && label && type) {
        possibleKeys.push({ id: field, name: label });
      }
    });
  }
  return possibleKeys;
};

const ExtendedSelectInput = addField(({ input, choices, source, label, disabled }) => (
  <SelectInput
    {...input}
    source={source}
    choices={[...fieldTypeChoices, ...choices]}
    required
    label={label}
    disabled={disabled}
  />
));

const ObjectTypeCreateComponent: FC<WithStyles & { push }> = ({
  push: propsPush,
  classes,
  ...props
}) => {
  const [isGroupFieldsEdit, setIsGroupFieldsEdit] = useState<boolean>(false);
  const [isWidgetFieldsEdit, setIsWidgetFieldsEdit] = useState<boolean>(false);
  const [editingData, setEditingData] = useState<[ITransferListItem[], string[], string] | []>([]);

  const onDrawerClose = (): void => {
    if (isGroupFieldsEdit) {
      setIsGroupFieldsEdit(false);
    } else {
      setIsWidgetFieldsEdit(false);
    }
  };

  const [currentKey, setCurrentKey] = useState(null);

  const handeKeyChange = (e, formData: any, scopedFormData: any) => {
    if (scopedFormData[ITemplateFieldName.fieldKey] !== currentKey && formData.groups) {
      console.log(currentKey, formData);

      /* eslint-disable-next-line no-param-reassign */
      formData.groups = formData.groups?.map(group => ({
        ...group,
        [ITemplateGroupFieldName.groupFields]: group[ITemplateGroupFieldName.groupFields]
          // eslint-disable-next-line no-confusing-arrow
          .map(field =>
            field === currentKey ? scopedFormData[ITemplateFieldName.fieldKey] : field,
          ),
      }));

      /* eslint-disable-next-line no-param-reassign */
      formData.widgets = formData.widgets?.map(widget => ({
        ...widget,
        [ITemplateWidgetFieldName.widgetFields]: widget[ITemplateWidgetFieldName.widgetFields]
          // eslint-disable-next-line no-confusing-arrow
          .map(field =>
            field === currentKey ? scopedFormData[ITemplateFieldName.fieldKey] : field,
          ),
      }));

      setCurrentKey(null);
      console.log(currentKey, formData);
    }
  };

  return (
    <Create title="Новый тип объекта" {...props}>
      <SimpleForm redirect="list">
        <>
          <>
            <Card className={classes.card}>
              <CardContent className={classes.сardContent}>
                <IconButton
                  onClick={(): void => propsPush(`/${Resources.REFERENCE_OBJECT_TYPE}`)}
                  className={classes.btn}
                >
                  <CloseIcon />
                </IconButton>
                <TextInput
                  source="alias"
                  label="Код"
                  fullWidth
                  validate={[keyRegex(), required(VALIDATE_ERRORS.REQUIRED)]}
                />
                <ReferenceArrayInput
                  source={organisationPrefix}
                  resource={Resources.ORGS}
                  reference={Resources.ORGS}
                  label="Организации"
                  fullWidth
                  emptyValue={null}
                  defaultValue={null}
                >
                  <SelectArrayInput optionText={record => getMultiLanguageName(record)} />
                </ReferenceArrayInput>
                <div className={classes.groupWrapper}>
                  <TextInput
                    source="name_ru"
                    label={commonLabel.name_ru}
                    className={classes.groupItem}
                    fullWidth
                    required
                  />
                  <TextInput source="name_en" label={commonLabel.name_en} fullWidth />
                </div>
                <LongTextInput
                  source="description_ru"
                  label={commonLabel.description_ru}
                  fullWidth
                />
                <LongTextInput
                  source="description_en"
                  label={commonLabel.description_en}
                  fullWidth
                />
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.сardContent}>
                <ArrayInput
                  source={ITemplateFormName.fields}
                  label="Шаблон мета-информации"
                  fullWidth
                >
                  <SimpleFormIterator className={classes.templateContainer}>
                    <FormDataConsumer>
                      {({ formData, scopedFormData, getSource, ...rest }): JSX.Element => (
                        <>
                          <TextInput
                            key="field-key"
                            {...rest}
                            source={getSource(`${ITemplateFieldName.fieldKey}`)}
                            validate={[
                              required('Обязательное поле'),
                              uniqueValues(ITemplateFormName.fields),
                            ]}
                            label="Код"
                            className={classes.fieldsWithMargin}
                            disabled={get(scopedFormData, ITemplateFieldName.fieldIsSpecial)}
                            onBlur={e => handeKeyChange(e, formData, scopedFormData)}
                            onClick={e => setCurrentKey(e.target.value)}
                          />
                          {availableLanguages.map(language => (
                            <TextInput
                              key={`field-label-${language}`}
                              {...rest}
                              source={getSource(`${ITemplateFieldName.fieldLabel}_${language}`)}
                              required
                              label={commonLabel[`name_${language}`]}
                              className={classes.fieldsWithMargin}
                              disabled={get(scopedFormData, ITemplateFieldName.fieldIsSpecial)}
                            />
                          ))}
                          {get(scopedFormData, ITemplateFieldName.fieldType) === 'target_ref' && (
                            <TextInput
                              {...rest}
                              source={getSource(ITemplateFieldName.fieldRef)}
                              required
                              label="Путь свойства"
                              className={classes.fieldsWithMargin}
                              disabled={get(scopedFormData, ITemplateFieldName.fieldIsSpecial)}
                            />
                          )}
                          {get(scopedFormData, ITemplateFieldName.fieldType) === 'reference' && (
                            <ReferenceInput
                              {...rest}
                              className={classes.fieldsWithMargin}
                              source={getSource(ITemplateFieldName.passportFieldReferenceId)}
                              reference={Resources.PASSPORT_FIELD}
                              label="Справ. паспортного поля"
                              validate={[required(VALIDATE_ERRORS.REQUIRED)]}
                            >
                              <SelectInput optionText={record => record.name} />
                            </ReferenceInput>
                          )}
                          <ReferenceInput
                            {...rest}
                            source={getSource(ITemplateFieldName.fieldType)}
                            reference={Resources.REFERENCE_JOB_UNIT}
                            label="Тип поля"
                            required
                          >
                            <ExtendedSelectInput
                              disabled={get(scopedFormData, ITemplateFieldName.fieldIsSpecial)}
                            />
                          </ReferenceInput>
                          <BooleanInput
                            label="Приватность поля"
                            source={getSource(ITemplateFieldName.fieldPrivate)}
                            className={classes.privateField}
                            options={{
                              disabled: get(scopedFormData, ITemplateFieldName.fieldIsSpecial),
                            }}
                          />
                          <BooleanInput
                            label="Только для чтения"
                            required
                            defaultValue={false}
                            source={getSource(ITemplateFieldName.readOnly)}
                            className={classes.privateField}
                            options={{
                              disabled: get(scopedFormData, ITemplateFieldName.fieldIsSpecial),
                            }}
                          />
                        </>
                      )}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
                <ReferenceInput
                  source={ITemplateFormName.passportAttributes}
                  resource={Resources.PASSPORT_ATTR}
                  reference={Resources.PASSPORT_ATTR}
                  label="Добавить из справочника атрибутов"
                  fullWidth
                >
                  <FormDataConsumer>
                    {({ formData, scopedFormData, getSource, ...rest }): JSX.Element => {
                      if (formData && get(formData, ITemplateFormName.passportAttributes)) {
                        const specialAttrKey = get(formData, ITemplateFormName.passportAttributes);
                        const newSpecialAttr = {
                          ...[...passportAttributesData].filter(
                            el => el[ITemplateFieldName.fieldKey] === specialAttrKey,
                          )[0],
                        };
                        delete newSpecialAttr?.id;
                        if (newSpecialAttr) {
                          /* eslint-disable-next-line no-param-reassign */
                          formData[ITemplateFormName.fields] = uniqBy(
                            [...(formData[ITemplateFormName.fields] || []), newSpecialAttr],
                            ITemplateFieldName.fieldKey,
                          );
                          /* eslint-disable-next-line no-param-reassign */
                          formData[ITemplateFormName.passportAttributes] = undefined;
                        }
                      }
                      return <SelectInput {...rest} optionText="fieldLabel_ru" />;
                    }}
                  </FormDataConsumer>
                </ReferenceInput>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.сardContent}>
                <ArrayInput
                  source={ITemplateFormName.groups}
                  label="Редактор групп полей"
                  fullWidth
                >
                  <SimpleFormIterator className={classes.templateContainer}>
                    <FormDataConsumer>
                      {({ formData, scopedFormData, getSource, ...rest }): JSX.Element => {
                        if (
                          scopedFormData &&
                          !get(scopedFormData, ITemplateGroupFieldName.groupKey)
                        ) {
                          /* eslint-disable-next-line no-param-reassign */
                          scopedFormData[ITemplateGroupFieldName.groupKey] = uuidv4();
                        }
                        return (
                          <>
                            <div>
                              {availableLanguages.map(language => (
                                <TextInput
                                  key={`group-label-${language}`}
                                  {...rest}
                                  source={getSource(
                                    `${ITemplateGroupFieldName.groupLabel}_${language}`,
                                  )}
                                  required
                                  label={commonLabel[`name_${language}`]}
                                  className={classes.fieldsWithMargin}
                                />
                              ))}
                              <NumberInput
                                {...rest}
                                source={getSource(ITemplateGroupFieldName.groupOrder)}
                                required
                                label="Порядковый номер"
                                step={1}
                                className={classes.fieldsWithMargin}
                              />
                              <Button
                                size="small"
                                variant="contained"
                                onClick={(): void => {
                                  const initRight = get(
                                    formData,
                                    getSource(ITemplateGroupFieldName.groupFields),
                                    [],
                                  );
                                  setEditingData([
                                    getPossibleFields(formData).sort((a, b) => {
                                      const aIndex = initRight.findIndex(el => el === a.id);
                                      const bIndex = initRight.findIndex(el => el === b.id);
                                      return aIndex - bIndex;
                                    }),
                                    initRight,
                                    getSource(ITemplateGroupFieldName.groupFields),
                                  ]);
                                  setIsGroupFieldsEdit(true);
                                }}
                                disabled={
                                  isGroupFieldsEdit ||
                                  isWidgetFieldsEdit ||
                                  getPossibleFields(formData).length === 0 ||
                                  !get(
                                    formData,
                                    getSource(`${ITemplateGroupFieldName.groupLabel}_ru`),
                                  ) || // TODO-08: no magic string
                                  !get(formData, getSource(ITemplateGroupFieldName.groupOrder))
                                }
                              >
                                Настроить поля
                              </Button>
                            </div>
                          </>
                        );
                      }}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.сardContent}>
                <ArrayInput
                  source={ITemplateFormName.widgets}
                  label="Редактор полей в виджетах"
                  fullWidth
                >
                  <SimpleFormIterator className={classes.templateContainer}>
                    <FormDataConsumer>
                      {({ formData, getSource, ...rest }): JSX.Element => (
                        <>
                          <SelectInput
                            {...rest}
                            source={getSource(ITemplateWidgetFieldName.widgetKey)}
                            required
                            label="Имя виджета"
                            choices={widgetNameChoices}
                            className={classes.fieldsWithMargin}
                          />
                          <Button
                            size="small"
                            variant="contained"
                            onClick={(): void => {
                              const initRight = get(
                                formData,
                                getSource(ITemplateWidgetFieldName.widgetFields),
                                [],
                              );
                              setEditingData([
                                getPossibleFields(formData).sort((a, b) => {
                                  const aIndex = initRight.findIndex(el => el === a.id);
                                  const bIndex = initRight.findIndex(el => el === b.id);
                                  return aIndex - bIndex;
                                }),
                                initRight,
                                getSource(ITemplateWidgetFieldName.widgetFields),
                              ]);
                              setIsWidgetFieldsEdit(true);
                            }}
                            disabled={
                              isGroupFieldsEdit ||
                              isWidgetFieldsEdit ||
                              getPossibleFields(formData).length === 0 ||
                              !get(formData, getSource(ITemplateWidgetFieldName.widgetKey))
                            }
                          >
                            Настроить поля
                          </Button>
                        </>
                      )}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <ArrayInput
                  source={ITemplateFormName.attributes}
                  label="Настройка атрибутов элементов модели"
                  fullWidth
                >
                  <SimpleFormIterator>
                    <FormDataConsumer>
                      {({ formData, scopedFormData, getSource, ...rest }): JSX.Element => (
                        <div className={classes.fieldsGrid}>
                          <TextInput
                            key="field-key"
                            {...rest}
                            source={getSource(`${ITemplateFieldName.fieldKey}`)}
                            label="Код"
                            className={classes.withoutWidth}
                            validate={[
                              // keyRegex(),
                              required('Обязательное поле'),
                              uniqueValues(ITemplateFormName.attributes),
                            ]}
                          />
                          <TextInput
                            key="field-label"
                            {...rest}
                            source={getSource(`${ITemplateFieldName.fieldLabel}`)}
                            label={commonLabel.name_ru}
                            validate={required('Обязательное поле')}
                            className={classes.withoutWidth}
                          />
                          <SelectInput
                            source={getSource(ITemplateFieldName.fieldType)}
                            choices={[...attrFieldTypeChoices]}
                            label="Тип поля"
                            validate={required('Обязательное поле')}
                            className={classes.withoutWidth}
                          />
                          {get(scopedFormData, ITemplateFieldName.fieldType) === 'date' && (
                            <SelectInput
                              source={getSource(ITemplateFieldName.fieldDateAssignmentFlag)}
                              choices={[...assignmentFlagChoices]}
                              label="Признак присваивания"
                              validate={required('Обязательное поле')}
                              className={classes.withoutWidth}
                            />
                          )}
                          <BooleanInput
                            label="Скрыть в модели объекта"
                            source={getSource(ITemplateFieldName.fieldHideInModelObject)}
                          />
                          <BooleanInput
                            label="Скрыть в отчетах"
                            source={getSource(ITemplateFieldName.fieldHideInReports)}
                          />
                        </div>
                      )}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              </CardContent>
            </Card>
          </>
          <>
            <Drawer
              open={isGroupFieldsEdit || isWidgetFieldsEdit}
              ModalProps={{
                disableEscapeKeyDown: true,
                disableBackdropClick: true,
              }}
              anchor="right"
              onClose={onDrawerClose}
            >
              <TransferList
                onClose={onDrawerClose}
                data={editingData}
                source={get(editingData, '[2]')}
              />
            </Drawer>
          </>
        </>
      </SimpleForm>
    </Create>
  );
};

const connectedObjectTypeCreate = connect(null, { push })(ObjectTypeCreateComponent);
export const ObjectTypeCreate = withStyles(styles as IObjectTypeCreateStyle)(
  connectedObjectTypeCreate,
);
