import axios from 'axios';
import { GET_LIST, GET_ONE, UPDATE, CREATE, DELETE, DELETE_MANY, GET_MANY } from 'react-admin';
import {
  IRADataProviderParams,
  IRAGetOneParams,
  IRACreateParams,
  IRAUpdateParams,
  IRADeleteParams,
  IReferenceElementCreateProvider,
  IRADeleteManyParams,
  IRAGetManyParams,
} from 'Interfaces';
import {
  API_URL,
  REST,
  referenceElementReferenceIdField,
  referenceElementReferenceDataPath,
  referenceElementReferencePrefix,
} from 'Constants';
import { get } from 'lodash';
import { setPrefix, removePrefix, PgUnAssoc, removePrefixInArray } from '../utils';

export const referenceElementReferenceProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.ElementReference.LIST}`,
          {},
        );
        const parsedElement = PgUnAssoc(get(response, referenceElementReferenceDataPath));
        const parsedElementWithId = parsedElement.map(el => ({
          ...el,
          id: setPrefix(referenceElementReferencePrefix, el[referenceElementReferenceIdField]),
        }));
        return { data: parsedElementWithId, total: parsedElementWithId.length };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(referenceElementReferencePrefix, id);
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.ElementReference.LIST}`,
          {
            [referenceElementReferenceIdField]: [clearId],
          },
        );
        const parsedElement = PgUnAssoc(get(response, referenceElementReferenceDataPath))[0];
        return {
          data: {
            ...parsedElement,
            id: setPrefix(
              referenceElementReferencePrefix,
              parsedElement[referenceElementReferenceIdField],
            ),
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const clearIds = removePrefixInArray(ids, referenceElementReferencePrefix);
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.ElementReference.LIST}`,
          {
            [referenceElementReferenceIdField]: clearIds,
          },
        );
        const parsedData = PgUnAssoc(get(response, referenceElementReferenceDataPath));
        const parsedDataWithId = parsedData.map(el => ({
          ...el,
          id: setPrefix(referenceElementReferencePrefix, el[referenceElementReferenceIdField]),
        }));
        return { data: parsedDataWithId };
      }
      case UPDATE: {
        const rawData = (params as IRAUpdateParams).data;
        const { id, ...others } = rawData;
        const sendData = { ...others };
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.ElementReference.UPDATE}`,
          sendData,
        );
        const parsedElement = get(response, referenceElementReferenceDataPath);
        return {
          data: {
            ...parsedElement,
            id: setPrefix(
              referenceElementReferencePrefix,
              parsedElement[referenceElementReferenceIdField],
            ),
          },
        };
      }
      case CREATE: {
        const rawData = (params as IRACreateParams).data as IReferenceElementCreateProvider;
        const sendData = { ...rawData };
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.ElementReference.CREATE}`,
          sendData,
        );
        const parsedElement = get(response, referenceElementReferenceDataPath);
        return {
          data: {
            ...parsedElement,
            id: setPrefix(
              referenceElementReferencePrefix,
              parsedElement[referenceElementReferenceIdField],
            ),
          },
        };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(referenceElementReferencePrefix, id);
        await axios.post(`${API_URL}${REST.AdminReference.ElementReference.DELETE}`, {
          [referenceElementReferenceIdField]: [clearId],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, referenceElementReferencePrefix);
        await axios.post(`${API_URL}${REST.AdminReference.ElementReference.DELETE}`, {
          [referenceElementReferenceIdField]: clearIds,
        });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${referenceElementReferencePrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${referenceElementReferencePrefix}-${type}`);
  }
};
