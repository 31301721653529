import React, { FC } from 'react';
import {
  LongTextInput,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  minLength,
  FormDataConsumer,
  NumberInput,
  minValue,
  required,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  referenceObjectTypePrefix,
  projectPrefix,
  Resources,
  commonLabel,
  calculationMethodIdField,
  scheduleDeviationDayMethodIdField,
  typeOfDirectoryIdField,
  folderTemplatesPrefix,
  scheduleTemplatePrefix,
  referenceElementReferencePrefix,
  referenceJobReferencePrefix,
  expenditureLevelTypes,
  referenceCostPlanFactIdField,
  factCorrectionPeriodByExpenditureTypes,
  VALIDATE_ERRORS,
} from 'Constants';
import { EditableUrlInput, SubmitCancelModal } from 'Components/Common';
import { getMultiLanguageName } from 'Components/utils/getFullName';
import { videoLinkFieldType } from 'Providers/utils';

const styles = {
  card: { width: '100%', marginTop: '1.5rem' },
  сardContent: { display: 'flex', flexDirection: 'column', position: 'relative' },
  groupWrapper: { display: 'flex', alignItems: 'flex-end', gap: '0 2rem' },
  groupWrapperConfirm: {
    display: 'flex',
    alignItems: 'center',
    gap: '0 1rem',
    fontSize: '0.875rem',
    fontFamily: 'Arial',
  },
  groupItem: { marginRight: '20px' },
  btn: { position: 'absolute', right: '0', top: '0' },
  textNoWrap: { whiteSpace: 'nowrap' },
  hidden: { display: 'none' },
};

const fieldTypeChoices = Object.entries(videoLinkFieldType).map(el => ({
  id: el[1].type,
  name: el[1].label,
}));

const BooleanInputWithConfirm = ({ source, label }: any) => (
  <BooleanInput
    source={source}
    label={label}
    defaultValue={false}
    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
      // eslint-disable-next-line no-restricted-globals
      const isToggle = confirm(
        'При смене режима переключателя, ранее введенные данные по трудозатратам и численности будут удалены. Вы подтверждаете данное действие??',
      );
      if (!isToggle) {
        e.preventDefault();
      }
    }}
  />
);

export const ObjectCreateComponent: FC = ({ push: propsPush, classes = {}, ...props }: any) => (
  <Create title="Новый объект" {...props}>
    <SimpleForm redirect="list">
      <FormDataConsumer>
        {({ formData, scopedFormData, getSource, ...rest }): JSX.Element => (
          <>
            <Card className={classes.card}>
              <CardContent className={classes.сardContent}>
                <IconButton
                  onClick={() => propsPush(`/${Resources.OBJECT}`)}
                  className={classes.btn}
                >
                  <CloseIcon />
                </IconButton>
                <div className={classes.groupWrapper}>
                  <TextInput
                    fullWidth
                    source="name_ru"
                    label={commonLabel.name_ru}
                    className={classes.groupItem}
                    required
                  />
                  <TextInput fullWidth source="name_en" label={commonLabel.name_en} />
                </div>
                <ReferenceInput
                  source={projectPrefix}
                  resource={Resources.PROJECTS}
                  reference={Resources.PROJECTS}
                  label="Проект"
                  fullWidth
                  required
                >
                  <SelectInput optionText={record => getMultiLanguageName(record)} />
                </ReferenceInput>
                <ReferenceInput
                  source={referenceObjectTypePrefix}
                  resource={Resources.REFERENCE_OBJECT_TYPE}
                  reference={Resources.REFERENCE_OBJECT_TYPE}
                  label="Тип объекта"
                  fullWidth
                  defaultValue={null}
                  validate={[required(VALIDATE_ERRORS.REQUIRED)]}
                >
                  <SelectInput optionText={record => getMultiLanguageName(record)} />
                </ReferenceInput>
                <ReferenceInput
                  source={calculationMethodIdField}
                  resource={Resources.CALCULATION_METHOD}
                  reference={Resources.CALCULATION_METHOD}
                  label="Метод расчета"
                  fullWidth
                  required
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput
                  source={`settings.${scheduleDeviationDayMethodIdField}`}
                  resource={Resources.SCHEDULE_DEVIATION_DAY_METHOD}
                  reference={Resources.SCHEDULE_DEVIATION_DAY_METHOD}
                  label="Метод расчёта дней отставания"
                  fullWidth
                  required
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput
                  source={folderTemplatesPrefix}
                  reference={Resources.FOLDER_TEMPLATES}
                  label="Шаблон папок"
                  fullWidth
                  allowEmpty
                  emptyValue={null}
                  defaultValue={null}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput
                  source={scheduleTemplatePrefix}
                  reference={Resources.SCHEDULE_TEMPLATE}
                  label="Шаблон модели объекта"
                  fullWidth
                  allowEmpty
                  emptyValue={null}
                  defaultValue={null}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                <SelectInput
                  label="Тип ссылки на видео"
                  source="video_type"
                  choices={[...fieldTypeChoices]}
                  defaultValue={null}
                />
                <EditableUrlInput source="broadcast_url" {...props} />
                <LongTextInput
                  source="description_ru"
                  label={commonLabel.description_ru}
                  fullWidth
                />
                <LongTextInput
                  source="description_en"
                  label={commonLabel.description_en}
                  fullWidth
                />
                <LongTextInput source="meta" label="Мета-информация" fullWidth />
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="caption">Настройки классификаторов</Typography>
                <ReferenceInput
                  source={typeOfDirectoryIdField}
                  resource={Resources.TYPEOF_DIRECTORY}
                  reference={Resources.TYPEOF_DIRECTORY}
                  label="Тип справочника конструктивных элементов"
                  fullWidth
                  required
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                <SelectInput
                  required
                  fullWidth
                  source="settings.ks2_forming_method"
                  label="Формирование актов КС-2"
                  choices={[
                    { id: 'by_file', name: 'По файлу' },
                    { id: 'by_schedule', name: 'На основе позиций из модели объекта' },
                    { id: 'by_estimate', name: 'На основе сметных позиций' },
                  ]}
                  defaultValue="by_file"
                />
                <ReferenceInput
                  source={`settings.${referenceElementReferencePrefix}`}
                  reference={Resources.REFERENCE_ELEMENT_REFERENCE}
                  resource={Resources.REFERENCE_ELEMENT_REFERENCE}
                  label="Справочник конструктивных элементов"
                  fullWidth
                  allowEmpty
                  emptyValue={null}
                  defaultValue={null}
                  disabled={formData[typeOfDirectoryIdField] !== 'common'}
                  className={formData[typeOfDirectoryIdField] !== 'common' ? classes.hidden : ''}
                >
                  <SelectInput optionText={record => record.name} />
                </ReferenceInput>
                <ReferenceInput
                  source={`settings.${referenceJobReferencePrefix}`}
                  reference={Resources.REFERENCE_JOB_REFERENCE}
                  resource={Resources.REFERENCE_JOB_REFERENCE}
                  label="Справочник работ"
                  required
                  fullWidth
                >
                  <SelectInput optionText={record => record.name} />
                </ReferenceInput>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.сardContent}>
                <Typography variant="caption">Настройки трудозатраты и численности</Typography>
                <div className={classes.groupWrapper}>
                  <BooleanInput
                    source="settings.labor_cost"
                    label="Планирование по трудозатратам"
                    defaultValue={false}
                  />
                </div>
                <div className={classes.groupWrapperConfirm}>
                  <span>Сводно по объекту</span>
                  <BooleanInputWithConfirm
                    source="settings.labor_cost_fact_use_schedule"
                    label="В разрезе видов работ"
                  />
                </div>
                <div className={classes.groupWrapperConfirm}>
                  <span>Сводно</span>
                  <BooleanInputWithConfirm
                    source="settings.labor_cost_fact_use_profession"
                    label="По специальностям"
                  />
                </div>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.сardContent}>
                <Typography variant="caption">Настройки трудящихся и механизмов</Typography>
                <ReferenceInput
                  source="settings.resource_fact_method"
                  resource={Resources.RESOURCE_FACT_METHOD}
                  reference={Resources.RESOURCE_FACT_METHOD}
                  label="Mетод ввода факта по ресурсам"
                  fullWidth
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.сardContent}>
                <Typography variant="caption">Настройки объекта</Typography>
                <div className={classes.groupWrapper}>
                  <BooleanInput
                    source="settings.guntt_status_gray"
                    label="Отображать серый статус"
                    defaultValue={false}
                  />
                </div>
                <div className={classes.groupWrapper}>
                  <BooleanInput
                    source="settings.image_gallery_is_public"
                    label="Публичная фотогалерея"
                    defaultValue={false}
                  />
                </div>
                <div className={classes.groupWrapper}>
                  <BooleanInput
                    source="settings.allow_schedule_object"
                    label="Разрешить использование подобъектов"
                    defaultValue={false}
                  />
                </div>
                <div className={classes.groupWrapper}>
                  <BooleanInput
                    source="settings.allow_cost_item"
                    label="Использовать статьи затрат"
                    defaultValue={false}
                  />
                </div>
                <div className={classes.groupWrapper}>
                  <BooleanInput
                    source="settings.critical_path"
                    label="Расчет критического пути"
                    defaultValue={false}
                  />
                </div>
                <div className={classes.groupWrapper}>
                  <SelectInput
                    fullWidth
                    source="settings.schedule_expenditure_plan_fact_input"
                    label="Уровень ввода финансов"
                    choices={expenditureLevelTypes}
                    optionText="name"
                    optionValue="value"
                  />
                </div>
                <div className={classes.groupWrapper}>
                  <NumberInput
                    fullWidth
                    source="settings.guntt_status_red_deviation_day"
                    label="Количество дней отставания (влияет на индикацию готовности работы по сценарию «Прораб»)"
                    defaultValue={0}
                    step={1}
                    validate={[minValue(0, 'Значение не может быть меньше 0')]}
                  />
                </div>
                <div className={classes.groupWrapper}>
                  <SelectInput
                    fullWidth
                    source="settings.fact_correction_period_by_expenditure"
                    label="Период документа верификации по стоимости"
                    choices={factCorrectionPeriodByExpenditureTypes}
                    optionText="name"
                    optionValue="value"
                  />
                </div>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.сardContent}>
                <Typography variant="caption">
                  Источник фактических данных для отчёта «Контроль финансирования и освоения ОКС»
                </Typography>
                <ReferenceInput
                  source="settings.report_object_cost_plan_fact_source"
                  resource={Resources.REFERENCE_COST_PLAN_FACT_SOURCE}
                  reference={Resources.REFERENCE_COST_PLAN_FACT_SOURCE}
                  label="Источник данных"
                  fullWidth
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography variant="caption">Сводный график</Typography>
                <div className={classes.groupWrapper}>
                  <BooleanInput
                    source="settings.use_object_plan_forecast_data"
                    label="Влияние на отчетность"
                    options={{
                      disabled: !formData.settings?.schedule_update_from_object_plan,
                    }}
                    defaultValue={false}
                  />
                </div>
                <div className={classes.groupWrapper}>
                  <BooleanInput
                    source="settings.schedule_update_from_object_plan"
                    label="Использовать данные для обновления модели объекта"
                    defaultValue={false}
                  />
                </div>
              </CardContent>
            </Card>
          </>
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

const connectedObjectCreate = connect(null, { push })(ObjectCreateComponent);
export const ObjectCreate = withStyles(styles as any)(connectedObjectCreate);
