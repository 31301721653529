import axios from 'axios';
import {
  GET_LIST,
  GET_ONE,
  DELETE,
  DELETE_MANY,
  UPDATE,
  CREATE,
  GET_MANY,
  GET_MANY_REFERENCE,
} from 'react-admin';
import { get } from 'lodash';
import {
  IRAGetListParams,
  IRADataProviderParams,
  IRAGetOneParams,
  IRADeleteParams,
  IRADeleteManyParams,
  IRAUpdateParams,
  IRACreateParams,
  IRAGetManyReferenceParams,
  IRAGetManyParams,
} from 'Interfaces';
import {
  setPrefixInArray,
  setPrefix,
  removePrefix,
  removePrefixInArray,
  PgUnAssoc,
} from 'Providers/utils';
import {
  API_URL,
  referenceObjectLifeCycleStageIdField,
  referenceObjectLifeCycleStagePrefix,
  REST,
  scheduleIdField,
  schedulePath,
  schedulePrefix,
  scheduleTemplateIdField,
  scheduleTemplatePrefix,
  scheduleParentIdField,
  scheduleAfterIdField,
} from 'Constants';

const hierarchySort = (data, parentId = null, level = 0) => {
  const children = data
    .filter(each => each[scheduleParentIdField] === parentId)
    .sort((a, b) => a.sort_order - b.sort_order)
    .map(el => ({ ...el, level }));
  const result = [...children];
  children.forEach(child => {
    const currentIndex = result.findIndex(el => el[scheduleIdField] === child[scheduleIdField]);
    result.splice(currentIndex + 1, 0, ...hierarchySort(data, child[scheduleIdField], level + 1));
  });
  return result;
};

export const scheduleProvider = async (
  type: string,
  params: any,
  // eslint-disable-next-line consistent-return
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const { filter } = params as IRAGetListParams;
        const {
          [scheduleTemplatePrefix]: scheduleTemplate,
          [scheduleParentIdField]: parentId,
        } = filter;
        const requestBody = {
          [scheduleTemplateIdField]: removePrefix(scheduleTemplatePrefix, scheduleTemplate),
        };
        const response = await axios.post(`${API_URL}${REST.AdminSchedule.LIST}`, requestBody);
        const parsedData = hierarchySort(PgUnAssoc(get(response, schedulePath))).filter(el => {
          if (parentId !== undefined) {
            return (
              el[scheduleParentIdField] ===
              (parentId === null ? null : removePrefix(schedulePrefix, parentId))
            );
          }
          return true;
        });
        const parsedDataWithId = parsedData.map(el => ({
          ...el,
          id: setPrefix(schedulePrefix, el[scheduleIdField]),
          [scheduleTemplatePrefix]: setPrefix(scheduleTemplatePrefix, el[scheduleTemplateIdField]),
          [scheduleParentIdField]: setPrefix(schedulePrefix, el[scheduleParentIdField]),
          [referenceObjectLifeCycleStagePrefix]: setPrefix(
            referenceObjectLifeCycleStagePrefix,
            el[referenceObjectLifeCycleStageIdField],
          ),
        }));
        return {
          data: parsedDataWithId,
          total: parsedDataWithId.length,
        };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(schedulePrefix, id);
        const response = await axios.post(`${API_URL}${REST.AdminSchedule.LIST}`, {
          [scheduleIdField]: [clearId],
        });
        const parsedData = PgUnAssoc(get(response, schedulePath))[0];
        return {
          data: {
            ...parsedData,
            id: setPrefix(schedulePrefix, parsedData[scheduleIdField]),
            [scheduleTemplatePrefix]: setPrefix(
              scheduleTemplatePrefix,
              parsedData[scheduleTemplateIdField],
            ),
            [scheduleParentIdField]: setPrefix(schedulePrefix, parsedData[scheduleParentIdField]),
            [referenceObjectLifeCycleStagePrefix]: setPrefix(
              referenceObjectLifeCycleStagePrefix,
              parsedData[referenceObjectLifeCycleStageIdField],
            ),
          },
        };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const clearIds = removePrefixInArray(ids, schedulePrefix).filter(el => el !== null);
        const response = await axios.post(`${API_URL}${REST.AdminSchedule.LIST}`, {
          [scheduleIdField]: clearIds,
        });
        const parsedData = hierarchySort(PgUnAssoc(get(response, schedulePath)));
        const parsedDataWithId = parsedData.map(el => ({
          ...el,
          id: setPrefix(schedulePrefix, el[scheduleIdField]),
          [scheduleTemplatePrefix]: setPrefix(scheduleTemplatePrefix, el[scheduleTemplateIdField]),
          [scheduleParentIdField]: setPrefix(schedulePrefix, el[scheduleParentIdField]),
          [referenceObjectLifeCycleStagePrefix]: setPrefix(
            referenceObjectLifeCycleStagePrefix,
            el[referenceObjectLifeCycleStageIdField],
          ),
        }));
        return { data: parsedDataWithId };
      }
      case GET_MANY_REFERENCE: {
        const { id } = params as IRAGetManyReferenceParams;
        const clearId = removePrefix(scheduleTemplatePrefix, id);
        const response = await axios.post(`${API_URL}${REST.AdminSchedule.LIST}`, {
          [scheduleTemplateIdField]: clearId,
        });
        const parsedSchedule = hierarchySort(PgUnAssoc(get(response, schedulePath)));
        const scheduleWithId = parsedSchedule.map(el => ({
          ...el,
          [scheduleTemplatePrefix]: setPrefix(scheduleTemplatePrefix, el[scheduleTemplateIdField]),
          [scheduleParentIdField]: setPrefix(schedulePrefix, el[scheduleParentIdField]),
          id: setPrefix(schedulePrefix, el[scheduleIdField]),
          [referenceObjectLifeCycleStagePrefix]: setPrefix(
            referenceObjectLifeCycleStagePrefix,
            el[referenceObjectLifeCycleStageIdField],
          ),
        }));
        return {
          data: scheduleWithId,
          total: scheduleWithId.length,
        };
      }
      case CREATE: {
        const { data } = params as IRACreateParams;
        const {
          id,
          [scheduleTemplatePrefix]: scheduleTemplate,
          [referenceObjectLifeCycleStagePrefix]: referenceObjectLifeCycleStage,
          [scheduleParentIdField]: parentId,
          [scheduleAfterIdField]: afterId,
          ...sendData
        } = data as any;
        const requestBody = {
          ...sendData,
          [scheduleTemplateIdField]: removePrefix(scheduleTemplatePrefix, scheduleTemplate),
          [scheduleParentIdField]: parentId ? removePrefix(schedulePrefix, parentId) : null,
          [referenceObjectLifeCycleStageIdField]: removePrefix(
            referenceObjectLifeCycleStagePrefix,
            referenceObjectLifeCycleStage,
          ),
        };
        const response = await axios.post(`${API_URL}${REST.AdminSchedule.CREATE}`, requestBody);
        const parsedData = get(response, schedulePath);
        const responseMove = await axios.post(`${API_URL}${REST.AdminSchedule.MOVE}`, {
          [scheduleIdField]: parsedData[scheduleIdField],
          [scheduleParentIdField]: parsedData[scheduleParentIdField],
          [scheduleAfterIdField]: afterId ? removePrefix(schedulePrefix, afterId) : null,
        });
        const parsedMoveData = get(responseMove, schedulePath);
        return {
          data: {
            ...parsedMoveData,
            id: setPrefix(schedulePrefix, parsedMoveData[scheduleIdField]),
            [scheduleTemplatePrefix]: setPrefix(
              scheduleTemplatePrefix,
              parsedMoveData[scheduleTemplateIdField],
            ),
            [scheduleParentIdField]: setPrefix(
              schedulePrefix,
              parsedMoveData[scheduleParentIdField],
            ),
            [referenceObjectLifeCycleStagePrefix]: setPrefix(
              referenceObjectLifeCycleStagePrefix,
              parsedMoveData[referenceObjectLifeCycleStageIdField],
            ),
          },
        };
      }
      case UPDATE: {
        const { data } = params as IRAUpdateParams;
        const {
          id,
          [referenceObjectLifeCycleStagePrefix]: referenceObjectLifeCycleStage,
          [scheduleTemplatePrefix]: scheduleTemplate,
          [scheduleParentIdField]: parentId,
          [scheduleAfterIdField]: afterId,
          sort_order: sortOrder,
          child_count: childCount,
          ...sendData
        } = data as any;
        const response = await axios.post(`${API_URL}${REST.AdminSchedule.UPDATE}`, {
          ...sendData,
          [referenceObjectLifeCycleStageIdField]: removePrefix(
            referenceObjectLifeCycleStagePrefix,
            referenceObjectLifeCycleStage,
          ),
          [scheduleParentIdField]: parentId ? removePrefix(schedulePrefix, parentId) : null,
        });
        const parsedData = get(response, schedulePath);
        const responseMove = await axios.post(`${API_URL}${REST.AdminSchedule.MOVE}`, {
          [scheduleIdField]: parsedData[scheduleIdField],
          [scheduleParentIdField]: parentId ? removePrefix(schedulePrefix, parentId) : null,
          [scheduleAfterIdField]: afterId ? removePrefix(schedulePrefix, afterId) : null,
        });
        const parsedMoveData = get(responseMove, schedulePath);
        const parsedDataWithId = {
          ...parsedMoveData,
          [scheduleTemplatePrefix]: setPrefix(
            scheduleTemplatePrefix,
            parsedMoveData[scheduleTemplateIdField],
          ),
          [scheduleParentIdField]: setPrefix(schedulePrefix, parsedMoveData[scheduleParentIdField]),
          [referenceObjectLifeCycleStagePrefix]: setPrefix(
            referenceObjectLifeCycleStagePrefix,
            parsedMoveData[referenceObjectLifeCycleStageIdField],
          ),
          id: setPrefix(schedulePrefix, parsedMoveData[scheduleIdField]),
        };
        return { data: parsedDataWithId };
      }
      case DELETE: {
        const { id, previousData } = params as IRADeleteParams;
        const clearId = removePrefix(schedulePrefix, id);
        await axios.post(`${API_URL}${REST.AdminSchedule.DELETE}`, {
          [scheduleIdField]: [clearId],
        });
        return { data: previousData };
      }
      case DELETE_MANY: {
        const { ids } = params as IRADeleteManyParams;
        const clearIds = removePrefixInArray(ids, schedulePrefix);
        await axios.post(`${API_URL}${REST.AdminSchedule.DELETE}`, {
          [scheduleIdField]: clearIds,
        });
        return { data: ids };
      }
      default:
        throw new Error(`Неизвестный метод получения ${schedulePrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода  ${schedulePrefix}: ${type}`);
  }
};
