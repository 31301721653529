export enum EVENT_LOG_ACTION_TYPES {
  system = 'system',
  admin = 'admin',
}

export type EventLogType = keyof typeof EVENT_LOG_ACTION_TYPES;

export interface IOrganization {
  name_ru: string;
  organisation_id: number;
}
