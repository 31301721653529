import axios from 'axios';
import { GET_LIST, GET_MANY } from 'react-admin';
import { IRADataProviderParams, IRAGetManyParams } from 'Interfaces';
import {
  API_URL,
  REST,
  referenceCostPlanFactDataPath,
  referenceCostPlanFactPrefix,
} from 'Constants';
import { get } from 'lodash';
import { mapArrayToResource, removePrefixInArray } from '../utils';

export const referenceCostPlanFactSourceProvider = async (
  type: string,
  params: IRADataProviderParams,
): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.CostPlanFactSource.LIST}`,
          {},
        );
        const costPlanFactSource = get(response, referenceCostPlanFactDataPath);
        const parsedCostPlanFactSource = mapArrayToResource(
          costPlanFactSource,
          null,
          'CostPlanFactSource',
        );
        return { data: parsedCostPlanFactSource, total: parsedCostPlanFactSource.length };
      }
      case GET_MANY: {
        const { ids } = params as IRAGetManyParams;
        const clearIds = removePrefixInArray(ids, referenceCostPlanFactPrefix);
        const response = await axios.post(
          `${API_URL}${REST.AdminReference.CostPlanFactSource.LIST}`,
          {},
        );
        const costPlanFactSource = get(response, referenceCostPlanFactDataPath);
        const filteredCostPlanFactSource = costPlanFactSource.filter(el => clearIds.includes(el));
        const parsedCostPlanFactSource = mapArrayToResource(
          filteredCostPlanFactSource,
          null,
          'CostPlanFactSource',
        );
        return { data: parsedCostPlanFactSource };
      }
      default:
        throw new Error(`Неизвестный метод получения ${referenceCostPlanFactPrefix}: ${type}`);
    }
  } catch (error) {
    throw new Error(`Ошибка метода ${referenceCostPlanFactPrefix}-${type}`);
  }
};
