import React, { FC } from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { specialityIdField } from '../../../Constants';

export const SpecialityList: FC = props => (
  <List {...props} exporter={false}>
    <Datagrid rowClick="edit">
      <TextField source={specialityIdField} label="Id" />
      <TextField source="name" label="Наименование специальности" />
      <TextField source="code" label="Код специальности" />
    </Datagrid>
  </List>
);
