import React, { useState } from 'react';
import { addField } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { get } from 'lodash';

export interface ITransferListItem {
  id: string;
  name: string;
}

export interface ITransferListStyle {
  root: {};
  paper: {};
  button: {};
  listContainer: {};
}

const styles = {
  root: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    width: 300,
    height: 350,
    overflow: 'auto',
  },
  button: {
    margin: '10px',
  },
  listContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '30px',
  },
};

function not(a: ITransferListItem[], b: ITransferListItem[]): ITransferListItem[] {
  const bId: string[] = b.map((el: ITransferListItem) => el.id);
  return a.filter((value: ITransferListItem) => !bId.includes(value.id));
}

function intersection(a: ITransferListItem[], b: ITransferListItem[]): ITransferListItem[] {
  const bId: string[] = b.map((el: ITransferListItem) => el.id);
  return a.filter((value: ITransferListItem) => bId.includes(value.id));
}

const TransferListComponent = ({ data, onClose, classes, input }): JSX.Element => {
  const { name, onChange } = input;
  const initAll: ITransferListItem[] = get(data, '[0]', []);
  const initRight: string[] = get(data, '[1]', []);
  const filteredLeft: ITransferListItem[] = initAll.filter(el => !initRight.includes(el.id));
  const filteredRight: ITransferListItem[] = initAll.filter(el => initRight.includes(el.id));

  const [checked, setChecked] = useState<ITransferListItem[]>([]);
  const [left, setLeft] = useState<ITransferListItem[]>(filteredLeft);
  const [right, setRight] = useState<ITransferListItem[]>(filteredRight);

  const leftChecked: ITransferListItem[] = intersection(checked, left);
  const rightChecked: ITransferListItem[] = intersection(checked, right);

  const handleToggle = (value: ITransferListItem) => (): void => {
    const currentIndex = checked.findIndex((el: ITransferListItem) => el.id === value.id);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleAllRight = (): void => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = (): void => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = (): void => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = (): void => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const upItemInRight = (e, item: ITransferListItem): void => {
    e.stopPropagation();
    const index = right.findIndex(el => el.id === item.id);
    if (index !== -1 && index !== 0) {
      const newRight = [].concat(
        right.slice(0, index - 1),
        [right[index]],
        [right[index - 1]],
        right.slice(index + 1),
      );
      setRight(newRight);
    }
  };

  const downItemInRight = (e, item: ITransferListItem): void => {
    e.stopPropagation();
    const index = right.findIndex(el => el.id === item.id);
    if (index !== -1 && index !== right.length - 1) {
      const newRight = [].concat(
        right.slice(0, index),
        [right[index + 1]],
        [right[index]],
        right.slice(index + 2),
      );
      setRight(newRight);
    }
  };

  const customList = (items: ITransferListItem[], isControl: boolean): JSX.Element => (
    <Paper className={classes.paper}>
      <List dense role="list">
        {items.map((value: ITransferListItem) => {
          const labelId = `transfer-list-item-${value.id}-label`;
          return (
            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={!!checked.find((el: ITransferListItem) => el.id === value.id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
              {isControl && (
                <>
                  <IconButton onClick={(e): void => upItemInRight(e, value)}>
                    <ArrowDropUpIcon />
                  </IconButton>
                  <IconButton onClick={(e): void => downItemInRight(e, value)}>
                    <ArrowDropDownIcon />
                  </IconButton>
                </>
              )}
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid container justify="center" alignItems="center" className={classes.root}>
      <div className={classes.listContainer}>
        <Grid item>{customList(left, false)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList(right, true)}</Grid>
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          onClick={(e): void => {
            onChange(right.map((el: ITransferListItem) => el.id));
            onClose(
              e,
              right.map((el: ITransferListItem) => el.id),
              name,
            );
          }}
        >
          Сохранить изменения
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          className={classes.button}
          onClick={onClose}
        >
          Отменить
        </Button>
      </div>
    </Grid>
  );
};

export const TransferList = addField(
  withStyles(styles as ITransferListStyle)(TransferListComponent),
);
