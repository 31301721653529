import axios from 'axios';
import { CREATE, DELETE, DELETE_MANY, GET_LIST, GET_ONE, UPDATE } from 'react-admin';
import {
  API_URL,
  REST,
  categoryPrefix,
  specialityDataPath,
  specialityPrefix,
  specialityIdField,
} from 'Constants';
import { get } from 'lodash';
import {
  setPrefixInArray,
  PgUnAssoc,
  setPrefix,
  removePrefix,
  removePrefixInArray,
} from '../utils';
import { IRAGetOneParams } from '../../Interfaces';

export const specialityProvider = async (type: string, params: any): Promise<any> => {
  try {
    switch (type) {
      case GET_LIST: {
        const response = await axios.post(`${API_URL}${REST.AdminSpeciality.LIST}`);
        const parsedSpeciality = PgUnAssoc(get(response, specialityDataPath));
        const specialityWithId = setPrefixInArray(
          parsedSpeciality,
          specialityPrefix,
          specialityIdField,
        );

        return { data: specialityWithId, total: specialityWithId.length };
      }
      case GET_ONE: {
        const { id } = params as IRAGetOneParams;
        const clearId = removePrefix(specialityPrefix, id);

        const response = await axios.post(`${API_URL}${REST.AdminSpeciality.LIST}`, {
          [specialityIdField]: [clearId],
        });
        const parsedSpeciality = PgUnAssoc(get(response, specialityDataPath))[0];

        return {
          data: {
            ...parsedSpeciality,
            id: setPrefix(specialityPrefix, parsedSpeciality[specialityIdField]),
          },
        };
      }
      case CREATE: {
        const { data: rawData } = params;
        const response = await axios.post(`${API_URL}${REST.AdminSpeciality.CREATE}`, {
          ...rawData,
        });
        const newSpeciality = get(response, specialityDataPath);

        return {
          data: {
            ...newSpeciality,
            id: setPrefix(specialityPrefix, newSpeciality[specialityIdField]),
          },
        };
      }
      case UPDATE: {
        const rawData = params.data;

        const response = await axios.post(`${API_URL}${REST.AdminSpeciality.UPDATE}`, {
          ...rawData,
        });

        const parsedSpeciality = get(response, specialityDataPath);
        return {
          data: {
            ...parsedSpeciality,
            id: setPrefix(specialityPrefix, parsedSpeciality[specialityIdField]),
          },
        };
      }
      case DELETE: {
        try {
          const { id, previousData } = params;
          const clearId = removePrefix(specialityPrefix, id);
          await axios.post(`${API_URL}${REST.AdminSpeciality.DELETE}`, {
            [specialityIdField]: [clearId],
          });
          return { data: previousData };
        } catch (error) {
          throw new Error(`${error.response.data.error.message}`);
        }
      }
      case DELETE_MANY: {
        try {
          const { ids } = params;
          const clearIds = removePrefixInArray(ids, specialityPrefix);
          await axios.post(`${API_URL}${REST.AdminSpeciality.DELETE}`, {
            [specialityIdField]: clearIds,
          });
          return { data: ids };
        } catch (error) {
          throw new Error(`${error.response.data.error.message}`);
        }
      }
      default: {
        throw new Error(`Неизвестный метод получения ${specialityPrefix}: ${type}`);
      }
    }
  } catch (error) {
    switch (error.message) {
      case 'object_category_in_use':
        throw new Error('Удаляемая категория используется на платформе');
      default:
        throw new Error(`Ошибка метода ${specialityPrefix}-${type}`);
    }
  }
};
