import { Filter, ReferenceArrayInput, SelectArrayInput, TextInput } from 'react-admin';
import React from 'react';
import { commonLabel, Resources } from '../../../Constants';

export const ObjectFilter = props => (
  <Filter {...props}>
    <TextInput source="name_ru" label={commonLabel.name_ru} alwaysOn />
    <TextInput source="name_en" label={commonLabel.name_en} alwaysOn />
    <ReferenceArrayInput
      style={{ minWidth: '300px' }}
      label="Организация"
      source="organisation_id"
      reference={Resources.ORGS}
      allowEmpty
    >
      <SelectArrayInput optionText="name_ru" />
    </ReferenceArrayInput>
  </Filter>
);
